import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";
import {NotificationType} from "@app/core/models/notification-type";
import {Notification} from "@app/core/models/notification.model";
import {Notificator} from "@app/core/models/notificator";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService{

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch all Notifications.
   * **/
  public getNotifications(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean): Observable<Array<Notification>>{
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }
    if (!isNullOrUndefined(deviceId)) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }
    if (!isNullOrUndefined(groupId)) {
      queryParameters = queryParameters.set('groupId', <any>groupId);
    }
    if (!isNullOrUndefined(refresh)) {
      queryParameters = queryParameters.set('refresh', <any>refresh);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Notification>>(`${this.basePath}${this.REST_URLS.notifications}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
  * Create a Notification
  *
  * @param body
  *
  **/
  public createNotification(body: Notification):Observable<Notification>{
    if (isNullOrUndefined(body)) {
      this.logger.error('Required parameter body was null or undefined when calling createNotification.');
      throw new Error('Required parameter body was null or undefined when calling createNotification.');
    }
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.post<Notification>(`${this.basePath}${this.REST_URLS.notifications}`,body,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }

  /**
   * Update Notification
   * **/
  public updateNotification(id: number, body: Notification): Observable<Notification>{
    if (isNullOrUndefined(id)) {
      this.logger.error('Required parameter body was null or undefined when calling updateNotification');
      throw new Error('Required parameter id was null or undefined when calling updateNotification');
    }
    if (isNullOrUndefined(body)) {
      this.logger.error('Required parameter body was null or undefined when calling updateNotification');
      throw new Error('Required parameter body was null or undefined when calling updateNotification');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.put<Notification>(`${this.basePath}${this.REST_URLS.notifications}/${encodeURIComponent(String(id))}`,
      body,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * DELETE NOTIFICATION
   * **/
  public deleteNotification(id:number){
    if (isNullOrUndefined(id)) {
      this.logger.error('Required parameter id was null or undefined when calling deleteNotification');
      throw new Error('Required parameter id was null or undefined when calling deleteNotification');
    }
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.delete<Notification>(`${this.basePath}${this.REST_URLS.notifications}/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * FETCH ALL NOTIFICATION TYPES AVALIABLES
   *
   **/
  public getNotificationsTypes(): Observable<Array<NotificationType>>{
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.get<Array<NotificationType>>(`${this.basePath}${this.REST_URLS.notificationTypes}`,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }
  /**
   * FETCH ALL NOTIFICATORS
   *
   **/
  public getNotificators(): Observable<Array<Notificator>>{
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.get<Array<Notificator>>(`${this.basePath}${this.REST_URLS.notificators}`,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }
}
