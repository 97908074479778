import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Event} from "@app/core/models/event.model";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";

@Pipe({
  name: 'sortEventByName'
})
export class SortEventByNamePipe implements PipeTransform {

  private pipe : NotificationTypePipe = new NotificationTypePipe();

  transform(values: Array<Event>, order?: string): Array<Event> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (this.pipe.transform(a.type) > this.pipe.transform(b.type) ? -1 : 1));
    }else{
      return values.sort((a, b) => (this.pipe.transform(a.type) > this.pipe.transform(b.type) ? 1 : -1));
    }
  }

}
