import {Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {Event} from "@app/core/models/event.model";
import {ApiService} from "@app/shared/services/api.service";
import {NGXLogger} from "ngx-logger";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IReportContext} from "@app/core/interfaces/ireport-context.interface";
import {IBundle} from "@app/core/interfaces/ibundle.interface";
import {ModelPageState, ReportGraphType} from "@app/core/types/buho-core-types";
import {AlertService} from "@app/shared/services/alert.service";
import {User} from "@app/core/models/user.model";
import {DatePipe, DOCUMENT} from "@angular/common";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {Device} from "@app/core/models/device.model";
import {Dictionary, Set} from "typescript-collections";
import {Geofence} from "@app/core/models/geofence.model";
import {IReportBundle} from "@app/core/interfaces/ireport-bundle";
import {ITableColumn} from "@app/core/interfaces/itable-column.interface";
import {Maintenance} from "@app/core/models/maintenance.model";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {Config} from "ngx-easy-table";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {KeysPipePipe} from "@app/shared/pipes/keys-pipe.pipe";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";
import {PositionAttributePipe} from "@app/core/pipes/position-attribute.pipe";

@Component({
  selector: 'app-report-event-bundle',
  templateUrl: './report-event-bundle.component.html',
  styleUrls: ['./report-event-bundle.component.css']
})
export class ReportEventBundleComponent implements OnInit, IBundle, IReportBundle {

  public state: ModelPageState = 'search';

  //Search
  public searchOptions: Array<ISearchParam> = [];

  //Reports
  public report: ReportGraphType = 'horizontal-bar';

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();


  @Input()
  public printTableSectionId: string = 'printTableSectionId';

  //DATATABLE
  public totals: any = {};
  public isLoading: boolean = false;
  public selectedRow: Event;
  public selectedPage: number = 1;
  toggleRowIndex;

  public pipe = new DatePipe('es-BO');

  /* smart table */
  public configuration: Config;
  public columns;
  //Data
  public rows: Array<any> = [];
  public temp: Array<any> = [];

  /* templates for cells */
  @ViewChild('deviceTpl')
  public deviceTpl: TemplateRef<any>;
  @ViewChild('geofenceTpl')
  public geofenceTpl: TemplateRef<any>;
  @ViewChild('maintenanceTpl')
  public maintenanceTpl: TemplateRef<any>;
  @ViewChild('attributesTemplate')
  public attributesTemplate: TemplateRef<any>;


  public keysPipe: KeysPipePipe = new KeysPipePipe();
  public keysPipeAttribute: PositionAttributePipe = new PositionAttributePipe();

  @Input()
  public limitPage: number = 10;

  private _filterDate = new DatePipe("es-BO");
  private _filterType: NotificationTypePipe = new NotificationTypePipe();

  @Input()
  public context: IReportContext;

  public graphData = [];

  /** Dependencies **/
  public devices: Array<Device> = [];
  public geofences: Array<Geofence> = [];
  public maintenances: Array<Maintenance> = [];


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  //Graph reports
  colorScheme = {
    domain: ['#1E2F23', '#6435C9', '#FBBD08', '#B5CC18', '#F2711C', '#40BCD8', '#A5673F', '#767676', '#21BA45', '#DB2828', '#E03997']
  };

  private _user: User;
  private role: any;
  public selectedGrouped: IModel;
  public groupOptions: Array<IModel>;
  public showdata: boolean;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              @Inject(DOCUMENT) private document: Document,
              private logger: NGXLogger) {

    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];

    this.loadDependencies();

    /* Search options */
    this.searchOptions = [{
      name: 'Evento',
      value: '',
      color: 'green',
      field: 'type',
      type: 'select',
      options: [],
      remote: false
    }, {
      name: 'Dispositivo',
      value: '',
      color: 'blue',
      field: 'device',
      type: 'select',
      options: [],
      remote: false
    }, {
      name: 'Geocerca',
      value: '',
      color: 'purple',
      field: 'geofence',
      type: 'select',
      options: [],
      remote: false
    }, {
      name: 'Mantenimiento',
      value: '',
      color: 'yellow',
      field: 'maintenance',
      type: 'select',
      options: [],
      remote: false
    }];
  }

  ngOnInit() {
    //table configuration
    this.configureTableData();
  }

  public configureTableData() {
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.columns = [
      {key: 'serverTime', title: 'Fecha', width: '15%'},
      {key: 'type', title: 'Tipo', width: '30%'},
      {key: 'device', title: 'Dispositivo', width: '25%'},
      {key: 'geofence', title: 'Geocerca', width: '15%'},
      {key: 'maintenance', title: 'Mantenimiento', width: '15%'},
      {key: 'attributes', title: 'Atributos',  orderEnabled: false, width: '20%'}
    ];
    this.configuration.checkboxes = false;
    this.configuration.paginationEnabled = true;
    this.configuration.paginationRangeEnabled = false;
    this.configuration.tableLayout.striped = false;
    this.configuration.groupRows = true;
    this.configuration.rows = this.limitPage;

    //group by
    this.groupOptions = [];
    //fill group by
    this.groupOptions.push({id:'type',name:'type', display:'Tipo de Evento'});
    this.groupOptions.push({id:'device',name:'device',display:'Dispositivo'});
    this.groupOptions.push({id:'geofence',name:'geofence',display:'Geocerca'});
    this.groupOptions.push({id:'maintenance',name:'maintenance',display:'Mantenimiento'});

    this.selectedGrouped=this.groupOptions[0];
  }

  public loadData(reportContext: IReportContext) {
    this.isLoading = true;
    this.context = reportContext;
    this.configureTableData();

    this.apiService.reportService.reportsEventsGet(
      this.context.from,
      this.context.to,
      this.context.deviceIds,
      this.context.groupIds,
      this.context.types).subscribe(value => {
      if (isNullOrUndefined(value)) {
        return;
      }
      this.rows = [];
      this.temp = [];
      const formatted = this.formatData(value);
      this.temp = [...formatted];
      this.rows = [...formatted];

    }, error1 => {
      this.alertService.error("Sucedio un error al cargar los datos", error1.toString());
    }, () => this.isLoading = false);
  }

  public loadDependencies() {
    //types
    this.apiService.notificationService.getNotificationsTypes().subscribe(value => {
      let options: Array<IModel> = [];
      value.forEach(type1 => {
        options.push({
          id: this._filterType.transform(type1.type),
          name: this._filterType.transform(type1.type),
          display: this._filterType.transform(type1.type),
        });
      });
      this.searchOptions[0].options = options;
    });

    //devices
    this.apiService.deviceService.getDevices(this.role == 'root').subscribe(value => {
      this.devices = value;
    },error1 => this.logger.error("Se produjo un error al cargar los dispositivos."),() => {
      //UserId
      let indexDeviceId = this.searchOptions.findIndex(value => value.field == 'device');
      let options: Array<IModel> = [];
      this.devices.forEach(device => {
        options.push({id: device.id.toString(), name: device.name, display: device.name});
      });
      if (indexDeviceId !== -1) {
        this.searchOptions[indexDeviceId].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
    //geofences
    this.apiService.geofenceService.getGeofences(this.role == 'root').subscribe(value => {
      this.geofences = value;
      //GEOFENCES SEARCH
      let index = this.searchOptions.findIndex(value => value.field == 'geofence');
      let options: Array<IModel> = [];
      value.forEach(geofence => {
        options.push({id: geofence.id.toString(), name: geofence.name, display: geofence.name});
      });
      if (index !== -1) {
        this.searchOptions[index].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
    //maintendances
    this.apiService.maintenanceService.getMaintenances(this.role == 'root').subscribe(value => {
      this.maintenances = value;
      //maintenance SEARCH
      let index = this.searchOptions.findIndex(value => value.field == 'maintenance');
      let options: Array<IModel> = [];
      value.forEach(maintenance => {
        options.push({id: maintenance.id.toString(), name: maintenance.name, display: maintenance.name});
      });
      if (index !== -1) {
        this.searchOptions[index].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
  }

  public formatData(data: Array<Event>): Array<any> {
    let formatted = [];
    let devices: Set<number> = new Set<number>();
    let geofences: Set<number> = new Set<number>();
    let maintenances: Set<number> = new Set<number>();
    let types: Set<string> = new Set<string>();
    let total: number = 0;
    this.clearTotals();

    data.forEach(event => {
      if (!isNullOrUndefined(event.deviceId))
        devices.add(+event.deviceId);
      if (!isNullOrUndefined(event.geofenceId) && event.geofenceId > 0)
        geofences.add(+event.geofenceId);
      if (!isNullOrUndefined(event.maintenanceId) && event.maintenanceId > 0)
        maintenances.add(+event.maintenanceId);
      types.add(event.type);
      formatted.push({
        serverTime: this._filterDate.transform(event.serverTime, 'dd/MM/yyyy - HH:mm'),
        serverTimeGraph: this._filterDate.transform(event.serverTime, 'yyyy-MM-ddTHH:mm:ss'),
        type: this._filterType.transform(event.type),
        deviceId: event.deviceId,
        deviceIcon: this.loadDeviceIcon(event.deviceId),
        device: this.loadDeviceName(event.deviceId),
        geofence: this.loadGeofenceName(event.geofenceId),
        maintenance: this.loadMaintenanceName(event.maintenanceId),
        attributes: this.formatAttributes(event.attributes)
      });
      total++;
    });
    this.totals = {
      devices: devices.size(),
      geofences: geofences.size(),
      maintenances: maintenances.size(),
      types: types.size(),
      total: total
    };
    return formatted;
  }

  public loadDeviceName(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].name;
      }
    }
  }

  public loadDeviceIcon(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].icon;
      }
    }
  }

  public loadGeofenceName(geofenceId: number): string {
    if (isNullOrUndefined(geofenceId)) {
      return '';
    } else {
      const index = this.geofences.findIndex(value => value.id == geofenceId);
      if (index !== -1) {
        return this.geofences[index].name;
      }
    }
  }
  public loadMaintenanceName(maintenanceId: number): string {
    if (isNullOrUndefined(maintenanceId)) {
      return '';
    } else {
      const index = this.maintenances.findIndex(value => value.id == maintenanceId);
      if (index !== -1) {
        return this.maintenances[index].name;
      }
    }
  }

  public onSearch(params: Array<ISearchParam>) {
    // const temp = this.filter.transform(this.temp, params);
    // this.rows = temp;
    //this.table.offset = 0;
    //this.configureTableData();

    let temp = this.temp;
    params.forEach(param => {
      if (param.field == 'type') {
        temp = temp.filter(value => value.type.toLowerCase().includes(param.value.toLowerCase()));
      } else if (param.field == 'device') {
        temp = temp.filter(value => value.device.toLowerCase().includes(param.value.toLowerCase()));
      } else if (param.field == 'geofence') {
        temp = temp.filter(value => {
          return isNullOrUndefined(value.geofence) ? false : value.geofence.toLowerCase().includes(param.value.toLowerCase());
        });
      }else if (param.field == 'maintenance') {
        temp = temp.filter(value => {
          return isNullOrUndefined(value.maintenance) ? false : value.maintenance.toLowerCase().includes(param.value.toLowerCase());
        });
      }
    });

    if (temp != this.rows) {
      // this.tableComponent.data = temp;
      this.rows = [...temp];
      this.recalculateTotals(temp);
      this.showdata = true;
      setTimeout( ()=> {
        this.showdata = false;
      }, 100);
    }
  }

  close(): void {
    this.onClose.emit();
  }

  public validateContext(reportContext: IReportContext, alert: boolean = false): boolean {
    if (isNullOrUndefined(reportContext)) {
      if (alert)
        this.alertService.error("Error al cargar el reporte", "No existe un contexto valido.");
      return false;
    } else {
      if (isNullOrUndefined(reportContext.from)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha inicial valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.to)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha final valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.deviceIds) || reportContext.deviceIds.length <= 0) {
        if (alert)
          this.alertService.error("Sin Dispositivos", "Debe seleccionar al menos un disposito.");
        return false;
      } else {
        return true;
      }
    }
  }

//base
  public clearTotals() {
    this.totals = {types: 0, devices: 0, geofences: 0, maintenances: 0};
  }

  public prepareGraphData() : void {
    //graphs...
    this.graphData = [];
    let dic: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();
    //fill data

    this.temp.forEach(value => {
      if (dic.containsKey(value.deviceId)) {
        //Updates
        let types = dic.getValue(value.deviceId);
        types.push(value.type);
        dic.setValue(value.deviceId, types);
      } else {
        //creates
        dic.setValue(value.deviceId, [value.type]);
      }
    });

    const devices = dic.keys();

    devices.forEach(deviceId => {
      let events = dic.getValue(deviceId);
      let series: Dictionary<string, number> = new Dictionary();
      events.forEach(value => {
        if (!series.containsKey(value))
          series.setValue(value, events.filter(value1 => value1 == value).length);
      });
      let array = [];
      series.forEach((key, value) => {
        array.push({name: key, value: value});
      });
      this.graphData.push({
        name: this.loadDeviceName(deviceId),
        series: array
      });
    });

    // this.logger.warn("GRAPH DATA -> " + JSON.stringify(this.graphData));
  }

  downloadReport(reportContext?: IReportContext) {
    if (!isNullOrUndefined(reportContext))
      this.context = reportContext;
    if (!isNullOrUndefined(this.context)) {
      if (isNullOrUndefined(this.rows) || this.rows.length == 0) {
        this.alertService.warning("Imposible Descargar", "No hay datos para descargar");
        return;
      }
      this.isLoading = true;
      this.apiService.reportService.reportsEventsGetXLSX(
        this.context.from,
        this.context.to,
        this.context.deviceIds,
        this.context.groupIds,
        this.context.types).subscribe(value => {
        if (isNullOrUndefined(value)) {
          this.alertService.error("Sucedio un error", "No puede descargarse el reporte");
          return;
        }
        //this.logger.warn("DATA DOWNLOADED "+JSON.stringify(value));
        let newBlob = new Blob([value], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.createNameFile("reporte_", ".xlsx");
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      }, error1 => {
        this.alertService.error("Sucedio un error al cargar descargar", error1.toString());
      }, () => this.isLoading = false);
    }
  }

  private createNameFile(prefix?: string, extension?: string) {
    let ans = `Eventos_${this.context.from.toISOString()}_${this.context.to.toISOString()}`;
    if (!isNullOrUndefined(prefix)) {
      ans = prefix + ans;
    }
    if (!isNullOrUndefined(extension)) {
      ans = ans + extension;
    }
    return ans;
  }

  public showBarsGraph() {
    this.prepareGraphData();
    this.report = 'horizontal-bar';
    this.state = 'new';
  }

  public showLineGraph() {
    this.prepareLineGraphData();
    this.report = 'line';
    this.state = 'new';
  }

  public prepareLineGraphData() : void {
    //graphs...
    this.graphData = [];
    let dic: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();
    let dicType: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();

    this.temp.forEach(value => {
      if (!dic.containsKey(value.deviceId)) {
        dic.setValue(value.deviceId, []);
      }
    });

    this.temp.forEach(value => {
      if (!dicType.containsKey(value.type)) {
        dicType.setValue(value.type, []);
      }
    });

    const devices = dic.keys();
    const types = dicType.keys();
    devices.forEach(deviceId => {
      let array = [];
      let count: number = 0;
      types.forEach(typeName => {
        count = this.searchTypes(typeName, deviceId);
        array.push({name: typeName, value: count});
      });

      this.graphData.push({
        name: this.loadDeviceName(deviceId),
        series: array
      });
    });

     //this.logger.warn("GRAPH DATA -> " + JSON.stringify(this.graphData));
  }

  private searchTypes(type: any, deviceId: any) : number{
    let count:number = 0;
    this.temp.forEach(value => {
      if (value.deviceId == deviceId && value.type == type ) {
        count = count + 1;
      }
    });
    return count;
  }

  public print() {
    // this.isPrintableMode = true;
    let popupWinindow;
    const printable = document.getElementById(this.printTableSectionId);
    // if(isNullOrUndefined(printable)){
    //   this.alertService.warning("Sin Datos","No hay datos para Imprimir.");
    //   return;
    // }
    let innerContents = printable.innerHTML;
    popupWinindow = window.open('', '_blank', 'top=0,left=0,height=100%,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    let styles: Array<string> = [];
    const styleElements = document.getElementsByTagName('style');
    for (let idx = 0; idx < styleElements.length; idx++) {
      styles.push(styleElements[idx].outerHTML);
    }
    popupWinindow.document.write(`<html><head><title>REPORTE DE EVENTOS. [${this.context.from.toLocaleDateString()} - ${this.context.to.toLocaleDateString()}]</title>${styles.join('\r\n')}</head><body onload="window.print();window.close();">${innerContents}</html>`);
    popupWinindow.document.close();
    // popupWinindow.document.addEventListener('onbeforeunload',event => {
    //     this.isPrintableMode = false;
    // });
  }


  private recalculateTotals(temp: Array<any>) {
    let counts: any = {};
    for (let i = 0; i < temp.length; i++) {
      counts[temp[i].device] = 1 + (counts[temp[i].device] || 0);
      counts[temp[i].geofence] = 1 + (counts[temp[i].geofence] || 0);
      counts[temp[i].maintenance] = 1 + (counts[temp[i].maintenance] || 0);
      counts[temp[i].type] = 1 + (counts[temp[i].type] || 0);
    }
    // this.logger.warn("Recalculando " + JSON.stringify(counts));
    this.totals = {
      devices: counts.devices,
      geofences: counts.geofences,
      maintenances: counts.maintenances,
      types: counts.types,
      total: temp.length
    };
  }

  expandItems($event, index: number): void {
    this.toggleRowIndex = { index };
  }

  // public openGoogleMaps() : void {
  //   if(!!this.selectedRow){
  //     this.alertService.warning("Sorry","");
  //     window.open(`https://www.google.com/maps?q=${this.selectedRow.latitude},-63.212725`, "_blank");
  //   }
  // }

  private formatAttributes(attributes: any) : string{
    let attrs = this.keysPipe.transform(attributes);
    let str :string = '';
    attrs.forEach(value => {
      let valor1 = this.keysPipeAttribute.transform(value.key);
      let valor2 = this.keysPipeAttribute.transform(value.value);
      if(valor2 != "undefined"){
        str += '{' + valor1 + ': ' + valor2 + '}';
      }
    });
    str += '';
    return str;
  }
}
