import { Pipe, PipeTransform } from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'filterDeviceBy'
})
export class FilterDeviceByPipe implements PipeTransform {

  transform(values: Array<Device>, args?: string): Array<Device> {
    if(!isNullOrUndefined(args)){
      //search first by name
      return values.filter(value => value.name.toLowerCase().includes(args.toLowerCase()));
      //|| value.uniqueId.toLowerCase().includes(args.toLowerCase())
    }else{
      return values;
    }
  }

}
