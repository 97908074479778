import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {Subscription} from "rxjs";
import {AlertService} from "@app/shared/services/alert.service";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styles: []
})
export class AlertComponent implements OnInit,OnDestroy {

  private subscription: Subscription;
  @ViewChild('alertSwal') private alertSwal: SwalComponent;

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      if(!isNullOrUndefined(message)){
        this.alertSwal.title = message.title;
        this.alertSwal.text = message.body;
        this.alertSwal.type = message.type;
        this.alertSwal.showCloseButton = false;
        this.alertSwal.showCancelButton = false;
        this.alertSwal.showConfirmButton = false;
        this.alertSwal.show();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
