import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Calendar} from "@app/core/models/calendar.model";

@Pipe({
  name: 'sortCalendarByName'
})
export class SortCalendarByNamePipe implements PipeTransform {

  transform(values: Array<Calendar>, order?: string): Array<Calendar> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (a.name > b.name ? -1 : 1));
    }else{
      return values.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }

}
