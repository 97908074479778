export declare type ModelPageState = 'search' | 'edit' | 'new' | 'select';
export declare type SearchType = 'text' | 'select' | 'multiselect' | 'date';
export declare type GeometryType = 'CIRCLE' | 'POLYGON' | 'LINESTRING';
export declare type ReportGraphType = 'line' |'area' | 'horizontal-bar'| 'vertical-bar' | 'pie' | 'bubble' | 'treemap'| 'gauge' | 'heatmap' | 'number-card';
export declare type CalendarRecurrenceMode = 'DAILY' |'WEEKLY' | 'MONTHLY' | 'YEARLY';
export declare type CalendarRepeatMode = 'FOREVER' | 'UNTIL' | 'OCCURENCE';
export declare type CalendarDay = 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';
export declare type DriverLicenceCategory = 'M' | 'T' | 'P' | 'A' | 'B' | 'C';
export declare type BloodType = 'O RH(-)' | 'O RH(+)' | 'A RH(-)' | 'A RH(+)' | 'B RH(-)' | 'B RH(+)' | 'AB RH(-)'| 'AB RH(+)';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  DOWN_ARROW = 40
}
export enum MENU_ITEMS {
  CALENDARS = 'calendars',
  LOGOUT = 'logout',
  MAINTENANCE = 'maintenance',
  NOTIFICATIONS = 'notifications',
  // ADMINISTRATION = 'administration',
  ACCOUNT = 'account',
  ACCOUNT_MENU = 'account_menu',
  EVENTS = 'events',
  ATTRIBUTES = 'attributes',
  REPORTS = 'reports',
  DEVICES = 'devices',
  GEOFENCES = 'geofences',
  DRIVERS = 'drivers',
  GROUPS = 'groups',
  USERS = 'users',
  PERMISSIONS = 'permissions',
  TUTORIALS = 'tutorials',
}
