import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./range-slider.component.css']
})
export class RangeSliderComponent implements OnInit {

  public startValue:number = 0;

  @Output()
  public startChange: EventEmitter<number> = new EventEmitter();

  @Input()
  public get start(): number{
    return this.startValue;
  }

  public set start(start:number){
    this.startValue = start;
    this.startChange.emit(this.startValue);
  }

  @Input()
  public min:number = 0;

  @Input()
  public max:number = 100;

  @Input()
  public step:number = 1;

  constructor() { }

  ngOnInit() {
  }

}
