import { Pipe, PipeTransform } from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {Group} from "@app/core/models/group.model";

@Pipe({
  name: 'filterGroupBySearchParam'
})
export class FilterGroupBySearchParamPipe implements PipeTransform {

  transform(values: Array<Group>, params: Array<ISearchParam>): Array<Group> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<Group> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }
        if(param.field == 'groupId'){
          filtered = (this.filterByGroupId(filtered,param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<Group>, name: string) {
    // console.log("FILTRADO POR NOMBRE");
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(group => {
      return group.name.toLocaleLowerCase().includes(name.toLowerCase());
    });
  }

  private filterByGroupId(values: Array<Group>, id: string) {
    // console.log("FILTRADO POR ID "+id);
    if(!values) return [];
    if(isNullOrUndefined(id)) return values;
    return values.filter(group => {
      return group.groupId == Number(id);
    });
  }

}
