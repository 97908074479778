import { Pipe, PipeTransform } from '@angular/core';
import {Dictionary} from "typescript-collections";
import {isNullOrUndefined} from "util";
@Pipe({
  name: 'notificationType'
})
export class NotificationTypePipe implements PipeTransform {

  private _mapKeys : Dictionary<string,string>;

  constructor() {
    this._mapKeys =  new Dictionary<string, string>();
    this._mapKeys.setValue('deviceOnline','AL CONECTARSE EL DISPOSITIVO');
    this._mapKeys.setValue('deviceOffline','AL DESCONECTARSE EL DISPOSITIVO');
    this._mapKeys.setValue('maintenance','AL ENTRAR A UN MANTENIMIENTO');
    this._mapKeys.setValue('ignitionOn','AL ENCENDERSE EL MOTOR');
    this._mapKeys.setValue('ignitionOff','AL APAGARSE EL MOTOR');
    this._mapKeys.setValue('alarm','AL ACTIVARSE UNA ALARMA');
    this._mapKeys.setValue('deviceOverspeed','AL EXCEDER LA VELOCIDAD MAX.');
    this._mapKeys.setValue('commandResult','AL RECIBIR UN COMANDO');
    this._mapKeys.setValue('geofenceExit','AL SALIR DE GEOCERCA');
    this._mapKeys.setValue('geofenceEnter','AL ENTRAR A GEOCERCA');
    this._mapKeys.setValue('textMessage','AL RECIBIR UN MENSAJE');
    this._mapKeys.setValue('deviceMoving','AL COMENZAR A MOVERSE');
    this._mapKeys.setValue('deviceUnknown','AL CONECTARSE UN DISPOSITIVO DESCONOCIDO');
    this._mapKeys.setValue('deviceStopped','AL DETENERSE');
    this._mapKeys.setValue('deviceFuelDrop','AL INICIAR A PERDER COMBUSTIBLE');
    this._mapKeys.setValue('driverChanged','AL CAMBIAR DE CONDUCTOR');
  }

  transform(type: string, args?:string): string {
    return this._mapKeys.getValue(type);
  }

  deTransform(type:string){
    let key = this._mapKeys.values().find(value => value.includes(type));
    if(!isNullOrUndefined(key)){
      return this._mapKeys.getValue(key);
    }
  }
}
