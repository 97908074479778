import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import {isNullOrUndefined} from 'util';

@Pipe({
  name: 'computatedAttributesPipe'
})
export class ComputatedAttributesPipe implements PipeTransform {

  transform(value: any, args?: AttribType): string {

    if(isNullOrUndefined(args)){
      args = "key";
    }
    if(args == "key"){
      if(value=="alarm"){
        return 'Alarma';
      }
      if(value=="type"){
        return 'Tipo';
      }
      if(value=="distance"){
        return 'Distancia';
      }
      if(value=="totalDistance"){
        return 'Dis. Recorrida';
      }
      if(value=="motion"){
        return 'En Movimiento?';
      }
    }else{
      if(value == "powerRestored"){
        return 'Energia Restablecida';
      }
      else if(value == "true"){
        return 'Si';
      }
      else if(value == "false"){
        return 'No';
      }else if (typeof value === "string"){
        return this.convertString(value);
      }else if (typeof value === "boolean"){
        return value? 'Si':'No';
      }else if(value == undefined || value == null){
        return 'Desconocido';
      }else{
        return value;
      }
    }
    return value;
  }

  private convertString(value: string):string {
    if(value.toUpperCase().includes("POWERON")){
      return 'Encendido'
    }else if(value.toUpperCase().includes("POWERON")){
      return 'Apagado'
    }else{
      return value.toUpperCase();
    }
  }
}
export declare type AttribType = 'key' | 'value' ;
