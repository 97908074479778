import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Driver} from "@app/core/models/driver.model";

@Pipe({
  name: 'sortDriverByName'
})
export class SortDriverByNamePipe implements PipeTransform {

  transform(values: Array<Driver>, order?: string): Array<Driver> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (a.name > b.name ? -1 : 1));
    }else{
      return values.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }
}
