import { Injectable } from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Observable} from "rxjs";
import {Group} from "@app/core/models/group.model";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Groups
   * Without any params, returns a list of the Groups the user belongs to
   * @param all Can only be used by admins or managers to fetch all entities
   * @param userId Standard users can use this only with their own _userId_
   */
  public getGroups(all?: boolean, userId?: number): Observable<Array<Group>>{

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");


    return this.httpClient.get<Array<Group>>(`${this.basePath}${this.REST_URLS.groups}`,
      {
        params: queryParameters,
        withCredentials: true,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Delete a Group
   *
   * @param id
   */
  public deleteGroup(id: number): Observable<any> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling deleteGroup.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.groups}/${encodeURIComponent(String(id))}`,
      {
        withCredentials: true,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * Update a Group
   *
   * @param id
   * @param body
   */
  public updateGroup(id: number, body: Group): Observable<Group> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling updateGroup.');
    }
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling updateGroup.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<Group>(`${this.basePath}${this.REST_URLS.groups}/${encodeURIComponent(String(id))}`,
      body,
      {
        withCredentials: true,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Create a Group
   *
   * @param body
   */
  public createGroup(body: Group): Observable<Group> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling createGroup.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<Group>(`${this.basePath}${this.REST_URLS.groups}`,
      body,
      {
        withCredentials: true,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }
}
