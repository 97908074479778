/***
 * project: BUHO Web Interface
 * author: Miguel Hurtado
 * */
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@env/environment';
import {AppRoutingModule} from './app-routing.module';

import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {SuiModule} from 'ng2-semantic-ui';
import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';

import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {SharedModule} from "@app/shared/shared.module";
import {AlertService} from "@app/shared/services/alert.service";
import {ApiService} from "@app/shared/services/api.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ErrorInterceptor} from "@app/shared/helpers/error.interceptor";
import {CookieService} from "ngx-cookie-service";
import {AuthGuard} from "@app/shared/guards/auth-guard";
import {AgmCoreModule, LAZY_MAPS_API_CONFIG} from "@agm/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AgmOverlays} from "agm-overlays";
import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import {AgmSnazzyInfoWindowModule} from "@agm/snazzy-info-window";
import {FilterPipeModule} from "ngx-filter-pipe";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {MomentModule} from "ngx-moment";
import 'moment/locale/es';
import {MenuItemService} from "@app/shared/services/menu-item.service";
import {MapsConfig} from "@app/maps-config";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {PropagationStopModule} from 'ngx-propagation-stop';
import {TableModule} from "ngx-easy-table";
import {CoreModule} from "@app/core/core.module";
import { ColorPickerModule } from 'ngx-color-picker';
import {AngularFireModule} from "@angular/fire";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {FCMMessagingService} from "@app/shared/services/fcmmessaging.service";
/* change language to spanish*/
registerLocaleData(localeEs, 'es');


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    NgxChartsModule,
    AppRoutingModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.OFF}),
    SuiModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      // customClass: 'content',
      confirmButtonClass: 'ui approve button',
      cancelButtonClass: 'ui cancel button'
    }),
    LoadingBarHttpClientModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyD0KBkFQN0IETQhEql6RyMmxJvRwWKsizo'
      apiKey: 'AIzaSyDlsI684jDtI1x98KdDj5QIlYU_PneU6yk'
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    BrowserAnimationsModule,
    SharedModule,
    FilterPipeModule,
    MomentModule,
    PropagationStopModule,
    TableModule,
    ColorPickerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // DeviceDetectorModule.forRoot()
  ],
  providers: [
    NGXLogger,
    CookieService,
    AlertService,
    AuthGuard,
    ApiService,
    WebConfigurationService,
    MenuItemService,
    FCMMessagingService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LAZY_MAPS_API_CONFIG, useClass: MapsConfig}
  ],
  bootstrap: [AppComponent],
  exports: [SuiModule,
    SharedModule, CoreModule]
})
export class AppModule {
}

