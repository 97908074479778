import {Injectable} from '@angular/core';
import {User} from "@app/core/models/user.model";
import {BuhoModel, RolePermission} from "@app/shared/interfaces/SharedTypes";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {Dictionary} from "typescript-collections";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private _user: User;
  private _role: RolePermission;

  private _devicePermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _notificationPermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _geofencePermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _groupPermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _driverPermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _maintenancePermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _calendarPermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _userPermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();
  private _attributesPermissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  get userPermissions(): Dictionary<string, boolean> {
    return this._userPermissions;
  }

  get calendarPermissions(): Dictionary<string, boolean> {
    return this._calendarPermissions;
  }

  get maintenancePermissions(): Dictionary<string, boolean> {
    return this._maintenancePermissions;
  }

  get devicePermissions(): Dictionary<string, boolean> {
    return this._devicePermissions;
  }

  get notificationPermissions(): Dictionary<string, boolean> {
    return this._notificationPermissions;
  }

  get geofencePermissions(): Dictionary<string, boolean> {
    return this._geofencePermissions;
  }

  get groupPermissions(): Dictionary<string, boolean> {
    return this._groupPermissions;
  }

  get driverPermissions(): Dictionary<string, boolean> {
    return this._driverPermissions;
  }

  get attributesPermissions(): Dictionary<string, boolean> {
    return this._attributesPermissions;
  }

  constructor() {
    if(isNullOrUndefined(this._user)){
      this._user = JSON.parse(sessionStorage.getItem("user")) as User;
      this._role = this._user.attributes['role'];
    }
    this.createActions();
  }

  public createActions() : void {
    this.createDeviceActions();
    this.createNotificationsActions();
    this.createGeofenceActions();
    this.createGroupActions();
    this.createDriverActions();
    this.createMaintenanceActions();
    this.createCalendarActions();
    this.createUserActions();
    this.createAttributesComputatedActions();
  }

  public set user(user: User) {
    this._user = user;
    this._role = user.attributes['role'];
    this.createActions();
  }

  public clearUser(): void{
    this._user = undefined;
    this._role = 'monitor';
  }

  public get role(): RolePermission {
    return this._role;
  }

  public checkRole(action: RolePermission, model: BuhoModel): boolean {
    if (isNullOrUndefined(this._user))
      return false;
    switch (model) {
      case "device":
        return this.checkRoleDevices(action);
      case "notification":
        return this.checkRoleNotifications(action);
      case "geofence":
        return this.checkRoleGeofences(action);
      default:
        return false;
    }
  }

  public checkRoleDevices(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      this._user = LocalService.currentUser;
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root' && this._user.administrator)
      return true;
    const aAction = this._user.attributes[`device-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'device':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'attributes':
        return this._role == 'administrator';
      case 'notification':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'geofence':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'driver':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'maintenance':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'group':
        return this._role == 'administrator';
      case 'edit':
        return this._role == 'administrator';
      case 'delete':
        return this._role == 'administrator';
      case 'create':
        return this._role == 'administrator';
      case 'accumulators':
        return this._role == 'administrator';
      case 'panel':
        return true;
      default:
        return false;
    }
  }

  public checkRoleNotifications(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`notification-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'device':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'group':
        return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
      case 'edit':
        return this._role == 'administrator';
      case 'delete':
        return this._role == 'administrator';
      case 'create':
        return this._role == 'administrator';
      case 'editType':
        return this._role == 'administrator';
      case 'all':
        return this._role == 'root';
    }
  }


  public checkRoleGeofences(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`geofence-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'device':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'group':
        return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
      case 'edit':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'delete':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'customize':
        return this._role == 'administrator';
      case 'create':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
    }
  }


  public checkRoleGroup(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`group-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'device':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'notification':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'group':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'driver':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'geofence':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'maintenance':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'attributes':
        return this._role == 'root';
      case 'edit':
        return this._role == 'administrator';
      case 'delete':
        return this._role == 'administrator';
      case 'create':
        return this._role == 'administrator';
    }
  }

  public checkRolePermissions(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`permissions-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'device':
        return this._role == 'administrator';
      case 'user':
        return this._role == 'administrator';
      case 'notification':
        return this._role == 'administrator';
      case 'group':
        return this._role == 'administrator';
      case 'driver':
        return this._role == 'administrator';
      case 'geofence':
        return this._role == 'administrator';
      case 'maintenance':
        return this._role == 'administrator';
      case 'attribute':
        return this._role == 'root';
      case 'attributes':
        return this._role == 'root';
      case 'calendar':
        return this._role == 'administrator';
    }
  }

  public checkRoleDriver(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`driver-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'create':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'edit':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'delete':
        return this._role == 'administrator';
      case 'group':
        return this._role == 'administrator';
      case 'user':
        return this._role == 'administrator';
      case 'device':
        return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
    }
  }

  public checkRoleMaintenance(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`maintenance-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'create':
        return this._role == 'administrator';
      case 'edit':
        return this._role == 'administrator';
      case 'delete':
        return this._role == 'administrator';
      case 'group':
        return this._role == 'administrator';
      case 'user':
        return this._role == 'administrator';
      case 'device':
        return this._role == 'administrator';
      // case 'attribute':
      //   return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
    }
  }

  public checkRoleCalendar(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`calendar-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'create':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'edit':
        return this._role == 'administrator' ? true : this._role == 'supervisor';
      case 'delete':
        return this._role == 'administrator';
      case 'user':
        return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
    }
  }

  public checkRoleUser(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`user-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'password':
        return this._role == 'administrator';
      case 'create':
        return this._role == 'administrator';
      case 'edit':
        return this._role == 'administrator';
      case 'delete':
        return this._role == 'administrator';
      case 'role':
        return this._role == 'administrator';
      //campos habilitados
      case 'userLimit':
        return this._role == 'root';
      case 'deviceLimit':
        return this._role == 'root';
      case 'readonly':
        return this._role == 'root';
      case 'deviceReadonly':
        return this._role == 'root';
      case 'permission':
        return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
    }
  }

  public checkRoleAttributes(action: RolePermission): boolean {
    if (isNullOrUndefined(this._user)) {
      // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
      this._user = LocalService.currentUser;
      if (isNullOrUndefined(this._user))
        return false;
    }
    if (this._role == 'root')
      return true;
    const aAction = this._user.attributes[`attributes-${action}`];
    if (!isNullOrUndefined(aAction)) {
      return aAction;
    }
    switch (action) {
      case 'create':
        return this._role == 'administrator';
      case 'edit':
        return this._role == 'administrator';
      case 'delete':
        return this._role == 'administrator';
      case 'group':
        return this._role == 'administrator';
      case 'user':
        return this._role == 'administrator';
      case 'device':
        return this._role == 'administrator';
      case 'attributes':
        return this._role == 'root';
    }
  }

  public getRoles(all?: boolean): Array<IModel> {
    if (all)
      return [
        {id: 'monitor', name: 'monitor', display: 'Monitor'},
        {id: 'supervisor', name: 'supervisor', display: 'Supervisor'},
        {id: 'administrator', name: 'administrator', display: 'Administrador'},
        {id: 'root', name: 'root', display: 'Super Usuario'}
        ];
    else
      return [
        {id: 'monitor', name: 'monitor', display: 'Monitor'},
        {id: 'supervisor', name: 'supervisor', display: 'Supervisor'},
        {id: 'administrator', name: 'administrator', display: 'Administrador'}
        ];
  }

  private createDeviceActions():void {
    this._devicePermissions.setValue('device', this.checkRoleDevices('device'));
    this._devicePermissions.setValue('attributes', this.checkRoleDevices('attributes'));
    this._devicePermissions.setValue('notification', this.checkRoleDevices('notification'));
    this._devicePermissions.setValue('geofence', this.checkRoleDevices('geofence'));
    this._devicePermissions.setValue('maintenance', this.checkRoleDevices('maintenance'));
    this._devicePermissions.setValue('group', this.checkRoleDevices('group'));
    this._devicePermissions.setValue('edit', this.checkRoleDevices('edit'));
    this._devicePermissions.setValue('delete', this.checkRoleDevices('delete'));
    this._devicePermissions.setValue('create', this.checkRoleDevices('create'));
    this._devicePermissions.setValue('panel', this.checkRoleDevices('panel'));
    this._devicePermissions.setValue('driver', this.checkRoleDevices('driver'));
    this._devicePermissions.setValue('accumulators', this.checkRoleDevices('accumulators'));
  }

  private createNotificationsActions():void {
    this._notificationPermissions.setValue('device', this.checkRoleNotifications('device'));
    this._notificationPermissions.setValue('group', this.checkRoleNotifications('group'));
    this._notificationPermissions.setValue('attributes', this.checkRoleNotifications('attributes'));
    this._notificationPermissions.setValue('edit', this.checkRoleNotifications('edit'));
    this._notificationPermissions.setValue('delete', this.checkRoleNotifications('delete'));
    this._notificationPermissions.setValue('create', this.checkRoleNotifications('create'));
    this._notificationPermissions.setValue('editType', this.checkRoleNotifications('editType'));
    this._notificationPermissions.setValue('all', this.checkRoleNotifications('all'));
  }

  private createGeofenceActions(): void{
    this._geofencePermissions.setValue('device', this.checkRoleGeofences('device'));
    this._geofencePermissions.setValue('group', this.checkRoleGeofences('group'));
    this._geofencePermissions.setValue('create', this.checkRoleGeofences('create'));
    this._geofencePermissions.setValue('edit', this.checkRoleGeofences('edit'));
    this._geofencePermissions.setValue('delete', this.checkRoleGeofences('delete'));
    this._geofencePermissions.setValue('customize', this.checkRoleGeofences('customize'));
    this._geofencePermissions.setValue('attributes', this.checkRoleGeofences('attributes'));
    this._geofencePermissions.setValue('all', this.checkRoleGeofences('all'));
    // this._geofencePermissions.setValue('', this.checkRoleGeofences(''));
  }

  private createGroupActions(): void {
    this._groupPermissions.setValue('device', this.checkRoleGroup('device'));
    this._groupPermissions.setValue('notification', this.checkRoleGroup('notification'));
    this._groupPermissions.setValue('group', this.checkRoleGroup('group'));
    this._groupPermissions.setValue('driver', this.checkRoleGroup('driver'));
    this._groupPermissions.setValue('geofence', this.checkRoleGroup('geofence'));
    this._groupPermissions.setValue('maintenance', this.checkRoleGroup('maintenance'));
    this._groupPermissions.setValue('attributes', this.checkRoleGroup('attributes'));
    this._groupPermissions.setValue('edit', this.checkRoleGroup('edit'));
    this._groupPermissions.setValue('delete', this.checkRoleGroup('delete'));
    this._groupPermissions.setValue('create', this.checkRoleGroup('create'));
  }

  private createDriverActions(): void {
    this._driverPermissions.setValue('device', this.checkRoleDriver('device'));
    this._driverPermissions.setValue('group', this.checkRoleDriver('group'));
    this._driverPermissions.setValue('user', this.checkRoleDriver('user'));
    this._driverPermissions.setValue('attributes', this.checkRoleDriver('attributes'));
    this._driverPermissions.setValue('create', this.checkRoleDriver('create'));
    this._driverPermissions.setValue('edit', this.checkRoleDriver('edit'));
    this._driverPermissions.setValue('delete', this.checkRoleDriver('delete'));
  }


  private createMaintenanceActions() : void {
    this._maintenancePermissions.setValue('device', this.checkRoleMaintenance('device'));
    this._maintenancePermissions.setValue('attributes', this.checkRoleMaintenance('attributes'));
    this._maintenancePermissions.setValue('user', this.checkRoleMaintenance('user'));
    this._maintenancePermissions.setValue('group', this.checkRoleMaintenance('group'));
    this._maintenancePermissions.setValue('create', this.checkRoleMaintenance('create'));
    this._maintenancePermissions.setValue('edit', this.checkRoleMaintenance('edit'));
    this._maintenancePermissions.setValue('delete', this.checkRoleMaintenance('delete'));
  }


  private createCalendarActions(): void {
    this._calendarPermissions.setValue('user', this.checkRoleCalendar('user'));
    this._calendarPermissions.setValue('create', this.checkRoleCalendar('create'));
    this._calendarPermissions.setValue('edit', this.checkRoleCalendar('edit'));
    this._calendarPermissions.setValue('delete', this.checkRoleCalendar('delete'));
    this._calendarPermissions.setValue('attributes', this.checkRoleCalendar('attributes'));

  }

  private createUserActions() : void {
    //actions for administrators.
    this._userPermissions.setValue('permission', this.checkRoleUser('permission'));
    this._userPermissions.setValue('password', this.checkRoleUser('password'));
    this._userPermissions.setValue('attributes', this.checkRoleUser('attributes'));
    this._userPermissions.setValue('role', this.checkRoleUser('role'));
    //fields only for administrators.
    this._userPermissions.setValue('userLimit', this.checkRoleUser('userLimit'));
    this._userPermissions.setValue('deviceLimit', this.checkRoleUser('deviceLimit'));
    this._userPermissions.setValue('readonly', this.checkRoleUser('readonly'));
    this._userPermissions.setValue('deviceReadonly', this.checkRoleUser('deviceReadonly'));
    //actions
    this._userPermissions.setValue('create', this.checkRoleUser('create'));
    this._userPermissions.setValue('edit', this.checkRoleUser('edit'));
    this._userPermissions.setValue('delete', this.checkRoleUser('delete'));
  }
  private createAttributesComputatedActions() : void {
    //actions for administrators.
    this._attributesPermissions.setValue('device', this.checkRoleAttributes('device'));
    this._attributesPermissions.setValue('attributes', this.checkRoleAttributes('attributes'));
    this._attributesPermissions.setValue('user', this.checkRoleAttributes('user'));
    this._attributesPermissions.setValue('group', this.checkRoleAttributes('group'));
    this._attributesPermissions.setValue('create', this.checkRoleAttributes('create'));
    this._attributesPermissions.setValue('edit', this.checkRoleAttributes('edit'));
    this._attributesPermissions.setValue('delete', this.checkRoleAttributes('delete'));
  }
}
