import { Pipe, PipeTransform } from '@angular/core';
import {Maintenance} from "@app/core/models/maintenance.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {PositionAttributePipe} from "@app/core/pipes/position-attribute.pipe";
import {AttributeComputated} from "@app/core/models/attribute-computated.model";

/*@Pipe({
  name: 'filterMaintenanceBySearchParam'
})*/
export class FilterAttributesBySearchParamPipe implements PipeTransform {

  //private pipe: PositionAttributePipe = new PositionAttributePipe();

  transform(values: Array<AttributeComputated>, params: Array<ISearchParam>): Array<AttributeComputated> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<Maintenance> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<AttributeComputated>, name: string) {
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(attribute => {
      return attribute.description.toLowerCase().includes(name.toLowerCase());
    });
  }


}
