import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import es from "ng2-semantic-ui/locales/es";
import {SuiLocalizationService} from 'ng2-semantic-ui';
import {SuiSelect} from 'ng2-semantic-ui/dist/modules/select/components/select';
import {Subject} from "rxjs";

@Component({
  selector: 'app-search-top-bar',
  templateUrl: './search-top-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./search-top-bar.component.css']
})
export class SearchTopBarComponent implements OnInit, AfterViewInit {

  /* STATE OF THE PAGE */
  stateValue: ModelPageState = "search";

  /* OPTIONS TEMPLATE ON STATE = SEARCH */
  @Input()
  searchOptionsTemplate: TemplateRef<any>;

  @Input()
  isInverted: boolean = false;
  //if its set to true, will by pass state evaluation and sents the state to on onCloseEvent
  @Input()
  bypassClose: boolean = false;

  /* OPTIONS TO SAVE/UPDATE. STATE = NEW / EDIT */
  @Input()
  actionOptionsTemplate: TemplateRef<any>;

  @Input()
  actionSaveSearchTemplate: TemplateRef<any>;

  /* SHOW ON THE MIDDLE. STATE = NEW / EDIT */
  @Input()
  newEditTemplate: TemplateRef<any>;
  /* SHOW INFO TEMPLATE */
  @Input()
  infoTemplate: TemplateRef<any>;
  /* TEMPLATE PARA OPCIONES AL SELECCIONAR UN ELEMENTO */
  @Input()
  selectOptionsTemplate: TemplateRef<any>;

  /* EVENTS */
  @Output()
  onClose: EventEmitter<any> = new EventEmitter();
  @Output()
  onCancel: EventEmitter<any> = new EventEmitter();

  @Output()
  onSearch: EventEmitter<Array<ISearchParam>> = new EventEmitter<Array<ISearchParam>>();

  /* Search Panel */
  isSearchPanelCollapsed: boolean;

  /* Search options */
  searchInputText: string = '';
  searchInputTextChanged: Subject<string> = new Subject<string>();

  selectedFilter: Array<ISearchParam> = [];

  @Input()
  searchOptions: Array<ISearchParam>;

  //@Input()
  selectedSearchParam: ISearchParam;

  selectedInputValue: IModel;

  // searchDate: Date;

  // @Input()
  // pickerMode : DatePickerMode = 'datetime';
  //
  // @ViewChild("searchInput") searchInput: ElementRef;

  @Output()
  stateChange = new EventEmitter<ModelPageState>();

  // filterDate = new DatePipe("es");

  @Input()
  hideSearchBar: boolean = false;

  @Input()
  get state() {
    return this.stateValue;
  }

  set state(val: ModelPageState) {
    this.stateValue = val;
    this.stateChange.emit(this.stateValue);
  }

  @ViewChild(SuiSelect)
  public searchOptionsSelect: SuiSelect<any, any>;

  //limit how many selections you can choose..
  @Input()
  public limitOptions: number = -1;
  //
  @Input()
  public searchOnClear: boolean = false;

  constructor(private readonly cdr: ChangeDetectorRef,
              public localizationService: SuiLocalizationService) {
    this.localizationService.load("es", es);
    this.localizationService.setLanguage("es");
    this.searchInputTextChanged.subscribe(value => this.searchInputText = value);
  }

  ngOnInit() {
    if (isNullOrUndefined(this.selectedSearchParam) && !isNullOrUndefined(this.searchOptions)) {
      this.selectedSearchParam = this.searchOptions[0];
    }
    // this.searchInput.nativeElement.focus();
  }


  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  /* search options */
  addSearchParam() {
    if (this.searchInputText.length > 0) {
      let newSearch: ISearchParam = {
        name: this.selectedSearchParam.name,
        value: this.searchInputText,
        color: this.selectedSearchParam.color,
        field: this.selectedSearchParam.field,
        type: this.selectedSearchParam.type,
        remote: this.selectedSearchParam.remote
      };
      //Add new param
      let index = this.selectedFilter.findIndex(value => value.field === newSearch.field && value.value === newSearch.value);
      if (index === -1) { //no existe
        if (this.limitOptions === -1) {
          this.selectedFilter.push(newSearch);
          this.doSearch();
        } else {
          if (this.limitOptions <= this.selectedFilter.length) {
            this.selectedFilter.splice(0, 1);
          }
          this.selectedFilter.push(newSearch);
          this.doSearch();
        }
      } else {//existe
        this.selectedFilter[index].value = newSearch.value;
        let indexS = this.searchOptions.findIndex(value => value.field == newSearch.field);
        if (indexS !== -1)
          this.selectedSearchParam = this.searchOptions[indexS];
      }

      // this.cdr.detectChanges();
    }
  }

  removeSearchParam(param: ISearchParam) {
    let index = this.selectedFilter.indexOf(param);
    if (index !== -1) {
      this.selectedFilter.splice(index, 1);
      this.doSearch();
    }
  }

  editSearchParam(param: ISearchParam) {
    let index = this.selectedFilter.findIndex(value => value.field == param.field && value.value == param.value);
    if (index !== -1) {
      if (this.selectedSearchParam.type == 'select') {
        this.selectedInputValue = this.selectedFilter[index].selectValue;
      }
      // else if(this.selectedSearchParam.type == 'date'){
      //   this.searchDate = new Date(this.selectedFilter[index].value);
      // }
      else {
        this.searchInputTextChanged.next(this.selectedFilter[index].value);
      }

      let indexS = this.searchOptions.findIndex(value => value.field == param.field);
      if (indexS !== -1)
        this.selectedSearchParam = this.searchOptions[indexS];

      // this.cdr.detectChanges();
    }
  }

  clearSearchParams(): void {
    this.selectedFilter.splice(0, this.selectedFilter.length);
    if (this.searchOnClear) {
      this.onSearch.emit(this.selectedFilter);
    }
  }

  /* regular options */
  close() {
    if (this.bypassClose) {
      // Cerrar ventana para el componente Attribute Compact
      if(this.state === 'new' || this.state === 'edit'){
        this.state = 'search';
      }else{
        this.onClose.emit(this.state);
      }
    } else {
      if (this.state === 'search') {
        this.onClose.emit();
        this.state = "search";
      } else if (this.state === "new") {
        // this.state = "search";
        this.onCancel.emit();
      } else if (this.state === "edit") {
        // this.state = "search";
        this.onCancel.emit();
      } else if (this.state === "select") {
        this.onClose.emit();
        this.state = "search";
      } else {
        // this.state = "search";
        this.onCancel.emit();
      }
    }
  }

  /* when the user selects an item to search */
  public prepareSelectOption(selected: ISearchParam) {
    if (this.selectedSearchParam.type === "select") {
      let newSearch: ISearchParam = {
        name: this.selectedSearchParam.name,
        value: this.selectedInputValue.name,
        color: this.selectedSearchParam.color,
        field: this.selectedSearchParam.field,
        type: this.selectedSearchParam.type,
        selectValue: this.selectedInputValue,
        remote: this.selectedSearchParam.remote
      };
      //Add new param
      let index = this.selectedFilter.findIndex(value => value.field === newSearch.field && value.selectValue === newSearch.selectValue);
      if (index === -1) { //no existe
        this.selectedFilter.push(newSearch);
        this.doSearch();
      } else {
        this.selectedFilter[index].value = newSearch.value;
        this.selectedFilter[index].selectValue = newSearch.selectValue;
        let indexS = this.searchOptions.findIndex(value => value.field == newSearch.field);
        if (indexS !== -1)
          this.selectedSearchParam = this.searchOptions[indexS];
      }
    }
  }

  public doSearch() {
    // this.cdr.detectChanges();
    this.onSearch.emit(this.selectedFilter);
    // this.searchInput.nativeElement.focus();
  }

  /* Date Search Actions */
  // todaySearchDate() {
  //   this.searchDate = new Date();
  // }
  //
  // unsetSearchDate() {
  //   this.searchDate = undefined;
  // }

  // prepareDateOption(date:Date) {
  //   if(this.selectedSearchParam.type === "date" && !isNullOrUndefined(date) && date.toString() != 'Invalid Date'){
  //     let newSearch: ISearchParam = {
  //       name: this.selectedSearchParam.name,
  //       value: this.filterDate.transform(date,'short'),
  //       color: this.selectedSearchParam.color,
  //       field: this.selectedSearchParam.field,
  //       type: this.selectedSearchParam.type,
  //       remote: this.selectedSearchParam.remote
  //     };
  //     //Add new param
  //     let index = this.selectedFilter.findIndex(value => value.field === newSearch.field && value.value === newSearch.value);
  //     if (index === -1) { //no existe
  //       this.selectedFilter.push(newSearch);
  //       this.doSearch();
  //     } else {
  //       this.selectedFilter[index].value = newSearch.value;
  //       this.selectedSearchParam = this.selectedFilter[index];
  //     }
  //   }
  // }

  updateSearchOptions(searchOptions: Array<ISearchParam>) {
    if (!isNullOrUndefined(searchOptions)) {
      this.searchOptions = searchOptions;
      if (!isNullOrUndefined(this.searchOptionsSelect))
        this.searchOptionsSelect.options = this.searchOptions;
      // this.cdr.detectChanges();
    }
  }

  changeInputText(text: string) {
    this.searchInputTextChanged.next(text);
  }
}
