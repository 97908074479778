import {Directive, ElementRef, OnChanges} from '@angular/core';

@Directive({
  selector: '[appFocusInput]'
})
export class FocusInputDirective implements OnChanges {

  private el:ElementRef;

  constructor(el: ElementRef) {
    this.el = el;
  }

  ngOnChanges() {
      this.el.nativeElement.focus();
  }


}
