import {Pipe, PipeTransform} from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {Geofence} from "@app/core/models/geofence.model";
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'filterGeofenceBySearchParam'
})
export class FilterGeofenceBySearchParamPipe implements PipeTransform {

  transform(values: Array<Geofence>, params: Array<ISearchParam>): Array<Geofence> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<Geofence> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }else if(param.field == 'description'){
            filtered = (this.filterByDescription(filtered,param.value));
        } else if(param.field == 'calendarId'){
            filtered = (this.filterByCalendarId(filtered,param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<Geofence>, name: string) {
    // console.log("FILTRADO POR NOMBRE");
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(geofence => {
      return geofence.name.toLocaleLowerCase().includes(name.toLowerCase());
    });
  }

  private filterByDescription(values: Array<Geofence>, descr: string) {
    if(!values) return [];
    if(isNullOrUndefined(descr)) return values;
    return values.filter(geofence => {
      return geofence.description.toLocaleLowerCase().includes(descr.toLowerCase());
    });
  }

  private filterByCalendarId(values: Array<Geofence>, calendarId: string) {
    if(!values) return [];
    if(isNullOrUndefined(calendarId)) return values;
    // console.log(`CALENDARIO: ${calendarId}`);
    return values.filter(geofence => {
      return Number(geofence.calendarId) == Number(calendarId);
    });
  }
}
