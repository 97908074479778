import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Geofence} from "@app/core/models/geofence.model";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {IReportType} from "@app/core/interfaces/ireport-type.interface";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {Device} from "@app/core/models/device.model";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {IReportContext} from "@app/core/interfaces/ireport-context.interface";
import {ReportTypePickerComponent} from "@app/core/components/report-type-picker/report-type-picker.component";
import {AlertService} from "@app/shared/services/alert.service";
import {ReportEventBundleComponent} from "@app/core/components/report-event-bundle/report-event-bundle.component";
import {ReportRouteBundleComponent} from "@app/core/components/report-route-bundle/report-route-bundle.component";
import {ReportStopBundleComponent} from "@app/core/components/report-stop-bundle/report-stop-bundle.component";
import {ReportTripBundleComponent} from "@app/core/components/report-trips-bundle/report-trip-bundle.component";
import {ReportSummary} from "@app/core/models/report-summary.model";
import {ReportSummaryBundleComponent} from "@app/core/components/report-summary-bundle/report-summary-bundle.component";

@Component({
  selector: 'app-report-bundle',
  templateUrl: './report-bundle.component.html',
  styleUrls: ['./report-bundle.component.css'],
})
export class ReportBundleComponent implements OnInit {


  @Output()
  public onClose: EventEmitter<Geofence> = new EventEmitter();

  public reportTypes: Array<IReportType> = [];

  public state: ModelPageState = "new";

  //REPORTS Container
  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild('formContext', {read: ViewContainerRef}) formContext: ViewContainerRef;
  //to keep track of elements.
  // public componentsReferences = [];

  //Reports - VISIBILITY
  public isVisibleReport: boolean = false;
  //CONTEXT TO MAKE REPORT
  public reportType: IReportType;
  //CURRENT DEVICES
  public devices: Array<Device>;

  // @ViewChild(ReportTypePickerComponent)
  // private appReportForm: ReportTypePickerComponent;

  public showTypes: boolean = false;

  public reportLimitPerPage: number;

  public lastContext: IReportContext = {from: null, to: new Date(), deviceIds: [], groupIds: [], types: []};

  private currentReportForm: ReportTypePickerComponent;

  constructor(private configService: WebConfigurationService,
              private alertService: AlertService,
              private cfr: ComponentFactoryResolver) {
    this.createReportTypes();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.configService.configuration.reportPagination))
      this.reportLimitPerPage = this.configService.configuration.reportPagination;
    this.configService.change.subscribe(val => {
      if (!isNullOrUndefined(val)) {
        // this.configuration = val;
        this.reportLimitPerPage = val.reportPagination;
      }
    });
  }

  public createReportTypes() {
    this.reportTypes = [{
      name: "Eventos",
      type: "events",
      icon: "huge bolt"
    }, {
      name: "Rutas",
      type: "routes",
      icon: "huge road"
    }, {
      name: "Paradas",
      type: "stops",
      icon: "huge map signs"
    }, {
      name: "Viajes",
      type: "trips",
      icon: "huge h square"
    }, {
      name: "Resumén",
      type: "summary",
      icon: "huge stopwatch"
    }];

  }

  public selectReportType(type: IReportType) {
    this.reportType = type;
    this.showTypes = this.reportType.type == "events";
    this.state = 'edit';
    this.createContextForm();
  }

  public makeReport() {
    if (!isNullOrUndefined(this.reportType)) {
      this.lastContext = this.currentReportForm.getData();
      if(this.validateContext()) {
        //remove context form
        this.removeContextForm();
        switch (this.reportType.type) {
          case 'events':
            this.createEventReport();
            break;
          case 'routes':
            this.createRouteReport();
            break;
          case 'stops':
            this.createStopsReport();
            break;
          case 'trips':
            this.createTripsReport();
            break;
          case 'summary':
            this.createSummaryReport();
            break;
        }
      }else{
        this.alertService.error("Faltan campos Requeridos", "Verifique que los datos \nhayan sido llenados correctamente");
      }
    } else {
      this.state = "new";
      this.removeContextForm();
    }
  }

  public close() {
    if (this.state == 'new') {
      //clear form data
      this.onClose.emit();
    } else if (this.state == 'edit') {
      this.state = "new";
      this.removeContextForm();
    } else {
      this.goToPicker();
    }
  }

  public goToPicker() {
    this.isVisibleReport = false;
    this.state = 'edit';
    this.createContextForm();
  }

  private createEventReport(): void {
    let componentFactory = this.cfr.resolveComponentFactory(ReportEventBundleComponent);
    let componentRef: ComponentRef<ReportEventBundleComponent> = this.container.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    //LLENO LA DATA NECESARIA PARA GENERAR AL REPORTE
    currentComponent.limitPage = this.reportLimitPerPage;
    currentComponent.loadData(this.lastContext);
    currentComponent.onClose.subscribe(x => {
      this.isVisibleReport = false;
      this.removeReport();
      this.state = 'edit';//return to the form.
      this.createContextForm();
    });

    this.isVisibleReport = true;
  }

  private createRouteReport(): void {
    let componentFactory = this.cfr.resolveComponentFactory(ReportRouteBundleComponent);
    let componentRef: ComponentRef<ReportRouteBundleComponent> = this.container.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    //LLENO LA DATA NECESARIA PARA GENERAR AL REPORTE
    currentComponent.limitPage = this.reportLimitPerPage;
    currentComponent.loadData(this.lastContext);
    currentComponent.onClose.subscribe(x => {
      this.isVisibleReport = false;
      this.removeReport();
      this.state = 'edit';//return to the form.
      this.createContextForm();
    });

    this.isVisibleReport = true;
  }

  private createStopsReport(): void {
    let componentFactory = this.cfr.resolveComponentFactory(ReportStopBundleComponent);
    let componentRef: ComponentRef<ReportStopBundleComponent> = this.container.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    //LLENO LA DATA NECESARIA PARA GENERAR AL REPORTE
    currentComponent.limitPage = this.reportLimitPerPage;
    currentComponent.loadData(this.lastContext);
    currentComponent.onClose.subscribe(x => {
      this.isVisibleReport = false;
      this.removeReport();
      this.state = 'edit';//return to the form.
      this.createContextForm();
    });

    this.isVisibleReport = true;
  }

  private createTripsReport(): void {
    let componentFactory = this.cfr.resolveComponentFactory(ReportTripBundleComponent);
    let componentRef: ComponentRef<ReportTripBundleComponent> = this.container.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    //LLENO LA DATA NECESARIA PARA GENERAR AL REPORTE
    currentComponent.limitPage = this.reportLimitPerPage;
    currentComponent.loadData(this.lastContext);
    currentComponent.onClose.subscribe(x => {
      this.isVisibleReport = false;
      this.removeReport();
      this.state = 'edit';//return to the form.
      this.createContextForm();
    });

    this.isVisibleReport = true;
  }

  private createSummaryReport(): void {
    let componentFactory = this.cfr.resolveComponentFactory(ReportSummaryBundleComponent);
    let componentRef: ComponentRef<ReportSummaryBundleComponent> = this.container.createComponent(componentFactory);
    let currentComponent = componentRef.instance;
    //LLENO LA DATA NECESARIA PARA GENERAR AL REPORTE
    currentComponent.limitPage = this.reportLimitPerPage;
    currentComponent.loadData(this.lastContext);
    currentComponent.onClose.subscribe(x => {
      this.isVisibleReport = false;
      this.removeReport();
      this.state = 'edit';//return to the form.
      this.createContextForm();
    });

    this.isVisibleReport = true;
  }

  private removeReport(): void {
    if (this.container.length < 1)
      return;
    // removing component from container
    this.container.clear();
  }

  private createContextForm() {
    let componentFactory = this.cfr.resolveComponentFactory(ReportTypePickerComponent);
    let componentRef: ComponentRef<ReportTypePickerComponent> = this.formContext.createComponent(componentFactory);
    this.currentReportForm = componentRef.instance;
    //LLENAMOS EL COMPONENTE
    this.currentReportForm.setData(this.lastContext);
    this.currentReportForm.showTypes = this.showTypes;

  }

  private removeContextForm() {
    if (this.formContext.length < 1)
      return;
    // removing component from container
    // this.lastContext = this.currentReportForm.getData();
    this.formContext.clear();
  }

  private validateContext() : boolean {
    return !isNullOrUndefined(this.lastContext) && !isNullOrUndefined(this.lastContext.from) && !isNullOrUndefined(this.lastContext.to)
      && !isNullOrUndefined(this.lastContext.deviceIds) && !isNullOrUndefined(this.lastContext.groupIds) &&
      (this.lastContext.groupIds.length > 0 || this.lastContext.deviceIds.length > 0);
  }
}
