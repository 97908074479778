import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, TemplateRef,
  ViewChild
} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {Geofence} from "@app/core/models/geofence.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {User} from "@app/core/models/user.model";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {FilterGeofenceBySearchParamPipe} from "@app/core/pipes/filter-geofence-by-search-param.pipe";
import {IGeofence} from "@app/core/interfaces/igeofence.interface";
import {IPosition} from "@app/core/interfaces/iposition";
import {KeysPipePipe} from "@app/shared/pipes/keys-pipe.pipe";
import {IAttribute} from "@app/core/interfaces/iattribute.interface";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Config} from "ngx-easy-table";
import {Dictionary, Set} from "typescript-collections";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {Calendar} from "@app/core/models/calendar.model";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-geofence-bundle',
  templateUrl: './geofence-bundle.component.html',
  styleUrls: ['./geofence-bundle.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfigurationTableService]
})
export class GeofenceBundleComponent implements OnInit, AfterViewInit {

  @Input()
  public device: Device;

  public currentGeofence: Geofence| any;
  public isCurrentGeofenceEditable: boolean = true;

  @Output()
  public onClose: EventEmitter<Geofence> = new EventEmitter();
  //when geofence area is updated
  @Output()
  public onGeofenceAreaUpdated: EventEmitter<IGeofence> = new EventEmitter();
  //when new geofence area is created
  @Output()
  public onGeofenceAreaCreated: EventEmitter<Geofence> = new EventEmitter();
  //when you select and want to display the geofence.
  @Output()
  public onSelectInMap: EventEmitter<Geofence> = new EventEmitter();

  @Input()
  public state: ModelPageState = "search";

  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  //* User and permissions
  private user: User;
  public role: string;
  public permissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  //USER'S GEOFENCES FROM API
  public geofences: Array<Geofence|any> = [];
  public defaultGeofences: Array<Geofence> = [];
  //DEVICE'S GEOFENCES
  public deviceGeofences: Array<Geofence> = [];
  //Calendars.
  public calendars: Array<Calendar>;

  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;

  public filter: FilterGeofenceBySearchParamPipe = new FilterGeofenceBySearchParamPipe();
  //ATTRIBUTES
  public isVisibleAttributesCompact: boolean = false;
  public filterAttributes: KeysPipePipe = new KeysPipePipe();
  private visibleIds: Array<number> = [];

  //table
  public configuration: Config;
  public columns;

  public optionsForAttributes: Array<IModel>;

  public selected: Set<number> = new Set<number>();

  @ViewChild('buttonsTpl')
  public buttonsTpl: TemplateRef<any>;

  @ViewChild('toogleTpl')
  public toogleTpl: TemplateRef<any>;

  @ViewChild('rowTpl')
  public rowTpl: TemplateRef<any>;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('alertSwal') private alertSwal: SwalComponent;

  //customize
  public defaultFillColor: string = '#287FB8';
  public defaultStrokeColorLine: string = '#21BA45';
  public defaultStrokeColorCircle: string = '#F3F3F3';
  public defaultStrokeColorPolygon: string = '#F3F3F3';
  public defaultFillOpacity: number = 0.75;
  public defaultStrokeOpacity: number = 0.9;
  public defaultStrokeWeightLine: number = 3;
  public defaultStrokeWeightCircle: number = 5;
  public defaultStrokeWeightPolygon: number = 1;


  constructor(private apiService: ApiService,
              private configService: WebConfigurationService,
              private alertService: AlertService,
              private readonly cdr: ChangeDetectorRef,
              private logger: NGXLogger) {
    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'green',
      field: 'name',
      type: 'text',
      remote: false
    }, {
      name: 'Descripcion',
      value: '',
      color: 'blue',
      field: 'description',
      type: 'text',
      remote: false
    }, {
      name: 'Calendario',
      value: '',
      color: 'pink',
      field: 'calendarId',
      type: 'select',
      remote: false
    }];
  }

  ngOnInit() {
    // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this.user = LocalService.currentUser;
    this.role = this.user.attributes['role'];
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.configuration.checkboxes = true;
    this.loadPermissions();

    if (!isNullOrUndefined(this.configService.configuration.mapOptions.visibleGeofenceIds))
      this.visibleIds = this.configService.configuration.mapOptions.visibleGeofenceIds;

    this.configService.change.subscribe(val => {
      if (!isNullOrUndefined(val)) {
        // this.configuration = val;
        this.visibleIds = val.mapOptions.visibleGeofenceIds;
      }
    });
  }

  public configureTableData() {
    if (isNullOrUndefined(this.device)) {
      this.columns = [
        {key: 'name', title: 'Nombre', cellTemplate: this.rowTpl, width: '40%'},
        {key: 'description', title: 'Descripción',  width: '40%'},
        {key: 'calendar', title: 'Calendario', width: '25%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '15%'}
      ];
      this.configuration.checkboxes = false;
    } else {
      this.columns = [
        {key: 'name', title: 'Nombre', cellTemplate: this.rowTpl, width: '30%'},
        {key: 'description', title: 'Descripción',  width: '40%'},
        {key: 'calendar', title: 'Calendario', width: '25%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '15%'},
        {key: '', title: 'Vincular', cellTemplate: this.toogleTpl, orderEnabled: false, width: '10%'}
      ];
      this.configuration.checkboxes = true;
    }

    //create options for attributes.
    this.optionsForAttributes = [{
      id: 'speedLimit',
      name: 'speedLimit',
      display: 'Velocidad Máxima (km/h)'
    },{
      id: 'polylineDistance',
      name: 'polylineDistance',
      display: 'Distancia de Polilínea (metros)'
    }];
  }

  public loadPermissions() {
    this.permissions = this.apiService.roleService.geofencePermissions;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public loadData(device?:Device) {
    // this.loadCalendars();
    if(!isNullOrUndefined(device)){
      this.device = device;
    }
    this.loadAdminSearchParam();
    this.loadGeofences();
  }

  private loadAdminSearchParam() {
    if (!isNullOrUndefined(this.user)) {
      if (this.user.administrator) {
        //UserId
        let indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'violet',
              type: 'select',
              options: options,
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
        //GROUP ID/
        let indexGroupId = this.searchOptions.findIndex(value => value.field == 'groupId');
        this.apiService.groupService.getGroups().subscribe(groups => {
          let options: Array<IModel> = [];
          groups.forEach(group => {
            options.push({id: group.id.toString(), name: group.id.toString(), display: group.name});
          });
          if (indexGroupId !== -1) {
            this.searchOptions[indexGroupId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Grupo',
              field: 'groupId',
              value: '',
              color: 'olive',
              type: 'select',
              options: options,
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
        //DEVICE ID/
        let indexDeviceId = this.searchOptions.findIndex(value => value.field == 'deviceId');
        this.apiService.deviceService.getDevices().subscribe(devices => {
          let options: Array<IModel> = [];
          devices.forEach(device => {
            options.push({id: device.id.toString(), name: device.id.toString(), display: device.name});
          });
          if (indexDeviceId !== -1) {
            this.searchOptions[indexDeviceId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Dispositivo',
              field: 'deviceId',
              value: '',
              color: 'orange',
              type: 'select',
              options: options,
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verifique sus privigelios.");
          this.logger.error(error1);
        });
      }
        //calendars
        //calendarId
        let calendarIndexId = this.searchOptions.findIndex(value => value.field == 'calendarId');
        this.apiService.calendarService.getCalendars().subscribe(calendars => {
          let options: Array<IModel> = [];
          calendars.forEach(calendar => {
            options.push({id: calendar.id.toString(), name: calendar.id.toString(), display: calendar.name});
          });
          if (calendarIndexId !== -1) {
            this.searchOptions[calendarIndexId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Calendario',
              value: '',
              color: 'pink',
              field: 'calendarId',
              type: 'select',
              options: options,
              remote: false
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verifique sus privigelios.");
          this.logger.error(error1);
        });
    }
  }

  private loadGeofences() {
    this.isLoading = true;
    if (isNullOrUndefined(this.calendars)) {
      //load calendars first
      this.apiService.calendarService.getCalendars().subscribe(value => {
        this.calendars = value;
      }, error1 => {
        this.logger.error("Error al cargar los calendarios.");
      }, () => {
        //load devices geofences first
        if (!isNullOrUndefined(this.device)) {
          this.apiService.geofenceService.getGeofences(false, null, this.device.id, null, true).subscribe(value => {
            this.deviceGeofences = value;
            this.isLoading = false;
          },error1 => {
            this.logger.error("Error al cargar las geocercas del dispositivo");
          },() => {
            //load geofences last
            this.apiService.geofenceService.getGeofences(false, null, null, null, true).subscribe(value => {
              const formatted = this.formatData(value);
              this.geofences = [...formatted];
              this.defaultGeofences = [...formatted];
            }, error1 => {
              this.logger.error(`Error al cargar las geocercas: ${error1.toString()}`);
            }, () => {
              this.configureTableData();
              this.isLoading = false;
              this.cdr.markForCheck();
            });
          });
        } else {
          this.apiService.geofenceService.getGeofences(false, null, null, null, true).subscribe(value => {
            const formatted = this.formatData(value);
            this.geofences = [...formatted];
            this.defaultGeofences = [...formatted];
          }, error1 => {
            this.logger.error(`Error al cargar las geocercas: ${error1.toString()}`);
          }, () => {
            this.configureTableData();
            this.isLoading = false;
            this.cdr.markForCheck();
          });
        }
      });
    }else{
      //load devices geofences
      if (!isNullOrUndefined(this.device)) {
        this.apiService.geofenceService.getGeofences(false, null, this.device.id, null, true).subscribe(value => {
          this.deviceGeofences = value;
        },error1 => {
          this.logger.error("Error al cargar las geocercas del dispositivo");
        },() => {
          //load geofences last
          this.apiService.geofenceService.getGeofences(false, null, null, null, true).subscribe(value => {
            const formatted = this.formatData(value);
            this.geofences = [...formatted];
            this.defaultGeofences = [...formatted];
          }, error1 => {
            this.logger.error(`Error al cargar las geocercas: ${error1.toString()}`);
          }, () => {
            this.configureTableData();
            this.isLoading = false;
            this.cdr.markForCheck();
          });
        });
      } else {
        //load geofences last
        this.apiService.geofenceService.getGeofences(false, null, null, null, true).subscribe(value => {
          const formatted = this.formatData(value);
          this.geofences = [...formatted];
          this.defaultGeofences = [...formatted];
        }, error1 => {
          this.logger.error(`Error al cargar las geocercas: ${error1.toString()}`);
        }, () => {
          this.configureTableData();
          this.isLoading = false;
          this.cdr.markForCheck();
        });
      }
    }
  }

  public loadCalendars(){
    this.apiService.calendarService.getCalendars().subscribe(value => {
      this.calendars = value;
    }, error1 => {
      this.logger.error(`Error al cargar los calendarios : ${error1.toString()}`);
    }, () => {
        this.cdr.detectChanges();
    });
  }

  private loadGeofencesForSearch(params: Array<ISearchParam>, userId?: number, deviceId?: number, groupId?: number) {
    this.isLoading = true;
    this.apiService.geofenceService.getGeofences(false, userId, deviceId, groupId).subscribe(value => {
      this.geofences = this.filter.transform(value, params);
    }, error1 => {
      this.logger.error(error1.toString());
      this.alertService.error("Acceso No Autorizado", "Verifique sus credenciales");
    }
    , () => {
      this.isLoading = false;
        this.cdr.detectChanges();
    });
  }

  private doSearch(params: Array<ISearchParam>) {

    let userId, groupId, deviceId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
        } else if (param.field == "groupId" && param.selectValue.id) {
          groupId = Number(param.selectValue.id);
        } else if (param.field == "deviceId" && param.selectValue.id) {
          deviceId = Number(param.selectValue.id);
        }
        search = true;
      }
    });
    if (search) {
      this.loadGeofencesForSearch(params, userId, deviceId, groupId);
    }
  }

  public encodeToPermissions(geofences: Array<Geofence>, deviceId?: number, userId?: number, groupId?: number): Array<any> {
    let permissions = [];
    if (!isNullOrUndefined(geofences)) {
      geofences.forEach(current => {
        if (!isNullOrUndefined(deviceId)) {
          permissions.push({
            deviceId: deviceId,
            geofenceId: current.id
          });
        } else if (!isNullOrUndefined(userId)) {
          permissions.push({
            userId: userId,
            geofenceId: current.id
          });
        } else if (!isNullOrUndefined(groupId)) {
          permissions.push({
            groupId: groupId,
            geofenceId: current.id
          });
        }
      });
    }
    return permissions;
  }

  /* actions */
  public onSearch(params: Array<ISearchParam>) {
    this.clearSelection();
    if (params.findIndex(value => value.remote == true) !== -1)
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    else {
      this.geofences = this.filter.transform(this.defaultGeofences, params);
      // this.logger.info("FILTERING: "+ JSON.stringify(params));
    }
  }

  public clearSelection() {
    this.currentGeofence = new Geofence();
    this.state = "search";
    this.geofences = this.defaultGeofences;
    // this.appSearchTopBarComponent.clearSearchParams();
    this.selected.clear();
    this.cdr.detectChanges();
  }

  public close() {
    this.currentGeofence = new Geofence();
    this.appSearchTopBarComponent.clearSearchParams();
    this.clearSelection();
    this.device = null;
    this.onClose.emit(this.currentGeofence);
  }

  public toogleForDevice(id: number, state: boolean) {
    if (state) {//existe..
      this.apiService.permissionService.permissionsPost({
        deviceId: this.device.id,
        geofenceId: id
      }).subscribe(value => {
        // this.loadData();
      }, error1 => {
        this.alertService.error('Error al activar la geocerca en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al activar la geocerca en el dispositivo. ' + error1.toString());
      });
    } else {//no existe..
      this.apiService.permissionService.permissionsDelete({
        deviceId: this.device.id,
        geofenceId: id
      }).subscribe(value => {
        // this.loadData();
      }, error1 => {
        this.alertService.error('Error al desactivar la alerta en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al desactivar la alerta en el dispositivo. ' + error1.toString());
      });
    }
  }

  public isGeofenceDeviceOn(id: number): boolean {
    if (!isNullOrUndefined(this.deviceGeofences))
      return this.deviceGeofences.findIndex(value => value.id == id) !== -1;
    else
      return false;
  }

  public isGeofenceOn(current: Geofence): boolean {
    if (!isNullOrUndefined(this.device)) {
      return this.isGeofenceDeviceOn(current.id);
    } else {
      return false;
    }
  }

  public addNewGeofence() {
    this.state = 'new';
    this.currentGeofence = new Geofence();
  }

  public saveNewGeofence(addDevice: boolean = false, addGroup: boolean = false) {
    if (this.validate()) {
      this.clearChunkData();
      this.apiService.geofenceService.createGeofence(this.currentGeofence).subscribe(value => {
        this.alertService.success("Guardado!", "Geocerca guardada con exito");
        this.currentGeofence = value;
      }, error1 => {
        this.alertService.error("Error al Guardar Geocerca", `Se produjo un error al guardar la geocerca`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`);
      }, () => {
        if (addDevice) {
          //add device permissions.
          this.apiService.permissionService.permissionsPost({
            deviceId: this.device.id,
            geofenceId: this.currentGeofence.id
          }).subscribe(value => {
            this.loadGeofences();
            this.state = "search";
            this.appSearchTopBarComponent.clearSearchParams();
          }, error1 => {
            this.alertService.error('Error al activar la geocerca en el dispositivo', 'Verifique sus credenciales.');
            this.logger.error('Error al activar la geocerca en el dispositivo. ' + error1.toString());
          });
        } else {
          //save just the geofences
          this.loadGeofences();
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
        }
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  /* CRUD OPERATIONS */
  public updateGeofence() {
    if (this.validate()) {
      this.clearChunkData();
      this.apiService.geofenceService.updateGeofence(this.currentGeofence.id, this.currentGeofence).subscribe(value => {
          this.alertService.success("Actualizada", "La Geocerca fue Actualizada con exito");
          this.state = "search";
          this.reDraw(value.id);
        },
        error1 => {
          this.alertService.error("Error al Actualizar", `Se produjo un error al actualizar`);
          this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`);
        },() => {
          this.loadGeofences();
          this.appSearchTopBarComponent.clearSearchParams();
        });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public deleteGeofence() {
    if (this.validate()) {
      this.apiService.geofenceService.deleteGeofence(this.currentGeofence.id).subscribe(value => {
          this.alertService.success("Eliminada", "La Geocerca fue Eliminada con exito");
          this.loadGeofences();
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
        },
        error1 => {
          this.alertService.error("Error al Eliminar", `Se produjo un error al eliminar`);
          this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`);
        });
    } else {
      this.alertService.error("Error al Eliminar", "Se ha corrompido el sistema, por favor actualice.");
    }
  }

  //on save/update
  private savePermissions(permissions) {
    this.apiService.permissionService.permissionsArrayPost(permissions).subscribe(value => {
      this.clearSelection();
    }, error1 => {
      this.logger.error(`Error al agregar las geocercas: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Guardado', 'Las geocercas han sido agregadas.');
      //reload data
      this.loadGeofences();
    });
  }

  private removePermissions(permissions) {
    this.apiService.permissionService.permissionsArrayDelete(permissions).subscribe(value => {
      this.clearSelection();
    }, error1 => {
      this.logger.error(`Error al quitar las geocercas: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Eliminado', 'Las geocercas han sido removidas.');
      //reload data
      this.loadData();
    });
  }

  public onSubmit() {
    if (this.state == "new") {
      this.saveNewGeofence(!isNullOrUndefined(this.device));
      //this.alertService.success("Guardado","se guardado la nueva geocerca laga");
    } else {
      this.updateGeofence();
      //this.alertService.success("Actualizado","se actualizo la geocerca laga");
    }
  }

  public validate(): boolean {
    if (isNullOrUndefined(this.currentGeofence))
      return false;
    return !!(this.currentGeofence.name && this.currentGeofence.description && this.currentGeofence.area);
    //return (!isNullOrUndefined(this.currentNotification.notificators) && !isNullOrUndefined(this.currentNotification.type))
  }


  //drawing
  public setAreaEditMode(id: number) {
    this.selectGeofenceById(id);
    if(this.checkEditable()) {
      //show on map and wait to continue after he is done.
      if (!isNullOrUndefined(this.currentGeofence) && this.isVisible(this.currentGeofence.id)) {
        this.toogleVisible(this.currentGeofence.id);
      }
      // this.logger.warn(`ID-> ${id} \n SELECTED: ${JSON.stringify(this.currentGeofence)}`);
      this.onGeofenceAreaUpdated.emit(this.toIGeofence(this.currentGeofence));
    }else{
      this.alertSwal.show();
    }
  }

  //when new fence is created.. for area.
  public enableDraw() {
    this.onGeofenceAreaCreated.emit(this.currentGeofence);
  }

  //continue after drawing in the map
  public setCurrentArea(shape: any) {
    this.currentGeofence.area = this.toWkt(shape);
    this.cdr.detectChanges();
    //this.logger.warn("WTK -> " + this.currentGeofence.area);
  }

  public updateCurrentArea(shape: IGeofence) {
    if (!isNullOrUndefined(shape)) {
      if (isNullOrUndefined(shape.paths) || shape.paths.length == 0) {
        this.alertService.error("Area No Definida", "El area de la geocerca no fue definida.");
      } else {
        this.currentGeofence.area = this.iGeofenceToWkt(shape);
        this.updateGeofence();
        // this.logger.warn("WTK -> " + this.currentGeofence.area);
      }
    }
  }

  public iGeofenceToWkt(geofence: any): string {
    let wtk: string = "";
    if (geofence.type == "CIRCLE") {
      wtk = `CIRCLE (${geofence.paths[0].lat()} ${geofence.paths[0].lng()}, ${geofence.radius})`;
      // this.logger.warn(wtk);
    } else if (geofence.type == "POLYGON") {
      wtk += "POLYGON ((";
      geofence.paths.forEach(coordinate => {
        wtk += `${coordinate.lat} ${coordinate.lng}, `;
      });
      wtk = wtk.substr(0, wtk.length - 2) + "))";
    } else if (geofence.type == "LINESTRING") {
      wtk += "LINESTRING (";
      geofence.paths.forEach(coordinate => {
        wtk += `${coordinate.lat()} ${coordinate.lng()}, `;
      });
      wtk = wtk.substr(0, wtk.length - 2) + ")";
    }
    return wtk;
  }

  public toWkt(shape: any): string {
    let wtk: string = "";
    if (shape.type == "circle") {
      let radius = shape.overlay.getRadius().toFixed(2);
      let center = shape.overlay.getCenter();
      wtk += `CIRCLE (${center.lat()} ${center.lng()}, ${radius})`;
    } else if (shape.type == "polygon") {
      wtk += "POLYGON ((";
      let paths: Array<any> = shape.overlay.getPath().getArray();
      paths.forEach(coordinate => {
        wtk += `${coordinate.lat()} ${coordinate.lng()}, `;
      });
      wtk = wtk.substr(0, wtk.length - 2) + "))";
    } else if (shape.type == "polyline") {
      wtk += "LINESTRING (";
      shape.overlay.getPath().getArray().forEach(coordinate => {
        wtk += `${coordinate.lat()} ${coordinate.lng()}, `;
      });
      wtk = wtk.substr(0, wtk.length - 2) + ")";
    }
    return wtk;
  }

  public toIGeofence(geofence: Geofence): IGeofence {
    let i: IGeofence = {id: geofence.id};
    if (geofence.area.startsWith('CIRCLE')) {
      let ans = this.getCirclePath(geofence.area);
      i.paths = [{lat: ans.latitude, lng: ans.longitude}];
      i.radius = ans.radius;
      i.type = "CIRCLE";
    } else if (geofence.area.startsWith('POLYGON')) {
      i.paths = this.getPaths(geofence.area);
      i.type = "POLYGON";
    } else if (geofence.area.startsWith('LINESTRING')) {
      i.paths = this.getLinePaths(geofence.area);
      i.type = "LINESTRING";
    }
    let attributes: Array<IAttribute> = this.filterAttributes.transform(this.currentGeofence.attributes);
    i.fillColor = this.getAttribute(attributes, 'fillColor', this.defaultFillColor).value;
    i.fillOpacity = Number(this.getAttribute(attributes, 'fillOpacity', this.defaultFillOpacity.toString()).value);
    if (i.type == "LINESTRING") {
      i.strokeColor = this.getAttribute(attributes, 'strokeColor', this.defaultStrokeColorLine).value;
      i.strokeWeight = Number(this.getAttribute(attributes, 'strokeWeight', this.defaultStrokeWeightLine.toString()).value);
    } else if (i.type == "CIRCLE") {
      i.strokeColor = this.getAttribute(attributes, 'strokeColor', this.defaultStrokeColorCircle).value;
      i.strokeWeight = Number(this.getAttribute(attributes, 'strokeWeight', this.defaultStrokeWeightCircle.toString()).value);
    } else {
      i.strokeColor = this.getAttribute(attributes, 'strokeColor', this.defaultStrokeColorPolygon).value;
      i.strokeWeight = Number(this.getAttribute(attributes, 'strokeWeight', this.defaultStrokeWeightPolygon.toString()).value);
    }
    i.strokeOpacity = Number(this.getAttribute(attributes, 'strokeOpacity', this.defaultStrokeOpacity.toString()).value);
    i.editable = true;
    i.visible = true;
    // i.draggable = true;
    // this.logger.warn(`IGEOFENCE -> ${JSON.stringify(i)}`);
    return i;
  }

  private getCirclePath(area: string) {
    // let info = area.replace("CIRCLE (", "").replace(")", "").split(", ");
    const paths = area.substr(area.indexOf('(') + 1, area.indexOf(')')).split(",");
    // this.logger.warn("CIRLCE "+ JSON.stringify(paths));
    const pos = paths[0].split(" ");
    return {
      latitude: Number(pos[0]),
      longitude: Number(pos[1]),
      radius: Number(paths[1].replace(")", ""))
    };
  }

  private getPaths(area: string): Array<IPosition> {
    let array: Array<IPosition> = [];
    //const rings = area.replace(")", "").replace(")", "").split(", ");
    // this.logger.warn(area);
    const rings = area.substr(area.indexOf("((") + 2, area.indexOf("))")).replace("))", "").split(", ");
    // this.logger.warn(JSON.stringify(rings));
    // this.logger.warn(`RINGS -> ${JSON.stringify(rings)}`);
    rings.forEach(value => {
      let pos = value.split(" ");
      array.push({
        lat: Number(pos[0]),
        lng: Number(pos[1])
      });
    });
    return array;
  }

  private getLinePaths(area: string): Array<IPosition> {
    let array: Array<IPosition> = [];
    //const rings = area.replace(")", "").replace(")", "").split(", ");
    // this.logger.warn(area);
    const rings = area.substr(area.indexOf("(") + 1, area.indexOf(")")).replace(")", "").split(", ");
    // this.logger.warn(JSON.stringify(rings));
    // this.logger.warn(`RINGS -> ${JSON.stringify(rings)}`);
    rings.forEach(value => {
      let pos = value.split(" ");
      array.push({
        lat: Number(pos[0]),
        lng: Number(pos[1])
      });
    });
    return array;
  }

  private getAttribute(attributes: Array<IAttribute>, key: string, defaultValue: string): IAttribute {
    let attrib = attributes.find(value => value.key == key);
    return isNullOrUndefined(attrib) ? {key: key, value: defaultValue} : attrib;
  }

  public onCloseAttribs(attribs) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentGeofence.attributes = attribs;
      this.updateGeofence();
      // this.alertService.success("Guardado", "Atributos Actualizados");
    }
  }

  /* CHECK ROLES */
  public checkRole(action: RolePermission): boolean {
    if(action == 'edit' || action == 'delete'){
      return this.checkEditable() && this.permissions.getValue(action);
    }
    return this.permissions.getValue(action);
  }

  /*ACTIONS*/
  public toogleVisible(id: number) {
    let index = this.visibleIds.indexOf(id);
    const indexG = this.geofences.findIndex(value => value.id == id);
    if (index >= 0) {//visible
      this.visibleIds.splice(index, 1);
      if(indexG !== -1)
        this.geofences[indexG].isVisible = false;
    } else {
      this.visibleIds.push(id);
      if(indexG !== -1)
        this.geofences[indexG].isVisible = true;
    }

    this.configService.configuration.mapOptions.visibleGeofenceIds = this.visibleIds;
    const config = this.configService.configuration;
    // this.logger.warn("VISIBLE GEOFENCES: "+JSON.stringify(config.mapOptions.visibleGeofenceIds));
    this.configService.updateConfig(config);
  }

  public reDraw(id: number): void{

    let index = this.visibleIds.indexOf(id);
    if (index !== -1) {//visible
      this.visibleIds.splice(index, 1); //remove
      this.configService.configuration.mapOptions.visibleGeofenceIds = this.visibleIds;
      const config = this.configService.configuration;
      this.configService.updateConfig(config);

      // this.logger.warn("REMOVIDO");
      setTimeout(() => {
        // this.logger.warn("REDIBUJANDO");
        this.visibleIds.push(id);
        this.configService.configuration.mapOptions.visibleGeofenceIds = this.visibleIds;
        const config2 = this.configService.configuration;
        this.configService.updateConfig(config2);
      }, 500);
      //

    }

  }

  public toogleAllVisible(visibility: boolean) {
    if (visibility) {
      //show
      this.selected.forEach(value => {
        let index: number = this.visibleIds.indexOf(value);
        if (index < 0) {
          this.visibleIds.push(value);
        }
      });
    } else {
      //remove
      this.selected.forEach(value => {
        let index: number = this.visibleIds.indexOf(value);
        if (index !== -1) {
          this.visibleIds.splice(index, 1);
        }
      });
    }
    this.configService.configuration.mapOptions.visibleGeofenceIds = this.visibleIds;
    const config = this.configService.configuration;
    this.configService.updateConfig(config);
  }

  public toogleAllForDevice(flag: boolean) {
    if (flag) {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceGeofences.findIndex(current => current.id == value);
        if (index === -1) { //no existe agregamos.
          valuesTo.push({
            deviceId: this.device.id,
            geofenceId: value
          });
        }
      });
      this.savePermissions(valuesTo);
    } else {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceGeofences.findIndex(current => current.id == value);
        if (index !== -1) { //existe agregamos.
          valuesTo.push({
            deviceId: this.device.id,
            geofenceId: value
          });
        }
      });
      this.removePermissions(valuesTo);
    }

  }

  public selectInMap(id: number) {
    this.selectGeofenceById(id);
    this.onSelectInMap.emit(this.currentGeofence);
    this.clearSelection();
    this.onClose.emit();
  }

  public isVisible(id: number): boolean {
    return this.visibleIds.indexOf(id) >= 0;
  }

  private checkEditable() : boolean{
    return isNullOrUndefined(this.currentGeofence.attributes['origin']);
  }

  /* new interactions */
  public edit(id: number): void {
    this.selectGeofenceById(id);
    if(this.checkEditable()) {
      this.state = 'edit';
    }else{
      this.alertSwal.show();
    }
  }

  public openAttributes(id: number): void {
    this.selectGeofenceById(id);
    this.isVisibleAttributesCompact = true;
  }

  public openDeleteDialog(id: number): void {
    this.selectGeofenceById(id);
    if(this.checkEditable()) {
      this.deleteSwal.show();
    }else{
      this.alertSwal.show();
    }
  }

  public onCancelEdit() {
    this.confirmSwal.show();
  }

  public openEdit(geofence: Geofence, refresh: boolean = false) {
    if(this.permissions.getValue("edit")) {
      this.currentGeofence = geofence;
      if (this.checkEditable()) {
        this.state = 'edit';
      } else {
        this.alertSwal.show();
      }
      if (refresh) {
        this.cdr.detectChanges();
      }
    }else{
      this.alertService.error("Acceso Restringido.","La acción necesita permisos que no posee.");
    }
  }

  public selectGeofenceById(id: number) {
    this.currentGeofence = this.geofences.find(value => value.id == id);
  }

  public onClickRow(event) {
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if(event.value.event.target.checked){ //existe
          this.selected.add(event.value.row.id);
        }else{ //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      case 'onDoubleClick':
        // this.alertService.success("Doble CLick", "MOLEK");
        this.openEdit(event.value.row);
        break;
      case 'onSelectAll':
        // this.logger.warn(`ROW: ${JSON.stringify(event, null, 2)}`);
        if (event.value == true) {
          this.geofences.forEach(value => {
            this.selected.add(value.id);
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
    // this.logger.warn(`EVENT: ${JSON.stringify(event.value.event.target.checked, null, 2)}`);
    // this.logger.warn(JSON.stringify(this.selected.toArray()));
  }

  private formatData(geofences: Array<Geofence>): Array<any> {
    let formatted = [];
    geofences.forEach(value => {
      formatted.push({
        id: value.id,
        attributes: value.attributes,
        calendarId: value.calendarId,
        calendar: this.getCalendarName(value.calendarId),
        name: value.name,
        description: value.description,
        area: value.area,
        isVisible: this.isVisible(value.id),
        isGeofenceOn: this.isGeofenceDeviceOn(value.id)
      });
    });
    return formatted;
  }

  public clearChunkData(): void {
    delete this.currentGeofence.calendar;
    delete this.currentGeofence.isVisible;
    delete this.currentGeofence.isGeofenceOn;
  }

  private getCalendarName(calendarId: number): string {
      if (!isNullOrUndefined(this.calendars)) {
        const calendar = this.calendars.find(value => value.id == calendarId);
        if (!isNullOrUndefined(calendar)) {
          return calendar.name;
        } else {
          return 'Sin Asignar';
        }
      } else {
        return 'Sin Asignar';
      }
    }
}
