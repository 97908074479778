import {Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {Event} from "@app/core/models/event.model";
import {ApiService} from "@app/shared/services/api.service";
import {NGXLogger} from "ngx-logger";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IReportContext} from "@app/core/interfaces/ireport-context.interface";
import {IBundle} from "@app/core/interfaces/ibundle.interface";
import {ModelPageState, ReportGraphType} from "@app/core/types/buho-core-types";
import {AlertService} from "@app/shared/services/alert.service";
import {User} from "@app/core/models/user.model";
import {DatePipe, DOCUMENT} from "@angular/common";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {Device} from "@app/core/models/device.model";
import {Dictionary, Set} from "typescript-collections";
import {Geofence} from "@app/core/models/geofence.model";
import {IReportBundle} from "@app/core/interfaces/ireport-bundle";
import {ITableColumn} from "@app/core/interfaces/itable-column.interface";
import {Maintenance} from "@app/core/models/maintenance.model";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {Config} from "ngx-easy-table";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {KeysPipePipe} from "@app/shared/pipes/keys-pipe.pipe";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-report-summary-bundle',
  templateUrl: './report-summary-bundle.component.html',
  styleUrls: ['./report-summary-bundle.component.css']
})
export class ReportSummaryBundleComponent implements OnInit, IBundle, IReportBundle {

  public state: ModelPageState = 'search';

  //Search
  public searchOptions: Array<ISearchParam> = [];

  //Reports
  public report: ReportGraphType = 'horizontal-bar';

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();


  @Input()
  public printTableSectionId: string = 'printTableSectionId';

  //DATATABLE
  public totals: any = {};
  public isLoading: boolean = false;
  public selectedRow: Event;
  public selectedPage: number = 1;
  toggleRowIndex;

  public pipe = new DatePipe('es-BO');

  /* smart table */
  public configuration: Config;
  public columns;
  //Data
  public rows: Array<any> = [];
  public temp: Array<any> = [];

  /* templates for cells */
  @ViewChild('deviceTpl')
  public deviceTpl: TemplateRef<any>;
  @ViewChild('geofenceTpl')
  public geofenceTpl: TemplateRef<any>;
  @ViewChild('maintenanceTpl')
  public maintenanceTpl: TemplateRef<any>;
  @ViewChild('attributesTemplate')
  public attributesTemplate: TemplateRef<any>;


  public keysPipe: KeysPipePipe = new KeysPipePipe();

  @Input()
  public limitPage: number = 10;

  private _filterDate = new DatePipe("es-BO");
  private _filterType: NotificationTypePipe = new NotificationTypePipe();

  @Input()
  public context: IReportContext;

  public graphData = [];

  /** Dependencies **/
  public devices: Array<Device> = [];
  public geofences: Array<Geofence> = [];
  public maintenances: Array<Maintenance> = [];


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  //Graph reports
  colorScheme = {
    domain: ['#1E2F23', '#6435C9', '#FBBD08', '#B5CC18', '#F2711C', '#40BCD8', '#A5673F', '#767676', '#21BA45', '#DB2828', '#E03997']
  };

  private _user: User;
  private role: any;
  public selectedGrouped: IModel;
  public groupOptions: Array<IModel>;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              @Inject(DOCUMENT) private document: Document,
              private logger: NGXLogger) {

    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];

    this.loadDependencies();

    /* Search options */
    this.searchOptions = [{
      name: 'Dispositivo',
      value: '',
      color: 'blue',
      field: 'device',
      type: 'select',
      options: [],
      remote: false
    }];
  }

  ngOnInit() {
    //table configuration
    this.configureTableData();
  }

  public configureTableData() {
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.columns = [
      {key: 'device', title: 'Dispositivo', cellTemplate: this.deviceTpl, width: '20%'},
      {key: 'distance', title: 'Distancia Km', width: '15%'},
      {key: 'averageSpeed', title: 'Vel. Promedio Km/h', width: '10%'},
      {key: 'maxSpeed', title: 'Vel. Maxima Km/h', width: '15%'},
      {key: 'spentFuel', title: 'Gasolina', width: '10%'},
      {key: 'startOdometer', title: 'Odometro Inicial', width: '10%'},
      {key: 'endOdometer', title: 'Odometro Final', width: '10%'},
      {key: 'engineHours', title: 'Motor Encendido Hrs.',  orderEnabled: false, width: '15%'}
    ];
    this.configuration.checkboxes = false;
    this.configuration.paginationEnabled = true;
    this.configuration.paginationRangeEnabled = false;
    this.configuration.tableLayout.striped = false;
    this.configuration.groupRows = false;
    this.configuration.rows = this.limitPage;

    //group by
    this.groupOptions = [];
    //fill group by
    /*this.groupOptions.push({id:'type',name:'type', display:'Tipo de Evento'});
    this.groupOptions.push({id:'device',name:'device',display:'Dispositivo'});
    this.groupOptions.push({id:'geofence',name:'geofence',display:'Geocerca'});
    this.groupOptions.push({id:'maintenance',name:'maintenance',display:'Mantenimiento'});*/

    this.selectedGrouped=this.groupOptions[0];
  }

  public loadData(reportContext: IReportContext) {
    this.isLoading = true;
    this.context = reportContext;

    this.apiService.reportService.reportsSummaryGet(
      this.context.from,
      this.context.to,
      this.context.deviceIds,
      this.context.groupIds
      ).subscribe(value => {
      if (isNullOrUndefined(value)) {
        return;
      }
      this.rows = [];
      this.temp = [];
      const formatted = this.formatData(value);
      this.temp = [...formatted];
      this.rows = [...formatted];
      this.configureTableData();

    }, error1 => {
      this.alertService.error("Sucedio un error al cargar los datos", error1.toString());
    }, () => this.isLoading = false);
  }

  public loadDependencies() {
    //devices
    this.apiService.deviceService.getDevices(this.role == 'root').subscribe(value => {
      this.devices = value;
    },error1 => this.logger.error("Se produjo un error al cargar los dispositivos."),() => {
      //UserId
      let indexDeviceId = this.searchOptions.findIndex(value => value.field == 'device');
      let options: Array<IModel> = [];
      this.devices.forEach(device => {
        options.push({id: device.id.toString(), name: device.name, display: device.name});
      });
      if (indexDeviceId !== -1) {
        this.searchOptions[indexDeviceId].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
    //geofences
    this.apiService.geofenceService.getGeofences(this.role == 'root').subscribe(value => {
      this.geofences = value;
      //GEOFENCES SEARCH
      let index = this.searchOptions.findIndex(value => value.field == 'geofence');
      let options: Array<IModel> = [];
      value.forEach(geofence => {
        options.push({id: geofence.id.toString(), name: geofence.name, display: geofence.name});
      });
      if (index !== -1) {
        this.searchOptions[index].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
    //maintendances
    this.apiService.maintenanceService.getMaintenances(this.role == 'root').subscribe(value => {
      this.maintenances = value;
      //maintenance SEARCH
      let index = this.searchOptions.findIndex(value => value.field == 'maintenance');
      let options: Array<IModel> = [];
      value.forEach(maintenance => {
        options.push({id: maintenance.id.toString(), name: maintenance.name, display: maintenance.name});
      });
      if (index !== -1) {
        this.searchOptions[index].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
  }

  public formatData(data: Array<any>): Array<any> {
    let formatted = [];
    let total: number = 0;
    let distance: number = 0;
    let maxSpeed: number = 0;
    this.clearTotals();

    data.forEach(value => {
      //averageSpeed = averageSpeed + this.formatSpeed(value.averageSpeed);
      distance = distance + value.distance;
      if(value.maxSpeed > maxSpeed){
        maxSpeed = value.maxSpeed;
      }
      formatted.push({
        deviceId: value.deviceId,
        deviceIcon: this.loadDeviceIcon(value.deviceId),
        device: this.loadDeviceName(value.deviceId),
        distance: this.formatDistance(value.distance),
        averageSpeed: this.formatSpeed(value.averageSpeed),
        maxSpeed: this.formatSpeed(value.maxSpeed),
        spentFuel: this.formatSpentFuel(value.spentFuel),
        startOdometer: this.formatDistance(value.startOdometer),
        endOdometer: this.formatDistance(value.endOdometer),
        engineHours: this.formatEngine(value.engineHours)
      });
      total++;
    });
    this.totals = {
      devices: 0,
      distance: distance,
      maxSpeed: maxSpeed,
      types: 0,
      total: total
    };
    return formatted;
  }

  public formatSpentFuel(value: number): string{
    //value = Math.round(value * 100) / 100;
    return value.toFixed(0) + ' Litros';
  }

  public formatEngine(value: number): string{
    //value = Math.round(value * 100) / 100;
    return value.toFixed(0) + ' Hrs.';
  }

  public formatSpeed(speed: number): string{
    let auxSpeed: number = (Number(speed)* 1.852);
    //auxSpeed = Math.round(auxSpeed * 100) / 100;
    return auxSpeed.toFixed(2) + ' km/h';
  }

  public formatDistance(value: string): string {
    if(!isNullOrUndefined(value)){
      const km = (Number(value) / 1000);
      const mts = Number(value) % 1000;
      if(km >= 1){
        if(mts > 0){
          return `${km.toFixed(0)} km. ${mts.toFixed(0)} mts.`;
        }else{
          return `${km.toFixed(0)} km.`;
        }
      }else{
        return `${mts.toFixed(0)} mts.`;
      }
    }else{
      return value;
    }
  }

  public loadDeviceName(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].name;
      }
    }
  }

  public loadDeviceIcon(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].icon;
      }
    }
  }

  public loadGeofenceName(geofenceId: number): string {
    if (isNullOrUndefined(geofenceId)) {
      return '';
    } else {
      const index = this.geofences.findIndex(value => value.id == geofenceId);
      if (index !== -1) {
        return this.geofences[index].name;
      }
    }
  }
  public loadMaintenanceName(maintenanceId: number): string {
    if (isNullOrUndefined(maintenanceId)) {
      return '';
    } else {
      const index = this.maintenances.findIndex(value => value.id == maintenanceId);
      if (index !== -1) {
        return this.maintenances[index].name;
      }
    }
  }

  public onSearch(params: Array<ISearchParam>) {
    // const temp = this.filter.transform(this.temp, params);
    // this.rows = temp;
    //this.table.offset = 0;
    let temp = this.temp;
    params.forEach(param => {
      if (param.field == 'device') {
        temp = temp.filter(value => value.device.toLowerCase().includes(param.value.toLowerCase()));
      }
    });
    if (temp != this.rows) {
      this.rows = [...temp];
      this.recalculateTotals(temp);
    }
  }

  close(): void {
    this.onClose.emit();
  }

  public validateContext(reportContext: IReportContext, alert: boolean = false): boolean {
    if (isNullOrUndefined(reportContext)) {
      if (alert)
        this.alertService.error("Error al cargar el reporte", "No existe un contexto valido.");
      return false;
    } else {
      if (isNullOrUndefined(reportContext.from)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha inicial valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.to)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha final valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.deviceIds) || reportContext.deviceIds.length <= 0) {
        if (alert)
          this.alertService.error("Sin Dispositivos", "Debe seleccionar al menos un disposito.");
        return false;
      } else {
        return true;
      }
    }
  }

//base
  public clearTotals() {
    this.totals = {distance: 0, devices: 0};
  }

  public prepareGraphData() : void {
    //graphs...
    this.graphData = [];
    let dic: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();
    //fill data

    this.temp.forEach(value => {
      if (dic.containsKey(value.deviceId)) {
        //Updates
        let types = dic.getValue(value.deviceId);
        types.push(value.type);
        dic.setValue(value.deviceId, types);
      } else {
        //creates
        dic.setValue(value.deviceId, [value.type]);
      }
    });

    const devices = dic.keys();

    devices.forEach(deviceId => {
      let events = dic.getValue(deviceId);
      let series: Dictionary<string, number> = new Dictionary();
      events.forEach(value => {
        if (!series.containsKey(value))
          series.setValue(value, events.filter(value1 => value1 == value).length);
      });
      let array = [];
      series.forEach((key, value) => {
        array.push({name: key, value: value});
      });
      this.graphData.push({
        name: this.loadDeviceName(deviceId),
        series: array
      });
    });

    // this.logger.warn("GRAPH DATA -> " + JSON.stringify(this.graphData));
  }

  downloadReport(reportContext?: IReportContext) {
    if (!isNullOrUndefined(reportContext))
      this.context = reportContext;
    if (!isNullOrUndefined(this.context)) {
      if (isNullOrUndefined(this.rows) || this.rows.length == 0) {
        this.alertService.warning("Imposible Descargar", "No hay datos para descargar");
        return;
      }
      this.isLoading = true;
      this.apiService.reportService.reportsSummaryGetXLSX(
        this.context.from,
        this.context.to,
        this.context.deviceIds,
        this.context.groupIds
        ).subscribe(value => {
        if (isNullOrUndefined(value)) {
          this.alertService.error("Sucedio un error", "No puede descargarse el reporte");
          return;
        }
        //this.logger.warn("DATA DOWNLOADED "+JSON.stringify(value));
        let newBlob = new Blob([value], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.createNameFile("reporte_", ".xlsx");
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      }, error1 => {
        this.alertService.error("Sucedio un error al cargar descargar", error1.toString());
      }, () => this.isLoading = false);
    }
  }

  private createNameFile(prefix?: string, extension?: string) {
    let ans = `Eventos_${this.context.from.toISOString()}_${this.context.to.toISOString()}`;
    if (!isNullOrUndefined(prefix)) {
      ans = prefix + ans;
    }
    if (!isNullOrUndefined(extension)) {
      ans = ans + extension;
    }
    return ans;
  }

  public showBarsGraph() {
    this.prepareGraphData();
    this.report = 'horizontal-bar';
    this.state = 'new';
  }

  public showLineGraph() {
    this.prepareLineGraphData();
    this.report = 'line';
    this.state = 'new';
  }

  public prepareLineGraphData() : void {
    //graphs...
    this.graphData = [];
    let dic: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();
    let dicType: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();

    this.temp.forEach(value => {
      if (!dic.containsKey(value.deviceId)) {
        dic.setValue(value.deviceId, []);
      }
    });

    this.temp.forEach(value => {
      if (!dicType.containsKey(value.type)) {
        dicType.setValue(value.type, []);
      }
    });

    const devices = dic.keys();
    const types = dicType.keys();
    devices.forEach(deviceId => {
      let array = [];
      let count: number = 0;
      types.forEach(typeName => {
        count = this.searchTypes(typeName, deviceId);
        array.push({name: typeName, value: count});
      });

      this.graphData.push({
        name: this.loadDeviceName(deviceId),
        series: array
      });
    });

     //this.logger.warn("GRAPH DATA -> " + JSON.stringify(this.graphData));
  }

  private searchTypes(type: any, deviceId: any) : number{
    let count:number = 0;
    this.temp.forEach(value => {
      if (value.deviceId == deviceId && value.type == type ) {
        count = count + 1;
      }
    });
    return count;
  }

  public print() {
    // this.isPrintableMode = true;
    let popupWinindow;
    const printable = document.getElementById(this.printTableSectionId);
    // if(isNullOrUndefined(printable)){
    //   this.alertService.warning("Sin Datos","No hay datos para Imprimir.");
    //   return;
    // }
    let innerContents = printable.innerHTML;
    popupWinindow = window.open('', '_blank', 'top=0,left=0,height=100%,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    let styles: Array<string> = [];
    const styleElements = document.getElementsByTagName('style');
    for (let idx = 0; idx < styleElements.length; idx++) {
      styles.push(styleElements[idx].outerHTML);
    }
    popupWinindow.document.write(`<html><head><title>REPORTE DE EVENTOS. [${this.context.from.toLocaleDateString()} - ${this.context.to.toLocaleDateString()}]</title>${styles.join('\r\n')}</head><body onload="window.print();window.close();">${innerContents}</html>`);
    popupWinindow.document.close();
    // popupWinindow.document.addEventListener('onbeforeunload',event => {
    //     this.isPrintableMode = false;
    // });
  }


  private recalculateTotals(temp: Array<any>) {
    let counts: any = {};
    for (let i = 0; i < temp.length; i++) {
      counts[temp[i].device] = 1 + (counts[temp[i].device] || 0);
      counts[temp[i].distance] = 1 + (counts[temp[i].distance] || 0);
    }
    // this.logger.warn("Recalculando " + JSON.stringify(counts));
    this.totals = {
      devices: counts.devices,
      distance: counts.distance,
      total: temp.length
    };
  }

}
