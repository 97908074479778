import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {isNullOrUndefined} from "util";
import {Observable} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {Position} from "@app/core/models/position.model";

@Injectable({
  providedIn: 'root'
})
export class PositionService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  public getPositionsJSON(deviceId?: number, from?: Date, to?: Date, ids?: number[]): Observable<Array<Position>> {

    let params = new HttpParams();
    if (!isNullOrUndefined(ids)) {
      for (let id of ids) {
        params.set("id", <any>id);
      }
    }
    if (!isNullOrUndefined(deviceId))
      params.set("deviceId", <any>deviceId);
    if ((!isNullOrUndefined(from)) && (!isNullOrUndefined(to))) {
      params.set("from", <any>from.toISOString());
      params.set("to", <any>to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.get<Array<Position>>(`${this.basePath}${this.REST_URLS.positions}`,{
      params: params,
      headers: headers,
      withCredentials: this.withCredentials
    });
  }

  public getPositionsCSV(deviceId?: number, from?: Date, to?: Date, ids?: number[]): Observable<Array<Position>> {
    let params = new HttpParams();
    if (!isNullOrUndefined(ids)) {
      for (let id of ids) {
        params.set("id", <any>id);
      }
    }
    if (!isNullOrUndefined(deviceId))
      params.set("deviceId", <any>deviceId);
    if ((!isNullOrUndefined(from)) && (!isNullOrUndefined(to))) {
      params.set("from", from.toISOString());
      params.set("to", to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "text/csv");

    return this.httpClient.get<Array<Position>>(`${this.basePath}${this.REST_URLS.positions}`,{
      params: params,
      headers: headers
    });
  }

  public getPositionsGPX(deviceId?: number, from?: Date, to?: Date, ids?: number[]): Observable<Array<Position>> {
    let params = new HttpParams();
    if (!isNullOrUndefined(ids)) {
      for (let id of ids) {
        params.set("id", <any>id);
      }
    }
    if (!isNullOrUndefined(deviceId))
      params.set("deviceId", <any>deviceId);
    if ((!isNullOrUndefined(from)) && (!isNullOrUndefined(to))) {
      params.set("from", from.toISOString());
      params.set("to", to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/gpx+xml");

    return this.httpClient.get<Array<Position>>(`${this.basePath}${this.REST_URLS.positions}`,{
      params: params,
      headers: headers
    });
  }
}

