import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Group} from "@app/core/models/group.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {User} from "@app/core/models/user.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
import {FilterGroupBySearchParamPipe} from "@app/core/pipes/filter-group-by-search-param.pipe";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Config} from "ngx-easy-table";
import {Dictionary} from "typescript-collections";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-group-bundle',
  templateUrl: './group-bundle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./group-bundle.component.css']
})
export class GroupBundleComponent implements OnInit, AfterViewInit {

  public currentGroup: Group | any;

  @Output()
  public onClose: EventEmitter<Group> = new EventEmitter();

  @Input()
  public state: ModelPageState = "search";

  public sortOrder: string;

  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;

  //USER'S GROUPS FROM API
  public groups: Array<Group> = [];
  public defaultGroups: Array<Group> = [];

  public filter: FilterGroupBySearchParamPipe = new FilterGroupBySearchParamPipe();
  //ATTRIBUTES
  public isVisibleAttributesCompact: boolean = false;

  private _user: User;
  public role: string;


  public selectedParentGroup: Group;


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  /* Table Fields*/
  public configuration: Config;
  public columns;
  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;
  @ViewChild('boldTpl') public boldTpl: TemplateRef<any>;

  public permissions: Dictionary<string, boolean>;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('alertSwal') private alertSwal: SwalComponent;

  /* FORM */
  public editForm: FormGroup;

  public optionsForAttributes: Array<IModel>;

  get formFields() {
    return this.editForm.controls;
  }

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private readonly cdr: ChangeDetectorRef,
              private fb: FormBuilder,
              private logger: NGXLogger) {
    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'green',
      field: 'name',
      type: 'text',
      remote: false
    }];
    //edit
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]]
    });
  }

  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.configuration.checkboxes = false;
    this.loadPermissions();
    this.configureTableData();
  }

  public configureTableData() {
    this.columns = [
      {key: 'name', title: 'Nombre', cellTemplate: this.boldTpl,width: '50%'},
      {key: 'group', title: 'Grupo Padre', width: '50%'},
      {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '20%'}
    ];

    //create options for attributes.
    this.optionsForAttributes = [{
      id: 'speedLimit',
      name: 'speedLimit',
      display: 'Velocidad Máxima (km/h)'
    },{
      id: 'processing.copyAttributes',
      name: 'processing.copyAttributes',
      display: 'Copiar Atributos'
    }];
  }

  public loadPermissions() {
    this.permissions = this.apiService.roleService.groupPermissions;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public loadData(): void {
    this.loadAdminSearchParam();
    this.loadGroups(this.role == 'root');
  }

  private loadAdminSearchParam() {
    if (!isNullOrUndefined(this._user)) {
      if (this._user.administrator) {
        //UserId
        let indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'violet',
              type: 'select',
              options: options,
              selectValue: {id: this._user.id, name: this._user.name, display: this._user.name},
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
      }
    }
  }

  public onSearch(params: Array<ISearchParam>): void {
    this.logger.warn("elementos"+JSON.stringify(params));
    this.clearSelection();
    if (params.findIndex(value => value.remote == true) !== -1)
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    else {
      this.groups = this.filter.transform(this.defaultGroups, params);
    }
  }

  private doSearch(params: Array<ISearchParam>) {

    let userId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
        }
        search = true;
      }
    });
    if (search) {
      this.loadGroupsForSearch(userId, params);
    }
  }

  private loadGroupsForSearch(userId: number, params: Array<ISearchParam>) {
    this.isLoading = true;
    this.apiService.groupService.getGroups(false, userId).subscribe(value => {
      const formatted = this.formatData(value);
      this.groups = this.filter.transform(formatted, params);
      // this.logger.warn(`Grupos: ${JSON.stringify(value)}`);
    }, error1 => {
      this.logger.error(`Error al cargar los grupos: ${error1.toString()}`);
    }, () => {
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  private loadGroups(all?: boolean, userId?: number) {
    this.apiService.groupService.getGroups(all, userId).subscribe(value => {
      if(value == null){
        this.alertService.error("Se produjo un error","No se pudo cargar los grupos");
      }else {
        const formatted = this.formatData(value);
        this.defaultGroups = [...formatted];
        this.groups = [...formatted];
      }
      // this.logger.warn(`Grupos: ${JSON.stringify(value)}`);
    }, error1 => {
      this.logger.error(`Error al cargar los grupos: ${error1.toString()}`);
    }, () => {
      //add to search fields
      let groupsOptions: Array<IModel> = [];
      this.groups.forEach(value => {
        groupsOptions.push({id: value.id.toString(), name: value.id.toString(), display: value.name});
      });
      let indexGroupId = this.searchOptions.findIndex(value => value.field == 'groupId');
      if (indexGroupId !== -1) {
        this.searchOptions[indexGroupId].options = groupsOptions;
      } else {
        this.searchOptions.push({
          name: 'Grupo',
          field: 'groupId',
          value: '',
          color: 'orange',
          type: 'select',
          options: groupsOptions,
          remote: false
        });
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
      this.cdr.detectChanges();
    });
  }

  /* CRUD OPERATIONS */
  public isValid(): boolean {
    return this.editForm.valid;
  }

  public saveGroup() {
    if (this.isValid()) {
      this.clearChunkData();
      this.apiService.groupService.createGroup(this.currentGroup).subscribe(value => {
        if(value == null){
          this.alertService.error("Error al Guardar","Se produjo un error al guardar el grupo");
        }else {
          this.alertService.success("Guardado!", "Grupo guardado con exito");
        }
        // this.currentGroup = value;
      }, error1 => {
        this.alertService.error("Error al Guardar Grupo", `Se produjo un error al guardar el grupo`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public updateGroup() {
    if (this.isValid()) {
      this.clearChunkData();
      this.apiService.groupService.updateGroup(this.currentGroup.id, this.currentGroup).subscribe(value => {
        if(value == null){
          this.alertService.error("Error al Actualizar","Se produjo un error al actualizar el grupo");
        }else {
          this.alertService.success("Guardado!", "Grupo actualizado con exito");
        }
        // this.currentGroup = value;
      }, error1 => {
        this.alertService.error("Error al Actualizar Grupo", `Se produjo un error al actualizar el grupo`);
        this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public deleteGroup() {
    this.apiService.groupService.deleteGroup(this.currentGroup.id).subscribe(value => {
        this.alertService.success("Eliminado", "El grupo fue eliminado con exito");
      },
      error1 => {
        this.alertService.error("Error al Eliminar", `Se produjo un error al eliminar`);
        this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`);
      }, () => {
        this.loadData();
        this.clearSelection();
      });
  }

  /* actions */
  public edit(id?: number) {
    this.selectById(id);
    if(this.checkEditable()) {
      this.selectedParentGroup = this.findById(this.currentGroup.groupId);
      this.state = 'edit';
    }else{
      this.alertSwal.show();
    }
  }

  public addNewGroup() {
    this.state = 'new';
    this.currentGroup = new Group();
    // this.editForm.reset();
    this.selectedParentGroup = undefined;
  }

  public onSubmit(): void {
    this.prepareData();
    if (!isNullOrUndefined(this.selectedParentGroup) && this.currentGroup.id == this.selectedParentGroup.id) {
      this.alertService.error("Grupo Circular", "No puede asignarse un grupo al mismo grupo");
    } else {
      if (this.state == "new") {
        this.saveGroup();
      } else {
        this.updateGroup();
      }
    }
  }

  public selectById(id: number) {
    const index = this.groups.findIndex(value => value.id == id);
    if (index !== -1) {
      this.currentGroup = this.groups[index];
      this.formFields.name.setValue(this.currentGroup.name)
    }
  }

  public close(): void {
    this.currentGroup = new Group();
    this.appSearchTopBarComponent.clearSearchParams();
    this.onClose.emit(this.currentGroup);
  }

  public clearSelection() {
    this.currentGroup = new Group();
    this.state = "search";
    this.selectedParentGroup = undefined;
    this.editForm.reset();
  }

  public onCloseAttribs(attribs) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentGroup.attributes = attribs;
      this.updateGroup();
    }
  }

  public prepareData() {
    if (!isNullOrUndefined(this.selectedParentGroup)) {
      this.currentGroup.groupId = this.selectedParentGroup.id;
    } else {
      this.currentGroup.groupId = 0;
    }
    this.currentGroup.name = this.formFields.name.value;
    // this.logger.warn("DATA A GUARDAR" + JSON.stringify(this.currentGroup));
  }

  public onClickRow(event) {
    switch (event.event) {
      case 'onDoubleClick':
        this.edit(event.value.row.id);
        break;
    }
    // this.logger.warn(`EVENT: ${JSON.stringify(event.value.event.target.checked, null, 2)}`);
    // this.logger.warn(JSON.stringify(this.selected.toArray()));
  }

  public onCancelEdit() {
    this.confirmSwal.show();
  }

  public openDeleteDialog(id: number): void {
    this.selectById(id);
    if(this.checkEditable()) {
      this.deleteSwal.show();
    }else{
      this.alertSwal.show();
    }
  }

  public openAttributes(id: number): void {
    this.selectById(id);
    this.isVisibleAttributesCompact = true;
  }

  /* CHECK ROLES */
  public checkRole(action: RolePermission): boolean {
    return this.permissions.containsKey(action);
  }

  private formatData(groups: Array<Group | any>): Array<any> {
    let formatted = [];
    groups.forEach(value => {
      formatted.push({
        id: value.id,
        name: value.name,
        groupId: value.groupId,
        group: this.getGroupName(value.groupId, groups),
        attributes: value.attributes
      });
    });
    return formatted;
  }

  private getGroupName(groupId: number, groups?: Array<any>): string {
    if (!isNullOrUndefined(groups)) {
      const index = groups.findIndex(value => value.id == groupId);
      if (index !== -1)
        return groups[index].name;
      else
        return '';
    } else {
      const index = this.groups.findIndex(value => value.id == groupId);
      if (index !== -1)
        return this.groups[index].name;
      else
        return '';
    }
  }

  public clearChunkData(): void {
    delete this.currentGroup.group;
  }

  private findById(groupId: number): Group | any {
    const index = this.groups.findIndex(value => value.id == groupId);
    if (index !== -1)
      return this.groups[index];
    else
      return undefined;
  }

  private checkEditable() : boolean{
    return isNullOrUndefined(this.currentGroup.attributes['origin']);
  }
}
