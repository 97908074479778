import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "@swimlane/ngx-datatable/release/utils";

@Pipe({
  name: 'totalDistance'
})
export class TotalDistancePipe implements PipeTransform {

  transform(value: string, args?: string): string {
    if(!isNullOrUndefined(value)){
      const km = (Number(value) / 1000);
      const mts = Number(value) % 1000;
      if(km >= 1){
        if(mts > 0){
          return `${km.toFixed(0)} km. ${mts.toFixed(0)} mts.`;
        }else{
          return `${km.toFixed(0)} km.`;
        }
      }else{
        return `${mts.toFixed(0)} mts.`;
      }
    }else{
      return value;
    }
  }

}
