import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MenuItemService} from "@app/shared/services/menu-item.service";
import {isNullOrUndefined} from "util";
import {Subscription} from "rxjs";
import {ItemData} from "@app/shared/interfaces/item-data.interface";
import {Device} from "@app/core/models/device.model";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit, OnDestroy {

  @Input()
  public alertCount: string = '';

  @Output()
  public onOpenEvents : EventEmitter<any> = new EventEmitter();

  @Output()
  public onItemClick: EventEmitter<ItemData> = new EventEmitter<ItemData>();

  @Output()
  public onSearch: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public showSearch: boolean = false;

  @Input()
  public searchOptions: Array<any> = [];

  public searchText: string;
  private subscription: Subscription;

  public items: Array<ItemData>;
  public rightItems: Array<ItemData>;

  constructor(private menuItemService: MenuItemService) {
  }

  ngOnInit() {
    this.subscription = this.menuItemService.getItem().subscribe(value => {
      this.items = value.filter(value12 => isNullOrUndefined(value12.right) || !value12.right);
      this.rightItems = value.filter(value => value.right);
    });
    if (isNullOrUndefined(this.items)) {
      const items = this.menuItemService.getItems();
      this.items = items.filter(value12 => isNullOrUndefined(value12.right) || !value12.right);
      this.rightItems = items.filter(value1 => value1.right);
    }
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onOptionSelected(option) {
    // console.log("BUSCAR: " + JSON.stringify(option));
    this.onSearch.emit(option);
  }

  public search(items: Array<Device>, query: any): Array<Device> | boolean {
    if (isNullOrUndefined(query))
      return false;
    if (isNullOrUndefined(query.name)) {
      const results = items.filter(state => state.name.toLowerCase().includes(query.toLowerCase()) ||
        state.uniqueId.toLowerCase().includes(query.toLowerCase()));
      return results;
    }else{
      return false;
    }
  }

  public selectItem(item){
    // console.log(JSON.stringify(item));
    this.onItemClick.emit(item);
  }

  public openEvents(){
    this.onOpenEvents.emit();
  }
}
