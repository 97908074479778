import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Device} from "@app/core/models/device.model";

@Pipe({
  name: 'sortDeviceByName'
})
export class SortDeviceByNamePipe implements PipeTransform {

  transform(values: Array<Device>, order?: string): Array<Device> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (a.name > b.name ? -1 : 1));
    }else{
      return values.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }

}
