import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Notification} from "@app/core/models/notification.model"
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
@Pipe({
  name: 'sortNotificationByName'
})
export class SortNotificationByNamePipe implements PipeTransform {

  private pipe : NotificationTypePipe = new NotificationTypePipe();

  transform(values: Array<Notification>, order?: string): Array<Notification> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (this.pipe.transform(a.type) > this.pipe.transform(b.type) ? -1 : 1));
    }else{
      return values.sort((a, b) => (this.pipe.transform(a.type) > this.pipe.transform(b.type) ? 1 : -1));
    }
  }

}
