import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {AttributeComputated} from "@app/core/models/attribute-computated.model";
import {Observable} from "rxjs";
import {isNullOrUndefined} from "util";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AttributeService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Attributes
   * */
  public getAttributes(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean): Observable<Array<AttributeComputated>> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }
    if (!isNullOrUndefined(deviceId)) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }
    if (!isNullOrUndefined(groupId)) {
      queryParameters = queryParameters.set('groupId', <any>groupId);
    }
    if (!isNullOrUndefined(refresh)) {
      queryParameters = queryParameters.set('refresh', <any>refresh);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<AttributeComputated>>(`${this.basePath}${this.REST_URLS.attributes}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Update an Attribute
   * */
  public updateAttribute(id: number, body: AttributeComputated): Observable<AttributeComputated>{
    if (isNullOrUndefined(id)) {
      throw new Error('Parametro id requerido y no proporcionado al actualizar Atributo.');
    }
    if (isNullOrUndefined(body)) {
      throw new Error('Parametro body requerido y no proporcionado al actualizar Atributo.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<Array<AttributeComputated>>(`${this.basePath}${this.REST_URLS.attributes}/${encodeURIComponent(String(id))}`,
      body,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Delete an Attribute
   *
   * @param id
   */
  public deleteAttribute(id: number): Observable<AttributeComputated> {

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    if (isNullOrUndefined(id)) {
      throw new Error('Parametro id requerido no proporcionado al eliminar Atributo.');
    }

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.attributes}/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * Create a Maintenance
   *
   * @param body
   */
  public createAttribute(body: AttributeComputated): Observable<AttributeComputated> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling createMaintenance.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<AttributeComputated>(`${this.basePath}${this.REST_URLS.attributes}`,
      body,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }

}
