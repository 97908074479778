import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Position} from "@app/core/models/position.model";
import {isNullOrUndefined} from "util";
import {Device} from "@app/core/models/device.model";

@Component({
  selector: 'app-position-info-table',
  templateUrl: './position-info-table.component.html',
  styleUrls: ['./position-info-table.component.css']
})
export class PositionInfoTableComponent implements OnInit {

  @Input()
  position: Position;
  @Input()
  device: Device;

  @Input()
  title: string = 'INFORMACION';

  @Input()
  isDimmed: boolean;
  @Input()
  message: string = "SIN INFORMACION PARA MOSTRAR";

  @Output()
  trigger = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.isDimmed = !!(isNullOrUndefined(this.device) || isNullOrUndefined(this.position));
  }

  updateData(position:Position, device:Device){
    this.position = position;
    this.device = device;
    this.isDimmed = false;
  }

  clearData(){
    this.position = this.device = null;
    this.isDimmed = true;
  }

  toogleDimmed(message?: string){
    if (!isNullOrUndefined(message)){
      this.message = message;
    }
    this.isDimmed = true;
  }

  closeBtn(){
    this.trigger.emit();
  }
}
