import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Geofence} from "@app/core/models/geofence.model";
import {isNullOrUndefined} from "util";
import {Calendar} from "@app/core/models/calendar.model";
import {ApiService} from "@app/shared/services/api.service";
import {IGeofence} from "@app/core/interfaces/igeofence.interface";

@Component({
  selector: 'app-geofence-edit',
  templateUrl: './geofence-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./geofence-edit.component.css']
})
export class GeofenceEditComponent implements OnInit, AfterViewInit {

  currentValue: Geofence;
  defaultValue: Geofence;

  //custom colors
  public fillColor: string = '#287fb8bf';
  public strokeColor: string = '#21BA45';
  public fillOpacity: number = 0.75;
  public strokeOpacity: number = 0.9;
  public strokeWeight: number = 3;

  @Output()
  currentChange: EventEmitter<Geofence> = new EventEmitter<Geofence>();
  @Output()
  onAreaUpdated: EventEmitter<Geofence> = new EventEmitter<IGeofence>();
  @Output()
  onAreaCreated: EventEmitter<Geofence> = new EventEmitter<Geofence>();

  @Input()
  get current(): Geofence {
    return this.currentValue;
  }

  set current(geofence: Geofence) {
    this.defaultValue = geofence;
    this.currentValue = Object.assign({}, this.defaultValue);
    this.currentChange.emit(this.currentValue);
  }

  /* Calendario */
  @Input()
  calendarOptions: Array<Calendar>;
  selectedCalendar: Calendar;

  constructor(private readonly cdr: ChangeDetectorRef, private apiService: ApiService) {

  }

  ngOnInit() {
    if (isNullOrUndefined(this.calendarOptions))
      this.loadCalendars();
    else {
      this.selectedCalendar = this.calendarOptions.find(value => value.id == this.currentValue.calendarId);
      this.cdr.detectChanges();
    }
    if (!isNullOrUndefined(this.currentValue.attributes)) {
      const fillC = this.currentValue.attributes['fillColor'];
      const fillO = this.currentValue.attributes['fillOpacity'];
      const strokeO = this.currentValue.attributes['strokeOpacity'];
      const strokeC = this.currentValue.attributes['strokeColor'];
      const strokeW = this.currentValue.attributes['strokeWeight'];
      if (!isNullOrUndefined(fillC)) {
        if (!isNullOrUndefined(fillO)) {
          this.fillOpacity = Number(fillO);
          this.fillColor = fillC + this.toHex(this.fillOpacity);
        } else {
          this.fillColor = fillC + (this.fillOpacity * 255).toString(16);
        }
      }
      if (!isNullOrUndefined(strokeC)) {
        if (!isNullOrUndefined(strokeO)) {
          this.strokeOpacity = Number(strokeO);
          this.strokeColor = strokeC + this.toHex(this.strokeOpacity);
        } else {
          this.strokeColor = strokeC + this.toHex(this.strokeOpacity);
        }
      }
      if (!isNullOrUndefined(strokeW)) {
        this.strokeWeight = Number(strokeW);
      }
      this.cdr.detectChanges();
    }
    // this.currentChange.emit(this.currentValue);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public isTextValid(name: string): boolean {
    return (!isNullOrUndefined(name) && name.length > 0);
  }

  // calendar
  public loadCalendars() {
    this.apiService.calendarService.getCalendars().subscribe(value => {
      this.calendarOptions = value;
      this.selectedCalendar = this.calendarOptions.find(value => value.id == this.currentValue.calendarId);
      this.cdr.detectChanges();
    });
  }

  public setCalendar(calendar: Calendar) {
    if (!isNullOrUndefined(calendar)) {
      this.currentValue.calendarId = calendar.id;
      this.cdr.detectChanges();
    }
  }

  public enableAreaEditMode() {
    if (!isNullOrUndefined(this.currentValue.id)) {
      //update
      this.onAreaUpdated.emit(this.currentValue);
      this.currentChange.emit(this.currentValue);
      this.cdr.detectChanges();
    } else {
      //new
      this.onAreaCreated.emit();
      this.currentChange.emit(this.currentValue);
      this.cdr.detectChanges();
    }
  }

  public clearCalendar(): void {
    // console.log("limpiar calendarios");
    this.selectedCalendar = undefined;
    this.currentValue.calendarId = 0;
    this.cdr.detectChanges();
  }

  public changeFillColor(color: string): void {
    if(!isNullOrUndefined(this.currentValue.attributes)){
      this.currentValue.attributes['fillColor'] = color.substr(0, color.length - 2);
      this.currentValue.attributes['fillOpacity'] = (Number.parseInt(color.substr(color.length - 2, 2), 16) / 255).toFixed(1);
    }
  }

  public changeStrokeColor(color: string): void {
    if(!isNullOrUndefined(this.currentValue.attributes)){
      this.currentValue.attributes['strokeColor'] = color.substr(0, color.length - 2);
      this.currentValue.attributes['strokeOpacity'] = (Number.parseInt(color.substr(color.length - 2, 2), 16) / 255).toFixed(1);
    }
  }

  public changeStrokeWeight(weight: number): void {
    if(!isNullOrUndefined(this.currentValue.attributes))
      this.currentValue.attributes['strokeWeight'] = weight;
  }

  public hexToRGB(hex): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const a = parseInt(hex.slice(7, 9), 16);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  private toHex(decimal: number): string {
    return Number((decimal * 255).toFixed(0)).toString(16);
  }
}
