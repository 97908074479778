import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {AngularFireAuth} from "@angular/fire/auth";
import {AngularFireMessaging} from "@angular/fire/messaging";
import {NGXLogger} from "ngx-logger";
import {LocalService} from "@app/core/service/local.service";
import {isNullOrUndefined} from "util";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";

@Injectable({
  providedIn: 'root'
})
export class FCMMessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    // private angularFireDB: AngularFireDatabase,
    private alertService: AlertService,
    private apiService: ApiService,
    private angularFireAuth: AngularFireAuth,
    private logger: NGXLogger,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) : void {
    // we can change this function to request our backend service
    // this.angularFireAuth.authState.pipe(take(1)).subscribe(
    //   () => {
    //     const data = {};
    //     data[userId] = token;
    //     this.angularFireDB.object('fcmTokens/').update(data);
    //   })
    //check local service
    if (!isNullOrUndefined(LocalService.currentUser)) {
      if (userId == LocalService.currentUser.id) {
        let tokens = LocalService.currentUser.attributes['notificationTokens'];
        if (!isNullOrUndefined(tokens)) {
          //check if already exists
          if(tokens.toString().includes(token)){
            this.logger.warn("El Token ya esta registrado "+token);
            return;
          }
          tokens = tokens + "," + token;
        } else {
          tokens = token;
        }
        LocalService.currentUser.attributes['notificationTokens'] = tokens;
        //now update user.
        let user = Object.assign({}, LocalService.currentUser);
        user.password = null;
        this.apiService.userService.updateUser(user.id, user).subscribe(value => {
          this.logger.warn("Usuario actualizado con token" + JSON.stringify(value.attributes, null, 2));
        },error1 => {
          this.logger.error("Error al actualizar el token del usuario.");
        });
      } else {
        this.alertService.error("Notificaciones Inactivas","Las notificaciones no pudieron ser activadas");
        this.logger.error("Las notificaciones no pudieron ser activadas");
      }
    } else {
      this.logger.error("No existe un usuario registrado.");
    }
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // this.logger.info(token);
        this.updateToken(userId, token);
      },
      (err) => {
        this.logger.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        // this.logger.info("new message received. ", JSON.stringify(payload,null, 2));
        this.currentMessage.next(payload);
      })
  }
}
