import { Pipe, PipeTransform } from '@angular/core';
import {User} from "@app/core/models/user.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'filterUserBySearchParam'
})
export class FilterUserBySearchParamPipe implements PipeTransform {


  transform(values: Array<User>, params: Array<ISearchParam>): Array<User> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<User> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }
        else if(param.field == 'email'){
          filtered = (this.filterByEmail(filtered,param.value));
        }
        else if(param.field == 'login'){
          filtered = (this.filterByLogin(filtered,param.value));
        }
        else if(param.field == 'phone'){
          filtered = (this.filterByPhone(filtered,param.value));
        }
        else if(param.field == 'role'){
          filtered = (this.filterByRole(filtered,param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<User>, name: string) {
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(user => {
      return user.name.toLowerCase().includes(name.toLowerCase());
    });
  }

  private filterByEmail(values: Array<User>, email: string) {
    if(!values) return [];
    if(isNullOrUndefined(email)) return values;
    return values.filter(user => {
      return user.email.toLowerCase().includes(email.toLowerCase());
    });
  }

  private filterByLogin(values: Array<User>, login: string) {
    if(!values) return [];
    if(isNullOrUndefined(login)) return values;
    return values.filter(user => {
      return user.login.toLowerCase().includes(login.toLowerCase());
    });
  }

  private filterByPhone(values: Array<User>, phone: string) {
    if(!values) return [];
    if(isNullOrUndefined(phone)) return values;
    return values.filter(user => {
      return isNullOrUndefined(user.phone) ? false : user.phone.toLowerCase().includes(phone.toLowerCase());
    });
  }

  private filterByRole(values: Array<User>, role: string) {
    if(!values) return [];
    if(isNullOrUndefined(role)) return values;
    return values.filter(user => {
      return isNullOrUndefined(user.attributes['role']) ? false : user.attributes['role'].toLowerCase().includes(role.toLowerCase());
    });
  }

}
