import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ApiService} from "@app/shared/services/api.service";
import {Notification} from "@app/core/models/notification.model";
import {Calendar} from "@app/core/models/calendar.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {NotificatorTypePipe} from "@app/core/pipes/notificator-type.pipe";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Device} from "@app/core/models/device.model";

@Component({
  selector: 'app-notification-edit',
  templateUrl: './notification-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notification-edit.component.css']
})
export class NotificationEditComponent implements OnInit, AfterViewInit {

  currentValue: Notification | any;
  // defaultValue: Notification;

  // @Output()
  // currentChange = new EventEmitter<Notification>();

  @Input()
  isDisabledNotificationType: boolean = false;

  /* Calendario */
  @Input()
  calendarOptions: Array<Calendar>;
  selectedCalendar: Calendar;

  /* Types */
  @Input()
  notificationTypes: Array<IModel>;
  selectedNotificationType: IModel;

  /* Notificators */
  selectedNotificators: Array<IModel> = [];
  @Input()
  notificatorsOptions: Array<IModel>;

  /* CURRENT NOTIFICATIONS */
  @Input()
  notifications: Array<Notification> = [];
  private pipe: NotificationTypePipe = new NotificationTypePipe();
  private notificatorPipe: NotificatorTypePipe = new NotificatorTypePipe();

  public notificationForm: FormGroup;


  // get current(): Notification | any {
  //   return this.currentValue;
  // }
  @Input()
  set current(val: Notification | any) {
    this.currentValue = val;//Object.assign({}, val);
    this.formFields.name.setValue(this.currentValue.name);
  }

  get formFields() {
    return this.notificationForm.controls;
  }


  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private readonly cdr: ChangeDetectorRef) {

    this.notificationForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]]
    });
  }

  ngOnInit() {
    /* load */
    if (isNullOrUndefined(this.notificationTypes))
      this.loadNotificatorTypes();
    else {
      if (!isNullOrUndefined(this.currentValue.type)) {
        //type
        const tIndex = this.notificationTypes.findIndex(value => value.name == this.currentValue.type);
        if (tIndex !== -1)
          this.selectedNotificationType = this.notificationTypes[tIndex];
      }
    }
    if (isNullOrUndefined(this.notificatorsOptions))
      this.loadNotificators();
    else {
      if (!isNullOrUndefined(this.currentValue.notificators)) {
        //notificators
        this.notificatorsOptions.filter(value => this.currentValue.notificators.includes(value.name)).forEach(value => {
          this.selectedNotificators.push(value);
        });
      }
    }
    if (isNullOrUndefined(this.calendarOptions))
      this.loadCalendars();
    else {
      if (!isNullOrUndefined(this.currentValue.calendarId)) {
        //calendar
        const cIndex = this.calendarOptions.findIndex(value => value.id == this.currentValue.calendarId);
        if (cIndex !== -1)
          this.selectedCalendar = this.calendarOptions[cIndex];
      }
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  /* api */
  public loadNotificatorTypes() {
    this.apiService.notificationService.getNotificationsTypes().subscribe(value => {
      this.notificationTypes = [];
      value.forEach(nType => {
        this.notificationTypes.push({
          name: nType.type,
          display: this.pipe.transform(nType.type),
        });
      });
    }, error1 => {
    }, () => {
      if (!isNullOrUndefined(this.currentValue.type)) {
        //type
        const tIndex = this.notificationTypes.findIndex(value => value.name == this.currentValue.type);
        if (tIndex !== -1)
          this.selectedNotificationType = this.notificationTypes[tIndex];
      }
    });
  }

  public loadNotificators() {
    this.apiService.notificationService.getNotificators().subscribe(value => {
      this.notificatorsOptions = [];
      value.forEach(notificator => {
        this.notificatorsOptions.push({
          name: notificator.type,
          display: this.notificatorPipe.transform(notificator.type),
        });
      });
    }, error1 => {
    }, () => {
      if (!isNullOrUndefined(this.currentValue.notificators)) {
        //notificators
        this.notificatorsOptions.filter(value => this.currentValue.notificators.includes(value.name)).forEach(value => {
          this.selectedNotificators.push(value);
        });
      }
    });
  }

  public loadCalendars() {
    this.apiService.calendarService.getCalendars().subscribe(value => {
      this.calendarOptions = value;
    }, error1 => {
    }, () => {
      if (!isNullOrUndefined(this.currentValue.calendarId)) {
        //calendar
        const cIndex = this.calendarOptions.findIndex(value => value.id == this.currentValue.calendarId);
        if (cIndex !== -1)
          this.selectedCalendar = this.calendarOptions[cIndex];
      }
    });
  }

  public setNotificationType(type: IModel) {
    if (!isNullOrUndefined(this.currentValue)) {
      this.currentValue.type = type.name;
      if(isNullOrUndefined(this.formFields.name.value)){
        this.formFields.name.setValue(type.display);
      }
    }
  }

  public setNotificator(notificators: Array<IModel>) {
    if (!isNullOrUndefined(this.currentValue) && !isNullOrUndefined(notificators)) {
      let notifs = '';
      notificators.forEach(value => {
        notifs += `${value.name},`
      });
      this.currentValue.notificators = notifs.substr(0, notifs.length - 1);
    }
  }

  public setCalendar(calendar: Calendar) {
    if (!isNullOrUndefined(calendar)) {
      this.currentValue.calendarId = calendar.id;
    }
  }

  public getInfo(): Notification {
    this.currentValue.name = this.formFields.name.value;
    //calendar
    const cIndex = this.calendarOptions.findIndex(value => value.id == this.currentValue.calendarId);
    if (cIndex !== -1)
      this.selectedCalendar = this.calendarOptions[cIndex];
    // console.log("SE PIDE 1 vez");
    return this.currentValue;
  }

}
