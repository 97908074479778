import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {IAttribute} from "@app/core/interfaces/iattribute.interface";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {KeysPipePipe} from "@app/shared/pipes/keys-pipe.pipe";
import {AlertService} from "@app/shared/services/alert.service";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {Config} from "ngx-easy-table";
import {Set} from "typescript-collections";
import {NGXLogger} from "ngx-logger";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {SwalComponent} from "@toverux/ngx-sweetalert2";

@Component({
  selector: 'app-attributes-compact',
  templateUrl: './attributes-compact.component.html',
  styleUrls: ['./attributes-compact.component.css']
})
export class AttributesCompactComponent implements OnInit {

  public save: boolean = false;

  @ViewChild('saveSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;

  //return the attributes string ready to.
  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  public selectedIndex: number = -1;

  @Input()
  get current(): any {
    return this.currentValue;
  }

  set current(current: any) {
    //need to parse it
    if (!isNullOrUndefined(current)) {
      this.currentValue = current;
      this.default = this.filter.transform(this.currentValue);
      if (!isNullOrUndefined(this.currentValue) && this.currentValue.length > 0) {
        // this.selected = this.currentValue[0];
        this.selectedIndex = 0;
      }
    } else {
      this.currentValue = undefined;
      this.default = [];
    }
    this.loadData();
  }

  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;

  public currentValue: any;
  public default: Array<IAttribute> = [];
  public attributeNew: Array<IAttribute> = [];

  public configuration: Config;
  public columns;
  public selected: Set<number> = new Set<number>();
  public attributes: Array<IAttribute> = [];

  public state: ModelPageState = "search";

  public searchOptions: Array<ISearchParam>;
  public filter: KeysPipePipe;

  public currentSearch: Array<IAttribute>;

  @Input()
  infoTitle: string[];

  @Input()
  keyOptions: IModel[];

  // @Input()
  // valueOptions : string[];

  constructor(private alertService: AlertService,
              private logger: NGXLogger) {
    this.filter = new KeysPipePipe();
    this.searchOptions = [];
    this.currentSearch = [];
    this.searchOptions.push({
      name: 'Llave',
      field: 'key',
      color: 'blue',
      value: '',
      remote: false,
      type: "text"
    });
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.configuration.checkboxes = false;
    /*this.searchOptions.push({
      name: 'Valor',
      field: 'value',
      color: 'green',
      value: '',
      remote: false,
      type: "text"
    });*/

  }

  ngOnInit() {
  }

  public configureTableData() {
    this.columns = [
      {key: 'key', title: 'Llave', width: '60%'},
      {key: 'value', title: 'Valor', width: '60%'},
      {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '10%'}
    ];
  }

  public loadData(): void {
    const formatted = this.formatData(this.default);
    this.attributes = [...formatted];
    this.clearEmpty();
    this.configureTableData();
  }

  public formatData(data: Array<IAttribute>): Array<IAttribute>{
    let results: Array<IAttribute> = [];
    data.forEach(value => {
      results.push({
        'key': value.key,
        'value': value.value
      });
    });
    return results;
  }

  public clearSelection() {
    this.selectedIndex = -1;
  }

  public close() {
    this.onClose.emit(this.save ? this.getObject() : undefined);
    this.save = false;
  }

  public reset() {
    this.default = this.filter.transform(this.currentValue);
    this.selectedIndex = -1;
  }

  public onSubmit() {
    this.save = true;
    this.state = 'search';
    let selectedAttribute = this.default[this.selectedIndex];
    if(selectedAttribute.key == 'speedLimit'){
      this.default[this.selectedIndex].value = this.toKnot(selectedAttribute.value).toFixed(2);
    }
    this.loadData();
  }

  private clearEmpty(){
    this.default = this.default.filter(value => value.key != '');
  }

  public onSearch(params: Array<ISearchParam>) {
    this.default = this.filter.transform(this.currentValue);
    this.currentSearch = params;
    this.clearEmpty();
  }

  public edit(row: IAttribute) {
    let index = this.attributes.indexOf(row);
    this.selectedIndex = index;
    if(row.key == 'speedLimit'){
      this.default[index].value = this.toKm(row.value).toFixed(2);
    }
    this.state = 'edit';
  }

  public delete() {
    this.default.splice(this.selectedIndex, 1);
    this.selectedIndex = -1;
    this.state = 'search';
    this.alertService.success("Eliminado", "Atributo eliminado con exito");
    this.loadData();
  }

  public openDialogDelete(row: IAttribute){
    let index = this.default.indexOf(row);
    this.selectedIndex = index;
    this.deleteSwal.show();
  }

  public onClickRow(event) {
    let index: number = event.value.rowId;
    //alert("este es el indice: " + index);
    this.selectedIndex = index;
    this.state = 'search';
    /*if (index == this.selectedIndex) {
      this.selectedIndex = -1;
      this.state = 'search';
    } else {
      this.selectedIndex = index;
      this.state = 'select';
    }*/
  }

  addNew() {
    /*this.selectedIndex = this.default.length;
    this.default.push({key: '', value: ''});*/
    if(this.attributeNew.length > 0){
      this.attributeNew[0].key = '';
      this.attributeNew[0].value = '';
    }else{
      this.attributeNew.push({key: '', value: ''});
    }
    this.state = "new";
  }

  public saveAttribute(){
    let key = this.attributeNew[0].key;
    let value = this.attributeNew[0].value;
    if(key != '' && value != ''){
      if(!this.findKey(key)){
        this.selectedIndex = this.default.length;
        if(this.attributeNew[0].key == 'speedLimit'){
          value = this.toKnot(this.attributeNew[0].value).toFixed(2);
        }
        this.default.push({
          key: key,
          value: value
        });
        this.save = true;
        this.state = 'search';
        this.alertService.success("Guardado", "Atributo guardado con exito");
        this.loadData();
      }else{
        this.alertService.error("Duplicidad", "No se puede registrar mas de una vez el mismo Atributo.");
      }
    }else{
      this.alertService.error("Datos vacios", "Los datos deben contener ");
    }
  }

  private findKey(key: any): boolean{
    let sw: IAttribute;
    sw = this.default.find(value => value.key == key);
    return !isNullOrUndefined(sw) ? true : false;
  }

  private toKnot(km: number): number {
    if(!isNullOrUndefined(km)){
      let Knot: number = (Number(km)/ 1.852);
      return Knot;
    }
  }

  private toKm(knot: number): number {
    if(!isNullOrUndefined(knot)){
      let km: number = (Number(knot)* 1.852);
      return km;
    }
  }

  private getObject() {
    let json: string = '{';
    let count: number = 0;
    for (let key of this.default) {
      if (key.key !== '') {
        json = json.concat(`"${key.key}":"${key.value}",`);
        count++;
      }
    }
    if (count > 0)
      json = json.substr(0, json.length - 1) + '}';
    else
      json += '}';
    return JSON.parse(json);
  }

}
