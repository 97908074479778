import { Component, OnInit } from '@angular/core';
import { SuiLocalizationService } from 'ng2-semantic-ui';

import es from "ng2-semantic-ui/locales/es";
import en from "ng2-semantic-ui/locales/en-US";
import {fadeAnimation} from "@app/animations.animation";
// import * as moment from 'moment';
// import 'moment/locale/es';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit{
  title = 'Buho';
  version = '2.0.0';
  language;

  constructor(public localizationService:SuiLocalizationService) {
    this.localizationService.load("es", es);
    this.localizationService.setLanguage("es");
    this.language = localizationService.language;
  }

  ngOnInit(): void {
    // moment.locale("es");
  }

  changeLanguage(language:string):void {
    switch (language) {
      case 'es':
        this.localizationService.load("es", es);
        this.localizationService.setLanguage("es");
        break;
      default:
        this.localizationService.load("en", en);
        this.localizationService.setLanguage("en");
        break;

    }
  }

}
