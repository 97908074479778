export class User {
  id: string;
  email: string;
  attributes: any;
  name: string;
  login: string;
  phone: string;
  readonly: boolean;
  administrator: boolean;
  map: any;
  latitude: number;
  longitude: number;
  zoom: number;
  twelveHourFormat: boolean;
  coordinateFormat: string;
  disabled: boolean;
  expirationTime: Date;
  deviceLimit: number;
  userLimit: number;
  deviceReadonly: boolean;
  token: string;
  limitCommands: boolean;
  poiLayer: string;
  webConfiguration: string;
  password: string;
}
