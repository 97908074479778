import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Server } from '@app/core/models/server.model';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";

@Injectable({
  providedIn: 'root'
})
export class ServerService extends BaseService{


  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  getServerInfo(): Observable<Server> {
    return this.httpClient.get<Server>(`${this.basePath}${this.REST_URLS.server}`,{
      headers: this.defaultHeaders
    });
  }

}
