import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {Device} from "@app/core/models/device.model";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {User} from "@app/core/models/user.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {NGXLogger} from "ngx-logger";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {FilterDeviceBySearchParamPipe} from "@app/core/pipes/filter-device-by-search-param.pipe";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {Geofence} from "@app/core/models/geofence.model";
import {IGeofence} from "@app/core/interfaces/igeofence.interface";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Dictionary, Set} from "typescript-collections";
import {Group} from "@app/core/models/group.model";
import {DatePipe} from "@angular/common";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {DeviceEditComponent} from "@app/core/components/device-edit/device-edit.component";
import {DeviceAccumulators} from "@app/core/models/device-accumulators.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-device-bundle',
  templateUrl: './device-bundle.component.html',
  styleUrls: ['./device-bundle.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfigurationTableService],
})
export class DeviceBundleComponent implements OnInit, AfterViewInit {

  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;
  // public optionsAreOpen: boolean = false;

  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  @Output()
  public onSelectInMap: EventEmitter<Device> = new EventEmitter();

  @Output()
  public onUpdateDevice: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onChangeDevice: EventEmitter<any> = new EventEmitter();

  //geofences
  //when geofence area is updated
  @Output()
  public onGeofenceAreaUpdated: EventEmitter<IGeofence> = new EventEmitter();
  //when new geofence area is created
  @Output()
  public onGeofenceAreaCreated: EventEmitter<Geofence> = new EventEmitter();

  public currentDevice: Device | any = new Device();

  public state: ModelPageState = "search";
  public filter: FilterDeviceBySearchParamPipe;
  // public sortOrder: string;

  //LINK OBJECT DIMMERS.
  public isVisibleAttributesCompact: boolean = false;
  // public isVisibleNotificationsCompact: boolean = false;

  /* CURRENT USER*/
  private _user: User;
  private role: string = '';
  public permissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  /* table */
  public defaultDevices: Array<any> = [];
  public devices: Array<any> = [];
  public groups: Array<Group> = [];

  @ViewChild('icontemplate')
  public icontemplate: TemplateRef<any>;

  @ViewChild('buttonsTpl')
  public buttonsTpl: TemplateRef<any>;

  public dateFilter: DatePipe = new DatePipe("es");

  public configuration;
  public columns;

  /* TO OPERATE ON SELECTED ITEMS. */
  public selected: Set<number> = new Set<number>();
  /* */
  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('alertSwal') private alertSwal: SwalComponent;
  @ViewChild('accumulatorsSwal') private accumulatorsSwal: SwalComponent;

  @ViewChild(DeviceEditComponent) private editForm: DeviceEditComponent;

  // MAP INTERACTIONS
  private visibleDeviceIds: Array<number> = [];
  // public viewMode: string = 'list';

  @Output()
  public onShowGeofenceBundle: EventEmitter<Device> = new EventEmitter();

  // @Output()
  // public onShowGroupsBundle: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onShowDriverBundle: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onShowMaintenanceBundle: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onShowNotificationBundle: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onShowAttributesComputatedBundle: EventEmitter<Device> = new EventEmitter();

  //accumulators
  public deviceAccumulators : DeviceAccumulators;
  public accumulatorsForm : FormGroup;
  private isIntegration: boolean = false;

  public optionsForAttributes: Array<IModel>;

  get accumulatorsFormFields() {
    return this.accumulatorsForm.controls;
  }

  constructor(private readonly cdr: ChangeDetectorRef,
              private apiService: ApiService,
              private configService: WebConfigurationService,
              private alertService: AlertService,
              private fb: FormBuilder,
              private logger: NGXLogger) {
    this.searchOptions = [{
      name: 'Placa',
      value: '',
      color: 'red',
      field: 'name',
      type: 'text',
      remote: false
    }, {
      name: 'Estado',
      value: '',
      color: 'blue',
      field: 'status',
      type: 'select',
      selectValue: null,
      options: [
        {id: 'online', name: 'online', display: 'Conectado'},
        {id: 'offline', name: 'offline', display: 'Desconectado'},
        {id: 'unknown', name: 'unknown', display: 'Desconocido'}],
      remote: false
    }, {
      name: 'IMEI',
      value: '',
      color: 'green',
      field: 'uniqueId',
      type: 'text',
      remote: false
    }, {
      name: 'Telefono',
      field: 'phone',
      color: 'orange',
      type: 'text',
      value: '',
      remote: false
    }, {
      name: 'Modelo/Marca',
      field: 'model',
      color: 'pink',
      type: 'text',
      value: '',
      remote: false
    }, {
      name: 'Contacto',
      field: 'contact',
      color: 'yellow',
      type: 'text',
      value: '',
      remote: false
    }, {
      name: 'Categoria',
      field: 'category',
      color: 'purple',
      type: 'text',
      value: '',
      remote: false
    },{
      name: 'Capacidad (Kg)',
      field: 'capacityWeight',
      color: 'violet',
      type: 'text',
      value: '',
      remote: false
    },{
      name: 'Volumen (m3)',
      field: 'capacityVolume',
      color: 'black',
      type: 'text',
      value: '',
      remote: false
    }];
    this.filter = new FilterDeviceBySearchParamPipe();
    //
    // this.configuration = ConfigurationTableService.config;
    this.accumulatorsForm = this.fb.group({
      hours: ['', Validators.pattern("\\d+(\\.\\d{1,2})?")],
      totalDistance: ['', Validators.pattern("\\d+(\\.\\d+)?")]
    });
  }

  ngOnInit(): void {
    /*load data */
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];
    //
    this.visibleDeviceIds = this.configService.configuration.mapOptions.visibleDeviceIds;
    this.configService.change.subscribe(val => {
      this.visibleDeviceIds = val.mapOptions.visibleDeviceIds;
    });
    //

    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.loadData();

    this.columns = [
      {key: 'name', title: 'Placa', cellTemplate: this.icontemplate, width: '30%'},
      {key: 'group', title: 'Grupo', width: '20%'},
      {key: 'phone', title: 'Teléfono', width: '10%'},
      {key: 'model', title: 'Modelo', width: '15%'},
      {key: 'category', title: 'Categoría', width: '15%'},
      {key: 'lastUpdateF', title: 'Ult. Actualización', width: '10%'},
      {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, width: '10%', orderEnabled: false}
    ];

    //create options for attributes.
    this.optionsForAttributes = [{
      id: 'speedLimit',
      name: 'speedLimit',
      display: 'Velocidad Máxima (km/h)'
    },{
      id: 'processing.copyAttributes',
      name: 'processing.copyAttributes',
      display: 'Copiar Atributos'
    }];
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public loadData() {
    this.loadAdminSearchParam();
    this.loadDevices();
    this.loadPermissions();
  }

  public loadAdminSearchParam() {
    if (!isNullOrUndefined(this._user)) {
      if (this._user.administrator) {
        //UserId
        let indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'grey',
              type: 'select',
              options: options,
              selectValue: {id: this._user.id, name: this._user.name, display: this._user.name},
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
        // Group
        let indexGroupId = this.searchOptions.findIndex(value => value.field == 'groupId');
        this.apiService.groupService.getGroups(this.role == 'root').subscribe(groups => {
          let options: Array<IModel> = [];
          groups.forEach(group => {
            options.push({id: group.id.toString(), name: group.id.toString(), display: group.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexGroupId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Grupo',
              field: 'groupId',
              value: '',
              color: 'olive',
              type: 'select',
              options: options,
              remote: false
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
      }
    }
  }

  public loadPermissions() {
    this.permissions = this.apiService.roleService.devicePermissions;
  }

  /* API */
  private loadDevices() {
    this.isLoading = true;
    //load groups
    this.apiService.groupService.getGroups(this.role=='root').subscribe(value => {
      this.groups = value;
    }, error1 => this.logger.error("Se produjo un error al cargar los grupos"), () => {
      this.apiService.deviceService.getDevices(this.role == 'root').subscribe(devices => {
        // devices = this.loadDummyData(devices);
        const formatted = this.formatData(devices);
        this.defaultDevices = [...formatted];
        this.devices = [...formatted];
      }, error1 => {
        this.logger.error(error1.toString());
        this.alertService.error("Acceso No Autorizado", "Verifique sus credenciales");
      }, () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      });
    });
  }

  private loadDevicesForSearch(userId: number, params: Array<ISearchParam>) {
    this.isLoading = true;
    this.apiService.deviceService.getDevices(null, null, null, userId).subscribe(devices => {
        // this.logger.warn(`userID -> ${userId} -> ${JSON.stringify(devices)}`);
        const formatted = this.formatData(devices);
        this.devices = this.filter.transform(formatted, params);
      }, error1 => {
        this.logger.error(error1.toString());
        this.alertService.error("Acceso No Autorizado", "Verifique sus credenciales");
      }
      , () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  //search
  public onSearch(params: Array<ISearchParam>) {
    if (params.findIndex(value => value.remote == true) !== -1)
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    else {
      this.devices = this.filter.transform(this.defaultDevices, params);
      this.cdr.detectChanges();
    }
  }

  public doSearch(params: Array<ISearchParam>) {

    let userId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
        }
        search = true;
      }
    });
    if (search) {
      this.loadDevicesForSearch(userId, params);
    }
  }


  public edit(id?: number) {
    if (!isNullOrUndefined(id)) {
      this.state = 'edit';
      this.currentDevice = Object.assign({},this.devices.find(value => value.id == id));
      this.isIntegration = this.checkIntegration();
      // this.editForm.setData(this.currentDevice);
      // this.logger.info( "EDITANDO "+id);
    }
    // this.cdr.detectChanges();
  }


  public openEdit(device: Device, refresh:boolean = false) {
    if(this.permissions.getValue("edit")) {
      this.currentDevice = device;
      this.state = 'edit';
      if (refresh) {
        this.cdr.detectChanges();
      }
    }else{
      this.alertService.error("Acceso Restringido.","La acción necesita permisos que no posee.");
    }
  }
  public clearSelection() {
    this.currentDevice = new Device();
    this.state = "search";
  }

  public close() {
    this.currentDevice = new Device();
    this.appSearchTopBarComponent.clearSearchParams();
    this.onClose.emit();
  }

  /* CRUD OPERATIONS */
  public addNewDevice() {
    this.state = 'new';
    this.currentDevice = new Device();
    // this.editForm.clear();
  }

  public onCancelEdit() {
    this.confirmSwal.show();
  }

  public onSubmit() {
    this.currentDevice = this.editForm.getDeviceInfo();
    if (this.state == "new") {
      this.newDevice();
    } else {
      this.editDevice();
    }
    // this.logger.warn(`PARA GUARDAR ${JSON.stringify(this.currentDevice, null, 2)}`);
  }

  public select(item: Device) {
    if (this.currentDevice != item) {
      this.currentDevice = item;
      this.state = 'select';
      // this.optionsAreOpen = true;
    } else {
      this.clearSelection();
    }
  }

  public deleteDevice() {
    if (!isNullOrUndefined(this.currentDevice)) {
      if(this.isIntegration){

      }else {
        this.apiService.deviceService.deleteDevice(this.currentDevice.id).subscribe(value => {
          this.alertService.success("Eliminado", "La Notificacion fue Eliminada con exito");
          this.loadData();
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
          this.onChangeDevice.emit();
        });
      }
    }
  }

  private newDevice() {
    if (this.isValid()) {
      this.prepareForSave();
      this.apiService.deviceService.createDevice(this.currentDevice).subscribe(newDevice => {
        this.alertService.success("Guardado", "Dispositivo guardado con exito");
        this.loadData();
        this.state = "search";
        this.appSearchTopBarComponent.clearSearchParams();
        this.onChangeDevice.emit();
        //update
        this.visibleDeviceIds.push(newDevice.id);
      });
    } else {
      this.alertService.error("Faltan datos", "Falta algunos campos requeridos.");
    }
  }

  private editDevice() {
    if (this.isValid()) {
      this.prepareForSave();
      this.clearChunkData();
      // this.logger.warn(JSON.stringify(this.currentDevice,null,2));
      this.apiService.deviceService.updateDevice(this.currentDevice.id, this.currentDevice).subscribe(updDevice => {
        if(updDevice == null){
          this.alertService.error("Se produjo un error", "No se pudo actualizar el Dipositivo.");
        }else {
          this.alertService.success("Guardado", "Dispositivo actualizado con exito");
          this.loadData();
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
          this.onUpdateDevice.emit(updDevice);
          this.onChangeDevice.emit();
        }
      }, error1 => {
        this.alertService.error("Se produjo un error", "No se pudo actualizar el Dipositivo.");
      });
    } else {
      this.alertService.error("Faltan Completar Datos", "Falta algunos campos requeridos.");
    }
  }

  private isValid() {
    // this.logger.info(`IS VALID: ${JSON.stringify(this.currentDevice)}`);
    return !isNullOrUndefined(this.currentDevice) && this.currentDevice.name && this.currentDevice.uniqueId.length >= 15;
  }

  /* COMPONENTES LINK OBJECTS*/

  public onCloseAttribs(attribs: string) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentDevice.attributes = attribs;
      this.editDevice();
      // this.alertService.success("Atributos Agregados", JSON.stringify(attribs));
    }
  }

  /* Map component interractions */
  public isVisible(deviceId: number): boolean {
    return this.visibleDeviceIds.indexOf(deviceId) >= 0;
  }

  public toogleAllVisible(visibility: boolean) {
    if (visibility) {
      //show
      this.selected.forEach(value => {
        let index: number = this.visibleDeviceIds.indexOf(value);
        if (index < 0) {
          this.visibleDeviceIds.push(value);
        }
      });
    } else {
      //remove
      this.selected.forEach(value => {
        let index: number = this.visibleDeviceIds.indexOf(value);
        if (index !== -1) {
          this.visibleDeviceIds.splice(index, 1);
        }
      });
    }
  }

  public toogleVisible(row) {
    const index = this.visibleDeviceIds.indexOf(row.id);
    const indexD = this.devices.findIndex(value => value.id == row.id);

    if (index >= 0) {//visible
      this.visibleDeviceIds.splice(index, 1);
      if(indexD !== -1)
        this.devices[indexD].isVisible = false;
    } else {
      this.visibleDeviceIds.push(row.id);
      if(indexD !== -1)
        this.devices[indexD].isVisible = true;
    }
  }

  public selectInMap(id: number) {
    this.selectDeviceById(id);
    // this.logger.warn("CURRENT DEVICE: [" + id + "]" + JSON.stringify(this.currentDevice.positionId));
    if (!isNullOrUndefined(this.currentDevice)) {
      if (!isNullOrUndefined(this.currentDevice.positionId) && this.currentDevice.positionId != 0) {
        this.onSelectInMap.emit(this.currentDevice);
        this.onClose.emit();
        this.clearSelection();
      } else {
        this.alertService.warning("No puede localizarse", "El Dispositivo no tiene una última posición.");
      }
    }
  }

  public onCloseNotifications() {
    // this.isVisibleNotificationsCompact = false;
  }

  private prepareForSave() {
    if (!isNullOrUndefined(this.currentDevice)) {
      if (!isNullOrUndefined(this.currentDevice.name))
        this.currentDevice.name = this.currentDevice.name.trim();
      if (!isNullOrUndefined(this.currentDevice.uniqueId))
        this.currentDevice.uniqueId = this.currentDevice.uniqueId.trim();
      if (!isNullOrUndefined(this.currentDevice.category))
        this.currentDevice.category = this.currentDevice.category.trim();
      if (!isNullOrUndefined(this.currentDevice.phone))
        this.currentDevice.phone = this.currentDevice.phone.trim();
      if (!isNullOrUndefined(this.currentDevice.model))
        this.currentDevice.model = this.currentDevice.model.trim();
      if (!isNullOrUndefined(this.currentDevice.contact))
        this.currentDevice.contact = this.currentDevice.contact.trim();
      //check speed
    }
  }

  // public setCurrentDevice(device: Device) {
  //   const index = this.defaultDevices.findIndex(value => value.id == device.id);
  //   if (index !== -1) {
  //     this.currentDevice = this.defaultDevices[index];
  //     this.state = 'select';
  //     // this.optionsAreOpen = true;
  //   }
  //   // this.select(device);
  // }
  public showGeofencesBundle(id: number) {
    this.selectDeviceById(id);
    this.onShowGeofenceBundle.emit(this.currentDevice);
  }

  public showDriverBundle(id: number) {
    this.selectDeviceById(id);
    this.onShowDriverBundle.emit(this.currentDevice);
  }

  public showMaintenanceBundle(id: number) {
    this.selectDeviceById(id);
    this.onShowMaintenanceBundle.emit(this.currentDevice);
  }

  public showNotificationBundle(id: number) {
    this.selectDeviceById(id);
    this.onShowNotificationBundle.emit(this.currentDevice);
  }

  public showAttributesComputatedBundle(id: number) {
    this.selectDeviceById(id);
    this.onShowAttributesComputatedBundle.emit(this.currentDevice);
  }

  public showAttributesBundle(id: number) {
    this.selectDeviceById(id);
    // this.onShowAttributesBundle.emit(this.currentDevice);
    this.isVisibleAttributesCompact = true;
  }

  /*events*/
  public onClickRow(event) {
    // this.logger.warn(`SELECTED ROW: ${JSON.stringify(event, null, 2)}`);
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if(event.value.event.target.checked){ //existe
          this.selected.add(event.value.row.id);
        }else{ //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      case 'onDoubleClick':
        // this.alertService.success("Doble CLick", "MOLEK");
        this.openEdit(event.value.row);
        break;
      case 'onSelectAll':
        // this.logger.warn(`ROW: ${JSON.stringify(event, null, 2)}`);
        if (event.value == true) {
          this.devices.forEach(value => {
            if(this.selected.contains(value)){
              this.selected.remove(value.id);
            }else {
              this.selected.add(value.id);
            }
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
  }

  public selectDeviceById(id: number) {
    this.currentDevice = this.devices.find(value => value.id == id);
    this.isIntegration = this.checkIntegration();
  }

  public getGroupName(id: number): string {
    const group = this.groups.find(value => value.id == id);
    if (!isNullOrUndefined(group))
      return group.name;
    else
      return 'Sin Asignar';
  }

  private formatData(devices: Array<Device>): Array<any> {
    let formatted = [];
    devices.forEach(value => {
      formatted.push({
        id: value.id,
        uniqueId: value.uniqueId,
        name: value.name,
        icon: value.icon,
        status: value.status,
        lastUpdateF: this.dateFilter.transform(value.lastUpdate, 'dd/MM/yyyy hh:mm'),
        lastUpdate: value.lastUpdate,
        group: this.getGroupName(value.groupId),
        positionId: value.positionId,
        disabled: value.disabled,
        groupId: value.groupId,
        phone: value.phone,
        category: value.category,
        model: value.model,
        contact: value.contact,
        capacityWeight: value.capacityWeight,
        capacityVolume: value.capacityVolume,
        geofenceIds: value.geofenceIds,
        attributes: value.attributes,
        isVisible: this.isVisible(value.id)
      });
    });
    return formatted;
  }

  public openDeleteDialog(id: number) : void {
    this.selectDeviceById(id);
    if(this.isIntegration)
      this.alertSwal.show();
    else
      this.deleteSwal.show();
  }

  public clearChunkData(): void {
    delete this.currentDevice.group;
    delete this.currentDevice.lastUpdateF;
    delete this.currentDevice.isVisible;
  }

  public openAccumulatorsDialog(id:number) : void{
    this.selectDeviceById(id);
    this.deviceAccumulators = new DeviceAccumulators();
    //load data from position if possible
    this.deviceAccumulators.deviceId = id;
    //load position
    if(this.currentDevice.positionId !== 0) {
      this.apiService.positionService.getPositionsJSON(null, null, null, [this.currentDevice.positionId]).subscribe(value => {
        // this.logger.warn("ID: " + this.currentDevice.positionId + " ->" + JSON.stringify(value, null, 2));
        if (!isNullOrUndefined(value)) {
          value.forEach(position => {
            if (position.deviceId == id) {
              this.deviceAccumulators.hours = position.attributes['hours'] | 0;
              this.deviceAccumulators.totalDistance = position.attributes['totalDistance'] | 0;
            }
          });
        } else {
          this.deviceAccumulators.hours = 0;
          this.deviceAccumulators.totalDistance = 0;
        }
      }, error1 => this.logger.error("Error al buscar la ultima posicion del Dispositivo: " + id), () => {
        this.accumulatorsFormFields.hours.setValue(this.deviceAccumulators.hours);
        this.accumulatorsFormFields.totalDistance.setValue(this.deviceAccumulators.totalDistance);
      });
    }else{
      this.deviceAccumulators.hours = 0;
      this.deviceAccumulators.totalDistance = 0;
      this.accumulatorsFormFields.hours.setValue(this.deviceAccumulators.hours);
      this.accumulatorsFormFields.totalDistance.setValue(this.deviceAccumulators.totalDistance);
    }
    this.accumulatorsSwal.show();
  }

  public updateAccumulators(){
    // this.logger.warn(`ACUMULADORES: ${JSON.stringify(this.deviceAccumulators)}`);
    if(this.accumulatorsForm.invalid){
      this.alertService.error("Error al guardar", "Existen valores invalidos, verifique e intente nuevamente.");
    }else {
      this.deviceAccumulators.hours = this.accumulatorsFormFields.hours.value;
      this.deviceAccumulators.totalDistance = this.accumulatorsFormFields.totalDistance.value;
      this.apiService.deviceService.updateAccumulators(this.currentDevice.id, this.deviceAccumulators)
        .subscribe(value => {
          this.alertService.success("Actualizado", "Los valores han sido actualizados");
          this.accumulatorsForm.reset();
          }
          , error1 => this.alertService.error("Error al guardar", "Se produjo un error al guardar."));

    }
  }

  private checkIntegration() : boolean {
    return !!(!isNullOrUndefined(this.currentDevice.attributes) && !isNullOrUndefined(this.currentDevice.attributes['origin']) && this.currentDevice.attributes['origin']);
  }
}
