import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Geofence} from "@app/core/models/geofence.model";

@Pipe({
  name: 'sortGeofenceByName'
})
export class SortGeofenceByNamePipe implements PipeTransform {

  transform(values: Array<Geofence>, order?: string): Array<Geofence> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (a.name > b.name ? -1 : 1));
    }else{
      return values.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }

}
