import { Injectable } from '@angular/core';
import {Observable, Observer, Subject} from "rxjs";
import {NGXLogger} from "ngx-logger";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private subject: Subject<MessageEvent>;
  webSocket: WebSocket;

  constructor(private logger: NGXLogger) { }

  public connect(url): Subject<MessageEvent>{
    if(!this.subject){
      this.subject = this.create(url);
      this.logger.info("Successfully connected: "+url);
    }
    return this.subject;
  }

  private create(url):Subject<MessageEvent>{
    this.webSocket = new WebSocket(url);

    let observable = Observable.create((obs: Observer<MessageEvent>) => {
      this.webSocket.onmessage = obs.next.bind(obs);
      this.webSocket.onerror = obs.error.bind(obs);
      this.webSocket.onclose = obs.complete.bind(obs);
      return this.webSocket.close.bind(this.webSocket);
    });

    let observer = {
      next: (data: Object) => {
        if (this.webSocket.readyState === WebSocket.OPEN) {
          this.webSocket.send(JSON.stringify(data)); // Convert to a string from json
        }
      }
    };

    return Subject.create(observer, observable);
  }

  public close(){
    this.webSocket.close();
  }

}
