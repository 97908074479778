import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {User} from "@app/core/models/user.model";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {Calendar} from "@app/core/models/calendar.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {CalendarEvent} from "@app/core/interfaces/calendar-event";
import {FilterCalendarBySearchParamPipe} from "@app/core/pipes/filter-calendar-by-search-param.pipe";
import ICSHelper, {ICalendar, IEvent} from "@app/shared/helpers/icshelper";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {Config} from "ngx-easy-table";
import {Dictionary, Set} from "typescript-collections";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-calendar-bundle',
  templateUrl: './calendar-bundle.component.html',
  styleUrls: ['./calendar-bundle.component.css']
})
export class CalendarBundleComponent implements OnInit, AfterViewInit {

  @Output()
  public onClose: EventEmitter<Calendar> = new EventEmitter();

  public currentCalendar: Calendar;

  @Input()
  public state: ModelPageState = "search";

  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;

  //USER'S GROUPS FROM API
  public calendars: Array<Calendar> = [];
  public defaultCalendars: Array<Calendar> = [];

  public filter: FilterCalendarBySearchParamPipe = new FilterCalendarBySearchParamPipe();

  //ATTRIBUTES
  public isVisibleAttributesCompact: boolean = false;
  private _user: User;
  public role: string;

  //custom calendar
  public events: Array<CalendarEvent> = [];

  //use to upload a file or to set the calendar
  public useIcsFile: boolean = false;

  //ICS
  //recurrentMode
  //public selectedRecurrenceMode: IModel;
  public recurrenceModes: Array<IModel> = [];

  //day
  //public selectedDays: Array<IModel> = [];
  public daysOfWeek: Array<IModel> = [];

  //hours
  //public selectedHours: Array<IModel> = [];
  //public hoursOfDay: Array<IModel> = [];

  //MONTHS
  //public selectedMonths: Array<IModel> = [];
  public monthsOfYear: Array<IModel> = [];

  //MONTHS DAYS
  // public selectedMonthDays: Array<IModel> = [];
  public daysOfMonth: Array<IModel> = [];


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  /* table data */
  public configuration: Config;
  public columns;
  public selected: Set<number> = new Set<number>();

  @ViewChild('nameTpl') public nameTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;

  public permissions: Dictionary<string, boolean>;

  public editForm: FormGroup;

  get formFields() {
    return this.editForm.controls;
  }

  constructor(private apiService: ApiService,
              private readonly fb: FormBuilder,
              private alertService: AlertService,
              private logger: NGXLogger) {
    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'green',
      field: 'name',
      type: 'text',
      remote: false
    }];
    //'WEEKLY' | 'DAILY' | 'MONTHLY' | 'YEARLY'
    this.recurrenceModes.push({id: 'DAILY', name: 'Diariamente'});
    this.recurrenceModes.push({id: 'WEEKLY', name: 'Semanalmente'});
    this.recurrenceModes.push({id: 'MONTHLY', name: 'Mensualmente'});
    this.recurrenceModes.push({id: 'YEARLY', name: 'Anualmente'});
    //HOURS
    // for (let i: number = 1; i <= 24; i++) {
    //   this.hoursOfDay.push({id: i.toString(), name: i.toString()});
    // }
    // MONTHS
    this.monthsOfYear.push({id: '1', name: 'Enero'});
    this.monthsOfYear.push({id: '2', name: 'Febrero'});
    this.monthsOfYear.push({id: '3', name: 'Marzo'});
    this.monthsOfYear.push({id: '4', name: 'Abril'});
    this.monthsOfYear.push({id: '5', name: 'Mayo'});
    this.monthsOfYear.push({id: '6', name: 'Junio'});
    this.monthsOfYear.push({id: '7', name: 'Julio'});
    this.monthsOfYear.push({id: '8', name: 'Agosto'});
    this.monthsOfYear.push({id: '9', name: 'Septiembre'});
    this.monthsOfYear.push({id: '10', name: 'Octubre'});
    this.monthsOfYear.push({id: '11', name: 'Noviembre'});
    this.monthsOfYear.push({id: '12', name: 'Diciembre'});
    //MONTH-DAYS
    for (let i: number = 1; i <= 31; i++) {
      this.daysOfMonth.push({id: i.toString(), name: i.toString()});
    }

    //DAYS
    this.daysOfWeek.push({id: 'MO', name: 'Lunes'});
    this.daysOfWeek.push({id: 'TU', name: 'Martes'});
    this.daysOfWeek.push({id: 'WE', name: 'Miércoles'});
    this.daysOfWeek.push({id: 'TH', name: 'Jueves'});
    this.daysOfWeek.push({id: 'FR', name: 'Viernes'});
    this.daysOfWeek.push({id: 'SA', name: 'Sábado'});
    this.daysOfWeek.push({id: 'SU', name: 'Domingo'});
    // this.daysOfWeek.push({id: '', name:''});

    //edit
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      file: ['']
    });
  }

  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.loadPermissions();
    this.configureTableData();
  }

  ngAfterViewInit(){
    // this.cdr.detectChanges();
  }

  public loadPermissions(): void {
    this.permissions = this.apiService.roleService.calendarPermissions;
  }

  public loadData(): void {
    this.loadCalendars(this.role == 'root');
    this.loadAdminSearchParam();
  }

  private loadCalendars(all?: boolean, userId?: number) {
    this.isLoading = true;
    this.apiService.calendarService.getCalendars(all, userId).subscribe(value => {
      this.calendars = value;
      this.defaultCalendars = value;
    }, error1 => {
      this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
      this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
    }, () => {
      this.isLoading = false;
      // this.cdr.detectChanges();
    });
  }a

  private loadAdminSearchParam() {
    if (!isNullOrUndefined(this._user)) {
      if (this._user.administrator) {
        //UserId
        const indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'violet',
              type: 'select',
              options: options,
              selectValue: {id: this._user.id, name: this._user.name, display: this._user.name},
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          // this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
      }
    }
  }


  private loadCalendarsForSearch(userId: number, params: Array<ISearchParam>) {
    this.isLoading = true;
    this.apiService.calendarService.getCalendars(null, userId).subscribe(calendars => {
        // this.logger.warn(`userID -> ${userId} -> ${JSON.stringify(devices)}`);
        this.calendars = this.filter.transform(calendars, params);
      }, error1 => {
        this.logger.error(error1.toString());
        this.alertService.error("Acceso No Autorizado", "Verifique sus credenciales");
      }
      , () => {
        this.isLoading = false;
        // this.cdr.detectChanges();
      });
  }

  public onSearch(params: Array<ISearchParam>): void {
    this.clearSelection();
    if (params.findIndex(value => value.remote == true) !== -1) {
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    } else {
      this.calendars = this.filter.transform(this.defaultCalendars, params);
    }
  }

  private doSearch(params: Array<ISearchParam>) {

    let userId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
        }
        search = true;
      }
    });
    if (search) {
      this.loadCalendarsForSearch(userId, params);
    }
  }

  public clearSelection() {
    this.currentCalendar = new Calendar();
    this.state = "search";
    this.calendars = this.defaultCalendars;
    this.useIcsFile = false;
    this.events = [];
    this.editForm.reset();
  }

  public onCloseAttribs(attribs) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentCalendar.attributes = attribs;
      this.update();
    }
  }

  public close(): void {
    this.currentCalendar = new Calendar();
    this.events = [];
    this.appSearchTopBarComponent.clearSearchParams();
    this.onClose.emit(this.currentCalendar);
  }

  /* validators */
  public isValid(): boolean {
    if (isNullOrUndefined(this.currentCalendar))
      return false;
    return !!(this.currentCalendar.name) || !!(this.currentCalendar.data);
  }

  /* API */
  public save() {
    if (this.isValid()) {
      // this.logger.warn("CALENDARIO: "+JSON.stringify(this.currentCalendar));
      this.apiService.calendarService.createCalendar(this.currentCalendar).subscribe(value => {
        this.alertService.success("Guardado!", "Calendario guardado con exito");
      }, error1 => {
        this.alertService.error("Error al Guardar el Calendario", `Se produjo un error al guardar el Calendario`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public update() {
    if (this.isValid()) {
      this.apiService.calendarService.updateCalendar(this.currentCalendar.id, this.currentCalendar).subscribe(value => {
        this.alertService.success("Actualizado!", "Calendario actualizado con exito");
      }, error1 => {
        this.alertService.error("Error al Actualizar el Calendario", `Se produjo un error al actualizar el Calendario`);
        this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public delete() {
    this.apiService.calendarService.deleteCalendar(this.currentCalendar.id).subscribe(value => {
      this.alertService.success("Eliminado!", "El Calendario se ha eliminado con exito");
    }, error1 => {
      this.alertService.error("Error al eliminar el Calendario", `Se produjo un error al eliminar el Calendario`);
      this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`)
    }, () => {
      this.loadData();
      this.clearSelection();
    });
  }

  /* actions */
  public selectById(id: number): void {
    this.currentCalendar = this.calendars.find(value => value.id == id);
    this.formFields.name.setValue(this.currentCalendar.name);
  }

  public addNew() {
    this.state = 'new';
    this.currentCalendar = new Calendar();
    this.editForm.reset();
  }

  public onSubmit(): void {
    this.prepareData();
    if (this.state == "new") {
      this.save();
    } else {
      this.update();
    }
  }

  private prepareData() {
    //if (isNullOrUndefined(this.currentCalendar.data)) {
      //load with forms values.
      this.currentCalendar.data = btoa(this.createCalendarData());
      this.currentCalendar.name = this.formFields.name.value;
    //}
  }

  public onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      if(file.name.substr(file.name.lastIndexOf('.')+1) != 'ics'){
        this.alertService.error('Formato Incorrecto','Por favor seleccione un archivo con la extension .ics');
        return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.currentCalendar.data = reader.result.split(',')[1];
        this.loadEventsFromFile(atob(this.currentCalendar.data));
        // this.useIcsFile = true;
        // this.logger.warn("Se cargo la data"+this.currentCalendar.data);
      }
    }
  }

  public clearData() {
    this.currentCalendar.data = null;
    this.events = [];
  }

  private createCalendarData(): string {
    const iCal = new ICalendar(this.currentCalendar.name);
    //add Events
    this.events.forEach(value => {
      const event = new IEvent(value.start, value.end, value.summary, value.description, value.allDay);
      if (isNullOrUndefined(event.summary)) {
        event.description = this.currentCalendar.name;
        event.summary = this.currentCalendar.name;
      }
      iCal.addEvent(event);
      if (value.recurrence) {
        event.setRecurrentEvent(value.recurrenceMode, value.repeatMode, value.interval, value.byHour,
          value.byMinute, value.byDay, value.byMonth, value.byMonthDay, value.until, value.occurrence);
      }
    });
    // this.logger.warn('\n'+iCal.toString());
    return iCal.toString();
  }

  public addNewEvent(): void {
    this.events.push({start: new Date(), end: new Date(), description: 'Nuevo Evento', summary: 'Nuevo Evento', recurrence: false});
  }

  public loadEventsFromFile(data: string): void {
    const calendar = ICSHelper.parseCalendar(data);
    this.events = [];
    calendar.events.forEach(event => {
      const iEvent: CalendarEvent = {
        allDay: event.allDay,
        start: event.getStartDate(),
        end: event.getEndDate(),
        recurrence: event.recurrence,
        summary: event.summary,
        description: event.description,
        recurrenceMode: event.recurrenceMode,
        until: event.getUntilDate(),
        repeatMode: event.repeatMode,
        interval: event.interval,
        occurrence: event.occurrence,
        byDay: event.getByDay(),
        selectedRecurrenceMode : this.recurrenceModes.find(value => value.id == event.recurrenceMode),
      };
      if(event.allDay){
        if(!isNullOrUndefined(event.start))
          iEvent.start.setFullYear(Number(event.start.substr(0,4)),Number(event.start.substr(4,2))-1,Number(event.start.substr(6)));
        if(!isNullOrUndefined(event.end))
          iEvent.end.setFullYear(Number(event.end.substr(0,4)),Number(event.end.substr(4,2))-1,Number(event.end.substr(6)));
        else
          iEvent.end = iEvent.start;
      }
      if(!isNullOrUndefined(event.interval)){
        iEvent.interval = event.interval;
      }else{
        iEvent.interval = 1;
      }
      //selected Days
      if(!isNullOrUndefined(event.byDay)) {
        let days: Array<IModel> = [];
        this.daysOfWeek.forEach(value => {
          if (event.byDay.includes(value.id)) {
            days.push(value);
          }
        });
        iEvent.selectedDays = days;
      }
      //selected Months
      if(!isNullOrUndefined(event.byMonth)) {
        let months: Array<IModel> = [];
        let byMonths: Array<string> = event.byMonth.split(',');
        this.monthsOfYear.forEach(value => {
          if (byMonths.indexOf(value.id) !== -1) {
            months.push(value);
          }
        });
        iEvent.selectedMonths = months;
      }
      //selected MonthsDays
      if(!isNullOrUndefined(event.byMonthDay)) {
        let monthsDays: Array<IModel> = [];
        let byMonths: Array<string> = event.byMonthDay.split(',');
        this.daysOfMonth.forEach(value => {
          if (byMonths.indexOf(value.id) !== -1) {
            monthsDays.push(value);
          }
        });
        iEvent.selectedMonthDays = monthsDays;
      }
      this.events.push(iEvent);
    });
  }

  public deleteEvent(event: CalendarEvent) {
    const index = this.events.findIndex(value => value.summary == event.summary);
    if (index !== -1) {
      this.events.splice(index, 1);
    }
  }


  //ICS
  public changeRecurrenceMode(mode: IModel, event: CalendarEvent) {
    //this.selectedRecurrenceMode = mode;
    const index = this.events.findIndex(value => value == event);
    if (index !== -1) {
      this.events[index].recurrenceMode = <any>mode.id;
      // this.events[index].selectedRecurrenceMode = mode;
    }
  }

  public changeDaysSelected(days: Array<IModel>, event: CalendarEvent) {
    //this.selectedDays = days;
    const index = this.events.findIndex(value => value == event);
    if (index !== -1) {
      const calendarDays = [];
      days.forEach(value => {
        calendarDays.push(value.id);
      });
      this.events[index].byDay = calendarDays;
      // this.events[index].selectedDays = days;
    }
  }

  // public changeHoursSelected(hours: Array<any>, event: CalendarEvent) {
  //   this.selectedHours = hours;
  //   const index = this.events.findIndex(value => value == event);
  //   if (index !== -1) {
  //     const hours = [];
  //     this.selectedHours.forEach(value => {
  //       hours.push(value.id);
  //     });
  //     this.events[index].byHour = hours;
  //   }
  // }

  public changeMonthDaysSelected(monthDays: Array<any>, event: CalendarEvent) {
    // this.selectedMonthDays = monthDays;
    const index = this.events.findIndex(value => value == event);
    if (index !== -1) {
      const daysOfMonth = [];
      monthDays.forEach(value => {
        daysOfMonth.push(value.id);
      });
      this.events[index].byMonthDay = daysOfMonth;
      // this.events[index].selectedMonthDays = monthDays;
    }
  }

  public changeMonthSelected(months: Array<any>, event: CalendarEvent) {
    // this.selectedMonths = months;
    const index = this.events.findIndex(value => value == event);
    if (index !== -1) {
      const cmonths = [];
      months.forEach(value => {
        cmonths.push(value.id);
      });
      this.events[index].byMonth = cmonths;
      // this.events[index].selectedMonths = months;
    }
  }


  public onChangeRecurrence(state: boolean, event: CalendarEvent) {
    if (state) {
      event.repeatMode = "FOREVER";
      event.recurrenceMode = "DAILY";
      event.interval = 1;
    }
  }

  public editCurrentCalendar() {
    this.state = 'edit';
    //we try to load data
    const data: string = atob(this.currentCalendar.data);
    const calendar = ICSHelper.parseCalendar(data);
    // this.logger.warn("CALENDAR:\n"+JSON.stringify(calendar));
    this.events = [];
    calendar.events.forEach(event => {
      const iEvent: CalendarEvent = {
        start: event.getStartDate(),
        end: event.getEndDate(),
        recurrence: event.recurrence,
        summary: event.summary,
        description: event.description,
        recurrenceMode: event.recurrenceMode,
        until: event.getUntilDate(),
        repeatMode: event.repeatMode,
        allDay: event.allDay,
        interval: event.interval,
        occurrence: event.occurrence,
        byDay: event.getByDay(),
        selectedRecurrenceMode : this.recurrenceModes.find(value => value.id == event.recurrenceMode),
      };
      //selected Days
      if(!isNullOrUndefined(event.byDay)) {
        let days: Array<IModel> = [];
        this.daysOfWeek.forEach(value => {
          if (event.byDay.includes(value.id)) {
            days.push(value);
          }
        });
        iEvent.selectedDays = days;
      }
      //selected Months
      if(!isNullOrUndefined(event.byMonth)) {
        let months: Array<IModel> = [];
        let byMonths: Array<string> = event.byMonth.split(',');
        this.monthsOfYear.forEach(value => {
          if (byMonths.indexOf(value.id) !== -1) {
            months.push(value);
          }
        });
        iEvent.selectedMonths = months;
      }
      //selected MonthsDays
      if(!isNullOrUndefined(event.byMonthDay)) {
        let monthsDays: Array<IModel> = [];
        let byMonths: Array<string> = event.byMonthDay.split(',');
        this.daysOfMonth.forEach(value => {
          if (byMonths.indexOf(value.id) !== -1) {
            monthsDays.push(value);
          }
        });
        iEvent.selectedMonthDays = monthsDays;
      }
      //
      //selected days;
      // this.logger.warn("EVENTO::\n"+JSON.stringify(iEvent));
      this.events.push(iEvent);
    });
  }

  private configureTableData() : void {
    this.columns = [
      {key: 'name', title: 'Nombre', cellTemplate: this.nameTpl, width: '100%'},
      {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '20%'}
    ];
    this.configuration.checkboxes = false;
  }

  //table *events*
  public onClickRow(event) {
    switch (event.event) {
      case 'onDoubleClick':
        this.edit(event.value.row.id);
        break;
    }
  }

  public edit(id: number) : void {
    this.selectById(id);
    this.editCurrentCalendar();
  }

  public openDeleteDialog(id: number) : void {
    this.selectById(id);
    this.deleteSwal.show();
  }

  public openAttributes(id: number): void {
    this.selectById(id);
    this.isVisibleAttributesCompact = true;
  }

  public onCancelEdit() : void{
    //restore data
    this.confirmSwal.show();
  }
}
