import {Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {Event} from "@app/core/models/event.model";
import {ApiService} from "@app/shared/services/api.service";
import {NGXLogger} from "ngx-logger";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IReportContext} from "@app/core/interfaces/ireport-context.interface";
import {IBundle} from "@app/core/interfaces/ibundle.interface";
import {ModelPageState, ReportGraphType} from "@app/core/types/buho-core-types";
import {AlertService} from "@app/shared/services/alert.service";
import {DatePipe, DOCUMENT} from "@angular/common";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {Dictionary, Set} from "typescript-collections";
import {Geofence} from "@app/core/models/geofence.model";
import {IReportBundle} from "@app/core/interfaces/ireport-bundle";
import {ITableColumn} from "@app/core/interfaces/itable-column.interface";
import {Maintenance} from "@app/core/models/maintenance.model";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {Config} from "ngx-easy-table";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Position} from "@app/core/models/position.model";
import {Device} from "@app/core/models/device.model";
import {User} from "@app/core/models/user.model";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

import {IMarker} from "@app/core/interfaces/imarker.interface";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {IMapOptions} from "@app/core/interfaces/imap-options.interface";
import {IPosition} from "@app/core/interfaces/iposition";
import {KeysPipePipe} from "@app/shared/pipes/keys-pipe.pipe";
import {
  ControlPosition,
  FullscreenControlOptions,
  MapTypeControlOptions,
  MapTypeControlStyle,
  MapTypeId
} from "@agm/core/services/google-maps-types";
import {GoogleMapsAPIWrapper} from "@agm/core";

declare var google: any;

@Component({
  selector: 'app-report-route-bundle',
  templateUrl: './report-route-bundle.component.html',
  styleUrls: ['./report-route-bundle.component.css']
})
export class ReportRouteBundleComponent implements OnInit, IBundle, IReportBundle {

  public state: ModelPageState = 'search';

  //Search
  public searchOptions: Array<ISearchParam> = [];

  //Reports
  public report: ReportGraphType = 'line';

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();


  @Input()
  public printTableSectionId: string = 'printTableSectionId';

  //DATATABLE
  public totals: any = {};
  public maxSpeed: number =0;
  public averageSpeed: number =0;
  public totalDistance: number =0;
  public isLoading: boolean = false;
  public isLoadingPre: boolean = false;
  public selectedRow: Event;
  public selectedPage: number = 1;
  public swPause: boolean = false;
  public timer: any;
  public polygon: any;
  public countAnimation: number = 0;
  public positionPercent: Array<any> = [];
  public positionActualMap: Array<any> = [];

  public pipe = new DatePipe('es-BO');

  /* smart table */
  public configuration: Config;
  public columns;
  //Data
  public rows: Array<any> = [];
  public temp: Array<any> = [];

  //Google maps
  public lat: number = -17.430747;
  public lng: number = -66.12587;
  public zoom: number = 14;
  public map: any;
  public selectedOverlay: any;
  public ready: boolean = false;
  public styles = [];
  public get markers(): Dictionary<number, IMarker>{
    return this.markersValue;
  }

  @Output()
  onMarkerClicked: EventEmitter<IMarker> = new EventEmitter<IMarker>();

  @Input()
  public set markers(markers: Dictionary<number, IMarker> ){
    this.markersValue = markers;
  }
  public markersValue: Dictionary<number, IMarker> = new Dictionary<number, IMarker>();
  public mapTypeId: string = 'roadmap';
  @Input()
  public focusZoom: number = 17;
  public deviceVisibleIds: Array<number> = [];
  public geofenceVisibleIds: Array<number> = [];
  public geofenceOverlay: Dictionary<number, any> = new Dictionary<number, any>();

  @Input()
  public limitPage: number = 10;

  private _filterDate = new DatePipe("es-BO");

  @Input()
  public context: IReportContext;

  public graphData = [];
  public separateDataDevice = [];
  public deviceIds = [];

  /** Dependencies **/
  public devices: Array<Device> = [];
  public geofences: Array<Geofence> = [];
  public maintenances: Array<Maintenance> = [];


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  //Graph reports
  colorScheme = {
    domain: ['#1E2F23', '#6435C9', '#FBBD08', '#B5CC18', '#F2711C', '#40BCD8', '#A5673F', '#767676', '#21BA45', '#DB2828', '#E03997']
  };

  private _user: User;
  private role: any;
  public selectedGrouped: IModel;
  public groupOptions: Array<IModel>;

  constructor(private configService: WebConfigurationService,
              private apiService: ApiService,
              private alertService: AlertService,
              private readonly cdr: ChangeDetectorRef,
              @Inject(DOCUMENT) private document: Document,
              private logger: NGXLogger) {

    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];

    this.loadDependencies();

    /* Search options */
    this.searchOptions = [{
      name: 'Dispositivo',
      value: '',
      color: 'blue',
      field: 'device',
      type: 'select',
      options: [],
      remote: false
    }, {
      name: 'Geocerca',
      value: '',
      color: 'purple',
      field: 'geofence',
      type: 'select',
      options: [],
      remote: false
    }];
  }

  ngOnInit() {
    //table configuration
    this.configureTableData();
  }

  public configureTableData() {
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.columns = [
      {key: 'time', name: 'Fecha', order: 'none'},
      {key: 'device', name: 'Dispositivo', order: 'none'},
      {key: 'latitude', name: 'Latitud', order: 'none'},
      {key: 'longitude', name: 'Longitud', order: 'none'},
      {key: 'altitude', name: 'Altitud', order: 'none'},
      {key: 'speed', name: 'Velocidad', order: 'none'},
      {key: 'valid', name: '¿Es Valida?', order: 'none'},
      {key: 'attributes', name: 'Atributos', orderEnabled: false, width: '20%'}
    ];
    this.configuration.checkboxes = false;
    this.configuration.paginationEnabled = true;
    this.configuration.paginationRangeEnabled = false;
    this.configuration.tableLayout.striped = false;
    this.configuration.groupRows = false;
    this.configuration.rows = this.limitPage;

    //group by
    this.groupOptions = [];
    //fill group by
    /*this.groupOptions.push({id:'type',name:'type', display:'Tipo de Evento'});
    this.groupOptions.push({id:'device',name:'device',display:'Dispositivo'});
    this.groupOptions.push({id:'geofence',name:'geofence',display:'Geocerca'});
    this.groupOptions.push({id:'maintenance',name:'maintenance',display:'Mantenimiento'});*/

    this.selectedGrouped=this.groupOptions[0];
  }

  public loadData(reportContext: IReportContext) {
    this.isLoading = true;
    this.context = reportContext;
    this.configureTableData();

    this.apiService.reportService.reportsRouteGet(
      this.context.from,
      this.context.to,
      this.context.deviceIds,
      this.context.groupIds
      ).subscribe(value => {
      if (isNullOrUndefined(value)) {
        return;
      }
      this.rows = [];
      this.temp = [];
      const formatted = this.formatData(value);
      this.temp = [...formatted];
      this.rows = [...formatted];

      this.preparePositions();
      this.cdr.detectChanges();
    }, error1 => {
      this.alertService.error("Sucedio un error al cargar los datos", error1.toString());
    }, () => this.isLoading = false);
  }

  public loadDependencies() {
    //devices
    this.apiService.deviceService.getDevices(this.role == 'root').subscribe(value => {
      this.devices = value;
    },error1 => this.logger.error("Se produjo un error al cargar los dispositivos."),() => {
      //UserId
      let indexDeviceId = this.searchOptions.findIndex(value => value.field == 'device');
      let options: Array<IModel> = [];
      this.devices.forEach(device => {
        options.push({id: device.id.toString(), name: device.name, display: device.name});
      });
      if (indexDeviceId !== -1) {
        this.searchOptions[indexDeviceId].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });
    //geofences
    this.apiService.geofenceService.getGeofences(this.role == 'root').subscribe(value => {
      this.geofences = value;
      //GEOFENCES SEARCH
      let index = this.searchOptions.findIndex(value => value.field == 'geofence');
      let options: Array<IModel> = [];
      value.forEach(geofence => {
        options.push({id: geofence.id.toString(), name: geofence.name, display: geofence.name});
      });
      if (index !== -1) {
        this.searchOptions[index].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });

  }

  public formatData(data: Array<Position>): Array<any> {
    let formatted = [];
    let devices: Set<number> = new Set<number>();
    let total: number = 0;
    let speed: number = 0;
    this.clearTotals();

    data.forEach(position => {
      if (!isNullOrUndefined(position.deviceId))
        devices.add(position.deviceId);
      formatted.push({
        fixTime: position.fixTime,
        serverTime: position.serverTime,
        valid: position.valid ? 'Si' : 'No',
        latitude: position.latitude,
        longitude: position.longitude,
        altitude: position.altitude,
        speed: position.speed,
        deviceId: position.deviceId,
        device: position.deviceId,
        attributes: position.attributes,
      });
      speed = position.speed + speed;
      total++;
    });
    this.totals = {
      devices: devices.size(),
      avgSpeed: speed / formatted.length,
      total: total
    };
    return formatted;
  }

  close(): void {
    this.onClose.emit();
  }

  public calculateTotals(temp: Array<any>) {
    let counts: any = {};
    for (let i = 0; i < temp.length; i++) {
      counts[temp[i].device] = 1 + (counts[temp[i].device] || 0);
      counts[temp[i].speed] = counts[temp[i].speed] + temp[i].speed;
    }
    this.totals = {
      devices: counts.devices,
      avgSpeed: counts.speed/temp.length,
      total: temp.length
    };
  }

  public onSearch(params: Array<ISearchParam>) {
    let temp = this.temp;
    params.forEach(param => {
      if (param.field == 'type') {
        temp = temp.filter(value => value.type.toLowerCase().includes(param.value.toLowerCase()));
      } else if (param.field == 'device') {
        temp = temp.filter(value => value.device.toLowerCase().includes(param.value.toLowerCase()));
      } else if (param.field == 'geofence') {
        temp = temp.filter(value => value.geofence.toLowerCase().includes(param.value.toLowerCase()));
      }
    });
    if (temp != this.rows) {
      this.rows = [...temp];
      this.preparePositions();
      this.calculateTotals(temp);
    }
  }


  public validateContext(reportContext: IReportContext, alert: boolean = false): boolean {
    if (isNullOrUndefined(reportContext)) {
      if (alert)
        this.alertService.error("Error al cargar el reporte", "No existe un contexto valido.");
      return false;
    } else {
      if (isNullOrUndefined(reportContext.from)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha inicial valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.to)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha final valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.deviceIds) || reportContext.deviceIds.length <= 0) {
        if (alert)
          this.alertService.error("Sin Dispositivos", "Debe seleccionar al menos un disposito.");
        return false;
      } else {
        return true;
      }
    }
  }


  //base
  public clearTotals() {
    this.totals = {devices: 0, avgSpeed: 0, total: 0};
  }

  //base

  public print() {
    let popupWinindow;
    let innerContents = document.getElementById(this.printTableSectionId).innerHTML;
    popupWinindow = window.open('', '_blank', 'top=0,left=0,height=100%,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    let styles: Array<string> = [];
    const styleElements = document.getElementsByTagName('style');
    for (let idx = 0; idx < styleElements.length; idx++) {
      styles.push(styleElements[idx].outerHTML);
    }
    popupWinindow.document.write(`<html><head><title>REPORTE DE RUTAS. [${this.context.from.toLocaleDateString()} - ${this.context.to.toLocaleDateString()}]</title>${styles.join('\r\n')}</head><body onload="window.print();window.close();">${innerContents}</html>`);
    popupWinindow.document.close();
  }

  public loadDeviceName(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].name;
      }
    }
  }

  public loadDeviceIcon(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].icon;
      }
    }
  }

  public getDevicesTemp(): Array<any>{
    let dic: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();
    this.rows.forEach(value => {
      if (!dic.containsKey(value.deviceId)) {
        dic.setValue(value.deviceId, []);
      }
    });
    this.deviceIds = dic.keys();
    return this.deviceIds;
  }

  public preparePositions() : void {
    let datePerDay:string;

    let deviceIds = this.getDevicesTemp();
    deviceIds.forEach(deviceId => {
      let dic: Dictionary<string, Array<any>> = new Dictionary<string, Array<any>>();
      let data = this.rows.filter(value => value.deviceId == deviceId);
      data.forEach(value => {
        datePerDay = this._filterDate.transform(value.serverTime, 'yyyy-MM-dd');
        if (!dic.containsKey(datePerDay)) {
          let filter = data.filter(value => this._filterDate.transform(value.serverTime, 'yyyy-MM-dd') == datePerDay);
          filter.sort();
          dic.setValue(datePerDay, filter);
        }
      });

      const auxDate = dic.keys();
      let array = [];
      auxDate.forEach(datePerDay => {
        this.getTotalRoute(dic.getValue(datePerDay));
        let pointStartAndEnd = this.formatToMarkerPointToPoint(dic.getValue(datePerDay));
        let markers = this.formatToMarker(dic.getValue(datePerDay));
        let pointValues = dic.getValue(datePerDay);
        array.push({
          date: datePerDay,
          dateDisplay: this._filterDate.transform(datePerDay, "EEEE d 'de' MMMM 'del' yyyy"),
          lat: this.lat,
          lng: this.lng,
          totalDistance: this.totalDistance,
          maxSpeed: this.maxSpeed,
          averageSpeed: this.averageSpeed,
          positionsMarker: markers,
          pointValues: pointValues,
          pointStartAndEnd: pointStartAndEnd,
          showMap: false
        });
      });

      array.sort();
      this.separateDataDevice.push({
        deviceId: deviceId,
        values: array
      });
    });
    this.isLoadingPre = true;
  }

  downloadReport(reportContext?: IReportContext) {
    if (!isNullOrUndefined(reportContext))
      this.context = reportContext;
    if (!isNullOrUndefined(this.context)) {
      if (isNullOrUndefined(this.rows) || this.rows.length == 0) {
        this.alertService.warning("Imposible Descargar", "No hay datos para descargar");
        return;
      }
      this.isLoading = true;
      this.apiService.reportService.reportsRouteGetXLSX(
        this.context.from,
        this.context.to,
        this.context.deviceIds,
        this.context.groupIds
        ).subscribe(value => {
        if (isNullOrUndefined(value)) {
          this.alertService.error("Sucedio un error", "No puede descargarse el reporte");
          return;
        }
        //this.logger.warn("DATA DOWNLOADED "+JSON.stringify(value));
        let newBlob = new Blob([value], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.createNameFile("reporte_", ".xlsx");
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      }, error1 => {
        this.alertService.error("Sucedio un error al cargar descargar", error1.toString());
      }, () => this.isLoading = false);
    }
  }

  private createNameFile(prefix?: string, extension?: string) {
    let ans = `Rutas ${this.context.from.toISOString()}_${this.context.to.toISOString()}`;
    if (!isNullOrUndefined(prefix)) {
      ans = prefix + ans;
    }
    if (!isNullOrUndefined(extension)) {
      ans = ans + extension;
    }
    return ans;
  }

  // Google Maps
  public mapTypeControlOptions : MapTypeControlOptions = {
    mapTypeIds: [MapTypeId.ROADMAP, MapTypeId.SATELLITE],
    position : ControlPosition.TOP_RIGHT,
    style : MapTypeControlStyle.HORIZONTAL_BAR
  };

  public fullscreenControlOptions :FullscreenControlOptions = {
    position: ControlPosition.BOTTOM_LEFT
  };

  markerClicked(marker: IMarker) {
    //alert("esta entrando" + marker.latitude + " -- " + marker.longitude);
    //this.centerMapToMarker(marker);
    //this.onMarkerClicked.emit(marker);
  }
  centerMapToMarker(marker: IMarker) {
    if (isNullOrUndefined(this.map)) return;
    const position = new google.maps.LatLng(marker.latitude, marker.longitude);
    // this.zoom = 18;
    this.map.panTo(position);
    // this.map.setCenter(position);
    this.map.setZoom(this.focusZoom);
  }

  mapReady(map, positions: Array<IMarker>) {
    this.map = map;
    this.ready = true;
    this.map.setMapTypeId(this.mapTypeId);
    this.centerMapToMarkersPositions(positions);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('AnimationPlay'));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('AnimationPause'));
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('AnimationReset'));

    this.drawRoutes(positions);
  }

  public centerMapToMarkersPositions(markers: Array<IMarker>) {
    if(this.ready){
      let bounds = new google.maps.LatLngBounds();
      markers.forEach(point => {
        bounds.extend(new google.maps.LatLng(point.latitude, point.longitude));
      });

      this.map.fitBounds(bounds);
      this.map.panToBounds(bounds);
    }
  }

  public configure(mapOptions: IMapOptions) {
    this.deviceVisibleIds = mapOptions.visibleDeviceIds;
    this.geofenceVisibleIds = mapOptions.visibleGeofenceIds;
    this.zoom = mapOptions.zoom;
    this.lat = mapOptions.latitude;
    this.lng = mapOptions.longitude;
    if(!isNullOrUndefined(mapOptions.styles))
      this.styles = mapOptions.styles;
    if(!isNullOrUndefined(mapOptions.mapTypeId)) {
      this.mapTypeId = mapOptions.mapTypeId;
      if(!isNullOrUndefined(this.map))
        this.map.setMapTypeId(this.mapTypeId);
    }
    this.focusZoom = mapOptions.focusZoom;
  }

  public showLineGraph() {
    this.prepareLineGraphData();
    this.cdr.detectChanges();
    this.report = 'line';
    this.state = 'new';
  }

  prepareGraphData(): void {}
  prepareLineGraphData(): void {
    this.graphData = [];
    let rows = this.separateDataDevice;
    let dicDate: Dictionary<string, Array<any>> = new Dictionary<string, Array<any>>();
    rows.forEach(values => {
      let auxDate = values.values;
      auxDate.forEach( row => {
        if (!dicDate.containsKey(row.date)) {
          dicDate.setValue(row.date, []);
        }
      });
    });

    let valueDate = dicDate.keys();
    valueDate.sort();

    rows.forEach(value => {
      let auxDate = value.values;
      let array = [];
      auxDate.forEach( row => {
        let speed: number = (Number(row.maxSpeed)* 1.852);
        speed = Math.round(speed * 100) / 100;
        let datePerDay = this._filterDate.transform(row.date, 'dd/MM/yyyy');
        array.push({name: datePerDay, value: speed});
      });
      array.sort();

      this.graphData.push({
        name: this.loadDeviceName(value.deviceId),
        series: array
      });
    });
  }

  private formatToMarker(value: Array<any>): Array<IMarker> {
    let result :  Array<IMarker>  = [];
    value.forEach(value1 => {
      result.push({
        latitude: value1.latitude,
        longitude: value1.longitude,
        iconURL: './assets/images/map/svg/thoseicons/019-pin-4.svg',
        deviceId: value1.deviceId
      });
    });
    return result;
  }

  private formatToMarkerPointToPoint(value: Array<any>): Array<IMarker> {
    let result :  Array<IMarker>  = [];
    let i = 0;
    value.forEach(value1 => {
      if(i == 0)
      result.push({
        latitude: value1.latitude,
        longitude: value1.longitude,
        iconURL: './assets/images/map/svg/thoseicons/start-marker.svg',
        deviceId: value1.deviceId
      });
      if(i == value.length-1)
        result.push({
          latitude: value1.latitude,
          longitude: value1.longitude,
          iconURL: './assets/images/map/svg/thoseicons/end-marker.svg',
          deviceId: value1.deviceId
        });
      i++;
    });
    return result;
  }

  private getTotalRoute(value: Array<any>): void{
    let speed:number = 0;
    let maxSpeed:number = 0;
    let totalPoints:number = 0;
    let distance:number = 0;
    value.forEach(value1 => {
      if(totalPoints < 1){
        this.lat = value1.latitude;
        this.lng = value1.longitude;
      }
      let attributes = value1.attributes;
      distance = distance + attributes.totalDistance;
      if(value1.speed > maxSpeed){
        maxSpeed = value1.speed;
      }
      speed = speed + value1.speed;
      totalPoints = totalPoints + 1;
    });

    let averageSpeed = speed / totalPoints;
    this.maxSpeed = maxSpeed;
    this.averageSpeed = averageSpeed;
    this.totalDistance = distance;
  }

  private drawRoutes(positions : Array<IMarker>) : void {
    let pos: Array<IPosition> = [];
    positions.forEach(value => {
      pos.push({lat: value.latitude, lng: value.longitude});
    });

    this.polygon = new google.maps.Polyline({
      path: pos,
      strokeColor: '#287FB8',
      strokeOpacity: 0.9,
      strokeWeight: 4,
      draggable: false,
      animation: google.maps.Animation.DROP,
      visible: true,
      editable: false,
      icons: [{
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          fillColor: '#595959',
          fillOpacity: 1,
          scale: 6,
          strokeWeight: 2
        },
        offset: '0%'
      }],
      map: this.map
    });
    //this.polygon.setMap(this.map);
  }

  playAnimation(positions: Array<any>){
    this.swPause = true;
    this.positionActualMap = [];
    let totalPositions = positions.length;
    let positionPercent = 100 / totalPositions;
    let countPercent: number = 1;

    positions.forEach(value => {
      this.positionPercent.push({
        lat: value.latitude,
        lng: value.longitude,
        percent: (positionPercent*countPercent).toFixed(2),
        numPoint: countPercent,
        speed: value.speed,
        serverTime: this._filterDate.transform(value.serverTime, 'dd/MM/yyyy - HH:mm:ss')
      });
      countPercent++;
    });

    let count = this.countAnimation;
    if(count < 100){
      clearInterval(this.timer);
      this.timer = setInterval(function(polygon){
        if (count <= 100) {
          const icons = polygon.get('icons');
          icons[0].offset = count + '%';
          polygon.set('icons', icons);
        }
        count++;
      }, 130, this.polygon);
    }

  }

  pauseAnimation(){
    this.swPause = false;
    clearInterval(this.timer);
    this.getValuesPosition();
    this.positionPercent = [];
  }

  getValuesPosition(){
    const icons = this.polygon.get('icons');
    this.countAnimation = parseFloat(icons[0].offset);

    let oldPercent:number = 0;
    this.positionPercent.forEach(value => {
      let percent = parseFloat(value.percent);
      if(this.countAnimation >= oldPercent && this.countAnimation <= percent ){
        this.positionActualMap.push({
          lat: value.latitude,
          lng: value.longitude,
          numPoint: value.numPoint,
          totalPoints: this.positionPercent.length,
          speed: value.speed,
          serverTime: value.serverTime
        });
      }
      oldPercent = parseFloat(value.percent);
    });
  }

  resetAnimation(){
    this.swPause = false;
    clearInterval(this.timer);
    this.countAnimation = 0;
    const icons = this.polygon.get('icons');
    icons[0].offset = 0 + '%';
    this.polygon.set('icons', icons);
  }

}
