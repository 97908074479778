import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {SessionService} from "@app/core/service/session.service";
import {isNullOrUndefined} from "util";
import {LocalService} from "@app/core/service/local.service";
import {HttpEvent, HttpHandler, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!isNullOrUndefined(LocalService.currentUser)) {
    // if (!isNullOrUndefined(sessionStorage.getItem('auth'))) {
      // logged in
      // check permissons.
      return true;
    }else{
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/session'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }

}
