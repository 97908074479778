import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModelPageState} from "@app/core/types/buho-core-types";
import {User} from "@app/core/models/user.model";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {Notification} from "@app/core/models/notification.model";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-notification-lite',
  templateUrl: './notification-lite.component.html',
  styleUrls: ['./notification-lite.component.css']
})
export class NotificationLiteComponent implements OnInit {

  public notifications: Array<Notification>;
  public currentNotification: Notification;

  public isLoading: boolean = false;
  public state: ModelPageState = 'search';

  public notificationIds: Array<number>; //all the notifications Ids to add the device.

  private _user: User;
  public search : string;
  public role: string;

  constructor(private apiService:ApiService, private alertService:AlertService) { }

  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];
    this.loadData();
  }

  public loadData(){
    this.isLoading = true;
    this.apiService.notificationService.getNotifications().subscribe(value => {
      this.notifications = value;
    }, error1 => {
      this.alertService.error("Error al cargar datos", "Verifique la conectividad.");
    }, () => {
      this.isLoading = false;
    });
  }

  public checkRole(action: RolePermission):boolean {
    if(this.role == 'root' && this._user.administrator)
      return true;
    switch (action) {
      case 'edit':
        return this.role == 'administrator' || this.role == 'supervisor';
      case 'delete':
        return this.role == 'administrator' || this.role == 'supervisor';
      case 'create':
        return this.role == 'administrator' || this.role == 'supervisor';
    }
  }

  public addNewNotification() {
    this.state = 'new';
    this.currentNotification = new Notification();
  }

  public saveNotification(){
    this.apiService.notificationService.createNotification(this.currentNotification).subscribe(value => {
      console.log("NOTIF: "+JSON.stringify(value));
      this.state = 'search';
    },error1 => {
      this.alertService.error("Se produjo un error","No pudo guardarse la notificacion");
    }, () => this.alertService.success("Alerta Agregada","Se ha agregado la alerta con exito!"));
  }

}
