import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnInit {

  @Input()
  public placeholder: string;

  @Input()
  public type : string = 'text';

  public ngModelValue : any;

  @Output()
  public ngModelChange: EventEmitter<any> = new EventEmitter();
  // searchInputTextChanged: Subject<string> = new Subject<string>();

  @Input()
  public get ngModel(): any{
    return this.ngModelValue;
  }

  public set ngModel(ngModel: any){
    this.ngModelValue = ngModel;
    this.ngModelChange.emit(ngModel);
  }

  constructor() { }

  ngOnInit() {
  }

  public changeInputText(text:any){
    this.ngModelValue = text;
    this.ngModelChange.emit(this.ngModelValue);
  }
}
