import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModelPageState} from "@app/core/types/buho-core-types";
import {Device} from "@app/core/models/device.model";
import {Notification} from "@app/core/models/notification.model";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {User} from "@app/core/models/user.model";
import {NGXLogger} from "ngx-logger";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {FilterNotificationBySearchParamPipe} from "@app/core/pipes/filter-notification-by-search-param.pipe";
import {isNullOrUndefined} from "util";
import {Notificator} from "@app/core/models/notificator";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-notification-select',
  templateUrl: './notification-select.component.html',
  styleUrls: ['./notification-select.component.css']
})
export class NotificationSelectComponent implements OnInit {

  @Input()
  public state: ModelPageState = "search";
  @Input()
  public selectOnly: boolean = false;

  @Input()
  public device: Device;

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  //NOTIFICATIONS FROM API - THIS IS USE TO SHOW IN
  public notifications: Array<Notification>;
  public defaultNotifications: Array<Notification>;
  //DEVICE ACTUAL NOTIFICATIONS
  private deviceNotifications: Array<Notification>;
  //Show All Global Notifications.
  //Sort
  public sortOrder: string;

  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;
  public pipe: NotificationTypePipe = new NotificationTypePipe();//Pipe for converting NotificationTypes
  public filter: FilterNotificationBySearchParamPipe = new FilterNotificationBySearchParamPipe();
  //User
  private _user: User;

  //
  public notificators: Array<Notificator>;

  //for new notifications
  public currentNotification: Notification;

  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  public isVisibleAttributesCompact: boolean = false;

  // public keyAttributesOptions: Array<string>;

  public viewMode: string = 'list';

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private logger: NGXLogger) {

    // this.keyAttributesOptions = ['forwardTo',''];

    this.searchOptions = [{
      name: 'Tipo',
      value: '',
      color: 'green',
      field: 'type',
      type: 'text',
      remote: false
    }, {
      name: 'Medio',
      value: '',
      color: 'blue',
      field: 'notificators',
      type: 'select',
      options: [],
      selectValue: undefined,
      remote: false
    }];

  }

  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser"));
    this._user = LocalService.currentUser;
  }

  public loadData(fastLoad: boolean = true) {
    this.isLoading = true;
    this.apiService.notificationService.getNotifications().subscribe(value => {
      this.notifications = value.sort((a, b) => {
        if (a.always && !b.always)
          return 1;
        else if (!a.always && b.always)
          return -1;
        else return 1;
      });
      this.defaultNotifications = value;
      // this.logger.warn("NOTIFICATIONS: "+JSON.stringify(value));
    }, error1 => {
      this.alertService.error('Error al cargar los datos', 'Verifique sus credenciales.');
      this.logger.error('Error al cargar los datos de las Alertas: ' + error1.toString());
    }, () => {
      this.apiService.notificationService.getNotifications(undefined, undefined, this.device.id, undefined, true).subscribe(value => {
        this.deviceNotifications = value;
        // this.logger.warn("NOTIFICATIONS DISP.: "+JSON.stringify(value));
      }, error1 => {
        this.alertService.error('Error al cargar las alertas del dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al cargar las alertas del dispositivo: ' + error1.toString());
      }, () => {
        this.isLoading = false;
        // this.logger.warn("temrino de cargar");
      });
    });
    if (fastLoad) {
      this.loadNotificators();
    }
  }

  private loadNotificators() {
    this.apiService.notificationService.getNotificators().subscribe(value => {
      let options: Array<IModel> = [];
      value.forEach(type1 => {
        options.push({
          name: type1.type,
          display: type1.type,
        });
      });
      this.searchOptions[1].options = options;
      this.notificators = value;
    });
  }

  private save(notifications) {
    this.apiService.permissionService.permissionsArrayPost(notifications).subscribe(value => {
    }, error1 => {
      this.logger.error(`Error al agregar las notificaciones: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Guardado', 'Las alertas han sido agregadas.');
      //reload data
      this.loadData();
    });
  }

  private remove(notifications) {
    this.apiService.permissionService.permissionsArrayDelete(notifications).subscribe(value => {
    }, error1 => {
      this.logger.error(`Error al quitar las notificaciones: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Eliminado', 'Las alertas han sido removidas.');
      //reload data
      this.loadData();
    });
  }


  private encodeToPermission(current: Array<Notification>): any {
    let permissions = [];
    current.forEach(current => {
      permissions.push({
        deviceId: this.device.id,
        notificationId: current.id
      })
    });
    return permissions;
  }

  /* SEARCH */
  public onSearch(params: Array<ISearchParam>) {
    this.notifications = this.filter.transform(this.defaultNotifications, params).sort((a, b) => {
      if (a.always && !b.always)
        return 1;
      else if (!a.always && b.always)
        return -1;
      else return 1;
    });
  }

  /* VIEW OPERATIONS */
  public select(notification: Notification) {
    if (this.currentNotification != notification) {
      this.currentNotification = notification;
      this.state = "select";
    } else {
      this.clearSelection();
    }
  }

  public clearSelection(){
    this.currentNotification = new Notification();
    this.state = "search";
  }

  public close() {
    this.onClose.emit();
  }

  public toogleAll(flag: boolean) {
    if (flag) {
      let valuesTo = [];
      this.notifications.forEach(value => {
        if (!value.always) {
          let index = this.deviceNotifications.findIndex(current => current.id == value.id);
          if (index === -1) { //no existe agregamos.
            valuesTo.push({
              deviceId: this.device.id,
              notificationId: value.id
            });
          }
        }
      });
      this.save(valuesTo);
    } else {
      this.remove(this.encodeToPermission(this.deviceNotifications));
    }
  }

  public toogle(current: Notification, state: boolean) {
    if (state) {//existe.. apagamos.
      this.logger.info("toggle ON: " + JSON.stringify(current));
      this.apiService.permissionService.permissionsPost({
        deviceId: this.device.id,
        notificationId: current.id
      }).subscribe(value => {
        this.appSearchTopBarComponent.clearSearchParams();
        this.loadData(false);
      }, error1 => {
        this.alertService.error('Error al activar la alerta en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al activar la alerta en el dispositivo. ' + error1.toString());
      });
    } else {//no existe.. agregamos
      this.logger.info("toggle oFF: " + JSON.stringify(current));
      this.apiService.permissionService.permissionsDelete({
        deviceId: this.device.id,
        notificationId: current.id
      }).subscribe(value => {
        this.appSearchTopBarComponent.clearSearchParams();
        this.loadData(false);
      }, error1 => {
        this.alertService.error('Error al desactivar la alerta en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al desactivar la alerta en el dispositivo. ' + error1.toString());
      });
    }
    this.state = 'search';
  }

  public isNotificationOn(current: Notification): boolean {
    if (!isNullOrUndefined(this.deviceNotifications))
      return this.deviceNotifications.findIndex(value => current.id == value.id) !== -1;
    else
      return false;
  }

  public addNewNotification() {
    this.state = 'new';
    this.currentNotification = new Notification();
  }

  public saveNewNotification() {
    if (this.validate()) {
      this.apiService.notificationService.createNotification(this.currentNotification).subscribe(value => {
        this.alertService.success("Guardado!", "Alerta guardada con exito");
        this.currentNotification = value;
      }, error1 => {
        this.alertService.error("Error al Guardar Alerta", `Se produjo un error al guardar la alerta`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`);
      }, () => {
        this.apiService.permissionService.permissionsPost({
          deviceId: this.device.id,
          notificationId: this.currentNotification.id
        }).subscribe(value => {
          this.loadData(false);
        }, error1 => {
          this.alertService.error('Error al activar la alerta en el dispositivo', 'Verifique sus credenciales.');
          this.logger.error('Error al activar la alerta en el dispositivo. ' + error1.toString());
        }, () => {
          this.loadData(true);
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
        });
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  /* CRUD OPERATIONS */
  public updateNotification() {
    if (this.validate()) {
      this.apiService.notificationService.updateNotification(this.currentNotification.id, this.currentNotification).subscribe(value => {
          this.alertService.success("Actualizado", "La Alerta fue Actualizada con exito");
          this.state = "search";
          // this.notifications[this.notifications.findIndex(value1 => value1.id == value.id)] = value;
          this.loadData(true);
          this.appSearchTopBarComponent.clearSearchParams();
        },
        error1 => {
          this.alertService.error("Error al Actualizar", `Se produjo un error al actualizar`);
          this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`);
        });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public deleteNotification() {
    if (this.validate()) {
      this.apiService.notificationService.deleteNotification(this.currentNotification.id).subscribe(value => {
          this.alertService.success("Eliminado", "La Alerta fue Eliminada con exito");
          this.loadData(true);
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
        },
        error1 => {
          this.alertService.error("Error al Eliminar", `Se produjo un error al eliminar`);
          this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`);
        });
    } else {
      this.alertService.error("Error al Eliminar", "Se ha corrompido el sistema, por favor actualice.");
    }
  }

  //on save/update
  public onSubmit() {
    if (this.state == "new") {
      this.saveNewNotification();
    } else {
      this.updateNotification();
    }
  }

  public validate(): boolean {
    if (isNullOrUndefined(this.currentNotification))
      return false;
    return !!(this.currentNotification.notificators && this.currentNotification.type);
    //return (!isNullOrUndefined(this.currentNotification.notificators) && !isNullOrUndefined(this.currentNotification.type))
  }


  public onCloseAttribs(attribs) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentNotification.attributes = attribs;
      this.updateNotification();
      //this.alertService.success("Guardado", "Atributos Actualizados.");
    }
  }

  public checkRole(action: RolePermission): boolean {
    return this.apiService.roleService.checkRoleNotifications(action);
  }

  public changeViewMode(mode: string) {
    this.viewMode = mode;
  }
}
