import { Injectable } from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Calendar} from "@app/core/models/calendar.model";
import {Observable} from "rxjs";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {catchError} from "rxjs/operators";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends BaseService{

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Calendars
   * */
  public getCalendars(all?: boolean, userId?: number): Observable<Array<Calendar>>{
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Calendar>>(`${this.basePath}${this.REST_URLS.calendars}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Create a Calendar
   * */
  public createCalendar(body: Calendar): Observable<Calendar>{
    if (isNullOrUndefined(body)) {
      throw new Error('Parametro body requerido no proporcionado al crear calendario.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<Calendar>(`${this.basePath}${this.REST_URLS.calendars}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }

  /**
   * Update a Calendar
   * */
  public updateCalendar(id: number, body: Calendar): Observable<Calendar>{
    if (isNullOrUndefined(id)) {
      throw new Error('Parametro id no proporcionado para actualizar Calendario');
    }
    if (isNullOrUndefined(body)) {
      throw new Error('Parametro body no proporcionado para actualizar Calendario');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<Calendar>(`${this.basePath}${this.REST_URLS.calendars}/${encodeURIComponent(String(id))}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Delete Calendar
   * */
  public deleteCalendar(id: number):Observable<Calendar>{
    if (isNullOrUndefined(id)) {
      throw new Error('Parametro id no proporcionado para actualizar Calendario');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.delete<Calendar>(`${this.basePath}${this.REST_URLS.calendars}/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }
}
