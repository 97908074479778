import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {User} from "@app/core/models/user.model";
import {FilterUserBySearchParamPipe} from "@app/core/pipes/filter-user-by-search-param.pipe";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {WebConfiguration} from "@app/core/models/web-configuration.model";
import {Config} from "ngx-easy-table";
import {Dictionary, Set} from "typescript-collections";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-user-bundle',
  templateUrl: './user-bundle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./user-bundle.component.css']
})
export class UserBundleComponent implements OnInit, AfterViewInit {

  @Output()
  public onClose: EventEmitter<User> = new EventEmitter();
  @Output()
  public onOpenPermissions: EventEmitter<User> = new EventEmitter();

  // public currentUser: User;
  public currentValue: User | any;

  @Input()
  public state: ModelPageState = "search";

  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;

  //USER'S GROUPS FROM API
  public users: Array<User> = [];
  public defaultUsers: Array<User> = [];

  public filter: FilterUserBySearchParamPipe = new FilterUserBySearchParamPipe();

  //ATTRIBUTES
  public isVisibleAttributesCompact: boolean = false;

  private _user: User;
  public role: string;

  public roles: Array<IModel>;
  public selectedRole: IModel;

  /* Change password */
  // public password: string = '';
  // public repassword: string = '';

  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  @ViewChild('passwordSwal') private passwordSwal: SwalComponent;
  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  //app data
  @ViewChild('nameTpl') public nameTpl: TemplateRef<any>;
  @ViewChild('roleTpl') public roleTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;

  public configuration: Config;
  public columns;
  public selected: Set<string> = new Set<string>();

  public permissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  public editForm: FormGroup;
  public passwordForm: FormGroup;

  get formFields() {
    return this.editForm.controls;
  }

  get passwordFormFields() {
    return this.passwordForm.controls;
  }

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private readonly cdr: ChangeDetectorRef,
              private fb: FormBuilder,
              private logger: NGXLogger) {

    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];

    this.roles = this.apiService.roleService.getRoles(this.role == 'root');
    // const opt = this.apiService.roleService.getRoles(true);

    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'green',
      field: 'name',
      type: 'text',
      remote: false
    }, {
      name: 'E-mail',
      value: '',
      color: 'blue',
      field: 'email',
      type: 'text',
      remote: false
    }, {
      name: 'Telefono',
      value: '',
      color: 'yellow',
      field: 'phone',
      type: 'text',
      remote: false
    }, {
      name: 'Login',
      value: '',
      color: 'olive',
      field: 'login',
      type: 'text',
      remote: false
    }, {
      name: 'Rol',
      value: '',
      color: 'green',
      field: 'role',
      type: 'select',
      options: this.roles,
      remote: false
    }];
    this.configuration = Object.assign({},ConfigurationTableService.config);

    //edit
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      login: ['', [Validators.required, Validators.maxLength(128)]],
      password: ['', [Validators.required ,Validators.maxLength(128), Validators.minLength(6)]],
      phone: ['', [Validators.required, Validators.pattern("^(\\d{7}|\\d{8})")]],
      disabled: [''],
      deviceLimit: [''],
      userLimit: [''],
      readonly: [''],
      deviceReadonly: [''],
    });

    this.passwordForm = this.fb.group({
      mpassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
      mrepassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    });
  }

  ngOnInit() {
    this.loadPermissions();
    this.configureTableData();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public loadPermissions(): void {
    this.permissions = this.apiService.roleService.userPermissions;
  }

  private configureTableData() {
    this.columns = [
      {key: 'name', title: 'Nombre', cellTemplate: this.nameTpl, width: '30%'},
      {key: 'email', title: 'E-mail', width: '20%'},
      {key: 'login', title: 'Login', width: '20%'},
      {key: 'phone', title: 'Teléfono', width: '20%'},
      {key: 'role', title: 'Rol', cellTemplate:this.roleTpl, width: '20%'},
      {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '15%'}
    ];
    this.configuration.checkboxes = false;
  }

  public loadData(): void {
    this.loadUsers();
  }

  private loadUsers(): void {
    this.isLoading = true;
    this.apiService.userService.getUser().subscribe(value => {
        const formatted = this.formatData(value);
        this.users = [...formatted];
        this.defaultUsers = [...formatted];
    }, error1 => {
      this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
      this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
    }, () => {
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  public onSearch(params: Array<ISearchParam>): void {
    this.clearSelection();
    this.users = this.filter.transform(this.defaultUsers, params);
  }

  public clearSelection(): void {
    this.currentValue = new User();
    this.selectedRole = this.roles[0];
    this.state = "search";
    this.users = this.defaultUsers;
    // this.appSearchTopBarComponent.clearSearchParams();
    this.editForm.reset();
  }

  public onCloseAttribs(attribs): void {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentValue.attributes = attribs;
      this.update();
    }
  }

  public close(): void {
    this.clearSelection();
    this.appSearchTopBarComponent.clearSearchParams();
    this.onClose.emit();
  }

  /* validators */
  public isValid(): boolean {
    if (isNullOrUndefined(this.currentValue))
      return false;
    if(this.state == 'new'){
      return this.formFields.name.valid && this.formFields.email.valid && this.formFields.login.valid
        && this.formFields.password.valid && this.formFields.phone.valid;
    }else if(this.state == 'edit'){
      return this.formFields.name.valid && this.formFields.email.valid && this.formFields.login.valid && this.formFields.phone.valid;
    }else{
      return true;//por update
    }
    // return this.editForm.valid;
  }

  // public isTextValid(text: string): boolean {
  //   return (!isNullOrUndefined(text) && text.length > 0);
  // }
  //
  // public isEmailValid(email: string): boolean {
  //   let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(email);
  // }

  /* API */
  public save(): void {
    if (this.isValid()) {
      // this.logger.warn("USUARIO: "+JSON.stringify(this.currentValue, null, 2));
      this.apiService.userService.createUser(this.currentValue).subscribe(value => {
        if(value == null)
          this.alertService.error("Se produjo un error al guardar el Usuario", `Verifique sus permisos.`);
        else
          this.alertService.success("Guardado!", "Usuario guardado con éxito");
      }, error1 => {
        this.alertService.error("Error al Guardar el Usuario", `Se produjo un error al guardar el Usuario`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public update(): void {
    if (this.isValid()) {
      this.clearChunkData();
        this.apiService.userService.updateUser(this.currentValue.id, this.currentValue).subscribe(value => {
          if(value != null)
            this.alertService.success("Actualizado!", "Usuario actualizado con éxito");
          else
            this.alertService.error("Se produjo un error al actualizar el Usuario", `Verifique sus permisos.`);
          // this.currentUser = value;
        }, error1 => {
          this.alertService.error("Error al Actualizar el Usuario", `Se produjo un error al actualizar el Usuario`);
          this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`)
        }, () => {
          this.loadData();
          this.clearSelection();
        });
      // this.logger.warn("USUARIO: "+JSON.stringify(this.currentValue, null, 2));
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public delete(): void {
    this.apiService.userService.deleteUser(this.currentValue.id).subscribe(value => {
      this.alertService.success("Eliminado!", "El Usuario se ha eliminado con exito");
    }, error1 => {
      this.alertService.error("Error al eliminar el Usuario", `Se produjo un error al eliminar el Usuario`);
      this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`)
    }, () => {
      this.loadData();
      this.clearSelection();
    });
  }

  /* actions */
  public select(user: User): void {
    if (this.currentValue != user) {
      this.currentValue = Object.assign({}, user);
      this.selectedRole = this.roles.find(value => value.id == user.attributes['role']);
      // if (!isNullOrUndefined(this.selectedRole)) {
      //   //check for root
      //   if (user.attributes['role'] != 'root')
      //     this.selectedRole = this.roles.find(value => value.id == 'monitor');
      // }
      this.state = "select";
    } else {
      this.clearSelection();
    }
  }

  public addNew(): void {
    this.state = 'new';
    this.currentValue = new User();
    this.selectedRole = this.roles[0];
  }

  public onSubmit(): void {
    this.fillFromForm();
    this.prepareData();
    if (this.state == "new") {
      this.save();
    } else {
      this.update();
    }
  }

  private fillFromForm() : void {
    this.currentValue.name = this.formFields.name.value;
    this.currentValue.email = this.formFields.email.value;
    this.currentValue.login = this.formFields.login.value;
    this.currentValue.phone = this.formFields.phone.value;
    this.currentValue.disabled = this.formFields.disabled.value;
    //special fields for root
    if(this.role == 'root') {
      this.currentValue.deviceLimit = this.formFields.deviceLimit.value;
      this.currentValue.userLimit = this.formFields.userLimit.value;
      this.currentValue.readonly = this.formFields.readonly.value;
      this.currentValue.deviceReadonly = this.formFields.deviceReadonly.value;
    }
    //set password for new user.
    if(this.state == 'new') {
      this.currentValue.password = this.formFields.password.value;
    }

  }

  private prepareData(): void {
    //add role
    this.configureRole();
    //add webconfigurations if its new or does not have;
    if (isNullOrUndefined(this.currentValue.webConfiguration)) {
      this.currentValue.webConfiguration = JSON.stringify(WebConfiguration.createFromUser(this.currentValue).IWebConfig);
    } else {
      const config = WebConfiguration.createFromSanitizedString(this.currentValue.webConfiguration);
      config.menuItems = WebConfiguration.createMenuItems(this.currentValue.attributes['role']);
      this.currentValue.webConfiguration = JSON.stringify(config.IWebConfig);
    }
  }

  public getRole(user: User): string {
    const role = user.attributes['role'];
    if (!isNullOrUndefined(role)) {
      return role;
    }
    return 'monitor';
    // return 'Monitor';
  }

  private configureRole(): void {
    if (!isNullOrUndefined(this.selectedRole)) {
      if (!isNullOrUndefined(this.currentValue.attributes))
        this.currentValue.attributes['role'] = this.selectedRole.id;
      else
        this.currentValue.attributes = {role: this.selectedRole.id};
    }
    else {
      this.currentValue.attributes['role'] = 'monitor'; //default
    }
    //assign
    switch (this.currentValue.attributes['role']) {
      case 'administrator':
        this.currentValue.administrator = true;
        // this.currentValue.readonly = false;
        break;
      case 'supervisor':
        this.currentValue.administrator = false;
        // this.currentValue.readonly = false;
        break;
      case 'monitor':
        this.currentValue.administrator = false;
        // this.currentValue.readonly = true;
        break;
      case 'root':
        this.currentValue.administrator = true;
        // this.currentValue.readonly = false;
        break;
    }
    //check if not put
    if (this.role != 'root') {
      this.currentValue.deviceLimit = -1;
      this.currentValue.deviceReadonly = false;
      this.currentValue.userLimit = 0;
      this.currentValue.expirationTime = null;
      this.currentValue.readonly = false;
    }
  }

  public changePassword() : void {
    if (this.getRole(this.currentValue) == 'root' && this.role != 'root') {
      this.alertService.warning("Acceso Denegado", "La contraseña solo puede ser actualizada por un Super Usuario.");
      this.passwordForm.reset();
      return;
    }
    if (this.passwordFormFields.mpassword.value == this.passwordFormFields.mrepassword.value) {
      this.clearChunkData();
      this.currentValue.password = this.passwordFormFields.mpassword.value;
      this.apiService.userService.updateUser(this.currentValue.id, this.currentValue).subscribe(value => {
        if(value.id == this._user.id){
          LocalService.currentUser.password = btoa(this.passwordFormFields.mpassword.value);
          // this.apiService.sessionService.logout().subscribe(value1 => {
            this.alertService.success("Contraseña Actualizada", "Su Contraseña ha sido actualizada.");
          // });
        }else{
          this.alertService.success("Contraseña Actualizada", "La Contraseña ha sido actualizada.");
        }
      }, error1 => {
        this.alertService.error("Error al Actualizar", "La Contraseña no pudo ser actualizada.");
        this.logger.error("Se ha producido un error al actualizar la contrasena: " + error1.toString());
      }, () => {
        this.passwordForm.reset();
      });
    }
    else {
      this.alertService.error("Contrasena Incorrecta", "La contrasena no coincide, verifique.");
      this.passwordForm.reset();
    }
  }

  // public isPasswordValid() : boolean {
  //   return this.password.length > 4 && this.password == this.repassword;
  // }

  public openPermissions(id: string){
    this.selectById(id);
    this.onOpenPermissions.emit(this.currentValue);
  }

  /* actions */

  public selectById(id: string) {
    this.currentValue = Object.assign({}, this.users.find(value => value.id == id));
  }

  public onCancelEdit() {
    //restore data
    this.confirmSwal.show();
  }

  public openAttributes(id: string): void {
    this.selectById(id);
    this.isVisibleAttributesCompact = true;
  }

  public openPasswordModal(id: string): void {
    this.selectById(id);
    this.passwordSwal.show();
  }

  public openDeleteDialog(id: string): void {
    this.selectById(id);
    this.deleteSwal.show();
  }

  public edit(id: string): void {
    this.selectById(id);
    //fill other stuff
    const index = this.roles.findIndex(value => value.id == this.getRole(this.currentValue));
    if (index !== -1)
      this.selectedRole = this.roles[index];
    // // this.currentMaintenance.startDate = t;
    // //form
    this.formFields.name.setValue(this.currentValue.name);
    this.formFields.email.setValue(this.currentValue.email);
    this.formFields.login.setValue(this.currentValue.login);
    this.formFields.phone.setValue(this.currentValue.phone);
    this.formFields.disabled.setValue(this.currentValue.disabled);
    //root edit
    this.formFields.deviceLimit.setValue(this.currentValue.deviceLimit);
    this.formFields.userLimit.setValue(this.currentValue.userLimit);
    this.formFields.readonly.setValue(this.currentValue.readonly);
    this.formFields.deviceReadonly.setValue(this.currentValue.deviceReadonly);

    this.state = 'edit';
    this.cdr.detectChanges();
  }

  /*events*/
  public onClickRow(event) {
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if (event.value.event.target.checked) { //existe
          this.selected.add(event.value.row.id);
        } else { //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      case 'onDoubleClick':
        this.edit(event.value.row.id);
        break;
      case 'onSelectAll':
        if (event.value == true) {
          this.users.forEach(value => {
            this.selected.add(value.id);
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
  }

  private formatData(users: Array<User>) : Array<any> {
    let formatted = [];
    users.forEach(user => {
      if(this.role != 'root') {
        //if not root, don't show root users.
        const role = this.getRole(user);
        if (role != 'root') {
          formatted.push({
            id: user.id,
            name: user.name,
            email: user.email,
            password: user.password,
            phone: user.phone,
            readonly: user.readonly,
            administrator: user.administrator,
            map: user.map,
            latitude: user.latitude,
            longitude: user.longitude,
            zoom: user.zoom,
            twelveHourFormat: user.twelveHourFormat,
            attributes: user.attributes,
            coordinateFormat: user.coordinateFormat,
            disabled: user.disabled,
            expirationTime: user.expirationTime,
            deviceLimit: user.deviceLimit,
            login: user.login,
            poiLayer: user.poiLayer,
            webConfiguration: user.webConfiguration,
            role: role
          });
        }
      }else{
        //if root show all.
        formatted.push({
          id: user.id,
          name: user.name,
          email: user.email,
          password: user.password,
          phone: user.phone,
          readonly: user.readonly,
          administrator: user.administrator,
          map: user.map,
          latitude: user.latitude,
          longitude: user.longitude,
          zoom: user.zoom,
          twelveHourFormat: user.twelveHourFormat,
          attributes: user.attributes,
          coordinateFormat: user.coordinateFormat,
          disabled: user.disabled,
          expirationTime: user.expirationTime,
          deviceLimit: user.deviceLimit,
          login: user.login,
          poiLayer: user.poiLayer,
          webConfiguration: user.webConfiguration,
          role: this.getRole(user)
        });
      }
    });
    return formatted;
  }

  public clearChunkData(): void {
    delete this.currentValue.role;
  }
}
