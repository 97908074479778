import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Event} from "@app/core/models/event.model";
import {Geofence} from "@app/core/models/geofence.model";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {Device} from "@app/core/models/device.model";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {ModelPageState} from "@app/core/types/buho-core-types";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {FilterEventBySearchParamPipe} from "@app/core/pipes/filter-event-by-search-param.pipe";
import {Maintenance} from "@app/core/models/maintenance.model";
import {Config} from "ngx-easy-table";
import {Set} from "typescript-collections";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";

@Component({
  selector: 'app-event-display',
  templateUrl: './event-display.component.html',
  styleUrls: ['./event-display.component.css']
})
export class EventDisplayComponent implements OnInit {

  public eventsValue: Array<Event | any> = [];
  public defaultEvents: Array<Event | any> = [];

  @Input()
  get events(): Array<Event> {
    return this.eventsValue;
  }

  set events(val: Array<Event>) {
    this.configureTableData();
    this.eventsValue = [];
    this.defaultEvents = [];
    //formatted...
    const formatted = this.formatData(val);
    this.eventsValue = [...formatted];
    this.defaultEvents = [...formatted];
    // this.eventsValue = val;
    // this.defaultEvents = val;
  }

  public geofences: Array<Geofence> = [];
  public devices: Array<Device> = [];
  public maintenances: Array<Maintenance> = [];
  //

  @Output()
  public eventsChange: EventEmitter<Array<Event>> = new EventEmitter();

  @Output()
  public onClose: EventEmitter<Array<Event>> = new EventEmitter();


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  public state: ModelPageState = "search";
  //Search
  public searchOptions: Array<ISearchParam>;
  public filter: FilterEventBySearchParamPipe = new FilterEventBySearchParamPipe();
  public notificationTypeFilter: NotificationTypePipe = new NotificationTypePipe();

  // data table
  public configuration: Config;
  public columns;
  public selected: Set<number> = new Set<number>();

  @ViewChild('eventTpl') public eventTpl: TemplateRef<any>;
  @ViewChild('dateTpl') public dateTpl: TemplateRef<any>;
  @ViewChild('deviceTpl') public deviceTpl: TemplateRef<any>;
  @ViewChild('geofenceTpl') public geofenceTpl: TemplateRef<any>;
  @ViewChild('maintenanceTpl') public maintenanceTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private logger: NGXLogger) {
    this.searchOptions = [{
      name: 'Tipo',
      value: '',
      color: 'purple',
      field: 'type',
      type: 'select',
      options: [],
      remote: false
    }, {
      name: 'Dispositivo',
      field: 'deviceId',
      value: '',
      color: 'green',
      type: 'select',
      options: [],
      selectValue: undefined,
      remote: false
    }, {
      name: 'Geocerca',
      field: 'geofenceId',
      value: '',
      color: 'blue',
      type: 'select',
      options: [],
      selectValue: undefined,
      remote: false
    }, {
      name: 'Mantenimiento',
      field: 'maintenanceId',
      value: '',
      color: 'orange',
      type: 'select',
      options: [],
      selectValue: undefined,
      remote: false
    }];
  }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    //types
    this.apiService.notificationService.getNotificationsTypes().subscribe(notificationTypes => {
      let types: Array<IModel> = [];
      notificationTypes.forEach(type => {
        types.push({id: type.type, name: type.type, display: this.notificationTypeFilter.transform(type.type)});
      });
      this.searchOptions[0].options = types;
    });

    //devices
    this.apiService.deviceService.getDevices().subscribe(devices => {
      this.devices = [...devices];
      let deviceOptions: Array<IModel> = [];
      devices.forEach(device => {
        deviceOptions.push({id: device.id.toString(), name: device.id.toString(), display: device.name});
      });
      this.searchOptions[1].options = deviceOptions;
    }, error1 => {
      this.alertService.error("Acceso Denegado", "Verique sus privilegios.");
      this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
    });
    //geofences
    this.apiService.geofenceService.getGeofences().subscribe(geofences => {
      this.geofences = [...geofences];
      let options: Array<IModel> = [];
      geofences.forEach(geofence => {
        options.push({id: geofence.id.toString(), name: geofence.id.toString(), display: geofence.name});
      });
      this.searchOptions[2].options = options;
    }, error1 => {
      this.alertService.error("Acceso Denegado", "Verique sus privilegios.");
      this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
    });
    //maintenances
    this.apiService.maintenanceService.getMaintenances().subscribe(value => {
      this.maintenances = [...value];
      let options: Array<IModel> = [];
      value.forEach(maintenance => {
        options.push({id: maintenance.id.toString(), name: maintenance.id.toString(), display: maintenance.name});
      });
      this.searchOptions[3].options = options;
    }, error1 => {
      this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
      this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
    });
  }

  public clearSelection() {
    this.selected.clear();
  }


  private getDevice(deviceId: number): Device {
    if (!isNullOrUndefined(this.devices)) {
      let index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index];
      }
    } else {
      return null;
    }
  }

  private getNameGeofence(geofenceId: number): string {
    if (!isNullOrUndefined(this.geofences)) {
      let index = this.geofences.findIndex(value => value.id == geofenceId);
      if (index !== -1) {
        return this.geofences[index].name;
      }
    } else {
      return null;
    }
  }

  private getNameMaintenance(id: number): string {
    if (!isNullOrUndefined(this.maintenances)) {
      let index = this.maintenances.findIndex(value => value.id == id);
      if (index !== -1) {
        return this.maintenances[index].name;
      }
    } else {
      return null;
    }
  }

  public onSearch(params: Array<ISearchParam>) {
    // this.logger.warn(JSON.stringify(params));
    this.defaultEvents = this.filter.transform(this.eventsValue, params);
  }

  public close() {
    this.clearSelection();
    this.appSearchTopBarComponent.clearSearchParams();
    this.onClose.emit(this.eventsValue);
  }

  private formatData(val: Array<Event>): Array<Event | any> {
    val.forEach(value => {
      //add extra data
      const device = this.getDevice(value.deviceId);
      value.device = device.name;
      value.deviceIcon = device.icon;
      value.geofence = this.getNameGeofence(value.geofenceId);
      value.maintenance = this.getNameMaintenance(value.maintenanceId);
      //
      this.eventsValue.push(value);
      this.defaultEvents.push(value);
    });
    return val;
  }

  // data
  private configureTableData() {
    this.configuration = Object.assign({}, ConfigurationTableService.config);
    this.columns = [
      {key: 'device', title: 'Dispositivo', cellTemplate: this.deviceTpl, width: '20%'},
      {key: 'type', title: 'Tipo de Evento', cellTemplate: this.eventTpl, width: '20%'},
      {key: 'serverTime', title: 'Fecha', cellTemplate: this.dateTpl, width: '20%'},
      {key: 'geofence', title: 'Geocerca', cellTemplate: this.geofenceTpl, width: '20%'},
      {key: 'maintenance', title: 'Mantenimiento', cellTemplate: this.maintenanceTpl, width: '20%'},
      {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '10%'}
    ];
    this.configuration.checkboxes = true;
  }

  public clearSelected(): void {
    this.selected.forEach(id => {
      const index = this.defaultEvents.findIndex(value => value.id == id);
      if (index !== -1) {
        this.defaultEvents.splice(index, 1);
      }
    });
    this.selected.clear();
    this.eventsChange.emit(this.defaultEvents);
  }

  /*events*/
  public onClickRow(event) {
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if (event.value.event.target.checked) { //existe
          this.selected.add(event.value.row.id);
        } else { //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      // case 'onDoubleClick':
      //   this.openRow(event.value.row.id);
      //   break;
      case 'onSelectAll':
        if (event.value == true) {
          this.defaultEvents.forEach(value => {
            if(this.selected.contains(value)){
              this.selected.remove(value.id);
            }else {
              this.selected.add(value.id);
            }
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
  }

  public remove(id: number): void {
    const index = this.defaultEvents.findIndex(value => value.id == id);
    if (index !== -1) {
      this.defaultEvents.splice(index, 1);
      this.logger.warn("Eliminar: " + id);
      this.eventsChange.emit(this.defaultEvents);
    }
  }

  // private openRow(id: number) : void  {
  //   const event = this.defaultEvents.find(value => value.id == id);
  //   if(!isNullOrUndefined(event)){
  //     this.alertService.success("ABRIR: ",event.type);
  //     this.logger.warn("ABRIR: ",JSON.stringify(event,null,2));
  //
  //     this.close();
  //   }
  // }
}
