/***
 * project: BUHO Web Interface
 * author: Miguel Hurtado
 * */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "@app/shared/guards/auth-guard";

const routes: Routes = [
  {
    path: '' ,
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'session',
    loadChildren: 'src/app/session/session.module#SessionModule'
  },
  {
    path: 'dashboard',
    loadChildren: 'src/app/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: '**', redirectTo:''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    CommonModule
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
