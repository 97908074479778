import { Pipe, PipeTransform } from '@angular/core';
import {IMarker} from "@app/core/interfaces/imarker.interface";

@Pipe({
  name: 'filterMarkerByDeviceId'
})
export class FilterMarkerByDeviceIdPipe implements PipeTransform {

  transform(values: IMarker[], ids?: number[]): IMarker[] {
    if(!values) return [];
    if(!ids) return values;
    //Search by
    return values.filter(marker => {
      return ids.indexOf(marker.deviceId) >= 0;
    });
  }

}
