import {User} from "@app/core/models/user.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IWebConfiguration} from "@app/core/interfaces/iweb-configuration.interface";
import {ItemData} from "@app/shared/interfaces/item-data.interface";
import {MENU_ITEMS} from "@app/core/types/buho-core-types";
import {IMapOptions} from "@app/core/interfaces/imap-options.interface";

export class WebConfiguration {
  // private _refreshPeriod: number;
  private _reconnectTimeout: number = 60000;
  // private _reportTimeout: number;
  private _menuItems: Array<ItemData>;
  // private _lang: string;
  private _mapOptions: IMapOptions;
  private _reportPagination: number = 50;


  constructor();
  constructor(reconnectTimeout?: number, reportPagination?: number,
              latitude?: number, longitude?: number, zoom?: number,
              visibleDevicesIds?: Array<number>,
              visibleGeofenceIds?: Array<number>, mapStyle?: Array<any>, menuItems?: Array<ItemData>, focusZoom?: number) {
    //this._refreshPeriod = refreshPeriod || 60000;
    this._reconnectTimeout = reconnectTimeout || 60000;
    this._reportPagination = reportPagination || 50;
    //this._reportTimeout = reportTimeout || 120000;
    //this._lang = lang || 'es';
    this._mapOptions = {
      latitude: latitude || -17.801276,
      longitude: longitude || -63.169325,
      zoom: zoom || 16,
      visibleDeviceIds: visibleDevicesIds || [],
      visibleGeofenceIds: visibleGeofenceIds || [],
      styles: mapStyle || [],
      focusZoom: focusZoom || 18
    };
    this._menuItems = menuItems;
  }

  // get refreshPeriod(): number {
  //   return this._refreshPeriod;
  // }
  //
  // set refreshPeriod(value: number) {
  //   this._refreshPeriod = value;
  // }

  get reconnectTimeout(): number {
    return this._reconnectTimeout;
  }

  set reconnectTimeout(value: number) {
    this._reconnectTimeout = value;
  }


  get reportPagination(): number {
    return this._reportPagination;
  }

  set reportPagination(value: number) {
    this._reportPagination = value;
  }

// get reportTimeout(): number {
  //   return this._reportTimeout;
  // }
  //
  // set reportTimeout(value: number) {
  //   this._reportTimeout = value;
  // }
  //
  // get lang(): string {
  //   return this._lang;
  // }
  //
  // set lang(value: string) {
  //   this._lang = value;
  // }


  get menuItems(): Array<ItemData> {
    return this._menuItems;
  }

  set menuItems(value: Array<ItemData>) {
    this._menuItems = value;
  }

  get mapOptions(): IMapOptions {
    return this._mapOptions;
  }

  set mapOptions(value: IMapOptions) {
    this._mapOptions = value;
  }

  static createFromUser(user: User): WebConfiguration {
    let config = new WebConfiguration();
    if (!isNullOrUndefined(user.latitude) && user.latitude > 0)
      config.mapOptions.latitude = user.latitude;
    if (!isNullOrUndefined(user.longitude) && user.longitude > 0)
      config.mapOptions.longitude = user.longitude;
    if (!isNullOrUndefined(user.zoom) && user.zoom > 0)
      config.mapOptions.zoom = user.zoom;
    config.menuItems = WebConfiguration.generateMenuItems(user.attributes);
    return config;
  }

  get IWebConfig(): IWebConfiguration {
    return {
      reconnectTimeout: this._reconnectTimeout,
      menuItems: this._menuItems,
      mapOptions: this._mapOptions,
      reportPagination: this._reportPagination
    };
  }

  static createFromSanitizedString(html: string): WebConfiguration {
    const safe = html.replace(/&#34;/gi, `"`);
    let configuration: IWebConfiguration = JSON.parse(safe);
    let config = new WebConfiguration();
    // config.refreshPeriod = configuration.refreshPeriod;
    config.reconnectTimeout = configuration.reconnectTimeout;
    // config.reportTimeout = configuration.reportTimeout;
    // config.lang = configuration.lang;
    config.mapOptions = configuration.mapOptions;
    config.menuItems = configuration.menuItems;
    config.reportPagination = configuration.reportPagination;
    return config;
  }

  static createFromInterface(configuration: IWebConfiguration): WebConfiguration {
    let config = new WebConfiguration();
    // config.refreshPeriod = configuration.refreshPeriod;
    config.reconnectTimeout = configuration.reconnectTimeout;
    // config.reportTimeout = configuration.reportTimeout;
    // config.lang = configuration.lang;
    config.mapOptions = configuration.mapOptions;
    config.menuItems = configuration.menuItems;
    config.reportPagination = configuration.reportPagination;
    return config;
  }

  public static generateMenuItems(attributes): Array<ItemData> {
    let role = attributes["role"];
    if (!isNullOrUndefined(role)) {
      if (role == "root") {
        return this.createSuperAdministratorMenuItems();
      } else if (role == "administrator") {
        return this.createAdministratorMenuItems();
      } else if (role == "supervisor") {
        return this.createSupervisorMenuItems();
      } else {
        return this.createUserMenuItems();
      }
    } else {
      return this.createUserMenuItems();
    }
  }

  public static createMenuItems(role: string): Array<ItemData> {
    if (!isNullOrUndefined(role)) {
      if (role == "root") {
        return this.createSuperAdministratorMenuItems();
      } else if (role == "administrator") {
        return this.createAdministratorMenuItems();
      } else if (role == "supervisor") {
        return this.createSupervisorMenuItems();
      } else {
        return this.createUserMenuItems();
      }
    } else {
      return this.createUserMenuItems();
    }
  }

  public static createUserMenuItems(): Array<ItemData> {
    return [
      {
        id: MENU_ITEMS.DEVICES,
        order: 1,
        title: 'Dispositivos',
        iconClass: 'hdd outline',
      }, {
        id: MENU_ITEMS.GEOFENCES,
        order: 2,
        title: 'Geocercas',
        iconClass: 'block layout'
      }, {
        id: MENU_ITEMS.REPORTS,
        order: 3,
        title: 'Reportes',
        iconClass: 'clipboard outline',
        children: []
      }, {
        id: MENU_ITEMS.ACCOUNT_MENU,
        order: 4,
        title: 'Cuenta',
        right: true,
        children: [{
          id: MENU_ITEMS.ACCOUNT,
          order: 1,
          title: 'Perfil',
          iconClass: 'user'
        }, {
          id: MENU_ITEMS.TUTORIALS,
          order: 2,
          title: 'Tutoriales',
          iconClass: 'book '
        }, {
          id: MENU_ITEMS.LOGOUT,
          order: 3,
          title: 'Salir',
          iconClass: 'sign-out'
        }],
        iconClass: 'user circle'
      }
    ];
  }

  public static createSupervisorMenuItems(): Array<ItemData> {
    return [
      {
        id: MENU_ITEMS.DEVICES,
        order: 1,
        title: 'Dispositivos',
        iconClass: 'hdd outline',
      }, {
        id: MENU_ITEMS.GEOFENCES,
        order: 2,
        title: 'Geocercas',
        iconClass: 'block layout'
      }, {
        id: MENU_ITEMS.REPORTS,
        order: 3,
        title: 'Reportes',
        iconClass: 'clipboard outline'
      }, {
        id: MENU_ITEMS.ACCOUNT_MENU,
        order: 4,
        title: 'Cuenta',
        right: true,
        children: [{
          id: MENU_ITEMS.ACCOUNT,
          order: 1,
          title: 'Perfil',
          iconClass: 'user'
        }, {
          id: MENU_ITEMS.TUTORIALS,
          order: 2,
          title: 'Tutoriales',
          iconClass: 'book '
        }, {
          id: MENU_ITEMS.LOGOUT,
          order: 3,
          title: 'Salir',
          iconClass: 'sign-out'
        }],
        iconClass: 'user circle'
      }, {
        id: MENU_ITEMS.DRIVERS,
        order: 5,
        title: 'Conductores',
        iconClass: 'address book outline'
      }, {
        id: MENU_ITEMS.MAINTENANCE,
        order: 6,
        title: 'Mantenimientos',
        iconClass: 'wrench'
      }, {
        id: MENU_ITEMS.CALENDARS,
        order: 7,
        title: 'Calendarios',
        iconClass: 'calendar alternate outline'
      }
    ];
  }

  public static createAdministratorMenuItems(): Array<ItemData> {
    return [
      {
        id: MENU_ITEMS.DEVICES,
        order: 1,
        title: 'Dispositivos',
        iconClass: 'hdd outline'
      }, {
        id: MENU_ITEMS.GEOFENCES,
        order: 2,
        title: 'Geocercas',
        iconClass: 'block layout'
      }, {
        id: MENU_ITEMS.REPORTS,
        order: 3,
        title: 'Reportes',
        iconClass: 'clipboard outline'
      }, {
        id: MENU_ITEMS.ACCOUNT_MENU,
        order: 4,
        title: 'Cuenta',
        right: true,
        children: [{
          id: MENU_ITEMS.ACCOUNT,
          order: 1,
          title: 'Perfil',
          iconClass: 'user'
        }, {
          id: MENU_ITEMS.TUTORIALS,
          order: 2,
          title: 'Tutoriales',
          iconClass: 'book '
        }, {
          id: MENU_ITEMS.LOGOUT,
          order: 3,
          title: 'Salir',
          iconClass: 'sign-out'
        }],
        iconClass: 'user circle'
      }, {
        id: MENU_ITEMS.GROUPS,
        order: 5,
        title: 'Grupos',
        iconClass: 'object group outline'
      }, {
        id: MENU_ITEMS.DRIVERS,
        order: 6,
        title: 'Conductores',
        iconClass: 'address book outline'
      }, {
        id: MENU_ITEMS.NOTIFICATIONS,
        order: 7,
        title: 'Alertas',
        iconClass: 'bell outline'
      }, {
        id: MENU_ITEMS.MAINTENANCE,
        order: 8,
        title: 'Mantenimientos',
        iconClass: 'wrench'
      }, {
        id: MENU_ITEMS.CALENDARS,
        order: 9,
        title: 'Calendarios',
        iconClass: 'calendar alternate outline'
      }, {
        id: MENU_ITEMS.USERS,
        order: 10,
        title: 'Usuarios',
        iconClass: 'users'
      }, {
        id: MENU_ITEMS.PERMISSIONS,
        order: 11,
        title: 'Permisos',
        iconClass: 'bullseye'
      },
    ];
  }

  public static createSuperAdministratorMenuItems(): Array<ItemData> {
    return [
      {
        id: MENU_ITEMS.DEVICES,
        order: 1,
        title: 'Dispositivos',
        iconClass: 'hdd outline'
      }, {
        id: MENU_ITEMS.GEOFENCES,
        order: 2,
        title: 'Geocercas',
        iconClass: 'block layout'
      }, {
        id: MENU_ITEMS.REPORTS,
        order: 3,
        title: 'Reportes',
        iconClass: 'clipboard outline'
      }, {
        id: MENU_ITEMS.ATTRIBUTES,
        order: 4,
        title: 'Atributos',
        iconClass: 'code'
      }, {
        id: MENU_ITEMS.ACCOUNT_MENU,
        order: 5,
        title: 'Cuenta',
        right: true,
        children: [{
          id: MENU_ITEMS.ACCOUNT,
          order: 1,
          title: 'Perfil',
          iconClass: 'user'
        }, {
          id: MENU_ITEMS.TUTORIALS,
          order: 2,
          title: 'Tutoriales',
          iconClass: 'book '
        }, {
          id: MENU_ITEMS.LOGOUT,
          order: 3,
          title: 'Salir',
          iconClass: 'sign-out'
        }],
        iconClass: 'user circle'
      }, {
        id: MENU_ITEMS.GROUPS,
        order: 6,
        title: 'Grupos',
        iconClass: 'object group outline'
      }, {
        id: MENU_ITEMS.NOTIFICATIONS,
        order: 7,
        title: 'Alertas',
        iconClass: 'bell outline'
      }, {
        id: MENU_ITEMS.DRIVERS,
        order: 8,
        title: 'Conductores',
        iconClass: 'address book outline'
      }, {
        id: MENU_ITEMS.MAINTENANCE,
        order: 9,
        title: 'Mantenimientos',
        iconClass: 'wrench'
      }, {
        id: MENU_ITEMS.CALENDARS,
        order: 10,
        title: 'Calendarios',
        iconClass: 'calendar alternate outline'
      }, {
        id: MENU_ITEMS.USERS,
        order: 11,
        title: 'Usuarios',
        iconClass: 'users',
      }, {
        id: MENU_ITEMS.PERMISSIONS,
        order: 12,
        title: 'Permisos',
        iconClass: 'bullseye'
      }
    ];
  }
}
