export class Maintenance {
  id?: number;
  name?: string;
  type?: string;
  start?: number;
  period?: number;
  startDate?: Date;
  periodDays?: number;
  attributes?: any;
}
