export const environment = {
  production: true,
  apiUrl: 'https://buho.grupo-venado.com/api/',
  wsUrl: 'wss://buho.grupo-venado.com/api/socket',
  firebase: {
    apiKey: "AIzaSyBxfGEQIKAqzQoe1c3k14C3Bz2BqkCVlDY",
    authDomain: "buho-venado-production.firebaseapp.com",
    databaseURL: "https://buho-venado-production.firebaseio.com",
    projectId: "buho-venado-production",
    storageBucket: "buho-2.appspot.com",
    messagingSenderId: "327010473848"
  }
};
