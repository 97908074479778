import { Injectable } from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Geofence} from "@app/core/models/geofence.model";
import {Observable} from "rxjs";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GeofenceService extends BaseService{

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Geofences
   * Without params, it returns a list of Geofences the user has access to
   * @param all Can only be used by admins or managers to fetch all entities
   * @param userId Standard users can use this only with their own _userId_
   * @param deviceId Standard users can use this only with _deviceId_s, they have access to
   * @param groupId Standard users can use this only with _groupId_s, they have access to
   * @param refresh
   */
  public getGeofences(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean): Observable<Array<Geofence>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }
    if (!isNullOrUndefined(deviceId)) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }
    if (!isNullOrUndefined(groupId)) {
      queryParameters = queryParameters.set('groupId', <any>groupId);
    }
    if (!isNullOrUndefined(refresh)) {
      queryParameters = queryParameters.set('refresh', <any>refresh);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Geofence>>(`${this.basePath}${this.REST_URLS.geofences}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Delete a Geofence
   *
   * @param id
   */
  public deleteGeofence(id: number): Observable<any> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling deleteGeofence.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.geofences}/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * Update a Geofence
   *
   * @param id
   * @param body
   */
  public updateGeofence(id: number, body: Geofence): Observable<Geofence> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling updateGeofence.');
    }
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling updateGeofence.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.put<Geofence>(`${this.basePath}${this.REST_URLS.geofences}/${encodeURIComponent(String(id))}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Create a Geofence
   *
   * @param body
   */
  public createGeofence(body: Geofence): Observable<Geofence> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling createGeofence.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.post<Geofence>(`${this.basePath}${this.REST_URLS.geofences}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }


}
