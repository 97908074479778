export class Position {
  id?: number;
  attributes?:any;
  created?:Date;
  deviceId?: number;
  protocol?: string;
  deviceTime?: Date;
  fixTime?: Date;
  serverTime?: Date;
  outdated?: boolean;
  valid?: boolean;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  /**
   * in knots
   */
  speed?: number;
  course?: number;
  address?: string;
  accuracy?: number;
  network?: string;

  getAtributtesValues?(): string[]{
    return Object.values(this.attributes);
  }

  getAtributtes?(): string[]{
    let props = Object.keys(this.attributes);
    let atribs = [];
    for (let prop of props){
      atribs.push(this.attributes[prop]);
    }
    return atribs;
  }

  getAtributtesKeys?(): string[]{
    return Object.keys(this.attributes);
  }
}
