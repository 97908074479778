import {EventEmitter, Injectable, Output} from '@angular/core';
import {WebConfiguration} from "@app/core/models/web-configuration.model";
import {IWebConfiguration} from "@app/core/interfaces/iweb-configuration.interface";
import {User} from "@app/core/models/user.model";

@Injectable()
export class WebConfigurationService {

  configuration : WebConfiguration;

  @Output()
  change: EventEmitter<WebConfiguration> = new EventEmitter();


  updateConfig(webConfiguration: WebConfiguration){
    this.configuration = webConfiguration;
    this.change.emit(this.configuration);
  }

  updateConfigFromInterface(webConfiguration: IWebConfiguration){
    this.configuration = WebConfiguration.createFromInterface(webConfiguration);
    this.change.emit(this.configuration);
  }

  updateConfigFromUser(user:User){
    this.configuration = WebConfiguration.createFromInterface(JSON.parse(user.webConfiguration));
    this.change.emit(this.configuration);
  }



}
