import {Injectable, Injector} from '@angular/core';
import {SessionService} from "@app/core/service/session.service";
import {ServerService} from "@app/core/service/server.service";
import {PositionService} from "@app/core/service/position.service";
import {DeviceService} from "@app/core/service/device.service";
import {SocketService} from "@app/core/service/socket.service";
import {AsyncDPEService} from "@app/core/service/async-dpe.service";
import {UserService} from "@app/core/service/user.service";
import {NotificationService} from "@app/core/service/notification.service";
import {CalendarService} from "@app/core/service/calendar.service";
import {AttributeService} from "@app/core/service/attribute.service";
import {PermissionService} from "@app/core/service/permission.service";
import {GeofenceService} from "@app/core/service/geofence.service";
import {ReportService} from "@app/core/service/report.service";
import {User} from "@app/core/models/user.model";
import {RoleService} from "@app/core/service/role.service";
import {GroupService} from "@app/core/service/group.service";
import {DriverService} from "@app/core/service/driver.service";
import {MaintenanceService} from "@app/core/service/maintenance.service";
import {SharedService} from "@app/core/service/shared.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private injector: Injector) { }

  public user:User;

  private _sessionService: SessionService;

  public get sessionService(): SessionService {
    if(!this._sessionService){
      this._sessionService = this.injector.get(SessionService);
    }
    return this._sessionService;
  }

  private _serverService: ServerService;

  public get serverService(): ServerService {
    if(!this._serverService){
      this._serverService = this.injector.get(ServerService);
    }
    return this._serverService;
  }

  private _positionService: PositionService;

  public get positionService(): PositionService {
    if(!this._positionService){
      this._positionService = this.injector.get(PositionService);
    }
    return this._positionService;
  }

  private _deviceService: DeviceService;

  public get deviceService(): DeviceService{
    if(!this._deviceService){
      this._deviceService = this.injector.get(DeviceService);
    }
    return this._deviceService;
  }

  // private _socketService: SocketService;
  //
  // public get socketService(): SocketService{
  //   if(!this._socketService){
  //     this._socketService = this.injector.get(SocketService);
  //   }
  //   return this._socketService;
  // }

  private _userService: UserService;

  public get userService(): UserService{
    if(!this._userService){
      this._userService = this.injector.get(UserService);
    }
    return this._userService;
  }

  private _asyncService: AsyncDPEService;

  public get asyncService(): AsyncDPEService{
    if(!this._asyncService){
      this._asyncService = this.injector.get(AsyncDPEService);
    }
    return this._asyncService;
  }

  private _notificationService: NotificationService;

  public get notificationService(): NotificationService{
    if(!this._notificationService){
      this._notificationService = this.injector.get(NotificationService);
    }
    return this._notificationService;
  }

  private _calendarService: CalendarService;

  public get calendarService(): CalendarService{
    if(!this._calendarService){
      this._calendarService = this.injector.get(CalendarService);
    }
    return this._calendarService;
  }

  private _attributeService: AttributeService;

  public get attributeService(): AttributeService{
    if(!this._attributeService){
      this._attributeService = this.injector.get(AttributeService);
    }
    return this._attributeService;
  }

  private _permissionService: PermissionService;

  public get permissionService(): PermissionService{
    if(!this._permissionService){
      this._permissionService = this.injector.get(PermissionService);
    }
    return this._permissionService;
  }

  private _geofenceService: GeofenceService;

  public get geofenceService(): GeofenceService{
    if(!this._geofenceService){
      this._geofenceService = this.injector.get(GeofenceService);
    }
    return this._geofenceService;
  }

  private _reportService: ReportService;

  public get reportService(): ReportService{
    if(!this._reportService){
      this._reportService = this.injector.get(ReportService);
    }
    return this._reportService;
  }

  private _groupService: GroupService;

  public get groupService(): GroupService{
    if(!this._groupService){
      this._groupService = this.injector.get(GroupService);
    }
    return this._groupService;
  }

  private _roleService: RoleService;

  public get roleService(): RoleService {
    if(!this._roleService){
      this._roleService = this.injector.get(RoleService);
    }
    return this._roleService;
  }

  private _sharedService: SharedService;

  public get sharedService(): SharedService {
    if(!this._sharedService){
      this._sharedService = this.injector.get(SharedService);
    }
    return this._sharedService;
  }

  private _driverService: DriverService;

  public get driverService(): DriverService {
    if(!this._driverService){
      this._driverService = this.injector.get(DriverService);
    }
    return this._driverService;
  }

  private _maintenanceService: MaintenanceService;

  public get maintenanceService(): MaintenanceService {
    if(!this._maintenanceService){
      this._maintenanceService = this.injector.get(MaintenanceService);
    }
    return this._maintenanceService;
  }
}
