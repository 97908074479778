import {BloodType, DriverLicenceCategory} from "@app/core/types/buho-core-types";

export class Driver {
  id?: number;
  name?: string;
  uniqueId?: string;
  attributes?: any;
  //new fields
  expiration?: Date;
  category? : DriverLicenceCategory | string;
  glasses? : boolean;
  headphones?: boolean;
  bloodType?: BloodType | string;
}
