import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IconDataPicker} from "@app/shared/interfaces/icon-data-picker.interface";
import {isNullOrUndefined} from "util";

@Component({
  selector: 'app-icon-dropdown-picker',
  templateUrl: './icon-dropdown-picker.component.html',
  styleUrls: ['./icon-dropdown-picker.component.css']
})
export class IconDropdownPickerComponent implements OnInit {

  public icons: IconDataPicker[];

  @Input()
  public iconName:string;

  @Input()
  public selectedOption:IconDataPicker;

  @Output()
  public onSelect: EventEmitter<IconDataPicker> = new EventEmitter();

  @Input()
  cssClass: string = 'floating labeled icon fluid blue basic button';

  constructor() {
    this.icons = [];
    //freepik
    // this.icons.push({name:'Asistente',icon:'./assets/images/avatar/svg/freepik/assistant.svg',collection:'freepik'});
    // this.icons.push({name:'Astronauta',icon:'./assets/images/avatar/svg/freepik/astronaut.svg',collection:'freepik'});
    // this.icons.push({name:'Vendedor',icon:'./assets/images/avatar/svg/freepik/businessman.svg',collection:'freepik'});
    // this.icons.push({name:'Capitan',icon:'./assets/images/avatar/svg/freepik/captain.svg',collection:'freepik'});
    // this.icons.push({name:'Cobrador',icon:'./assets/images/avatar/svg/freepik/cashier.svg',collection:'freepik'});
    // this.icons.push({name:'Chef',icon:'./assets/images/avatar/svg/freepik/chef.svg',collection:'freepik'});
    // this.icons.push({name:'Cocinera',icon:'./assets/images/avatar/svg/freepik/cooker.svg',collection:'freepik'});
    // this.icons.push({name:'Courier',icon:'./assets/images/avatar/svg/freepik/courier.svg',collection:'freepik'});
    // this.icons.push({name:'Croupier',icon:'./assets/images/avatar/svg/freepik/croupier.svg',collection:'freepik'});
    // this.icons.push({name:'Croupier*',icon:'./assets/images/avatar/svg/freepik/croupier-1.svg',collection:'freepik'});
    // this.icons.push({name:'Detective',icon:'./assets/images/avatar/svg/freepik/detective.svg',collection:'freepik'});
    // this.icons.push({name:'Dj',icon:'./assets/images/avatar/svg/freepik/disc-jockey.svg',collection:'freepik'});
    // this.icons.push({name:'Buzo',icon:'./assets/images/avatar/svg/freepik/diver.svg',collection:'freepik'});
    // this.icons.push({name:'Doctor',icon:'./assets/images/avatar/svg/freepik/doctor.svg',collection:'freepik'});
    // this.icons.push({name:'Doctora',icon:'./assets/images/avatar/svg/freepik/doctor-1.svg',collection:'freepik'});
    // this.icons.push({name:'Ingeniero',icon:'./assets/images/avatar/svg/freepik/engineer.svg',collection:'freepik'});
    // this.icons.push({name:'Granjero',icon:'./assets/images/avatar/svg/freepik/farmer.svg',collection:'freepik'});
    // this.icons.push({name:'Bombero',icon:'./assets/images/avatar/svg/freepik/firefighter.svg',collection:'freepik'});
    // this.icons.push({name:'Bombera',icon:'./assets/images/avatar/svg/freepik/firefighter-1.svg',collection:'freepik'});
    // this.icons.push({name:'Caballero',icon:'./assets/images/avatar/svg/freepik/gentleman.svg',collection:'freepik'});
    // this.icons.push({name:'Periodista',icon:'./assets/images/avatar/svg/freepik/journalist.svg',collection:'freepik'});
    // this.icons.push({name:'Juez',icon:'./assets/images/avatar/svg/freepik/judge.svg',collection:'freepik'});
    // this.icons.push({name:'Mensajero',icon:'./assets/images/avatar/svg/freepik/loader.svg',collection:'freepik'});
    // this.icons.push({name:'Ama de casa',icon:'./assets/images/avatar/svg/freepik/maid.svg',collection:'freepik'});
    // this.icons.push({name:'Gerente',icon:'./assets/images/avatar/svg/freepik/manager.svg',collection:'freepik'});
    // this.icons.push({name:'Minero',icon:'./assets/images/avatar/svg/freepik/miner.svg',collection:'freepik'});
    // this.icons.push({name:'Motociclista',icon:'./assets/images/avatar/svg/freepik/motorcyclist.svg',collection:'freepik'});
    // this.icons.push({name:'Monja',icon:'./assets/images/avatar/svg/freepik/nun.svg',collection:'freepik'});
    // this.icons.push({name:'Enfermera',icon:'./assets/images/avatar/svg/freepik/nurse.svg',collection:'freepik'});
    // this.icons.push({name:'Piloto',icon:'./assets/images/avatar/svg/freepik/pilot.svg',collection:'freepik'});
    // this.icons.push({name:'Policia',icon:'./assets/images/avatar/svg/freepik/policeman.svg',collection:'freepik'});
    // this.icons.push({name:'Cura',icon:'./assets/images/avatar/svg/freepik/priest.svg',collection:'freepik'});
    // this.icons.push({name:'Alguacil',icon:'./assets/images/avatar/svg/freepik/sheriff.svg',collection:'freepik'});
    // this.icons.push({name:'Empresario',icon:'./assets/images/avatar/svg/freepik/showman.svg',collection:'freepik'});
    // this.icons.push({name:'Soldado',icon:'./assets/images/avatar/svg/freepik/soldier.svg',collection:'freepik'});
    // this.icons.push({name:'Soldada',icon:'./assets/images/avatar/svg/freepik/soldier-1.svg',collection:'freepik'});
    // this.icons.push({name:'Azafata',icon:'./assets/images/avatar/svg/freepik/stewardess.svg',collection:'freepik'});
    // this.icons.push({name:'Cirujano',icon:'./assets/images/avatar/svg/freepik/surgeon.svg',collection:'freepik'});
    // this.icons.push({name:'Swat',icon:'./assets/images/avatar/svg/freepik/swat.svg',collection:'freepik'});
    // this.icons.push({name:'Taxi',icon:'./assets/images/avatar/svg/freepik/taxi-driver.svg',collection:'freepik'});
    // this.icons.push({name:'Profesor',icon:'./assets/images/avatar/svg/freepik/teacher.svg',collection:'freepik'});
    // this.icons.push({name:'Ladron',icon:'./assets/images/avatar/svg/freepik/thief.svg',collection:'freepik'});
    // this.icons.push({name:'Mesero',icon:'./assets/images/avatar/svg/freepik/waiter.svg',collection:'freepik'});
    // this.icons.push({name:'Soldador',icon:'./assets/images/avatar/svg/freepik/welder.svg',collection:'freepik'});
    // this.icons.push({name:'Trabajador',icon:'./assets/images/avatar/svg/freepik/worker.svg',collection:'freepik'});
    // this.icons.push({name:'Trabajadora',icon:'./assets/images/avatar/svg/freepik/worker-1.svg',collection:'freepik'});
    // this.icons.push({name:'Escritor',icon:'./assets/images/avatar/svg/freepik/writer.svg',collection:'freepik'});
    // // monkik
    // this.icons.push({name:'Hombre con Lentes',icon:'./assets/images/avatar/svg/monkik/001-man-13.svg',collection:'monkik'});
    // this.icons.push({name:'Mujer con Lentes',icon:'./assets/images/avatar/svg/monkik/002-woman-14.svg',collection:'monkik'});
    // this.icons.push({name:'Mujer',icon:'./assets/images/avatar/svg/monkik/003-woman-13.svg',collection:'monkik'});
    // this.icons.push({name:'Mujer Rubia',icon:'./assets/images/avatar/svg/monkik/004-woman-12.svg',collection:'monkik'});
    // this.icons.push({name:'Mujer Emo',icon:'./assets/images/avatar/svg/monkik/005-woman-11.svg',collection:'monkik'});
    this.icons.push({name:'Camión',icon:'./assets/images/map/svg/freepik-transportation/001-trucking.svg',collection:'freepik'});
    this.icons.push({name:'Bus',icon:'./assets/images/map/svg/freepik-transportation/003-bus.svg',collection:'freepik'});
    this.icons.push({name:'Trailer',icon:'./assets/images/map/svg/freepik-transportation/006-trailer.svg',collection:'freepik'});
    // this.icons.push({name:'Excavadora',icon:'./assets/images/map/svg/freepik-transportation/007-excavator.svg',collection:'freepik'});
    // this.icons.push({name:'Bicicleta',icon:'./assets/images/map/svg/freepik-transportation/009-bicycle.svg',collection:'freepik'});
    this.icons.push({name:'Camioneta',icon:'./assets/images/map/svg/freepik-transportation/014-pickup-truck.svg',collection:'freepik'});
    this.icons.push({name:'Cisterna',icon:'./assets/images/map/svg/freepik-transportation/018-tanker.svg',collection:'freepik'});
    // this.icons.push({name:'Cisterna',icon:'./assets/images/map/svg/freepik-transportation/018-tanker.svg',collection:'freepik'});
    this.icons.push({name:'Motocicleta',icon:'./assets/images/map/svg/freepik-transportation/019-motorbiking.svg',collection:'freepik'});
    // this.icons.push({name:'Camión de Entregas',icon:'./assets/images/map/svg/freepik-transportation/023-delivery-truck.svg',collection:'freepik'});
    this.icons.push({name:'Camión de Entregas',icon:'./assets/images/map/svg/freepik-transportation/023-delivery-truck.svg',collection:'freepik'});
    // this.icons.push({name:'Camión de Refrigeración',icon:'./assets/images/map/svg/freepik-transportation/024-ice-cream-truck.svg',collection:'freepik'});
    this.icons.push({name:'Auto',icon:'./assets/images/map/svg/freepik-transportation/025-car.svg',collection:'freepik'});
    // this.icons.push({name:'Camión de Basura',icon:'./assets/images/map/svg/freepik-transportation/026-trash-truck.svg',collection:'freepik'});
    // this.icons.push({name:'Camión de Comida',icon:'./assets/images/map/svg/freepik-transportation/026-trash-truck.svg',collection:'freepik'});
    this.icons.push({name:'Motocicleta',icon:'./assets/images/map/svg/freepik-transportation/027-motorcycle.svg',collection:'freepik'});
    // this.icons.push({name:'Mezcladora de Cemento',icon:'./assets/images/map/svg/freepik-transportation/032-cement-truck.svg',collection:'freepik'});
    this.icons.push({name:'SUV',icon:'./assets/images/map/svg/freepik-transportation/033-suv.svg',collection:'freepik'});
    this.icons.push({name:'Camión Pesado',icon:'./assets/images/map/svg/freepik-transportation/035-off-road.svg',collection:'freepik'});
    // this.icons.push({name:'Grúa',icon:'./assets/images/map/svg/freepik-transportation/039-crane.svg',collection:'freepik'});
    // this.icons.push({name:'Bulldozer',icon:'./assets/images/map/svg/freepik-transportation/042-bulldozer.svg',collection:'freepik'});
    this.icons.push({name:'Auto Deportivo',icon:'./assets/images/map/svg/freepik-transportation/044-sport-car.svg',collection:'freepik'});
    this.icons.push({name:'Microbus',icon:'./assets/images/map/svg/freepik-transportation/045-school-bus.svg',collection:'freepik'});
    // this.icons.push({name:'Taxi',icon:'./assets/images/map/svg/freepik-transportation/047-taxi.svg',collection:'freepik'});

    //buho
    // this.icons.push({name:'Buho',icon:'./assets/images/map/svg/buho/blue.svg',collection:'buho'});
  }

  ngOnInit() {
    if(isNullOrUndefined(this.selectedOption)){
      this.selectedOption = this.icons[0];
    }
    else if(!isNullOrUndefined(this.iconName)){
      this.selectedOption = this.icons.find(value => value.icon.includes(this.iconName));
    }
    /* FILL icons */

    //
    //
  }

  public onSelection(option:IconDataPicker){
    this.onSelect.emit(option);
  }

  public formatter(option:IconDataPicker, query?:string):string {
    // return `<img class="ui image" [src]="${option.icon}"/>`;
    return `${option.name}`;
  }

  public setIcon(icon:string){
    this.iconName = icon;
    this.selectedOption = this.icons.find(value => value.icon.includes(this.iconName));
  }
}
