import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {isNullOrUndefined} from "util";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {Device} from "@app/core/models/device.model";
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";
import {DeviceAccumulators} from "@app/core/models/device-accumulators.model";

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  public getDevices(all?: boolean, uniqueIds?: number[], ids?: number[], userId?: number): Observable<Array<Device>> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.append('all', <any>all);
    }
    if (!isNullOrUndefined(uniqueIds)) {
      for (let uniqueId in uniqueIds) {
        queryParameters = queryParameters.append('uniqueId', <any>uniqueId);
      }
    }
    if (!isNullOrUndefined(ids)) {
      for (let id in ids) {
        queryParameters = queryParameters.append('id', id);
      }
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.append('userId', <any>userId);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Device>>(`${this.basePath}${this.REST_URLS.devices}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  public updateDevice(id: number, body: Device): Observable<Device> {
    if (isNullOrUndefined(id) || isNullOrUndefined(body)) {
      this.logger.error('Al menos un parametro requerido no esta proporcionado.');
      throw new Error('Al menos un parametro requerido no esta proporcionado.');
    }
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<Device>(`${this.basePath}${this.REST_URLS.devices}/${encodeURIComponent(String(id))}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  public createDevice(body: Device): Observable<Device> {
    if (isNullOrUndefined(body)) {
      this.logger.error('Es necesario la informacion del dispositivo, para guardarlo.');
      throw new Error('No se ha proporcionado ningun dispositivo.');
    }
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");
    return this.httpClient.post<Device>(`${this.basePath}${this.REST_URLS.devices}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }).pipe(
      catchError(this.handleError('post', null))
    );
  }

  public deleteDevice(id: number): Observable<any> {
    if (isNullOrUndefined(id)) {
      this.logger.error('Al menos un parametro requerido no esta proporcionado.');
      throw new Error('Al menos un parametro requerido no esta proporcionado.');
    }
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.devices}/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }
  /*
  * update accumulators in real time.
  * */
  public updateAccumulators(id: number, body: DeviceAccumulators): Observable<any> {
    if (isNullOrUndefined(id) || isNullOrUndefined(body)) {
      this.logger.error('Al menos un parametro requerido no esta proporcionado.');
      throw new Error('Al menos un parametro requerido no esta proporcionado.');
    }
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<DeviceAccumulators>(`${this.basePath}${this.REST_URLS.devices}/${encodeURIComponent(String(id))}/${this.REST_URLS.accumulators}`,
      body,
      {
        headers: headers,
        withCredentials: this.withCredentials
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

}
