import { Pipe, PipeTransform } from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {Calendar} from "@app/core/models/calendar.model";

@Pipe({
  name: 'filterCalendarBySearchParam'
})
export class FilterCalendarBySearchParamPipe implements PipeTransform {

  transform(values: Array<Calendar>, params: Array<ISearchParam>): Array<Calendar> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<Calendar> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<Calendar>, name: string) {
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(calendar => {
      return calendar.name.toLowerCase().includes(name.toLowerCase());
    });
  }

}
