import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "@swimlane/ngx-datatable/release/utils";

@Pipe({
  name: 'speed'
})
export class SpeedPipe implements PipeTransform {

  transform(value: string, args?: string): string {
    if(!isNullOrUndefined(value)){
      let km: number = (Number(value)* 1.852);
      return km.toFixed(2) + ' km/h';
    }else{
      return value;
    }
  }

}
