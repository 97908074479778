import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {SessionService} from "@app/core/service/session.service";
import {NGXLogger} from "ngx-logger";
import {AlertService} from "@app/shared/services/alert.service";
import {Router} from "@angular/router";
import {CookieService} from "ngx-cookie-service";
import {LocalService} from "@app/core/service/local.service";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService,
              private alertService: AlertService,
              private cookieService: CookieService,
              private router: Router,
              private logger: NGXLogger) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.cookieService.delete("JSESSIONID");

        // LocalService.currentUser = null;
        location.reload(true);

        // return;
        // this.alertService.warning("Cambio de contraseña","Ingrese nuevamente por favor");
        // this.router.navigateByUrl('/session');
        return;
      }

      const error = err.error.message || err.statusText;
      this.logger.error(error);
      this.alertService.error("ERROR", error);
      return throwError(error);
    }))
  }
}
