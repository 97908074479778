import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {User} from "@app/core/models/user.model";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {isNullOrUndefined} from "util";
import {Position} from "@app/core/models/position.model";
import {ApiService} from "@app/shared/services/api.service";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";
import {Dictionary} from "typescript-collections";

@Component({
  selector: 'app-device-side-bar',
  templateUrl: './device-side-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./device-side-bar.component.css']
})
export class DeviceSideBarComponent implements OnInit, AfterViewInit {

  public devices: Array<Device> = [];
  public positions: Array<Position> = [];

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();
  @Output()
  public onSelectInMap: EventEmitter<any> = new EventEmitter();
  @Output()
  public onOpenNotifications: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onOpenGeofences: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onCenterMarkesOnMap: EventEmitter<any> = new EventEmitter();
  @Output()
  public onOpenDrivers: EventEmitter<Device> = new EventEmitter();
  @Output()
  public onOpenMaintenances: EventEmitter<Device> = new EventEmitter();

  @Output()
  public onAddClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public onOpenAdminPanel: EventEmitter<any> = new EventEmitter();

  private _user: User;
  public visibleDeviceIds: Array<number> = [];
  public currentDevice: Device;

  @Input()
  public state: ModelPageState = "search";
  public position: Position;

  public search: string;
  public searchChanged: Subject<string> = new Subject<string>();

  public role: string;
  public permissions: Dictionary<string,boolean> = new Dictionary<string, boolean>();

  constructor(private apiService: ApiService,
              private router: Router,
              private readonly cdr: ChangeDetectorRef,
              private configService: WebConfigurationService) {
    this.searchChanged.pipe(distinctUntilChanged()).subscribe(value => this.search = value);
  }

  ngOnInit() {
    /*load data */
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    //load permissions
    this.role = this._user.attributes['role'];

    this.visibleDeviceIds = this.configService.configuration.mapOptions.visibleDeviceIds;
    this.configService.change.subscribe(val => {
      this.visibleDeviceIds = val.mapOptions.visibleDeviceIds;
    });
    // this.loadData();
    //load permissions
    this.permissions = this.apiService.roleService.devicePermissions;
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public clearSelection() {
    this.currentDevice = new Device();
    this.state = 'search';
    this.searchChanged.next('');
    this.cdr.detectChanges();
  }

  public select(item: Device) {
    if (this.currentDevice != item) {
      this.currentDevice = item;
      this.state = 'select';
      //add pos info
      const indexPos = this.positions.findIndex(value => value.deviceId == this.currentDevice.id);
      if (indexPos !== -1) {
        this.position = this.positions[indexPos];
      }else{
        this.position = null;
      }
      this.onSelectInMap.emit(this.currentDevice);
    } else {
      this.clearSelection();
    }
  }

  public selectDevice(device: Device, pos: Position) {
    this.state = 'select';
    this.currentDevice = device;
    this.position = pos;
    this.cdr.detectChanges();
  }

  public close() {
    this.onClose.emit();
  }

  public isVisible(deviceId: number): boolean {
    return this.visibleDeviceIds.indexOf(deviceId) !== -1;
  }

  /* ACTIONS */
  public toogleCurrentVisibility() {
    const index = this.visibleDeviceIds.indexOf(this.currentDevice.id);
    if (index >= 0) {//visible
      this.visibleDeviceIds.splice(index, 1);
    } else {
      this.visibleDeviceIds.push(this.currentDevice.id);
    }
    this.cdr.detectChanges();
    // this.configService.configuration.mapOptions.visibleDeviceIds = this.visibleDeviceIds;
  }

  public selectInMap() {
    if (!isNullOrUndefined(this.currentDevice)) {
      this.onSelectInMap.emit(this.currentDevice);
      this.clearSelection();
    }
  }

  public showNotifications() {
    this.onOpenNotifications.emit(this.currentDevice);
  }

  public showGeofences() {
    this.onOpenGeofences.emit(this.currentDevice);
  }

  public openWizard() {
    this.onAddClick.emit();
  }

  public openAdminPanel() {
    if (this.state == 'select') {
      if (this.checkRole('create')) {
        this.onOpenAdminPanel.emit(this.currentDevice);
      } else {
        this.onOpenAdminPanel.emit();
      }
    } else
      this.onOpenAdminPanel.emit();
  }

  public checkRole(action: RolePermission): boolean {
    // return this.apiService.roleService.checkRoleDevices(action);
    return this.permissions.getValue(action);
  }

  public showDrivers() {
    this.onOpenDrivers.emit(this.currentDevice);
  }

  public showMaintenances() {
    this.onOpenMaintenances.emit(this.currentDevice);
  }

  public changeInputText(text: string) {
    this.searchChanged.next(text);
  }

  public updatePositions(positions: Array<Position>) : void {
    this.positions = positions;
    // console.log(JSON.stringify(positions,null,2));
    this.cdr.detectChanges();
  }

  public centerMap() : void{
    this.onCenterMarkesOnMap.emit();
  }
}
