import { Pipe, PipeTransform } from '@angular/core';
import {Device} from "@app/core/models/device.model";

@Pipe({
  name: 'filterDeviceByName'
})
export class FilterDeviceByNamePipe implements PipeTransform {

  transform(values: Device[], search: string): Device[] {
    if(!values) return [];
    if(!search) return values;
    //Search by
    return values.filter(device => {
      return device.name.toLocaleLowerCase().includes(search.toLowerCase()) ||
        device.uniqueId.toLocaleLowerCase().includes(search.toLowerCase()) ;
    });
  }

}
