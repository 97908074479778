import {Injectable} from '@angular/core';
import {User} from "@app/core/models/user.model";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  static get currentUser(): User {
    if (this._currentUser == null) {
      const user = sessionStorage.getItem('user');
      if (!isNullOrUndefined(user)) {
        this._currentUser = JSON.parse(user) as User;
      }
    }
    return this._currentUser;
  }

  static set currentUser(value: User) {
    this._currentUser = value;
    if (!isNullOrUndefined(value)) {
      sessionStorage.setItem('auth', 'Basic ' + btoa(value.email + ':' + atob(value.password)));
      sessionStorage.setItem('user', JSON.stringify(this._currentUser));
    } else {
      sessionStorage.removeItem('auth');
    }
  }

  private static _currentUser: User;

  constructor() {
  }

}
