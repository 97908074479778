import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {IReportContext} from "@app/core/interfaces/ireport-context.interface";
import {DatePickerMode} from "@app/shared/interfaces/SharedTypes";
import {Device} from "@app/core/models/device.model";
import {ApiService} from "@app/shared/services/api.service";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {Group} from "@app/core/models/group.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {NGXLogger} from "ngx-logger";

@Component({
  selector: 'app-report-type-picker',
  templateUrl: './report-type-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./report-type-picker.component.css']
})
export class ReportTypePickerComponent implements OnInit, AfterViewInit {


  public contextValue: IReportContext;

  public selectedDevices: Array<Device> = [];
  public selectedGroups: Array<Group> = [];
  public selectedTypes: Array<IModel> = [];

  @Input()
  public showTypes: boolean = false;

  public devicesValue: Array<Device> = [];
  public groupsValue: Array<Group> = [];

  @Input()
  public eventTypes: Array<IModel> = [];

  public pipe: NotificationTypePipe = new NotificationTypePipe();

  //Date Options
  @Input()
  public pickerMode: DatePickerMode = 'datetime';
  @Input()
  public nativeOnMobile: boolean = true;


  constructor(private apiService: ApiService,
              private logger: NGXLogger,
              private readonly cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    // this.loadData();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public loadData() {
    //load devices
    this.apiService.deviceService.getDevices().subscribe(value => {
      this.devicesValue = value;
    });
    //load groups
    this.apiService.groupService.getGroups().subscribe(value => {
      this.groupsValue = value;
    });
    //load notifications
    this.apiService.notificationService.getNotificationsTypes().subscribe(value => {
      this.eventTypes = [];
      value.forEach(nType => {
        this.eventTypes.push({
          id: nType.type,
          name: nType.type,
          display: this.pipe.transform(nType.type),
        });
      });
    });
  }

  public getData(): IReportContext {
    this.contextValue.deviceIds = [];
    this.contextValue.groupIds = [];
    this.contextValue.types = [];

    this.selectedDevices.forEach(value => {
      this.contextValue.deviceIds.push(value.id);
    });

    this.selectedGroups.forEach(value => {
      this.contextValue.groupIds.push(value.id);
    });

    this.selectedTypes.forEach(value => {
      this.contextValue.types.push(value.id);
    });
    return this.contextValue;
  }

  public setData(context: IReportContext) : void {

    this.contextValue = context;
    if(isNullOrUndefined(this.contextValue.from))
      this.contextValue.from = new Date();

    this.apiService.deviceService.getDevices().subscribe(devices => {
      this.devicesValue = devices;
    },error1 => this.logger.error("Error al cargar los dispositivos"),() => {
      //fill devices
      this.selectedDevices = [];
      this.contextValue.deviceIds.forEach(value => {
        const device = this.devicesValue.find(value1 => value1.id == Number(value));
        if(!isNullOrUndefined(device))
          this.selectedDevices.push(device);
      });
      this.cdr.detectChanges();
    });

    //fill groups
    this.apiService.groupService.getGroups().subscribe(groups => {
      this.groupsValue = groups;
    },error1 => this.logger.error("Error al cargar los grupos"), () => {
      this.selectedGroups = [];
      this.contextValue.groupIds.forEach(value => {
        const groupIndex = this.groupsValue.findIndex(value1 => value1.id == Number(value));
        if(groupIndex != -1) {
          this.selectedGroups.push(this.groupsValue[groupIndex]);
        }
      });
      this.cdr.detectChanges();

    });

    //load notifications
    this.apiService.notificationService.getNotificationsTypes().subscribe(value => {
      this.eventTypes = [];
      value.forEach(nType => {
        this.eventTypes.push({
          id: nType.type,
          name: nType.type,
          display: this.pipe.transform(nType.type),
        });
      });
    },error1 => this.logger.error("Se produjo un error al cargar los tipos de alertas."), () => {
      //fill types
      this.selectedTypes = [];
      this.contextValue.types.forEach(value => {
        const type = this.eventTypes.find(value1 => value1.id == value);
        if(!isNullOrUndefined(type))
          this.selectedTypes.push(type);
      });
      this.cdr.detectChanges();
    });
  }


  // setSelectedDevices(devices: Array<IModel>) {
  //   devices.forEach(value => {
  //     this.contextValue.deviceIds.push(Number(value.id));
  //   });
  // }
  //
  // setSelectedGroups(groups: Array<IModel>) {
  //   groups.forEach(value => {
  //     this.contextValue.groupIds.push(Number(value.id));
  //   });
  // }
  //
  // setSelectedTypes(types: Array<IModel>) {
  //   types.forEach(value => {
  //     this.contextValue.types.push(value.name);
  //   });
  // }
}
