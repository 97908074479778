import {isNullOrUndefined} from "util";
import {DatePipe} from "@angular/common";
import {CalendarDay, CalendarRecurrenceMode, CalendarRepeatMode} from "@app/core/types/buho-core-types";

export default class ICSHelper {

  public static createRandomUID(length: number = 26): string {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  public static parseCalendar(calendar: string): ICalendar {
    let cal = new ICalendar('', '', '', '', '', '');
    cal.parse(calendar);
    return cal;
  }

}

export class IEvent {
  public start: string; //formato
  public end: string;
  public stamp: string;//when calendar was created.
  public uid: string;
  public created: string;//when the event was created.
  public lastModified: string;//when the event was created.
  public description: string = '';
  public status: string = 'CONFIRMED';
  public transp: string = 'OPAQUE';
  public summary: string = '';
  public location: string = '';
  public sequence: number = 0;

  public rrule?: string = '';

  public recurrence: boolean = false;
  public until?: string;
  public repeatMode?: CalendarRepeatMode;
  public recurrenceMode?: CalendarRecurrenceMode;
  public interval?: number;//day: 1/30 | MONTH: 1/12-18-24-36-48 | YEAR: 1/10
  public byMinute?: string; //0/59 SET MINUTES
  public byHour?: string; //0/23 SET HOURS
  public byDay?: string; //MO/SU  //WEEKLY USE ONLY
  public byMonthDay?: string; //1-31 //MONTH USE ONLY
  public byMonth?: string; //1-12 //MONTH
  public occurrence?: number = 1; //how many times this repeats.
  public allDay?: boolean = false;

  public utc: boolean = false; //use UTC on dates

  public pipe = new DatePipe('es-BO');
  public format: string = 'yyyyMMddTHHmmss';
  public formatAllDay: string = 'yyyyMMdd';

  constructor(start?: Date, end?: Date, summary?: string, description?: string, allDay?: boolean, rrule?: string, uid?: string, stamp?: Date,
              created?: Date, status?: string, transp?: string, location?: string, lastModified?: Date, utc?: boolean) {

    if (!isNullOrUndefined(allDay)) {
      this.allDay = allDay;
    } else {
      this.allDay = false;
    }

    if (!isNullOrUndefined(start))
      this.setStartDate(start);
    if (!isNullOrUndefined(end))
      this.setEndDate(end);

    if (!isNullOrUndefined(stamp))
      this.setStampDate(stamp);
    else
      this.setStampDate(new Date());
    if (!isNullOrUndefined(uid))
      this.uid = uid;
    else {
      this.uid = ICSHelper.createRandomUID();
    }
    if (!isNullOrUndefined(created))
      this.setCreatedDate(created);
    else
      this.setCreatedDate(new Date());
    if (!isNullOrUndefined(description))
      this.description = description;
    if (!isNullOrUndefined(status))
      this.status = status;
    if (!isNullOrUndefined(transp))
      this.transp = transp;
    if (!isNullOrUndefined(summary))
      this.summary = summary;
    if (!isNullOrUndefined(location))
      this.location = location;
    if (!isNullOrUndefined(lastModified))
      this.setLastModifiedDate(lastModified);
    else
      this.setLastModifiedDate(new Date());

    if (!isNullOrUndefined(rrule))
      this.rrule = rrule;
    if (!isNullOrUndefined(utc)) {
      this.utc = utc;
    }
  }

  public setStartDate(date: Date): void {
    if (this.allDay) {
      this.start = this.pipe.transform(date, this.formatAllDay);
    } else {
      this.start = this.pipe.transform(date, this.format);
    }
    if (this.utc)
      this.start = this.start + 'Z';
  }

  public getStartDate(): Date {
    if (!isNullOrUndefined(this.start)) {
      if (!this.allDay) {
        const start = this.start.substr(0, 4) + '-' + this.start.substr(4, 2) + '-' +
          this.start.substr(6, 5) + ':' + this.start.substr(11, 2) + ':' + this.start.substr(13, 2);
        return new Date(start);
      } else {
        const start = this.start.substr(0, 4) + '-' + this.start.substr(4, 2) + '-' + this.start.substr(6) + 'T00:00:00';
        return new Date(start);
      }
    }else{
      return new Date();
    }
  }

  public setEndDate(date: Date): void {
    if (this.allDay) {
      this.end = this.pipe.transform(date, this.formatAllDay);
    } else {
      this.end = this.pipe.transform(date, this.format);
    }
    if (this.utc)
      this.end = this.end + 'Z';
  }

  public getEndDate(): Date {
    if (!isNullOrUndefined(this.end)) {
      if (!this.allDay)
        return new Date(`${this.end.substr(0, 4)}-${this.end.substr(4, 2)}-${this.end.substr(6, 5)}:${this.end.substr(11, 2)}:${this.end.substr(13, 2)}`);
      else
        return new Date(`${this.end.substr(0, 4)}-${this.end.substr(4, 2)}-${this.end.substr(6)}T00:00:00`);
    } else {
      if (!isNullOrUndefined(this.start))
        return this.getStartDate();
      else
        return new Date();
    }
  }

  public setRecurrentEvent(recurrenceMode: CalendarRecurrenceMode, repeatMode: CalendarRepeatMode,
                           interval: number, byHour?: Array<number>, byMinute?: Array<number>, byDay?: Array<CalendarDay>,
                           byMonth?: Array<number>, byMonthDay?: Array<number>, until?: Date, occurrence?: number) {
    this.recurrence = true;
    this.recurrenceMode = recurrenceMode;
    this.repeatMode = repeatMode;
    this.interval = interval;
    //rule
    this.rrule = `RRULE:FREQ=${this.recurrenceMode};INTERVAL=${this.interval};`;
    //hour
    if (!isNullOrUndefined(byHour)) {
      this.setByHour(byHour);
      if (!isNullOrUndefined(this.byHour))
        this.rrule = this.rrule + `BYHOUR=${this.byHour};`;
    }
    //minute
    if (!isNullOrUndefined(byMinute)) {
      this.setByMinute(byMinute);
      if (!isNullOrUndefined(this.byMinute))
        this.rrule = this.rrule + `BYMINUTE=${this.byMinute};`;
    }
    //day
    if (!isNullOrUndefined(byDay)) {
      this.setByDay(byDay);
      if (!isNullOrUndefined(this.byDay))
        this.rrule = this.rrule + `BYDAY=${this.byDay};`;
    }
    //month
    if (!isNullOrUndefined(byMonth)) {
      this.setByMonth(byMonth);
      if (!isNullOrUndefined(this.byMonth))
        this.rrule = this.rrule + `BYMONTH=${this.byMonth};`;
    }
    //month Day
    if (!isNullOrUndefined(byMonthDay)) {
      this.setByMonthDay(byMonthDay);
      if (!isNullOrUndefined(this.byMonthDay))
        this.rrule = this.rrule + `BYMONTHDAY=${this.byMonthDay};`;
    }
    //at the end
    switch (this.repeatMode) {
      case "UNTIL":
        this.setUntil(until);
        this.rrule = this.rrule + `UNTIL=${this.until}\n`;
        break;
      case "OCCURENCE":
        this.occurrence = occurrence;
        this.rrule = this.rrule + `COUNT=${this.occurrence}\n`;
        break;
      case "FOREVER":
        const lastChar = this.rrule[this.rrule.length - 1];
        if (lastChar.includes(';')) {
          this.rrule = this.rrule.substr(0, this.rrule.length - 1);
        }
        this.rrule = this.rrule + "\n";
        break;
    }
  }


  public setStampDate(date: Date): void {
    this.stamp = this.pipe.transform(date, this.format);
    if (this.utc)
      this.stamp = this.stamp + 'Z';
  }

  public setCreatedDate(date: Date): void {
    this.created = this.pipe.transform(date, this.format);
    if (this.utc)
      this.created = this.created + 'Z';
  }

  public setLastModifiedDate(date: Date): void {
    this.lastModified = this.pipe.transform(date, this.format);
    this.sequence++;
    if (this.utc)
      this.lastModified = this.lastModified + 'Z';
  }

  public setUntil(until: Date): void {
    this.until = this.pipe.transform(until, this.format);
    if (this.utc)
      this.until = this.until + 'Z';
  }

  public getUntilDate(): Date {
    if (!isNullOrUndefined(this.until))
      return new Date(`${this.until.substr(0, 4)}-${this.until.substr(4, 2)}-${this.until.substr(6, 5)}:${this.until.substr(11, 2)}:${this.until.substr(13, 2)}`);
    else
      return null;
  }

  public setByHour(byHour: Array<number>) {
    let hours = '';
    byHour.forEach(value => {
      if (value >= 0 && value < 24)
        hours = hours + value + ',';
    });
    if (hours.length > 0)
      this.byHour = hours.substr(0, hours.length - 1);
  }

  public setByMinute(byMinute: Array<number>) {
    let minutes = '';
    byMinute.forEach(value => {
      if (value >= 0 && value < 60)
        minutes = minutes + value + ',';
    });
    if (minutes.length > 0)
      this.byMinute = minutes.substr(0, minutes.length - 1);
  }

  public setByDay(byDay?: Array<CalendarDay>) {
    let days = '';
    byDay.forEach(value => {
      days = days + value + ',';
    });
    if (days.length > 0)
      this.byDay = days.substr(0, days.length - 1) + ';WKST=MO';
  }

  public setByMonth(byMonth: Array<number>) {
    let months = '';
    byMonth.forEach(value => {
      if (value > 0 && value <= 12)
        months = months + value + ',';
    });
    if (months.length > 0)
      this.byMonth = months.substr(0, months.length - 1);
  }

  public setByMonthDay(byMonthDay: Array<number>) {
    let months = '';
    byMonthDay.forEach(value => {
      if (value > 0 && value <= 31)
        months = months + value + ',';
    });
    if (months.length > 0)
      this.byMonthDay = months.substr(0, months.length - 1);
  }

  public toString(): string {
    if (this.allDay) {
      return `BEGIN:VEVENT\nDTSTART;VALUE=DATE:${this.start}\n${this.rrule}DTEND;VALUE=DATE:${this.end}\nDTSTAMP:${this.stamp}\nUID:${this.uid}@swissbytes.ch\nCREATED:${this.created}\nDESCRIPTION:${this.description}\nLAST-MODIFIED:${this.lastModified}\nLOCATION:${this.location}\nSEQUENCE:${this.sequence}\nSTATUS:${this.status}\nSUMMARY:${this.summary}\nTRANSP:${this.transp}\nEND:VEVENT`;
    } else {
      return `BEGIN:VEVENT\nDTSTART:${this.start}\n${this.rrule}DTEND:${this.end}\nDTSTAMP:${this.stamp}\nUID:${this.uid}@swissbytes.ch\nCREATED:${this.created}\nDESCRIPTION:${this.description}\nLAST-MODIFIED:${this.lastModified}\nLOCATION:${this.location}\nSEQUENCE:${this.sequence}\nSTATUS:${this.status}\nSUMMARY:${this.summary}\nTRANSP:${this.transp}\nEND:VEVENT`;
    }
  }

  public isValid() {
    return !!isNullOrUndefined(this.start) && !isNullOrUndefined(this.end);
  }


  public parseRule(rule: string) {
    this.rrule = rule;
    // console.log(rule);
    //parse
    const data = rule.split(';');
    data.forEach(value => {
      if (value.includes('FREQ=')) {
        this.recurrence = true;
        this.repeatMode = "FOREVER"; //default
        const recurrence = value.substr(value.indexOf('=') + 1);
        if (recurrence == 'DAILY') {
          this.recurrenceMode = "DAILY";
        } else if (recurrence == 'WEEKLY') {
          this.recurrenceMode = "WEEKLY";
        } else if (recurrence == 'MONTHLY') {
          this.recurrenceMode = "MONTHLY";
        } else if (recurrence == 'YEARLY') {
          this.recurrenceMode = "YEARLY";
        }
      } else if (value.includes('INTERVAL=')) {
        this.interval = Number(value.substr(value.indexOf('=') + 1));
      } else if (value.includes('BYDAY=')) {
        this.byDay = value.substr(value.indexOf('=') + 1);
      } else if (value.includes('UNTIL=')) {
        this.until = value.substr(value.indexOf('=') + 1);
        this.repeatMode = "UNTIL";
      } else if (value.includes('COUNT=')) {
        this.occurrence = Number(value.substr(value.indexOf('=') + 1));
        this.repeatMode = "OCCURENCE";
      } else if (value.includes('BYMONTHDAY=')) {
        this.byMonthDay = value.substr(value.indexOf('=') + 1);
      } else if (value.includes('BYMONTH=')) {
        this.byMonth = value.substr(value.indexOf('=') + 1);
      } else if (value.includes('BYHOUR=')) {
        this.byHour = value.substr(value.indexOf('=') + 1);
      } else if (value.includes('BYMINUTE=')) {
        this.byMinute = value.substr(value.indexOf('=') + 1);
      }
    });
  }

  public getByDay(): Array<CalendarDay> {
    if (!isNullOrUndefined(this.byDay)) {
      const days = this.byDay.split(',');
      let daysOfWeek: Array<CalendarDay> = [];
      days.forEach(value => {
        daysOfWeek.push(<CalendarDay>value);
      });
      return daysOfWeek;
    } else {
      return [];
    }
  }
}

export class ICalendar {

  public name: string;
  public productId: string = '-//SwissBytes Ltda//SwissBytes Calendar 2.0//ES';
  public version: string = '2.0';
  public calScale: string = 'GREGORIAN';
  public method: string = 'PUBLISH';
  public timezone: string = 'America/La_Paz';

  public events: Array<IEvent> = [];


  constructor(name: string, productId?: string, version?: string, calScale?: string, method?: string, timezone?: string) {
    this.name = name;
    if (!isNullOrUndefined(productId))
      this.productId = productId;
    if (!isNullOrUndefined(version))
      this.version = version;
    if (!isNullOrUndefined(calScale))
      this.calScale = calScale;
    if (!isNullOrUndefined(method))
      this.method = method;
    if (!isNullOrUndefined(timezone))
      this.timezone = timezone;
  }

  public parse(calendar: string): void {

    const data = calendar.substr(0, calendar.indexOf('BEGIN:VEVENT')).split('\n');
    const events = calendar.substr(calendar.indexOf('BEGIN:VEVENT'), calendar.indexOf('END:VCALENDAR')).split('END:VEVENT');

    data.forEach(value => {
      if (value.includes('PRODID:')) {
        this.productId = value.substr(value.indexOf(':') + 1);
      } else if (value.includes('VERSION:')) {
        this.version = value.substr(value.indexOf(':') + 1);
      } else if (value.includes('CALSCALE:')) {
        this.calScale = value.substr(value.indexOf(':') + 1);
      } else if (value.includes('METHOD:')) {
        this.method = value.substr(value.indexOf(':') + 1);
      } else if (value.includes('X-WR-CALNAME:')) {
        this.name = value.substr(value.indexOf(':') + 1);
      } else if (value.includes('X-WR-TIMEZONE:')) {
        this.timezone = value.substr(value.indexOf(':') + 1);
      }
    });
    //parse events
    events.forEach(value => {
      if (value.includes('BEGIN:VEVENT')) {
        // console.log(value);
        let iEvent: IEvent = new IEvent();
        //CHECK FO EACH ONE
        const dataE = value.substr(value.indexOf('BEGIN:VEVENT') + 12, value.length).split('\n');
        dataE.forEach(field => {
          if (field.includes('DTSTART:')) {
            iEvent.start = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('DTSTART;VALUE=DATE')) {
            iEvent.start = field.substr(field.indexOf(':') + 1);
            iEvent.allDay = true;
          } else if (field.includes('DTEND:')) {
            iEvent.end = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('DTEND;VALUE=DATE:')) {
            iEvent.end = field.substr(field.indexOf(':') + 1);
            iEvent.allDay = true;
          } else if (field.includes('DTSTAMP:')) {
            iEvent.stamp = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('RRULE:')) {
            iEvent.parseRule(field.substr(field.indexOf(':') + 1));
          } else if (field.includes('UID:')) {
            iEvent.uid = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('CREATED:')) {
            iEvent.created = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('LAST-MODIFIED:')) {
            iEvent.lastModified = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('LOCATION:')) {
            iEvent.location = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('SEQUENCE:')) {
            iEvent.sequence = Number(field.substr(field.indexOf(':') + 1));
          } else if (field.includes('STATUS:')) {
            iEvent.status = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('SUMMARY:')) {
            iEvent.summary = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('DESCRIPTION:')) {
            iEvent.description = field.substr(field.indexOf(':') + 1);
          } else if (field.includes('TRANSP:')) {
            iEvent.transp = field.substr(field.indexOf(':') + 1);
          }
          // console.log(field);
        });
        this.addEvent(iEvent);
      }
    });
  }

  public toString(): string {
    return `BEGIN:VCALENDAR\nPRODID:${this.productId}\nVERSION:${this.version}\nCALSCALE:${this.calScale}\nMETHOD:${this.method}\nX-WR-CALNAME:${this.name}\nX-WR-TIMEZONE:${this.timezone}\n${this.eventsToString()}\nEND:VCALENDAR`;
  }

  public eventsToString(): string {
    let iEvents = '';
    this.events.forEach(value => {
      iEvents = iEvents + value.toString() + '\n';
    });
    return iEvents.substr(0, iEvents.length - 1);
  }

  public addEvent(event: IEvent) {
    if (!isNullOrUndefined(event)) {
      this.events.push(event);
    }
  }
}
