import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {Driver} from "@app/core/models/driver.model";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {User} from "@app/core/models/user.model";
import {FilterDriverBySearchParamPipe} from "@app/core/pipes/filter-driver-by-search-param.pipe";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {Dictionary, Set} from "typescript-collections";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Config} from "ngx-easy-table";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {DatePipe} from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-driver-bundle',
  templateUrl: './driver-bundle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./driver-bundle.component.css']
})
export class DriverBundleComponent implements OnInit {

  @Input()
  public device: Device;

  @Output()
  public onClose: EventEmitter<Driver> = new EventEmitter();

  public currentDriver: Driver | any;

  @Input()
  public state: ModelPageState = "search";
  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;

  //USER'S GROUPS FROM API
  public drivers: Array<Driver> = [];
  public defaultDrivers: Array<Driver> = [];
  //
  private deviceDrivers: Array<Driver> = [];

  public filter: FilterDriverBySearchParamPipe = new FilterDriverBySearchParamPipe();
  //categories
  public selectedCategory: IModel;
  public defaultCategories: Array<IModel> = [];
  //BloodTypes
  public selectedBloodType: IModel;
  public defaultBloodTypes: Array<IModel> = [];

  //ATTRIBUTES
  public isVisibleAttributesCompact: boolean = false;
  // public filterAttributes: KeysPipePipe = new KeysPipePipe();

  // public viewMode: string = 'list';

  public permissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  private _user: User;
  public role: string;

  public isIntegration: boolean = false;

  public dateFilter: DatePipe = new DatePipe("es");

  @ViewChild(SearchTopBarComponent) public appSearchTopBarComponent: SearchTopBarComponent;

  @ViewChild('boldTpl') public boldTpl: TemplateRef<any>;
  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;
  @ViewChild('toogleTpl') public toogleTpl: TemplateRef<any>;
  @ViewChild('glassesTpl') public glassesTpl: TemplateRef<any>;
  @ViewChild('headphonesTpl') public headphonesTpl: TemplateRef<any>;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;
  @ViewChild('alertSwal') private alertSwal: SwalComponent;

  public configuration: Config;
  public columns;
  public selected: Set<number> = new Set<number>();

  public editForm: FormGroup;

  get formFields() {
    return this.editForm.controls;
  }


  constructor(private apiService: ApiService,
              private fb: FormBuilder,
              private readonly cdr: ChangeDetectorRef,
              private alertService: AlertService,
              private logger: NGXLogger) {

    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'green',
      field: 'name',
      type: 'text',
      remote: false
    }, {
      name: 'Lic. de Conducir',
      value: '',
      color: 'violet',
      field: 'uniqueId',
      type: 'text',
      remote: false
    }];
    this.createCategories();
    this.createBloodTypes();
    //edit
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      uniqueId: ['', [Validators.required, Validators.maxLength(128)]],
      glasses : [''],
      headphones : ['']
    });
  }

  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.loadPermissions();
  }

  public configureTableData() {
    if (isNullOrUndefined(this.device)) {
      this.columns = [
        {key: 'name', title: 'Nombre', cellTemplate: this.boldTpl, width: '30%'},
        {key: 'uniqueId', title: 'Lic. de Conducir', width: '15%'},
        {key: 'category', title: 'Categoria', width: '10%'},
        {key: 'expirationText', title: 'Vencimiento', width: '15%'},
        {key: 'glasses', title: '¿Usa Lentes?', cellTemplate: this.glassesTpl, width: '10%'},
        {key: 'headphones', title: '¿Usa Audifonos?', cellTemplate: this.headphonesTpl, width: '10%'},
        {key: 'bloodType', title: 'Tipo de Sangre', width: '10%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '10%'}
      ];
      this.configuration.checkboxes = false;
    } else {
      this.columns = [
        {key: 'name', title: 'Nombre', cellTemplate: this.boldTpl, width: '25%'},
        {key: 'uniqueId', title: 'Lic. de Conducir', width: '15%'},
        {key: 'category', title: 'Categoria', width: '10%'},
        {key: 'expirationText', title: 'Vencimiento', width: '10%'},
        {key: 'glasses', title: '¿Usa Lentes?', cellTemplate: this.glassesTpl, width: '10%'},
        {key: 'headphones', title: '¿Usa Audifonos?', cellTemplate: this.headphonesTpl, width: '10%'},
        {key: 'bloodType', title: 'Tipo de Sangre', width: '10%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '10%'},
        {key: '', title: 'Vincular', cellTemplate: this.toogleTpl, orderEnabled: false, width: '10%'}
      ];
      this.configuration.checkboxes = true;
    }
  }

  public loadPermissions(): void {
    this.permissions = this.apiService.roleService.driverPermissions;
  }

  public loadData(): void {
    this.loadDrivers(this.role == 'root');
    this.loadAdminSearchParam();
  }

  private loadDrivers(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean) {
    this.isLoading = true;
    //load Device Drivers
    if (!isNullOrUndefined(this.device)) {
      this.apiService.driverService.getDrivers(false, null, this.device.id).subscribe(value => {
        this.deviceDrivers = value;
      }, error1 => this.logger.error("Se produjo un error al cargar los conductores del dispositivo."), () => {
        //load devices
        this.apiService.driverService.getDrivers(all, userId, deviceId, groupId, refresh).subscribe(value => {
          const formatted = this.formatData(value);
          this.drivers = [...formatted];
          this.defaultDrivers = [...formatted];
        }, error1 => {
          this.logger.error("Se ha producido un error al cargar los datos.");
          this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
        }, () => {
          this.isLoading = false;
          this.configureTableData();
          this.cdr.markForCheck();
        });
      });
    } else {
      //load devices
      this.apiService.driverService.getDrivers(all, userId, deviceId, groupId, refresh).subscribe(value => {
        const formatted = this.formatData(value);
        this.drivers = [...formatted];
        this.defaultDrivers = [...formatted];
      }, error1 => {
        this.logger.error("Se ha producido un error al cargar los datos.");
        this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
      }, () => {
        this.isLoading = false;
        this.configureTableData();
        this.cdr.markForCheck();
      });
    }
  }


  private loadAdminSearchParam() {
    if (!isNullOrUndefined(this._user)) {
      if (this._user.administrator) {
        //UserId
        const indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'violet',
              type: 'select',
              options: options,
              selectValue: {id: this._user.id, name: this._user.name, display: this._user.name},
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });

        //DeviceId
        const indexDeviceId = this.searchOptions.findIndex(value => value.field == 'deviceId');
        this.apiService.deviceService.getDevices(this.role == 'root').subscribe(devices => {
          let options: Array<IModel> = [];
          devices.forEach(device => {
            options.push({id: device.id.toString(), name: device.id.toString(), display: device.name});
          });
          if (indexDeviceId !== -1) {
            this.searchOptions[indexDeviceId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Dispositivo',
              field: 'deviceId',
              value: '',
              color: 'olive',
              type: 'select',
              options: options,
              remote: true
            });
          }//update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
        //groupId
        const indexGroupId = this.searchOptions.findIndex(value => value.field == 'groupId');
        this.apiService.groupService.getGroups(this.role == 'root').subscribe(groups => {
          let options: Array<IModel> = [];
          groups.forEach(group => {
            options.push({id: group.id.toString(), name: group.id.toString(), display: group.name});
          });
          if (indexGroupId !== -1) {
            this.searchOptions[indexGroupId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Grupo',
              field: 'groupId',
              value: '',
              color: 'orange',
              type: 'select',
              options: options,
              remote: true
            });
          }//update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
      }
    }
  }

  public onSearch(params: Array<ISearchParam>): void {
    this.clearSelection();
    if (params.findIndex(value => value.remote == true) !== -1)
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    else {
      this.drivers = this.filter.transform(this.defaultDrivers, params);
    }
  }

  private doSearch(params: Array<ISearchParam>) {

    let userId: number;
    let deviceId: number;
    let groupId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
        } else if (param.field == "deviceId" && param.selectValue.id) {
          deviceId = Number(param.selectValue.id);
        } else if (param.field == "groupId" && param.selectValue.id) {
          groupId = Number(param.selectValue.id);
        }
        search = true;
      }
    });
    if (search) {
      this.loadDriversForSearch(userId, deviceId, groupId, params);
    }
  }

  private loadDriversForSearch(userId: number, deviceId: number, groupId: number, params: Array<ISearchParam>) {
    this.isLoading = true;
    this.apiService.driverService.getDrivers(false, userId, deviceId, groupId).subscribe(drivers => {
        this.drivers = this.filter.transform(this.formatData(drivers), params);
      }, error1 => {
        this.logger.error(error1.toString());
        this.alertService.error("Acceso No Autorizado", "Verifique sus credenciales");
      }
      , () => {
        this.isLoading = false;
      });
  }

  public clearSelection() {
    this.currentDriver = new Driver();
    this.state = "search";
    this.drivers = this.defaultDrivers;
    this.selected.clear();
    this.editForm.reset();
    // this.selectedBloodType = undefined;
    // this.selectedCategory = undefined;
  }

  public onCloseAttribs(attribs) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentDriver.attributes = attribs;
      this.update();
    }
  }

  public close(): void {
    this.currentDriver = new Driver();
    this.appSearchTopBarComponent.clearSearchParams();
    this.selected.clear();
    this.onClose.emit(this.currentDriver);
  }

  /* CHECK ROLES */
  // public checkRole(action: RolePermission): boolean {
  //   return this.apiService.roleService.checkRoleDriver(action);
  // }

  /* API */
  public addNewDriver() {
    this.state = 'new';
    this.currentDriver = new Driver();
    this.selectedCategory = this.defaultCategories[0];
    this.selectedBloodType = this.defaultBloodTypes[0];
    this.isIntegration = false;
  }

  public onSubmit(): void {
    this.prepareData();
    if (this.state == "new") {
      this.save();
    } else {
      this.update();
    }
    // this.logger.warn("DRIVER: "+JSON.stringify(this.currentDriver));
  }

  public isValid(): boolean {
    if (isNullOrUndefined(this.currentDriver))
      return false;
    return !!(this.currentDriver.name) || !!(this.currentDriver.uniqueId);
  }

  private save() {
    if (this.isValid()) {
      this.apiService.driverService.createDriver(this.currentDriver).subscribe(value => {
        if(value != null)
          this.alertService.success("Guardado!", "Conductor guardado con exito");
        else
          this.alertService.error("Error al Guardar el Conductor", `Se produjo un error al guardar el Conductor`);
        // this.currentDriver = value;
      }, error1 => {
        this.alertService.error("Error al Guardar el Conductor", `Se produjo un error al guardar el Conductor`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  private update() {
    if (this.isValid()) {
      this.apiService.driverService.updateDriver(this.currentDriver.id, this.currentDriver).subscribe(value => {
        if(value != null)
          this.alertService.success("Actualizado!", "Conductor actualizado con exito");
        else
          this.alertService.error("Error al Actualizar el Conductor", `Se produjo un error al actualizar el Conductor`);
        // this.currentDriver = value;
      }, error1 => {
        this.alertService.error("Error al Actualizar el Conductor", `Se produjo un error al actualizar el Conductor`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public delete() {
    this.apiService.driverService.deleteDriver(this.currentDriver.id).subscribe(value => {
      if(value != null)
        this.alertService.success("Eliminado!", "Conductor se ha eliminado con exito");
      else
        this.alertService.error("Error al eliminar el Conductor", `Se produjo un error al eliminar el Conductor`);
      // this.currentDriver = value;
    }, error1 => {
      this.alertService.error("Error al eliminar el Conductor", `Se produjo un error al eliminar el Conductor`);
      this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`)
    }, () => {
      this.loadData();
      this.clearSelection();
    });
  }

  /* actions */
  // public select(driver: Driver): void {
  //   if (this.currentDriver != driver) {
  //     this.currentDriver = driver;
  //     this.state = "select";
  //     this.currentDriver.expiration = new Date(Date.parse(driver.expiration.toString()));
  //     this.selectedBloodType = this.defaultBloodTypes.find(value => value.id == this.currentDriver.bloodType);
  //     this.selectedCategory = this.defaultCategories.find(value => value.id == this.currentDriver.category);
  //   } else {
  //     this.clearSelection();
  //   }
  // }

  public isDeviceOn(id: number): boolean {
    if (!isNullOrUndefined(this.deviceDrivers))
      return this.deviceDrivers.findIndex(value => value.id == id) !== -1;
    else
      return false;
  }

  public toogleForDevice(id: number, state: boolean) {
    if (state) {//existe..
      this.apiService.permissionService.permissionsPost({
        deviceId: this.device.id,
        driverId: id
      }).subscribe(value => {
        // this.loadData();
      }, error1 => {
        this.alertService.error('Error al activar el conductor en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al activar al conductor en el dispositivo. ' + error1.toString());
      });
    } else {//no existe..
      this.apiService.permissionService.permissionsDelete({
        deviceId: this.device.id,
        driverId: id
      }).subscribe(value => {
        // this.loadData();
      }, error1 => {
        this.alertService.error('Error al desactivar al conductor en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al desactivar al conductor en el dispositivo. ' + error1.toString());
      });
    }
  }

  public toogleSelection(flag: boolean): void {
    if (flag) {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceDrivers.findIndex(current => current.id == value);
        if (index === -1) { //no existe agregamos.
          valuesTo.push({
            deviceId: this.device.id,
            driverId: value
          });
        }
      });
      this.savePermissions(valuesTo);
    } else {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceDrivers.findIndex(current => current.id == value);
        if (index !== -1) { //si existe eliminamos.
          valuesTo.push({
            deviceId: this.device.id,
            driverId: value
          });
        }
      });
      this.removePermissions(valuesTo);
    }
  }

  /* permissions */
  private savePermissions(values) {
    this.apiService.permissionService.permissionsArrayPost(values).subscribe(value => {
      if (isNullOrUndefined(value)) {
        this.alertService.error(`Error al vincular los conductores`, 'Verifique sus credenciales.');
      }
    }, error1 => {
      this.logger.error(`Error al vincular los conductores: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Guardado', 'Los conductores han sido vinculados.');
      //reload data
      this.loadDrivers(this.role == 'root');
      // this.selected.clear();
    });
  }

  private removePermissions(values) {
    this.apiService.permissionService.permissionsArrayDelete(values).subscribe(value => {
      if (isNullOrUndefined(value)) {
        this.alertService.error(`Error al desvincular los conductores`, 'Verifique sus credenciales.');
      }
    }, error1 => {
      this.logger.error(`Error al desvincular los conductores: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Eliminado', 'Los conductores han sido desvinculados.');
      //reload data
      this.loadDrivers(this.role == 'root');
      // this.selected.clear();
    });
  }

  public selectCategory(category: IModel) {
    this.selectedCategory = category;
    this.currentDriver.category = category.id;
  }

  public createCategories() {
    //categories
    this.defaultCategories = [];
    this.defaultCategories.push({id: 'P', name: 'Particular'});
    this.defaultCategories.push({id: 'A', name: 'Profesional A'});
    this.defaultCategories.push({id: 'B', name: 'Profesional B'});
    this.defaultCategories.push({id: 'C', name: 'Profesional C'});
    this.defaultCategories.push({id: 'M', name: 'Motociclista'});
    this.defaultCategories.push({id: 'T', name: 'Máquinaria Pesada'});
  }

  public selectBloodType(bloodType: IModel) {
    this.selectedBloodType = bloodType;
    this.currentDriver.bloodType = bloodType.id;
  }

  public createBloodTypes() {
    //categories
    this.defaultBloodTypes = [];
    this.defaultBloodTypes.push({id: 'O RH(-)', name: 'O RH(-)'});
    this.defaultBloodTypes.push({id: 'O RH(+)', name: 'O RH(+)'});
    this.defaultBloodTypes.push({id: 'A RH(-)', name: 'A RH(-)'});
    this.defaultBloodTypes.push({id: 'A RH(+)', name: 'A RH(+)'});
    this.defaultBloodTypes.push({id: 'B RH(-)', name: 'B RH(-)'});
    this.defaultBloodTypes.push({id: 'B RH(+)', name: 'B RH(+)'});
    this.defaultBloodTypes.push({id: 'AB RH(-)', name: 'AB RH(-)'});
    this.defaultBloodTypes.push({id: 'AB RH(+)', name: 'AB RH(+)'});

  }

  private prepareData(): void {
    if (!isNullOrUndefined(this.selectedCategory)) {
      this.currentDriver.category = this.selectedCategory.id;
    }
    if (!isNullOrUndefined(this.selectedBloodType)) {
      this.currentDriver.bloodType = this.selectedBloodType.id;
    }
    this.clearChunkData();
    //form data
    //form
    this.currentDriver.name = this.formFields.name.value.trim();
    this.currentDriver.uniqueId = this.formFields.uniqueId.value.trim();
    this.currentDriver.glasses = this.formFields.glasses.value;
    this.currentDriver.headphones = this.formFields.headphones.value;
  }


  public clearChunkData(): void {
    delete this.currentDriver.expirationText;
    delete this.currentDriver.isDeviceOn;
  }

  /**/
  public edit(id: number): void {
    this.selectById(id);
    // if(this.checkEditable()) {
      //fill other stuff
      if(!isNullOrUndefined(this.currentDriver.expiration))
        this.currentDriver.expiration = new Date(Date.parse(this.currentDriver.expiration.toString()));
      if(!isNullOrUndefined(this.currentDriver.bloodType))
        this.selectedBloodType = this.defaultBloodTypes.find(value => value.id == this.currentDriver.bloodType);
      if(!isNullOrUndefined(this.currentDriver.category))
        this.selectedCategory = this.defaultCategories.find(value => value.id == this.currentDriver.category);
      //form
      this.formFields.name.setValue(this.currentDriver.name);
      this.formFields.uniqueId.setValue(this.currentDriver.uniqueId);
      this.formFields.glasses.setValue(this.currentDriver.glasses);
      this.formFields.headphones.setValue(this.currentDriver.headphones);
      this.state = 'edit';
      this.isIntegration = this.checkEditable();
    // }else{
    //   this.alertSwal.show();
    // }
  }

  public selectById(id: number) {
    this.currentDriver = Object.assign({},this.drivers.find(value => value.id == id));
    this.isIntegration = this.checkEditable();
  }

  public openDeleteDialog(id: number): void {
    this.selectById(id);
    if(this.isIntegration) {
      this.alertSwal.show();
    }else{
      this.deleteSwal.show();
    }
  }

  public openAttributes(id: number): void {
    this.selectById(id);
    this.isVisibleAttributesCompact = true;
  }

  public onCancelEdit() {
    //restore data

    this.confirmSwal.show();
  }

  /*events*/
  public onClickRow(event) {
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if (event.value.event.target.checked) { //existe
          this.selected.add(event.value.row.id);
        } else { //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      case 'onDoubleClick':
        this.edit(event.value.row.id);
        break;
      case 'onSelectAll':
        if (event.value == true) {
          this.drivers.forEach(value => {
            this.selected.add(value.id);
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
  }

  private formatData(drivers: Array<Driver>): Array<any> {
    let formatted = [];
    drivers.forEach(value => {
      formatted.push({
        id: value.id,
        name: value.name,
        uniqueId: value.uniqueId,
        attributes: value.attributes,
        expiration: value.expiration,
        expirationText: this.dateFilter.transform(value.expiration, 'dd/MM/yyyy'),
        category: value.category,
        glasses: value.glasses,
        headphones: value.headphones,
        bloodType: value.bloodType,
        isDeviceOn: this.isDeviceOn(value.id)
      });
    });
    return formatted;
  }

  private checkEditable() : boolean{
    return !!(!isNullOrUndefined(this.currentDriver.attributes) && !isNullOrUndefined(this.currentDriver.attributes['origin']) && this.currentDriver.attributes['origin']);
  }

}
