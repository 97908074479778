import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {User} from "@app/core/models/user.model";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {Maintenance} from "@app/core/models/maintenance.model";
import {FilterMaintenanceBySearchParamPipe} from "@app/core/pipes/filter-maintenance-by-search-param.pipe";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {KnownAttribute} from "@app/core/models/known-attribute.model";
import {Dictionary, Set} from "typescript-collections";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {Config} from "ngx-easy-table";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {DatePipe} from "@angular/common";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-maintenance-bundle',
  templateUrl: './maintenance-bundle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./maintenance-bundle.component.css']
})
export class MaintenanceBundleComponent implements OnInit, AfterViewInit {

  @Input()
  public device: Device;

  @Output()
  public onClose: EventEmitter<Maintenance> = new EventEmitter();

  public currentMaintenance: Maintenance | any;

  @Input()
  public state: ModelPageState = "search";

  //Search
  public searchOptions: Array<ISearchParam>;
  public isLoading: boolean = false;
  public sortOrder: string;

  //USER'S GROUPS FROM API
  public maintenances: Array<Maintenance> = [];
  public defaultMaintenances: Array<Maintenance> = [];
  //
  private deviceMaintenances: Array<Maintenance> = [];
  //types
  public selectedType: KnownAttribute;
  public defaultTypes: Array<KnownAttribute>;

  public filter: FilterMaintenanceBySearchParamPipe = new FilterMaintenanceBySearchParamPipe();

  //ATTRIBUTES
  public isVisibleAttributesCompact: boolean = false;
  // public filterAttributes: KeysPipePipe = new KeysPipePipe();

  public permissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  private _user: User;
  public role: string;

  @ViewChild(SearchTopBarComponent) appSearchTopBarComponent: SearchTopBarComponent;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;

  @ViewChild('nameTpl') public nameTpl: TemplateRef<any>;
  @ViewChild('typeTpl') public typeTpl: TemplateRef<any>;
  @ViewChild('startTpl') public startTpl: TemplateRef<any>;
  @ViewChild('periodTpl') public periodTpl: TemplateRef<any>;

  @ViewChild('buttonsTpl') public buttonsTpl: TemplateRef<any>;
  @ViewChild('toogleTpl') public toogleTpl: TemplateRef<any>;

  public configuration: Config;
  public columns;
  public selected: Set<number> = new Set<number>();

  public dateFilter: DatePipe = new DatePipe("es");

  public editForm: FormGroup;

  get formFields() {
    return this.editForm.controls;
  }


  constructor(private apiService: ApiService,
              private readonly fb: FormBuilder,
              private alertService: AlertService,
              private cdr: ChangeDetectorRef,
              private logger: NGXLogger) {

    //load known Attributes.u
    this.defaultTypes = this.apiService.sharedService.getMaintenanceKnownAttributes();

    let options: Array<IModel> = [];
    this.defaultTypes.forEach(user => {
      options.push({id: user.type, name: user.type, display: user.name});
    });

    this.selectedType = this.defaultTypes[0];

    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'green',
      field: 'name',
      type: 'text',
      remote: false
    }, {
      name: 'Tipo',
      value: '',
      color: 'blue',
      field: 'type',
      type: 'select',
      options: options,
      remote: false
    }];
//edit
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      start: ['0', [Validators.required, Validators.min(0)]],
      period: ['0', [Validators.required, Validators.min(0)]],
      startDate: [''],
      periodDays: ['']
    });
  }


  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.loadPermissions();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public loadPermissions(): void {
    this.permissions = this.apiService.roleService.maintenancePermissions;
  }

  public loadData(): void {
    this.loadMaintenances(this.role == 'root');
    this.loadAdminSearchParam();
  }

  private loadMaintenances(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean) {
    this.isLoading = true;
    //load Device Maintenance first
    if (!isNullOrUndefined(this.device)) {
      this.apiService.maintenanceService.getMaintenances(false, null, this.device.id).subscribe(value => {
        this.deviceMaintenances = value;
      }, error1 => this.logger.error("Se produjo un error al cargar los datos de mantenimientos"), () => {
        //now load maintenance
        this.apiService.maintenanceService.getMaintenances(all, userId, deviceId, groupId, refresh).subscribe(value => {
          const formatted = this.formatData(value);
          this.maintenances = [...formatted];
          this.defaultMaintenances = [...formatted];
        }, error1 => {
          this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
          this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
        }, () => {
          this.isLoading = false;
          this.configureTableData();
          this.cdr.markForCheck();
        });
      });
    } else {
      this.apiService.maintenanceService.getMaintenances(all, userId, deviceId, groupId, refresh).subscribe(value => {
        const formatted = this.formatData(value);
        this.maintenances = [...formatted];
        this.defaultMaintenances = [...formatted];
      }, error1 => {
        this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
        this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
      }, () => {
        this.isLoading = false;
        this.configureTableData();
        this.cdr.markForCheck();
      });
    }

  }

  private loadAdminSearchParam() {
    if (!isNullOrUndefined(this._user)) {
      if (this._user.administrator) {
        //UserId
        const indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'violet',
              type: 'select',
              options: options,
              selectValue: {id: this._user.id, name: this._user.name, display: this._user.name},
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });

        //DeviceId
        const indexDeviceId = this.searchOptions.findIndex(value => value.field == 'deviceId');
        this.apiService.deviceService.getDevices(this.role == 'root').subscribe(devices => {
          let options: Array<IModel> = [];
          devices.forEach(device => {
            options.push({id: device.id.toString(), name: device.id.toString(), display: device.name});
          });
          if (indexDeviceId !== -1) {
            this.searchOptions[indexDeviceId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Dispositivo',
              field: 'deviceId',
              value: '',
              color: 'olive',
              type: 'select',
              options: options,
              remote: true
            });
          }//update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
        //groupId
        const indexGroupId = this.searchOptions.findIndex(value => value.field == 'groupId');
        this.apiService.groupService.getGroups(this.role == 'root').subscribe(groups => {
          let options: Array<IModel> = [];
          groups.forEach(group => {
            options.push({id: group.id.toString(), name: group.id.toString(), display: group.name});
          });
          if (indexGroupId !== -1) {
            this.searchOptions[indexGroupId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Grupo',
              field: 'groupId',
              value: '',
              color: 'orange',
              type: 'select',
              options: options,
              remote: true
            });
          }//update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
      }
    }
  }

  public onSearch(params: Array<ISearchParam>): void {
    if (params.findIndex(value => value.remote == true) !== -1)
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    else {
      this.maintenances = this.filter.transform(this.defaultMaintenances, params);
    }
  }

  private doSearch(params: Array<ISearchParam>) {

    let userId: number;
    let deviceId: number;
    let groupId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
        } else if (param.field == "deviceId" && param.selectValue.id) {
          deviceId = Number(param.selectValue.id);
        } else if (param.field == "groupId" && param.selectValue.id) {
          groupId = Number(param.selectValue.id);
        }
        search = true;
      }
    });
    if (search) {
      this.loadMaintenancesForSearch(userId, deviceId, groupId, params);
    }
  }

  private loadMaintenancesForSearch(userId: number, deviceId: number, groupId: number, params: Array<ISearchParam>) {
    this.isLoading = true;
    this.apiService.maintenanceService.getMaintenances(false, userId, deviceId, groupId).subscribe(value => {
      const formatted = this.formatData(value);
      this.maintenances = this.filter.transform(formatted, params);
    }, error1 => {
      this.logger.error("Se ha producido un error al cargar los datos. " + error1.toString());
      this.alertService.error("Verifique sus Credenciales", "Se ha producido un error al cargar los datos.");
    }, () => {
      this.isLoading = false;
    });
  }

  public clearSelection() {
    this.currentMaintenance = new Maintenance();
    this.editForm.reset();
    this.state = "search";
    this.maintenances = this.defaultMaintenances;
    this.appSearchTopBarComponent.clearSearchParams();
  }

  public onCloseAttribs(attribs) {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentMaintenance.attributes = attribs;
      this.update();
    }
  }

  public close(): void {
    this.clearSelection();
    this.onClose.emit(this.currentMaintenance);
  }

  /* CHECK ROLES */
  // public checkRole(action: RolePermission): boolean {
  //   return this.apiService.roleService.checkRoleMaintenance(action);
  // }

  /* validators */
  public isValid(): boolean {
    if (isNullOrUndefined(this.currentMaintenance))
      return false;
    if (this.currentMaintenance.type != 'date')
      return !!(this.currentMaintenance.name) || !!(this.currentMaintenance.type) || !!(this.currentMaintenance.start) || !!(this.currentMaintenance.period);
    else
      return !!(this.currentMaintenance.name) || !!(this.currentMaintenance.type) || !!(this.currentMaintenance.startDate) || !!(this.currentMaintenance.periodDays);
  }

  /* API */
  public save() {
    if (this.isValid()) {
      this.fillFromForm();
      this.apiService.maintenanceService.createMaintenance(this.currentMaintenance).subscribe(value => {
        this.alertService.success("Guardado!", "Mantenimiento guardado con exito");
        // this.currentMaintenance = value;
      }, error1 => {
        this.alertService.error("Error al Guardar el Mantenimiento", `Se produjo un error al guardar el Mantenimiento`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public update() {
    if (this.isValid()) {
      this.fillFromForm();
      this.apiService.maintenanceService.updateMaintenance(this.currentMaintenance.id, this.currentMaintenance).subscribe(value => {
        this.alertService.success("Actualizado!", "Mantenimiento actualizado con exito");
        // this.currentMaintenance = value;
      }, error1 => {
        this.alertService.error("Error al Actualizar el Mantenimiento", `Se produjo un error al actualizar el Mantenimiento`);
        this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`)
      }, () => {
        this.loadData();
        this.clearSelection();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public delete() {
    this.apiService.maintenanceService.deleteMaintenance(this.currentMaintenance.id).subscribe(value => {
      this.alertService.success("Eliminado!", "El Mantenimiento se ha eliminado con exito");
      // this.currentMaintenance = value;
    }, error1 => {
      this.alertService.error("Error al eliminar el Mantenimiento", `Se produjo un error al eliminar el Mantenimiento`);
      this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`)
    }, () => {
      this.loadData();
      this.clearSelection();
    });
  }

  /* actions */
  public isDeviceOn(id: number): boolean {
    if (!isNullOrUndefined(this.device)) {
      return this.deviceMaintenances.findIndex(value => value.id == id) !== -1;
    } else {
      return false;
    }
  }

  public toogleForDevice(id: number, state: boolean) {
    if (state) {//existe..
      this.apiService.permissionService.permissionsPost({
        deviceId: this.device.id,
        maintenanceId: id
      }).subscribe(value => {
        // this.loadData();
      }, error1 => {
        this.alertService.error('Error al activar el mantenimiento en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al activar al mantenimiento en el dispositivo. ' + error1.toString());
      });
    } else {//no existe..
      this.apiService.permissionService.permissionsDelete({
        deviceId: this.device.id,
        maintenanceId: id
      }).subscribe(value => {
        // this.loadData();
      }, error1 => {
        this.alertService.error('Error al desactivar el mantenimiento en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al desactivar el mantenimiento en el dispositivo. ' + error1.toString());
      });
    }
  }

  public toogleSelection(flag: boolean): void {
    if (flag) {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceMaintenances.findIndex(current => current.id == value);
        if (index === -1) { //no existe agregamos.
          valuesTo.push({
            deviceId: this.device.id,
            maintenanceId: value
          });
        }
      });
      this.savePermissions(valuesTo);
    } else {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceMaintenances.findIndex(current => current.id == value);
        if (index !== -1) { //si existe eliminamos.
          valuesTo.push({
            deviceId: this.device.id,
            maintenanceId: value
          });
        }
      });
      this.removePermissions(valuesTo);
    }
  }

  /* permissions */
  private savePermissions(values) {
    this.apiService.permissionService.permissionsArrayPost(values).subscribe(value => {
      if (isNullOrUndefined(value)) {
        this.alertService.error(`Error al vincular los mantenimientos`, 'Verifique sus credenciales.');
      }
    }, error1 => {
      this.logger.error(`Error al vincular los mantenimientos: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Guardado', 'Los mantenimientos han sido vinculados.');
      //reload data
      this.loadMaintenances(this.role == 'root');
      // this.selected.clear();
    });
  }

  private removePermissions(values) {
    this.apiService.permissionService.permissionsArrayDelete(values).subscribe(value => {
      if (isNullOrUndefined(value)) {
        this.alertService.error(`Error al desvincular los mantenimientos`, 'Verifique sus credenciales.');
      }
    }, error1 => {
      this.logger.error(`Error al desvincular los mantenimientos: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Eliminado', 'Los mantenimientos han sido desvinculados.');
      //reload data
      this.loadMaintenances(this.role == 'root');
      // this.selected.clear();
    });
  }

  public addNew() {
    this.state = 'new';
    this.currentMaintenance = new Maintenance();
    this.currentMaintenance.startDate = new Date();
    this.editForm.reset();
    this.selectedType = this.defaultTypes[0];
  }

  public onSubmit(): void {
    this.prepareData();
    if (this.state == "new") {
      this.save();
    } else {
      this.update();
    }
  }

  private prepareData() {
    if (this.selectedType.type != 'date') {
      this.currentMaintenance.periodDays = 0;
      this.currentMaintenance.startDate = null;
    } else {
      this.currentMaintenance.start = 0;
      this.currentMaintenance.period = 0;
    }
    this.currentMaintenance.type = this.selectedType.type;
    // this.currentMaintenance.name = this.formFields.name.value;
    this.clearChunkData();
  }

  public clearChunkData(): void {
    delete this.currentMaintenance.startDateF;
    delete this.currentMaintenance.isDeviceOn;
  }

  //
  public edit(id: number): void {
    this.selectById(id);
    //fill other stuff
    const index = this.defaultTypes.findIndex(value => value.type == this.currentMaintenance.type);
    if (index !== -1)
      this.selectedType = this.defaultTypes[index];
    // this.currentMaintenance.startDate = t;
    //form
    this.formFields.name.setValue(this.currentMaintenance.name);
    this.formFields.start.setValue(this.currentMaintenance.start);
    this.formFields.period.setValue(this.currentMaintenance.period);
    this.formFields.periodDays.setValue(this.currentMaintenance.periodDays);
    //
    if (!isNullOrUndefined(this.currentMaintenance.startDate))
      this.currentMaintenance.startDate = new Date(Date.parse(this.currentMaintenance.startDate.toString()));
    this.state = 'edit';

    this.cdr.detectChanges();
  }

  public selectById(id: number) {
    this.currentMaintenance = Object.assign({}, this.maintenances.find(value => value.id == id));
  }

  public openDeleteDialog(id: number): void {
    this.selectById(id);
    this.deleteSwal.show();
  }

  public openAttributes(id: number): void {
    this.selectById(id);
    this.isVisibleAttributesCompact = true;
  }

  public onCancelEdit() {
    //restore data
    this.confirmSwal.show();
  }

  /*events*/
  public onClickRow(event) {
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if (event.value.event.target.checked) { //existe
          this.selected.add(event.value.row.id);
        } else { //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      case 'onDoubleClick':
        if(this.permissions.getValue('edit'))
          this.edit(event.value.row.id);
        break;
      case 'onSelectAll':
        if (event.value == true) {
          this.maintenances.forEach(value => {
            this.selected.add(value.id);
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
  }

  private configureTableData() {
    if (isNullOrUndefined(this.device)) {
      this.columns = [
        {key: 'name', title: 'Nombre', cellTemplate: this.nameTpl, width: '40%'},
        {key: 'type', title: 'Tipo', cellTemplate: this.typeTpl, width: '20%'},
        {key: 'start', title: 'Inicio', cellTemplate: this.startTpl, width: '20%'},
        {key: 'period', title: 'Periodo', cellTemplate: this.periodTpl, width: '20%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '15%'}
      ];
      this.configuration.checkboxes = false;
    } else {
      this.columns = [
        {key: 'name', title: 'Nombre', cellTemplate: this.nameTpl, width: '40%'},
        {key: 'type', title: 'Tipo', cellTemplate: this.typeTpl, width: '20%'},
        {key: 'start', title: 'Inicio', cellTemplate: this.startTpl, width: '20%'},
        {key: 'period', title: 'Periodo', cellTemplate: this.periodTpl, width: '15%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '10%'},
        {key: '', title: 'Vincular', cellTemplate: this.toogleTpl, orderEnabled: false, width: '10%'}
      ];
      this.configuration.checkboxes = true;
    }
  }

  private formatData(maintenances: Array<Maintenance>) {
    let formatted = [];
    maintenances.forEach(value => {
      formatted.push({
        id: value.id,
        name: value.name,
        type: value.type,
        start: value.start,
        startDateF: this.dateFilter.transform(value.startDate, 'dd/MM/yyyy'),
        startDate: value.startDate,
        period: value.period,
        periodDays: value.periodDays,
        attributes: value.attributes,
        isDeviceOn: this.isDeviceOn(value.id)
      });
    });
    return formatted;
  }

  private fillFromForm() {
    this.currentMaintenance.name = this.formFields.name.value;
    this.currentMaintenance.type = this.selectedType.type;
    this.currentMaintenance.start = this.formFields.start.value;
    // this.currentMaintenance.startDate = this.formFields.start.value;
    this.currentMaintenance.period = this.formFields.period.value;
    this.currentMaintenance.periodDays = this.formFields.periodDays.value;
  }
}
