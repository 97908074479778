import {Pipe, PipeTransform} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'filterDeviceBySearchParam'
})
export class FilterDeviceBySearchParamPipe implements PipeTransform {

  transform(values: Array<Device>, search: Array<ISearchParam>): Array<Device> {
    if (!values) return [];
    if (!search || search.length == 0) return values;
    let filtered: Array<Device> = values;
    search.forEach(value => {
      // console.log(JSON.stringify(value));
      if (value.field == "name") {
        // console.log(`ORDENAR POR NOMBRE -> ${value.value}`);
        filtered = (this.filterByName(filtered, value.value));
      }
      else if (value.field == "uniqueId") {
        filtered = (this.filterByUniqueId(filtered, value.value));
      } else if (value.field == "model") {
        // console.log(`ORDENAR POR MODEL -> ${value.value}`);
        filtered = (this.filterByModel(filtered, value.value));
      } else if (value.field == "phone") {
        filtered = (this.filterByPhone(filtered, value.value));
      } else if (value.field == "contact") {
        filtered = (this.filterByContact(filtered, value.value));
      } else if (value.field == "status") {
        // console.log(`ORDENAR POR NOMBRE -> ${value.value}`);
        filtered = (this.filterByStatus(filtered, value.value));
      } else if (value.field == "category") {
        filtered = (this.filterByCategory(filtered, value.value));
      } else if (value.field == "groupId") {
        filtered = (this.filterByGroupId(filtered, value.value));
      } else if (value.field == "capacityWeight") {
        filtered = (this.filterByCapacityWeight(filtered, value.value));
      }else if (value.field == "capacityVolume") {
        filtered = (this.filterByCapacityVolume(filtered, value.value));
      }
    });
    return filtered;
  }


  filterByName(values: Array<Device>, name: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {
      return device.name.toLocaleLowerCase().includes(name.toLowerCase());
    });
  }

  filterByUniqueId(values: Array<Device>, uniqueId: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {
      return device.uniqueId.toLocaleLowerCase().includes(uniqueId.toLowerCase());
    });
  }

  filterByModel(values: Array<Device>, model: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {
      if (!isNullOrUndefined(device.model))
        return device.model.toLocaleLowerCase().includes(model.toLowerCase());
      else
        return false;
    });
  }

  filterByPhone(values: Array<Device>, phone: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {

      if (!isNullOrUndefined(device.phone))
        return device.phone.toLocaleLowerCase().includes(phone.toLowerCase());
      else
        return false;
    });
  }

  filterByContact(values: Array<Device>, contact: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {
      if (!isNullOrUndefined(device.contact))
        return device.contact.toLocaleLowerCase().includes(contact.toLowerCase());
      else
        return false;
    });
  }

  filterByStatus(values: Array<Device>, status: string): Array<Device> {
    if (!values) return [];
    // console.log('STATUS -> ' + status);
    return values.filter(device => {
      if (!isNullOrUndefined(device.status))
        return device.status.toLocaleLowerCase().includes(status.toLowerCase());
      else
        return false;
    });
  }

  filterByCategory(values: Array<Device>, category: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {
      if (!isNullOrUndefined(device.category))
        return device.category.toLocaleLowerCase().includes(category.toLowerCase());
      else
        return false;
    });
  }

  filterByGroupId(values: Array<Device>, groupId: string): Array<Device> {
    if (!values) return [];
    return values.filter(device => {
      if (!isNullOrUndefined(device.groupId))
        return device.groupId == Number(groupId);
      else
        return false;
    });
  }

  filterByCapacityWeight(values: Array<Device>, capacity: string): Array<Device> {
    if (!values) return [];
    const capacityWeight = Number(capacity);
    if(isNullOrUndefined(capacityWeight))
      return values;
    return values.filter(device => {
      if (!isNullOrUndefined(device.capacityWeight))
        return device.capacityWeight == capacityWeight;
      else
        return false;
    });
  }

  filterByCapacityVolume(values: Array<Device>, capacity: string): Array<Device> {
    if (!values) return [];
    const capacityVolume = Number(capacity);
    if(isNullOrUndefined(capacityVolume))
      return values;
    return values.filter(device => {
      if (!isNullOrUndefined(device.capacityVolume))
        return device.capacityVolume == capacityVolume;
      else
        return false;
    });
  }
}
