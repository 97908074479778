import { Injectable } from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Maintenance} from "@app/core/models/maintenance.model";
import {Observable} from "rxjs";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";
import {KnownAttribute} from "@app/core/models/known-attribute.model";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }
  /**
   * Fetch a list of Maintenances
   * Without params, it returns a list of Maintenances the user has access to
   * @param all Can only be used by admins or managers to fetch all entities
   * @param userId Standard users can use this only with their own _userId_
   * @param deviceId Standard users can use this only with _deviceId_s, they have access to
   * @param groupId Standard users can use this only with _groupId_s, they have access to
   * @param refresh
   */
  public getMaintenances(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean): Observable<Array<Maintenance>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId )) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }
    if (!isNullOrUndefined(deviceId)) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }
    if (!isNullOrUndefined(groupId )) {
      queryParameters = queryParameters.set('groupId', <any>groupId);
    }
    if (!isNullOrUndefined(refresh)) {
      queryParameters = queryParameters.set('refresh', <any>refresh);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Maintenance>>(`${this.basePath}${this.REST_URLS.maintenances}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        headers: headers,
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Delete a Maintenance
   *
   * @param id
   */
  public deleteMaintenance(id: number): Observable<any> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling deleteMaintenance.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.maintenances}/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * Update a Maintenance
   *
   * @param id
   * @param body
   */
  public updateMaintenance(id: number, body: Maintenance): Observable<Maintenance>{
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling updateMaintenance.');
    }
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling updateMaintenance.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<Maintenance>(`${this.basePath}${this.REST_URLS.maintenances}/${encodeURIComponent(String(id))}`,
      body,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Create a Maintenance
   *
   * @param body
   */
  public createMaintenance(body: Maintenance): Observable<Maintenance> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling createMaintenance.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<Maintenance>(`${this.basePath}${this.REST_URLS.maintenances}`,
      body,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }

  /**
   * Get Maintenance Types
   *
   */
  public getMaintenanceTypes():Observable<KnownAttribute>{
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<KnownAttribute>>(`${this.basePath}${this.REST_URLS.maintenanceTypes}`,
      {
        withCredentials: this.withCredentials,
        headers: headers,
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }
}
