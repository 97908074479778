import { Pipe, PipeTransform } from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {Driver} from "@app/core/models/driver.model";

@Pipe({
  name: 'filterDriverBySearchParam'
})
export class FilterDriverBySearchParamPipe implements PipeTransform {

  transform(values: Array<Driver>, params: Array<ISearchParam>): Array<Driver> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<Driver> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }
        if(param.field == 'uniqueId'){
          filtered = (this.filterByUniqueId(filtered,param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<Driver>, name: string) {
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(driver => {
      return driver.name.toLowerCase().includes(name.toLowerCase());
    });
  }

  private filterByUniqueId(values: Array<Driver>, uniqueId: string) {
    if(!values) return [];
    if(isNullOrUndefined(uniqueId)) return values;
    return values.filter(driver => {
      return driver.uniqueId.toLowerCase().includes(uniqueId.toLowerCase());
    });
  }


}
