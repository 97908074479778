import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificatorType'
})
export class NotificatorTypePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    switch (value) {
      case 'web':
        return 'Web';
      case 'mail':
        return 'Correo';
      case 'firebase':
        return 'Móvil';
      case 'sms':
        return 'Sms';
      default:
        return value;
    }
  }

}
