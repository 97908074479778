import {Pipe, PipeTransform} from '@angular/core';
import {Notification} from "@app/core/models/notification.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";

@Pipe({
  name: 'filterNotificationBySearchParam'
})
export class FilterNotificationBySearchParamPipe implements PipeTransform {

  private pipe: NotificationTypePipe = new NotificationTypePipe();//Pipe for converting NotificationTypes

  transform(values: Array<Notification>, search: Array<ISearchParam>): Array<Notification> {
    if (!values) return [];
    if (!search || search.length == 0) return values;
    let filtered: Array<Notification> = values;
    search.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote) {
        if (param.field == 'always') {
          filtered = (this.filterByAlways(filtered, param.value));
        }
        if (param.field == 'name') {
          filtered = (this.filterByName(filtered, param.value));
        }
        if (param.field == 'type') {
          if (param.type == 'select')
            filtered = (this.filterByType(filtered, param.value));
          else
            filtered = (this.filterByTypeText(filtered, param.value));
        } else if (param.field == 'notificators') {
          filtered = (this.filterByNotificators(filtered, param.value));
        }
      }
    });
    return filtered;
  }

  public filterByType(values: Array<Notification>, type: string) {
    if (!values) return [];
    return values.filter(notification => {
      return notification.type.toLocaleLowerCase().includes(type.toLowerCase());
    });
  }

  private filterByNotificators(values: Array<Notification>, value: string) {
    if (!values) return [];
    return values.filter(notification => {
      return notification.notificators.toLocaleLowerCase().includes(value.toLowerCase());
    });
  }

  private filterByAlways(values: Array<Notification>, always: string) {
    if (!values) return [];
    return values.filter(notification => {
      return (notification.always == (always == 'true'));
    });
  }
  private filterByName(values: Array<Notification>, name: string) {
    if (!values) return [];
    if (isNullOrUndefined(name)) return values;
    return values.filter(notification => {
      return (notification.name.toLowerCase().includes(name.toLowerCase()));
    });
  }

  private filterByTypeText(values: Array<Notification>, type: string) {
    if (!values) return [];
    if (isNullOrUndefined(type)) return values;
    return values.filter(notification => {
      if (isNullOrUndefined(notification.type) || isNullOrUndefined(type)) {
        return false;
      }
      return this.pipe.transform(notification.type).toLocaleLowerCase().includes(type.toLowerCase());
    });
  }
}
