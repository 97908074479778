export class Device {
  id?: number;
  attributes?: any;
  name?: string;
  uniqueId?: string;
  status?: string;
  disabled?: boolean;
  lastUpdate?: Date;
  positionId?: number;
  groupId?: number;
  phone?: string;
  model?: string;
  contact?: string;
  category?: string;
  geofenceIds?: Array<number>;
  // vehiculeIds?: Array<number>;
  icon?:string;
  capacityWeight?: number; //kg
  capacityVolume?: number; //m3
}
