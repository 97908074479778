import {Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {Event} from "@app/core/models/event.model";
import {ApiService} from "@app/shared/services/api.service";
import {NGXLogger} from "ngx-logger";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IReportContext} from "@app/core/interfaces/ireport-context.interface";
import {IBundle} from "@app/core/interfaces/ibundle.interface";
import {ModelPageState, ReportGraphType} from "@app/core/types/buho-core-types";
import {AlertService} from "@app/shared/services/alert.service";
import {DatePipe, DOCUMENT} from "@angular/common";
import {Dictionary, Set} from "typescript-collections";
import {IReportBundle} from "@app/core/interfaces/ireport-bundle";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {Config} from "ngx-easy-table";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Position} from "@app/core/models/position.model";
import {Device} from "@app/core/models/device.model";
import {User} from "@app/core/models/user.model";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

import {IMarker} from "@app/core/interfaces/imarker.interface";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {IMapOptions} from "@app/core/interfaces/imap-options.interface";
import {IPosition} from "@app/core/interfaces/iposition";
import {KeysPipePipe} from "@app/shared/pipes/keys-pipe.pipe";
import {
  ControlPosition,
  FullscreenControlOptions,
  MapTypeControlOptions,
  MapTypeControlStyle,
  MapTypeId
} from "@agm/core/services/google-maps-types";

declare var google: any;

@Component({
  selector: 'app-report-stop-bundle',
  templateUrl: './report-stop-bundle.component.html',
  styleUrls: ['./report-stop-bundle.component.css']
})
export class ReportStopBundleComponent implements OnInit, IBundle, IReportBundle {

  public state: ModelPageState = 'search';

  //Search
  public searchOptions: Array<ISearchParam> = [];

  //Reports
  public report: ReportGraphType = 'line';

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();


  @Input()
  public printTableSectionId: string = 'printTableSectionId';

  //DATATABLE
  public totals: any = {};
  public maxSpeed: number =0;
  public averageSpeed: number =0;
  public totalDistance: number =0;
  public isLoading: boolean = false;
  public isLoadingPre: boolean = false;
  public selectedRow: Event;
  public selectedPage: number = 1;

  public pipe = new DatePipe('es-BO');

  /* smart table */
  public configuration: Config;
  public columns;
  //Data
  public rows: Array<any> = [];
  public temp: Array<any> = [];

  //Google maps
  public lat: number = 0;
  public lng: number = 0;
  public zoom: number = 14;
  public map: any;
  public selectedOverlay: any;
  public ready: boolean = false;
  public styles = [];
  public get markers(): Dictionary<number, IMarker>{
    return this.markersValue;
  }

  @Output()
  onMarkerClicked: EventEmitter<IMarker> = new EventEmitter<IMarker>();

  @Input()
  public set markers(markers: Dictionary<number, IMarker> ){
    this.markersValue = markers;
  }
  public markersValue: Dictionary<number, IMarker> = new Dictionary<number, IMarker>();
  public mapTypeId: string = 'roadmap';
  @Input()
  public focusZoom: number = 17;
  public deviceVisibleIds: Array<number> = [];

  @Input()
  public limitPage: number = 10;

  private _filterDate = new DatePipe("es-BO");

  @Input()
  public context: IReportContext;

  public graphData = [];
  public separateDataDevice = [];
  public deviceIds = [];

  /** Dependencies **/
  public devices: Array<Device> = [];


  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  //Graph reports
  colorScheme = {
    domain: ['#1E2F23', '#6435C9', '#FBBD08', '#B5CC18', '#F2711C', '#40BCD8', '#A5673F', '#767676', '#21BA45', '#DB2828', '#E03997']
  };

  private _user: User;
  private role: any;

  constructor(private configService: WebConfigurationService,
              private apiService: ApiService,
              private alertService: AlertService,
              private readonly cdr: ChangeDetectorRef,
              @Inject(DOCUMENT) private document: Document,
              private logger: NGXLogger) {

    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];

    this.loadDependencies();

    /* Search options */
    this.searchOptions = [];
  }

  ngOnInit() {
    //table configuration
    this.configureTableData();
  }

  public configureTableData() {
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.columns = [
      {key: 'device', name: 'Dispositivo', order: 'none'},
      {key: 'latitude', name: 'Latitud', order: 'none'},
      {key: 'longitude', name: 'Longitud', order: 'none'},
      {key: 'speed', name: 'Velocidad', order: 'none'}
    ];
    this.configuration.checkboxes = false;
    this.configuration.paginationEnabled = true;
    this.configuration.paginationRangeEnabled = false;
    this.configuration.tableLayout.striped = false;
    this.configuration.groupRows = false;
    this.configuration.rows = this.limitPage;
  }

  public loadData(reportContext: IReportContext) {
    this.isLoading = true;
    this.context = reportContext;
    this.configureTableData();

    this.apiService.reportService.reportsStopsGet(
      this.context.from,
      this.context.to,
      this.context.deviceIds,
      this.context.groupIds
      ).subscribe(value => {
      if (isNullOrUndefined(value)) {
        return;
      }
      this.rows = [];
      this.temp = [];
      const formatted = this.formatData(value);
      this.temp = [...formatted];
      this.rows = [...formatted];

      this.preparePositions();
      this.cdr.detectChanges();
    }, error1 => {
      this.alertService.error("Sucedio un error al cargar los datos", error1.toString());
    }, () => this.isLoading = false);
  }

  public loadDependencies() {
    //devices
    this.apiService.deviceService.getDevices(this.role == 'root').subscribe(value => {
      this.devices = value;
    },error1 => this.logger.error("Se produjo un error al cargar los dispositivos."),() => {
      //UserId
      let indexDeviceId = this.searchOptions.findIndex(value => value.field == 'device');
      let options: Array<IModel> = [];
      this.devices.forEach(device => {
        options.push({id: device.id.toString(), name: device.name, display: device.name});
      });
      if (indexDeviceId !== -1) {
        this.searchOptions[indexDeviceId].options = options;
      }
      //update
      this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
    });

  }

  public formatData(data: Array<any>): Array<any> {
    let formatted = [];

    data.forEach(stop => {
      formatted.push({
        deviceId: stop.deviceId,
        latitude: stop.latitude,
        longitude: stop.longitude,
        startTime: stop.startTime,
        endTime: stop.endTime,
        engineHours: stop.engineHours,
        startOdometer: stop.startOdometer,
        endOdometer: stop.endOdometer,
        duration: stop.duration
      });
    });
    return formatted;
  }

  close(): void {
    this.onClose.emit();
  }

  public onSearch(params: Array<ISearchParam>) {
    let temp = this.temp;
    params.forEach(param => {
      if (param.field == 'type') {
        temp = temp.filter(value => value.type.toLowerCase().includes(param.value.toLowerCase()));
      } else if (param.field == 'device') {
        temp = temp.filter(value => value.device.toLowerCase().includes(param.value.toLowerCase()));
      }
    });
    if (temp != this.rows) {
      this.rows = [...temp];
      this.preparePositions();
    }
  }


  public validateContext(reportContext: IReportContext, alert: boolean = false): boolean {
    if (isNullOrUndefined(reportContext)) {
      if (alert)
        this.alertService.error("Error al cargar el reporte", "No existe un contexto valido.");
      return false;
    } else {
      if (isNullOrUndefined(reportContext.from)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha inicial valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.to)) {
        if (alert)
          this.alertService.error("Fecha Invalida", "No ha ingresado una fecha final valida.");
        return false;
      } else if (isNullOrUndefined(reportContext.deviceIds) || reportContext.deviceIds.length <= 0) {
        if (alert)
          this.alertService.error("Sin Dispositivos", "Debe seleccionar al menos un disposito.");
        return false;
      } else {
        return true;
      }
    }
  }

  //base
  public print() {}

  public loadDeviceName(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].name;
      }
    }
  }

  public loadDeviceIcon(deviceId: number): string {
    if (isNullOrUndefined(deviceId)) {
      return '';
    } else {
      const index = this.devices.findIndex(value => value.id == deviceId);
      if (index !== -1) {
        return this.devices[index].icon;
      }
    }
  }

  public getDevicesTemp(): Array<any>{
    let dic: Dictionary<number, Array<string>> = new Dictionary<number, Array<string>>();
    this.rows.forEach(value => {
      if (!dic.containsKey(value.deviceId)) {
        dic.setValue(value.deviceId, []);
      }
    });
    this.deviceIds = dic.keys();
    return this.deviceIds;
  }

  public preparePositions() : void {
    let deviceIds = this.getDevicesTemp();
    deviceIds.forEach(deviceId => {
      let array = [];
      let data = this.rows.filter(value => value.deviceId == deviceId);
      data.forEach(value => {
        const result : IMarker = {
          latitude : value.latitude,
          longitude :value.longitude,
          iconURL : './assets/images/map/svg/thoseicons/start-marker.svg'
        };
        let duration = ((value.duration / 1000) / 60); // min
        //duration = Math.round(duration * 100) / 100;
        let startTime = this._filterDate.transform(value.startTime, 'HH:mm:ss');
        let endTime = this._filterDate.transform(value.endTime, 'HH:mm:ss');
        array.push({
          lat: value.latitude,
          lng: value.latitude,
          startTime: startTime,
          endTime: endTime,
          engineHours: value.engineHours,
          startOdometer: value.startOdometer,
          endOdometer: value.endOdometer,
          address: value.address,
          showMap: false,
          duration: value.duration,
          durationMins: duration.toFixed(2),
          pointStop: result
        });
      });
      this.separateDataDevice.push({
        deviceId: deviceId,
        stops: array
      });
    });

    this.isLoadingPre = true;
  }

  downloadReport(reportContext?: IReportContext) {
    if (!isNullOrUndefined(reportContext))
      this.context = reportContext;
    if (!isNullOrUndefined(this.context)) {
      if (isNullOrUndefined(this.rows) || this.rows.length == 0) {
        this.alertService.warning("Imposible Descargar", "No hay datos para descargar");
        return;
      }
      this.isLoading = true;
      this.apiService.reportService.reportsStopsGetXLSX(
        this.context.from,
        this.context.to,
        this.context.deviceIds,
        this.context.groupIds
        ).subscribe(value => {
        if (isNullOrUndefined(value)) {
          this.alertService.error("Sucedio un error", "No puede descargarse el reporte");
          return;
        }
        //this.logger.warn("DATA DOWNLOADED "+JSON.stringify(value));
        let newBlob = new Blob([value], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = this.createNameFile("reporte_", ".xlsx");
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      }, error1 => {
        this.alertService.error("Sucedio un error al cargar descargar", error1.toString());
      }, () => this.isLoading = false);
    }
  }

  private createNameFile(prefix?: string, extension?: string) {
    let ans = `Paradas ${this.context.from.toISOString()}_${this.context.to.toISOString()}`;
    if (!isNullOrUndefined(prefix)) {
      ans = prefix + ans;
    }
    if (!isNullOrUndefined(extension)) {
      ans = ans + extension;
    }
    return ans;
  }

  // Google Maps
  public mapTypeControlOptions : MapTypeControlOptions = {
    mapTypeIds: [MapTypeId.ROADMAP, MapTypeId.SATELLITE],
    position : ControlPosition.BOTTOM_RIGHT,
    style : MapTypeControlStyle.DROPDOWN_MENU
  };

  public fullscreenControlOptions :FullscreenControlOptions = {
    position: ControlPosition.BOTTOM_LEFT
  };

  markerClicked(marker: IMarker) {
    this.centerMapToMarker(marker);
    this.onMarkerClicked.emit(marker);
  }
  centerMapToMarker(marker: IMarker) {
    if (isNullOrUndefined(this.map)) return;
    const position = new google.maps.LatLng(marker.latitude, marker.longitude);
    // this.zoom = 18;
    this.map.panTo(position);
    // this.map.setCenter(position);
    this.map.setZoom(this.focusZoom);
  }

  mapReady(map, marker: IMarker) {
    this.map = map;
    this.ready = true;
    this.map.setMapTypeId(this.mapTypeId);
    this.centerMapToMarkerStop(marker);
  }

  public centerMapToMarkerStop(marker: IMarker) {
    const position = new google.maps.LatLng(marker.latitude, marker.longitude);
    this.map.panTo(position);
    this.map.setZoom(this.focusZoom);
  }

  public configure(mapOptions: IMapOptions) {
    this.deviceVisibleIds = mapOptions.visibleDeviceIds;
    this.zoom = mapOptions.zoom;
    this.lat = mapOptions.latitude;
    this.lng = mapOptions.longitude;
    if(!isNullOrUndefined(mapOptions.styles))
      this.styles = mapOptions.styles;
    if(!isNullOrUndefined(mapOptions.mapTypeId)) {
      this.mapTypeId = mapOptions.mapTypeId;
      if(!isNullOrUndefined(this.map))
        this.map.setMapTypeId(this.mapTypeId);
    }
    this.focusZoom = mapOptions.focusZoom;
  }

  public showBarGraph() {
    this.prepareBarGraphData();
    this.cdr.detectChanges();
    this.report = 'horizontal-bar';
    this.state = 'new';
  }

  prepareGraphData(): void {}
  prepareBarGraphData(): void {
    this.graphData = [];
    let rows = this.separateDataDevice;
    rows.forEach(values => {
      let auxRow = values.stops;
      let duration = 0;
      auxRow.forEach( row => {
        let auxDate = row.durationMins / 60; // hrs
        duration = duration + auxDate;
      });

      this.graphData.push({
        name: this.loadDeviceName(values.deviceId),
        series: [{name:this.loadDeviceName(values.deviceId), value:duration}]
      });
    });
  }

}
