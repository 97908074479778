import { Injectable } from '@angular/core';
import {SocketService} from "@app/core/service/socket.service";
import {Subject} from "rxjs";
import {IDataDPE} from "@app/core/interfaces/idata-dpe";
import {environment} from "@env/environment";
import {map} from 'rxjs/operators';
const URL = environment.wsUrl;

@Injectable({
  providedIn: 'root'
})
export class AsyncDPEService {

  public data: Subject<IDataDPE>;
  public wsService: SocketService;

  constructor(wsService: SocketService) {
    this.wsService = wsService;
    this.data = <Subject<IDataDPE>>this.wsService
      .connect(URL).pipe(
        map((response: MessageEvent): IDataDPE => {
          const data = JSON.parse(response.data);
          // console.log(response.data);
          return {
            devices: data.devices,
            positions: data.positions,
            events: data.events
          }
        })
      );
  }

  public reconnect(){
    this.data = <Subject<IDataDPE>>this.wsService
      .connect(URL).pipe(
        map((response: MessageEvent): IDataDPE => {
          const data = JSON.parse(response.data);
          return {
            devices: data.devices,
            positions: data.positions,
            events: data.events
          }
        })
      );
  }

}
