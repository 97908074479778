import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'counterLimit'
})
export class CounterLimitPipe implements PipeTransform {

  transform(value: number, maxCountValue?: number): string {
    if(isNullOrUndefined(maxCountValue))
      maxCountValue = 99;
    if(value <=0)
      return '';
    return value > maxCountValue ? `+${maxCountValue}` : `${value}`;
  }

}
