import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";
import {Group} from "@app/core/models/group.model";

@Pipe({
  name: 'sortGroupByName'
})
export class SortGroupByNamePipe implements PipeTransform {

  transform(values: Array<Group>, order?: string): Array<Group> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (a.name > b.name ? -1 : 1));
    }else{
      return values.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }

}
