import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {User} from "@app/core/models/user.model";
import {Observable} from "rxjs";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Users
   *
   * @param userId Can only be used by admin or manager users
   *
   **/
  public getUser(userId?: string): Observable<Array<User>> {
    let queryParameters = new HttpParams();
    if (userId !== undefined) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<User>>(`${this.basePath}${this.REST_URLS.users}`, {
      params: queryParameters,
      headers: headers,
      withCredentials: this.withCredentials
    }).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Delete a User
   *
   * @param userId
   **/
  public deleteUser(userId: string): Observable<any> {
    if (isNullOrUndefined(userId)) {
      throw new Error('El parametro requerido es nulo o indefinido.');
    } else {
      let headers = this.addBasicAuth();
      // headers.set("Accept", "application/json");
      return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.users}/${encodeURIComponent(userId)}`, {
        headers: headers,
        withCredentials: this.withCredentials
      }).pipe(
        catchError(this.handleError('delete', null))
      );
    }
  }

  /**
   * Update a User
   *
   * @param id - from the user to be updated
   * @param body - User new data
   *
   **/
  public updateUser(userId: string, body: User): Observable<User> {
    if (isNullOrUndefined(userId) || isNullOrUndefined(body)) {
      throw new Error('Al menos uno de los parametros requeridos es nulo o indefinido.');
    } else {
      // this.logger.info('UPDATE USER REQUEST: ',JSON.stringify(body));
      let headers = this.addBasicAuth();
      // headers.set("Accept", "application/json; charset=utf-8");
      // this.logger.warn(`USER: ${JSON.stringify(body)}`);
      return this.httpClient.put<User>(`${this.basePath}${this.REST_URLS.users}/${encodeURIComponent(userId)}`,
        body,
        {
          headers: headers,
          withCredentials: this.withCredentials
        }
      );
    }
  }

  /**
   * Create a User
   *
   * @param body: User to be created.
   * */
  public createUser(body: User): Observable<User> {
    if (isNullOrUndefined(body)) {
      throw new Error('El parametro requerido es nulo o indefinido.');
    } else {
      let headers = this.addBasicAuth();
      headers.set("Accept", "application/json");
      return this.httpClient.post<User>(`${this.basePath}${this.REST_URLS.users}`,
        body,
        {
          headers: headers,
          withCredentials: this.withCredentials
        }
      ).pipe(
        catchError(this.handleError('post', null))
      );
    }
  }

  /**
   * Reset Token
   * @param body: Email of the user to be reset.
   * */
  public resetToken(userEmail:string): Observable<any>{
    let queryParameters = new HttpParams();
    if (userEmail !== undefined) {
      queryParameters = queryParameters.set('userEmail', <any>userEmail);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<User>>(`${this.basePath}${this.REST_URLS.resetToken}`, {
      params: queryParameters,
      headers: headers,
      withCredentials: this.withCredentials
    }).pipe(
      catchError(this.handleError('get', null))
    );
  }
}
