import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {ItemData} from "@app/shared/interfaces/item-data.interface";
import {isNullOrUndefined} from "util";
import {Color, LabelType} from "@app/shared/interfaces/SharedTypes";


export declare type SortType = 'asc' | 'desc' | 'none';

@Injectable()
export class MenuItemService {
  //the subscriber
  private subject = new Subject<Array<ItemData>>();
  private items: Array<ItemData>;

  private sortBy: SortType = 'none';

  constructor() {
    this.items = [];
  }

  public addItem(menuItem: ItemData) {
    this.items.push(menuItem);
    this.sortItems();
    this.subject.next(this.items);
  }

  private sortItems() {
    switch (this.sortBy) {
      case "asc":
        this.items = this.items.sort((a, b) => {
          return a.order - b.order;
        });
        break;
      case "desc":
        this.items = this.items.sort((a, b) => {
          return b.order - a.order;
        });
        break;
    }
  }

  public changeSort(sort: SortType) {
    this.sortBy = sort;
    this.sortItems();
    this.subject.next(this.items);
  }

  public addItems(items: Array<ItemData>) {
    this.items = [];
    items.forEach(value => {
      this.items.push(value);
    });
    this.sortItems();
    this.subject.next(this.items);
    // console.log("Lo lleno y notificacmos a todos");
  }

  public addLabel(label: string, labelType: LabelType, labelColor: Color, id: string | number) {
    if (isNullOrUndefined(this.items)) {
      return;
    }
    let index = this.items.findIndex(value => value.id == id);
    if (this.items.length > 0 && (index > -1)) {
      let item = this.items[index];
      this.items[index] = {
        id: item.id,
        title: item.title,
        order: item.order,
        iconClass: item.iconClass,
        label: label,
        labelType: labelType,
        labelColor: labelColor
      };
    }
    this.subject.next(this.items);
  }

  getItem(): Observable<Array<ItemData>> {
    return this.subject.asObservable();
  }

  setOrder(order: number, id: number) {
    let index = this.items.findIndex(value => value.id == id);
    if (index > -1) {
      this.items[index].order = order;
      this.sortItems();
      this.subject.next(this.items);
    }
  }

  getItems(): Array<ItemData> {
    return this.items;
  }

}
