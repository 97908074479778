import { Pipe, PipeTransform } from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {Event} from "@app/core/models/event.model";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {Device} from "@app/core/models/device.model";

@Pipe({
  name: 'filterEventBySearchParam'
})
export class FilterEventBySearchParamPipe implements PipeTransform {

  private pipe: NotificationTypePipe = new NotificationTypePipe();//Pipe for converting NotificationTypes

  transform(values: Array<Event>, search: Array<ISearchParam>): Array<Event> {
    if (!values) return [];
    if (!search || search.length == 0) return values;
    let filtered: Array<Event> = values;
    search.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote) {
        if (param.field == 'type') {
          if (param.type == 'select')
            filtered = this.filterByType(filtered, param.value);
          else
            filtered = this.filterByTypeText(filtered, param.value);
        }
        if (param.field == 'deviceId') {
          // console.log(`FILTAR POR DISPOSITIVO: ${param.value}`);
          filtered = this.filterByDeviceId(filtered, param.value);
        }
        if (param.field == 'geofenceId') {
          // console.log(`FILTAR POR GEOCERCA: ${JSON.stringify(param.value)}`);
          filtered = this.filterByGeofenceId(filtered, param.value);
        }
        if (param.field == 'maintenanceId') {
          // console.log(`FILTAR POR MANTENIMIENTO: ${param.value}`);
          filtered = this.filterByMaintenanceId(filtered, param.value);
        }
      }
    });
    return filtered;
  }

  private filterByType(values: Array<Event>, type: string): Array<Event>  {
    if (!values) return [];
    return values.filter(notification => {
      return notification.type.toLocaleLowerCase().includes(type.toLowerCase());
    });
  }

  private filterByTypeText(values: Array<Event>, type: string): Array<Event>  {
    if (!values) return [];
    return values.filter(notification => {
      // console.log(this.pipe.transform(notification.type).toLowerCase().includes(type.toLowerCase()));
      return this.pipe.transform(notification.type).toLowerCase().includes(type.toLowerCase());
    });
  }

  private filterByDeviceId(values: Array<Event>, deviceId: string) : Array<Event> {
    if (!values) return [];
    // if (isNullOrUndefined(deviceId)) return values;
    return values.filter(event => {
      if (!isNullOrUndefined(deviceId))
        return event.deviceId == Number(deviceId);
      else
        return false;
    });
  }

  private filterByGeofenceId(values: Array<Event>, geofenceId: string): Array<Event>  {
    if (!values) return [];
    if (isNullOrUndefined(geofenceId)) return values;
    return values.filter(event => {
      return event.geofenceId == Number(geofenceId);
    });
  }

  private filterByMaintenanceId(values: Array<Event>, id: string): Array<Event>  {
    if (!values) return [];
    if (isNullOrUndefined(id)) return values;
    return values.filter(event => {
      return event.maintenanceId == Number(id);
    });
  }
}
