import { Injectable } from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Observable} from "rxjs";
import {KnownAttribute} from "@app/core/models/known-attribute.model";
import {catchError} from "rxjs/operators";
import {Dictionary} from "typescript-collections";

@Injectable({
  providedIn: 'root'
})
export class SharedService extends BaseService {

  private maintenanceKnownAttributes: Array<KnownAttribute> = [];
  private attributesKnownTypes: Array<any> = [];

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
    this.createKnownAttributes();
    this.createKnownAttributesForMaintendance();
    this.createKnownTypesForAttributes();
  }

  private _mapKeys: Dictionary<string, string>;

  public createKnownAttributes(){
    this._mapKeys = new Dictionary<string, string>();
    this._mapKeys.setValue('distance', 'Distancia');
    this._mapKeys.setValue('totalDistance', 'Distancia Recorrida');
    this._mapKeys.setValue('motion', 'En Movimiento');
    this._mapKeys.setValue('alarm', 'Alarma');
    this._mapKeys.setValue('type', 'Tipo');
    this._mapKeys.setValue('powerRestored', 'Energia Restablecida');
    this._mapKeys.setValue('powerOn', 'Disp. Encendido');
    this._mapKeys.setValue('powerOff', 'Disp. Apagado');
    this._mapKeys.setValue('acceleration', 'Aceleracion');
    this._mapKeys.setValue('odometer', 'Odómetro');
    this._mapKeys.setValue("battery", "Batería");
    this._mapKeys.setValue("batteryLevel", "Nivel de Batería");
    this._mapKeys.setValue("raw", "Bruto");
    this._mapKeys.setValue("index", "Indice");
    this._mapKeys.setValue("hdop", "HDOP");
    this._mapKeys.setValue("vdop", "VDOP");
    this._mapKeys.setValue("pdop", "PDOP");
    this._mapKeys.setValue("sat", "Satelites");
    this._mapKeys.setValue("satVisible", "Satelites Visibles");
    this._mapKeys.setValue("rssi", "RSSI");
    this._mapKeys.setValue("gps", "GPS");
    this._mapKeys.setValue("roaming", "Roaming");
    this._mapKeys.setValue("event", "Evento");
    this._mapKeys.setValue("status", "Estado");
    this._mapKeys.setValue("serviceOdometer", "Odómetro de Mantenimiento");
    this._mapKeys.setValue("tripOdometer", "Odómetro de Viaje");
    this._mapKeys.setValue("hours", "Horas");
    this._mapKeys.setValue("steps", "Pasos");
    this._mapKeys.setValue("input", "Input");
    this._mapKeys.setValue("output", "Output");
    this._mapKeys.setValue("power", "Energía");
    this._mapKeys.setValue("battery", "Batería");
    this._mapKeys.setValue("batteryLevel", "Nivel de Batería");
    this._mapKeys.setValue("fuel", "Combustible");
    this._mapKeys.setValue("fuelConsumption", "Consumo de Combustible");
    this._mapKeys.setValue("versionFw", "Versión de Firmware");
    this._mapKeys.setValue("versionHw", "Versión de Hardware");
    this._mapKeys.setValue("ignition", "Encendido de motor");
    this._mapKeys.setValue("flags", "Banderas");
    this._mapKeys.setValue("charge", "Carga");
    this._mapKeys.setValue("ip", "IP");
    this._mapKeys.setValue("archive", "Archivo");
    this._mapKeys.setValue("rpm", "Rpm");
    this._mapKeys.setValue("vin", "VIN");
    this._mapKeys.setValue("approximate", "Aproximado");
    this._mapKeys.setValue("throttle", "Acelerador");
    this._mapKeys.setValue("armed", "Armado");
    this._mapKeys.setValue("geofence", "Geocerca");
    this._mapKeys.setValue("deviceTemp", "Temperatura del Dispositivo");
    this._mapKeys.setValue("operator", "Operador");
    this._mapKeys.setValue("command", "Comando");
    this._mapKeys.setValue("blocked", "Bloqueado");
    this._mapKeys.setValue("dtcs", "Códigos de Problemas de Diagnóstico");
    this._mapKeys.setValue("obdSpeed", "Velocimetro (Diagnóstico a Bordo)");
    this._mapKeys.setValue("result", "Resultado del Comando");
    this._mapKeys.setValue("driverUniqueId", "Licencia del conductor");
    this._mapKeys.setValue("date", "Fecha");
    this._mapKeys.setValue("movement", "En Movimiento");
    this._mapKeys.setValue("movement", "En Movimiento");
    this._mapKeys.setValue("powerCut", "Se corto el suministro de energía");
    this._mapKeys.setValue("Command RTO accepted", "Comando RTO aceptado");
  }

  public getKnownAttributes(): Array<KnownAttribute>{
    let array: Array<KnownAttribute> = [];
    this._mapKeys.forEach((key, value) => {
      array.push({type : key, name: value});
    });
    return array;
  }

  public getMaintenanceKnownAttributes(): Array<KnownAttribute>{
    return this.maintenanceKnownAttributes;
  }

  public getAttributesKnownTypes(): Array<any>{
    return this.attributesKnownTypes;
  }

  /**
   * Get Maintenance Types
   *
   */
  public getMaintenanceTypes():Observable<Array<KnownAttribute>>{
    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<KnownAttribute>>(`${this.basePath}${this.REST_URLS.maintenanceTypes}`,
      {
        withCredentials: this.withCredentials,
        headers: headers,
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  private createKnownAttributesForMaintendance() {
    this.maintenanceKnownAttributes = [];
    this.maintenanceKnownAttributes.push({type: 'fuelConsumption',name: 'Consumo de Combustible'});
    // this.maintenanceKnownAttributes.push({type: 'distance',name: 'Distancia'});
    this.maintenanceKnownAttributes.push({type: 'totalDistance',name: 'Distancia Recorrida'});
    this.maintenanceKnownAttributes.push({type: 'date',name: 'Fecha'});
    // this.maintenanceKnownAttributes.push({type: '',name: ''});
  }

  private createKnownTypesForAttributes() {
    this.attributesKnownTypes = [];
    this.attributesKnownTypes.push({type: 'String',name: 'Cadena'});
    this.attributesKnownTypes.push({type: 'Boolean',name: 'Boolean'});
    this.attributesKnownTypes.push({type: 'Number',name: 'Numero'});
    // this.maintenanceKnownAttributes.push({type: '',name: ''});
  }
}
