import { Pipe, PipeTransform } from '@angular/core';
import {Notification} from "@app/core/models/notification.model";

@Pipe({
  name: 'filterNotificationTypeByNotifications'
})
export class FilterNotificationTypeByNotificationsPipe implements PipeTransform {

  transform(values: any, args?: Array<Notification>): any {
    if(!args || args.length == 0) return values;
    return values.filter(type => {
      return !(args.findIndex(notification => notification.type == type.name) !== -1);
    });
  }

}
