import { Pipe, PipeTransform } from '@angular/core';
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {IAttribute} from "@app/core/interfaces/iattribute.interface";

@Pipe({
  name: 'filterAttribsBySearchParam'
})
export class FilterAttribsBySearchParamPipe implements PipeTransform {

  transform(values: Array<IAttribute>, params?: Array<ISearchParam>): Array<IAttribute> {
    if(!values) return [];
    if(!params || params.length == 0) return values;
    let filtered = values;
    params.forEach(param =>{
      if(param.field == 'key'){
        filtered = this.filterByKey(filtered,param.value);
      }else{
        filtered = this.filterByValue(filtered,param.value);
      }
    });
    return filtered;
  }

  private filterByKey(filtered: any, value: string) {
    if(!filtered) return [];
    return filtered.filter(current => {
      return current.key.toLowerCase().includes(value.toLowerCase());
    });
  }

  private filterByValue(filtered: any, value: string) {
    if(!filtered) return [];
    return filtered.filter(current => {
      return current.value.toString().toLowerCase().includes(value.toLowerCase());
    });
  }

}
