import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "@swimlane/ngx-datatable/release/utils";

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {

  transform(value: string, args?: string): string {
    if(!isNullOrUndefined(value)){
      const days = Math.floor(Number(value) / 24);
      const hours = Number(value) % 24;
      if(days >= 1){
        if(hours > 0)
          return `${days.toFixed(0)} día(s) y ${hours.toFixed(0)} hrs.`;
        else
          return `${days.toFixed(0)} día(s).`;
      }else{
        return `${hours.toFixed(0)} hrs.`;
      }
    }else{
      return value;
    }
  }

}
