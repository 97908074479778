import {Component, Input, OnInit} from '@angular/core';
import {IMarker} from "@app/core/interfaces/imarker.interface";

@Component({
  selector: 'app-buho-marker',
  templateUrl: './buho-marker.component.html',
  styleUrls: ['./buho-marker.component.css']
})
export class BuhoMarkerComponent implements OnInit {

  public markerValue: IMarker;

  @Input()
  get marker(): IMarker {
    return this.markerValue;
  }

  set marker(value: IMarker) {
    this.markerValue = value;
  }

  constructor() {
  }

  ngOnInit() {
    //this.logger.info(`Marcador iniciado [${this.marker.label}]`);
  }

}
