import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Observable} from "rxjs";
import {Event} from "@app/core/models/event.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {catchError} from "rxjs/operators";
import {ReportStops} from "@app/core/models/report-stops.model";
import {ReportTrips} from "@app/core/models/report-trips.model";
import {Position} from "@app/core/models/position.model";
import {ReportSummary} from "@app/core/models/report-summary.model";

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Events within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   * @param type % can be used to return events of all types
   */
  public reportsEventsGet(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>,
                          type?: Array<string>): Observable<Array<Event>> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsEventsGet.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsEventsGet.');
    }
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      })
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      })
    }
    if (type) {
      type.forEach((element) => {
        queryParameters = queryParameters.append('type', <any>element);
      })
    }
    if (!isNullOrUndefined(from)) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (!isNullOrUndefined(to)) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Event>>(`${this.basePath}${this.REST_URLS.eventsReport}`,
      {
        params: queryParameters,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Fetch a list of Events within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   * @param type % can be used to return events of all types
   * @param mail: if you put an email address. this report will be send it.
   */
  public reportsEventsGetXLSX(from: Date, to: Date, deviceId?: Array<number>,
                              groupId?: Array<number>, type?: Array<string>, mail?: boolean): Observable<any> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsEventsGetXLSX.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsEventsGetXLSX.');
    }
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      })
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      })
    }
    if (type) {
      type.forEach((element) => {
        queryParameters = queryParameters.append('type', <any>element);
      })
    }
    if (mail) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (!isNullOrUndefined(from)) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (!isNullOrUndefined(to)) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': sessionStorage.getItem('auth')
    });
    // let headers = this.defaultHeaders;
    // headers.set("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    return this.httpClient.get(`${this.basePath}${this.REST_URLS.eventsReport}`,
      {
        params: queryParameters,
        headers: headers,
        withCredentials: this.withCredentials,
        responseType: 'blob'
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Fetch a list of Positions within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   * @param mail
   */
  public reportsRouteGet(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>): Observable<Array<Position>> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsRouteGet.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsRouteGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      })
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      })
    }
    if (!isNullOrUndefined(from)) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (!isNullOrUndefined(to)) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Position>>(`${this.basePath}${this.REST_URLS.routesReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Fetch a list of Positions within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z;
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z;
   * @param deviceId
   * @param groupId
   * @param mail
   */
  public reportsRouteGetXLSX(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>, mail?: boolean): Observable<any> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsRouteGetXLSX.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsRouteGetXLSX.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      })
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      })
    }
    if (mail) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (!isNullOrUndefined(from)) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (!isNullOrUndefined(to)) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    // let headers = this.addBasicAuth();
    // headers.set("Accept", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");

    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': sessionStorage.getItem('auth')
    });

    return this.httpClient.get(`${this.basePath}${this.REST_URLS.routesReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        responseType: 'blob',
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Fetch a list of ReportStops within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   */
  public reportsStopsGet(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>): Observable<Array<ReportStops>> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsStopsGet.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsStopsGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      })
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      })
    }
    if (from !== undefined) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers = headers.set("Accept", 'application/json');

    return this.httpClient.get<Array<ReportStops>>(`${this.basePath}${this.REST_URLS.stopsReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Fetch a list of ReportStops within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   * @param mail: sends the email to the configurated mail in the user's device.
   */
  public reportsStopsGetXLSX(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>, mail?: boolean): Observable<any> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsStopsGetXLSX.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsStopsGetXLSX.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      });
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      });
    }
    if (mail) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (from !== undefined) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    // let headers = this.addBasicAuth();
    // headers = headers.set("Accept", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': sessionStorage.getItem('auth')
    });

    return this.httpClient.get(`${this.basePath}${this.REST_URLS.stopsReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        responseType: 'blob',
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Fetch a list of ReportTrips within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   */
  public reportsTripsGet(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>): Observable<Array<ReportTrips>> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsTripsGet.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsTripsGet.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      });
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      });
    }
    if (from !== undefined) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers = headers.set("Accept", 'application/json');
    return this.httpClient.get<Array<ReportTrips>>(`${this.basePath}${this.REST_URLS.tripsReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        headers: headers
      }
    );
  }

  /**
   * Fetch a list of ReportTrips within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z
   * @param deviceId
   * @param groupId
   * @param email : sent an email to the current user.
   */
  public reportsTripsGetXLSX(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>, mail?: boolean): Observable<any> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsTripsGetXLSX.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsTripsGetXLSX.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      });
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      });
    }
    if (mail) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (from !== undefined) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    // let headers = this.addBasicAuth();
    // headers = headers.set("Accept", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': sessionStorage.getItem('auth')
    });

    return this.httpClient.get(`${this.basePath}${this.REST_URLS.tripsReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        responseType: 'blob',
        headers: headers
      }
    );
  }

  /**
   * Fetch a list of ReportSummary within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z&#x60;
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z&#x60;
   * @param deviceId
   * @param groupId
   */
  public reportsSummaryGet(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>): Observable<Array<ReportSummary>> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsSummaryGet.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsSummaryGet.');
    }
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      });
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      })
    }
    if (from !== undefined) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    let headers = this.addBasicAuth();
    headers = headers.set("Accept", 'application/json');
    return this.httpClient.get<Array<ReportSummary>>(`${this.basePath}${this.REST_URLS.summaryReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        headers: headers,
      }
    );
  }

  /**
   * Fetch a list of ReportSummary within the time period for the Devices or Groups
   * At least one _deviceId_ or one _groupId_ must be passed
   * @param from in IS0 8601 format. eg. 1963-11-22T18:30:00Z&#x60;
   * @param to in IS0 8601 format. eg. 1963-11-22T18:30:00Z&#x60;
   * @param deviceId
   * @param groupId
   * @param email : sent an email to the current user.
   */
  public reportsSummaryGetXLSX(from: Date, to: Date, deviceId?: Array<number>, groupId?: Array<number>, mail?: boolean): Observable<any> {
    if (isNullOrUndefined(from)) {
      throw new Error('Required parameter from was null or undefined when calling reportsSummaryGetXLSX.');
    }
    if (isNullOrUndefined(to)) {
      throw new Error('Required parameter to was null or undefined when calling reportsSummaryGetXLSX.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (deviceId) {
      deviceId.forEach((element) => {
        queryParameters = queryParameters.append('deviceId', <any>element);
      });
    }
    if (groupId) {
      groupId.forEach((element) => {
        queryParameters = queryParameters.append('groupId', <any>element);
      });
    }
    if (mail) {
      queryParameters = queryParameters.set('mail', <any>mail);
    }
    if (from !== undefined) {
      queryParameters = queryParameters.set('from', <any>from.toISOString());
    }
    if (to !== undefined) {
      queryParameters = queryParameters.set('to', <any>to.toISOString());
    }

    // let headers = this.addBasicAuth();
    // headers = headers.set("Accept", 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    let headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'If-Modified-Since': '0',
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Authorization': sessionStorage.getItem('auth')
    });
    return this.httpClient.get(`${this.basePath}${this.REST_URLS.summaryReport}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        responseType: 'blob',
        headers: headers,
      }
    );
  }
}
