import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {IconDataPicker} from "@app/shared/interfaces/icon-data-picker.interface";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IconDropdownPickerComponent} from "@app/shared/components/icon-dropdown-picker/icon-dropdown-picker.component";
import {Group} from "@app/core/models/group.model";
import {ApiService} from "@app/shared/services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {p} from "@angular/core/src/render3";

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./device-edit.component.css']
})
export class DeviceEditComponent implements OnInit, AfterViewInit {

  currentValue: Device | any;

  @ViewChild(IconDropdownPickerComponent)
  public appIconDropdownPickerComponent: IconDropdownPickerComponent;

  public selectedGroup: Group;
  public selectedIcon: IconDataPicker;

  @Input()
  public defaultGroups: Array<Group> = [];

  public isIntegration: boolean = false;

  public deviceForm: FormGroup;

  get deviceFields() {
    return this.deviceForm.controls;
  }


  @Input()
  set current(device: Device | any) {
    this.currentValue = device;//Object.assign({}, device) as Device;
    this.checkIntegration();

    if (!isNullOrUndefined(device)) {
      this.fillForm(device);
      this.selectedGroup = this.defaultGroups.find(value => value.id == device.groupId);
      if (!isNullOrUndefined(device.icon) && !isNullOrUndefined(this.appIconDropdownPickerComponent)) {
        this.appIconDropdownPickerComponent.setIcon(device.icon);
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private readonly cdr: ChangeDetectorRef) {

    this.deviceForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      uniqueId: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(15)]],
      disabled: [''],
      groupId: [''],
      phone: ['', [Validators.pattern("\\d+"),Validators.maxLength(13)]],
      model: [''],
      contact: [''],
      category: [''],
      icon: [''],
      capacityWeight: ['', Validators.pattern("\\d+(\\.\\d{1,2})?")],
      capacityVolume: ['', Validators.pattern("\\d+(\\.\\d{1,2})?")],
      maxSpeed: ['', Validators.pattern("\\d+(\\.\\d{1,2})?")],
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public changeIcon(icon: IconDataPicker) {
    this.currentValue.icon = icon.icon;
  }

  public selectThisGroup(group: Group): void {
    if (!isNullOrUndefined(group)) {
      this.currentValue.groupId = group.id;
    }
    else {
      this.currentValue.groupId = null;
    }
  }

  public clearSelectedGroup(): void {
    this.selectedGroup = null;
    this.currentValue.groupId = null;
  }

  private fillForm(device: Device): void {
    this.deviceFields.name.setValue(device.name);
    this.deviceFields.uniqueId.setValue(device.uniqueId);
    this.deviceFields.groupId.setValue(device.groupId);
    this.deviceFields.model.setValue(device.model);
    this.deviceFields.phone.setValue(device.phone);
    this.deviceFields.contact.setValue(device.contact);
    this.deviceFields.category.setValue(device.category);
    this.deviceFields.capacityWeight.setValue(device.capacityWeight);
    this.deviceFields.capacityVolume.setValue(device.capacityVolume);
    this.deviceFields.disabled.setValue(!device.disabled);
    this.deviceFields.maxSpeed.setValue(this.getMaxSpeed(device).toFixed(2));
  }

  public getDeviceInfo(): Device {
    this.currentValue.name = this.deviceFields.name.value;
    this.currentValue.uniqueId = this.deviceFields.uniqueId.value;
    this.currentValue.model = this.deviceFields.model.value;
    // this.currentValue.icon = this.selectedIcon.icon;
    this.currentValue.phone = this.deviceFields.phone.value;
    this.currentValue.capacityWeight = this.deviceFields.capacityWeight.value;
    this.currentValue.capacityVolume = this.deviceFields.capacityVolume.value;
    this.currentValue.category = this.deviceFields.category.value;
    this.currentValue.contact = this.deviceFields.contact.value;
    this.currentValue.disabled = !this.deviceFields.disabled.value;
    // console.log("SE PIDE 1 vez");
    if(!isNullOrUndefined(this.currentValue.attributes)) {
      this.currentValue.attributes['speedLimit'] = this.toKnots(Number(this.deviceFields.maxSpeed.value)).toFixed(2);
    }
    return this.currentValue;
  }

  public clear() {
    this.deviceForm.reset();
    this.clearSelectedGroup();
  }

  private checkIntegration() : void {
    this.isIntegration = !!(!isNullOrUndefined(this.currentValue.attributes) && !isNullOrUndefined(this.currentValue.attributes['origin']) && this.currentValue.attributes['origin']);
  }

  private toKmh(knots: number) : number {
    return knots*1.852;
  }

  private toKnots(kmH: number) : number {
    if(!isNullOrUndefined(kmH))
      return kmH/1.852;
    else
      return 0;
  }

  private getMaxSpeed(device: Device) : number {
    if(!isNullOrUndefined(device) && !isNullOrUndefined(device.attributes) && !isNullOrUndefined(device.attributes['speedLimit'])){
      const speed = Number(device.attributes['speedLimit']);
      if (!isNullOrUndefined(speed))
        return this.toKmh(speed);
      else
        return 0;
    }else{
      return 0;
    }
  }
}
