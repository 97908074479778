import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ItemData} from "@app/shared/interfaces/item-data.interface";
import {isNullOrUndefined} from "util";
import {Subscription} from "rxjs";
import {MenuItemService} from "@app/shared/services/menu-item.service";

@Component({
  selector: 'app-full-page-item-list',
  templateUrl: './full-page-item-list.component.html',
  styleUrls: ['./full-page-item-list.component.css']
})
export class FullPageItemListComponent implements OnInit, OnDestroy {

  // @Input()
  public items: Array<ItemData>;

  @Input()
  public columnCount: number;

  @Input()
  public margins: boolean;

  @Output()
  public onItemClick: EventEmitter<ItemData> = new EventEmitter();

  private subscription: Subscription;

  constructor(private menuItemService: MenuItemService) {
    if (isNullOrUndefined(this.columnCount)) {
      this.columnCount = 5;
    }
  }

  getColumnCSSClass(): string {
    if (this.margins) {
      switch (this.columnCount) {
        case 1:
          return 'two';
        case 2:
          return 'three';
        case 3:
          return 'four';
        case 4:
          return 'five';
        case 5:
          return 'six';
        case 6:
          return 'seven';
        case 7:
          return 'eight';
        case 8:
          return 'nine';
        case 9:
          return 'ten';
        case 10:
          return 'eleven';
        case 11:
          return 'twelve';
        case 12:
          return 'thirteen';
        case 13:
          return 'fourteen';
        case 14:
          return 'fifteen';
        default:
          return 'five';
      }
    } else {
      switch (this.columnCount) {
        case 1:
          return 'one';
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        case 5:
          return 'five';
        case 6:
          return 'six';
        case 7:
          return 'seven';
        case 8:
          return 'eight';
        case 9:
          return 'nine';
        case 10:
          return 'ten';
        case 11:
          return 'eleven';
        case 12:
          return 'twelve';
        case 13:
          return 'thirteen';
        case 14:
          return 'fourteen';
        case 15:
          return 'fifteen';
        case 16:
          return 'sixteen';
        default:
          return 'six';
      }
    }
  }

  ngOnInit() {
    this.subscription = this.menuItemService.getItem().subscribe(value => {
      this.items = value;
    });
    if (isNullOrUndefined(this.items)) {
      this.items = this.menuItemService.getItems();
    }
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // public itemsSorted(): Array<ItemData>{
  //   if(isNullOrUndefined(this.items))
  //     return [];
  //   else{
  //     switch (this.sort) {
  //       case "asc":
  //         return this.items.sort((a, b) => {
  //           return a.order - b.order;
  //         });
  //       case "desc":
  //         return this.items.sort((a, b) => {
  //           return b.order - a.order;
  //         });
  //       default:
  //         return this.items;
  //     }
  //   }
  // }

  // public addLabel(label:string, labelType: LabelType, labelColor: Color, id?:string | number, menuItem?: ItemData ){
  //   if(isNullOrUndefined(this.items)){
  //     console.log("NO HAY NADA EN ITEMS PARECE");
  //     return;
  //   }
  //   let index = 0;
  //   if(isNullOrUndefined(menuItem))
  //     index = this.items.indexOf(menuItem);
  //   if(isNullOrUndefined(id))
  //     index = this.items.findIndex(value => value.id == id);
  //   if(this.items.length > 0 && (!isNullOrUndefined(this.items[index]))){
  //     let item = this.items[index];
  //     this.items[index] = {
  //       id: item.id,
  //       title: item.title,
  //       order: item.order,
  //       iconClass: item.iconClass,
  //       label: label,
  //       labelType: labelType,
  //       labelColor: labelColor
  //     };
  //   }else{
  //     console.log("PARECE QUE NO LO PILLA");
  //   }
  // }
}
