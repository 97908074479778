import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {isNullOrUndefined} from "util";
import {IconDataPicker} from "@app/shared/interfaces/icon-data-picker.interface";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {FilterDeviceBySearchParamPipe} from "@app/core/pipes/filter-device-by-search-param.pipe";
import {ModelPageState} from "@app/core/types/buho-core-types";


@Component({
  selector: 'app-device-page-dimmer-selector',
  templateUrl: './device-page-dimmer-selector.component.html',
  styleUrls: ['./device-page-dimmer-selector.component.css']
})
export class DevicePageDimmerSelectorComponent implements OnInit {

  @Input()
  public isClickable: boolean;
  @Input()
  public isDimmed: boolean;

  @Input()
  public devices: Array<Device>;

  public state: ModelPageState = 'search';

  public visibleDeviceIds: Array<number>;

  @Input()
  public selectedItemIconURL: string; //the default selected item
  @Input()
  public default404ImageURL: string; //the default selected item

  @Output()
  public onClose = new EventEmitter<Array<number>>();

  @Output()
  public onSelect = new EventEmitter<number>();

  @Output()
  public onUpdateDevice = new EventEmitter<Device>();

  @Output()
  public onNewDevice = new EventEmitter<Device>();

  @Output()
  public onDeleteDevice = new EventEmitter<any>();

  public isSearchPanelCollapsed: boolean = false;

  /* ADVANCE SEARCH BOX */
  selectedFilter: Array<ISearchParam> = [];
  searchInputText: string = '';

  filterOptions: Array<ISearchParam> = [
    {
      name: 'Nombre',
      field: 'name',
      color: 'red',
      value: ''
    }, {
      name: 'Tracker ID',
      field: 'uniqueId',
      color: 'green',
      value: ''
    }, {
      name: 'Estado',
      field: 'status',
      color: 'yellow',
      value: ''
    }, {
      name: 'Telefono',
      field: 'phone',
      color: 'orange',
      value: ''
    }, {
      name: 'Modelo',
      field: 'model',
      color: 'pink',
      value: ''
    }, {
      name: 'Contacto',
      field: 'contact',
      color: 'blue',
      value: ''
    }, {
      name: 'Categoria',
      field: 'category',
      color: 'purple',
      value: ''
    }
  ];

  selectedSearchParam: ISearchParam = this.filterOptions[0];
  // filter: FilterDeviceBySearchParamPipe = new FilterDeviceBySearchParamPipe();

  /* EDITS */
  @ViewChild("searchInput") searchInput: ElementRef;

  /* FORM */
  registerForm: FormGroup;
  submitted = false;

  currentDevice: Device = new Device();
  @Input()
  defaultDevices: Array<Device>;

  /* Transitions */
  // public transitionController = new TransitionController();

  /* filter */
  public filter: FilterDeviceBySearchParamPipe;

  constructor(private configService: WebConfigurationService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private apiService: ApiService) {
    this.filter = new FilterDeviceBySearchParamPipe();
  }

  ngOnInit() {
    if (isNullOrUndefined(this.selectedItemIconURL)) {
      this.selectedItemIconURL = './assets/images/buho/svg/check-circle-o.svg';
      // this.selectedItemIconURL = './assets/images/buho/svg/selected.svg';
    }
    if (isNullOrUndefined(this.default404ImageURL)) {
      // this.default404ImageURL = './assets/images/map/svg/buho/blue.svg';
      this.default404ImageURL = './assets/images/avatar/svg/freepik/assistant.svg';
    }
    this.visibleDeviceIds = this.configService.configuration.mapOptions.visibleDeviceIds;
    this.configService.change.subscribe(val => {
      this.visibleDeviceIds = val.mapOptions.visibleDeviceIds;
    });
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      uniqueId: ['', Validators.required],
      phone: [''],
      model: [''],
      contact: [''],
      category: ['']
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  addNewDevice() {
    this.state = "new";
    this.currentDevice = new Device();
    this.registerForm.reset();
  }

  editDevice(device: Device) {
    this.state = "edit";
    this.currentDevice = device;
    this.f.name.setValue(this.currentDevice.name);
    this.f.uniqueId.setValue(this.currentDevice.uniqueId);
    this.f.phone.setValue(this.currentDevice.phone);
    this.f.model.setValue(this.currentDevice.model);
    this.f.category.setValue(this.currentDevice.category);
    this.f.contact.setValue(this.currentDevice.contact);
  }

  /**
   * to send new/edit
   * */
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    //set the current
    this.currentDevice.name = this.f.name.value;
    this.currentDevice.uniqueId = this.f.uniqueId.value;
    this.currentDevice.phone = this.f.phone.value;
    this.currentDevice.model = this.f.model.value;
    this.currentDevice.category = this.f.category.value;
    this.currentDevice.contact = this.f.contact.value;
    //
    if (this.state == 'edit') {
      this.apiService.deviceService.updateDevice(this.currentDevice.id, this.currentDevice).subscribe(value => {
        this.currentDevice = value;
        this.onUpdateDevice.emit(value);
        this.alertService.success('Guardado con exito!', 'Se ha actualizado el dispositivo.');
      }, error1 => {
        this.alertService.error('Error al actualizar', 'Se ha producido un error al actualizar.');
      });
    } else if (this.state == 'new') {
      this.apiService.deviceService.createDevice(this.currentDevice).subscribe(value => {
        this.currentDevice = value;
        this.onNewDevice.emit(value);
        this.alertService.success('Guardado con exito!', 'Se ha guardado el nuevo Dispositivo.');
      }, error1 => {
        this.alertService.error('Error al guardar', 'Se ha producido un error.');
      });
    }
    this.state = 'search';
    this.onClose.emit(this.visibleDeviceIds);
  }

  public deleteDevice(device: Device) {
    this.apiService.deviceService.deleteDevice(device.id).subscribe(value => {
      this.onDeleteDevice.emit(device);
      this.alertService.success('Eliminado con exito!', 'Se ha eliminado el Dispositivo.');
    })
  }

  public setAllVisible(visibility: boolean) {
    if (visibility) {
      this.devices.forEach(value => {
        let index: number = this.visibleDeviceIds.indexOf(value.id);
        if (index < 0) {
          this.visibleDeviceIds.push(value.id);
        }
      });
    } else {
      this.visibleDeviceIds.splice(0, this.visibleDeviceIds.length);
    }
  }

  public toogleVisible(deviceId: number) {
    let index = this.visibleDeviceIds.indexOf(deviceId);
    if (index >= 0) {//visible
      this.visibleDeviceIds.splice(index, 1);
    } else {
      this.visibleDeviceIds.push(deviceId);
    }
  }

  public isVisible(deviceId: number): boolean {
    return this.visibleDeviceIds.indexOf(deviceId) >= 0;
  }

  public selectOnMap(deviceId: number) {
    this.onSelect.emit(deviceId);
  }


  public closeDimmer() {

    if (this.state === 'search') {
      this.onClose.emit(this.visibleDeviceIds);
      this.state = "search";
    } else if (this.state === "new") {
      this.state = "search";
    } else if (this.state === "edit") {
      this.state = "search";
    } else if (this.state === "select") {
      this.onClose.emit(this.visibleDeviceIds);
      this.state = "search";
    } else {
      this.state = "search";
    }
    this.currentDevice = new Device();
  }

  public openAndEdit(deviceId: number) {
    this.state = "search";
    this.currentDevice = this.devices.find(value => value.id == deviceId);

  }

  // selectDevice(device: Device) {
  //   this.deviceFilter = device;
  // }
  hasIcon(currentDevice: Device) {
    return !(isNullOrUndefined(currentDevice.icon));
  }

  changeIcon(icon: IconDataPicker) {
    this.currentDevice.icon = icon.icon;
  }

  selectDevice(device: Device) {
    if (device != this.currentDevice) {
      this.currentDevice = device;
      this.state = "select";
    } else {
      this.state = "search";
      this.currentDevice = new Device();
    }
  }

  doSearch() {
    //| filterDeviceBySearchParam: selectedFilter
    // if(this.selectedFilter.length > 0){
    //   for
    //   let deviceFilter:Device = {
    //     name: this.selectedFilter[index].value
    //   };
    //   this.defaultDevices = this.filter.transform(this.devices, deviceFilter);
    // }
    // let filters:Device = new Device();
    // this.selectedFilter.forEach(value => {
    //       filters.addParam(value);
    // });
    // console.log(`FILTERS-> ${JSON.stringify(this.selectedFilter)}`);
    this.defaultDevices = this.filter.transform(this.devices, this.selectedFilter);
    // console.log(`devices-> ${JSON.stringify(this.defaultDevices)}`);
    // this.transitionController.animate(TransitionHelper.GetTransition("tada"));
  }


  addSearchParam() {
    if (this.searchInputText.length > 0) {
      // let newSearch = Object.assign({}, this.selectedSearchParam);
      let newSearch: ISearchParam = {
        name: this.selectedSearchParam.name,
        value: this.searchInputText,
        color: this.selectedSearchParam.color,
        field: this.selectedSearchParam.field,
        type: this.selectedSearchParam.type
      };
      //Add new param
      let index = this.selectedFilter.findIndex(value => value.field === newSearch.field && value.value === newSearch.value);
      if (index === -1) { //no existe
        this.selectedFilter.push(newSearch);
        this.doSearch();
      } else {
        this.selectedFilter[index].value = newSearch.value;
      }
    }
  }

  removeSearchParam(param: ISearchParam) {
    let index = this.selectedFilter.indexOf(param);
    if (index !== -1) {
      this.selectedFilter.splice(index, 1);
      this.doSearch();
    }
  }

  editSearchParam(param: ISearchParam) {
    let index = this.selectedFilter.indexOf(param);
    if (index !== -1) {
      this.searchInputText = this.selectedFilter[index].value;
      this.selectedSearchParam = this.selectedFilter[index];
    }
  }

  clearSearchParams() {
    this.selectedFilter.splice(0, this.selectedFilter.length);

  }

  /* dimmer */
  onShow(state: boolean) {
    if (state) {
      this.searchInput.nativeElement.focus();

    }
    console.log("Estado -> " + state);
  }
}
