import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'paginate'
})
export class PaginatePipe implements PipeTransform {

  transform(values: Array<any>, page: number, limit:number): Array<any> {
    if(isNullOrUndefined((page))|| isNullOrUndefined(limit)){
      return values;
    }else{
      if(values.length <= limit){
        return values;
      }
      const index: number = (page * limit) - 1;
      const start:number = index - limit + 1;
      if(index <= values.length)
        return values.slice(start, index);
      else //last page.
        return values.slice(start, values.length);
    }
  }

}
