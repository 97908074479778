import { Pipe, PipeTransform } from '@angular/core';
import {Maintenance} from "@app/core/models/maintenance.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
import {isNullOrUndefined} from "util";
import {PositionAttributePipe} from "@app/core/pipes/position-attribute.pipe";

@Pipe({
  name: 'filterMaintenanceBySearchParam'
})
export class FilterMaintenanceBySearchParamPipe implements PipeTransform {

  private pipe: PositionAttributePipe = new PositionAttributePipe();

  transform(values: Array<Maintenance>, params: Array<ISearchParam>): Array<Maintenance> {
    if (!values) return [];
    if (!params || params.length == 0) return values;
    let filtered: Array<Maintenance> = values;
    params.forEach(param => {
      if (isNullOrUndefined(param.remote) || !param.remote){
        if(param.field == 'name'){
          filtered = (this.filterByName(filtered,param.value));
        }
        if (param.field == 'type') {
          if (param.type == 'select')
            filtered = (this.filterByType(filtered, param.value));
          else
            filtered = (this.filterByTypeText(filtered, param.value));
        }
      }
    });
    return filtered;
  }

  private filterByName(values: Array<Maintenance>, name: string) {
    if(!values) return [];
    if(isNullOrUndefined(name)) return values;
    return values.filter(maintenance => {
      return maintenance.name.toLowerCase().includes(name.toLowerCase());
    });
  }

  public filterByType(values: Array<Maintenance>, type: string) {
    if (!values) return [];
    return values.filter(maintenance => {
      return maintenance.type.toLocaleLowerCase().includes(type.toLowerCase());
    });
  }

  private filterByTypeText(values: Array<Maintenance>, type: string) {
    if(!values) return [];
    if(isNullOrUndefined(type)) return values;
    return values.filter(maintenance => {
      return this.pipe.transform(maintenance.type).toLocaleLowerCase().includes(type.toLowerCase());
    });
  }

}
