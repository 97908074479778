import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'role'
})
export class RolePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    switch (value) {
      case 'root': return 'Super Usuario';
      case 'administrator': return 'Administrador';
      case 'supervisor': return 'Supervisor';
      case 'monitor': return 'Monitor';
      default: return 'Sin Asignar';
    }
  }

}
