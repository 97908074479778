import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {NGXLogger} from "ngx-logger";
import {ILogin} from "@app/core/interfaces/ilogin.interface";
import {catchError, map} from "rxjs/operators";
import {User} from "@app/core/models/user.model";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
import {LocalService} from "@app/core/service/local.service";
// @ts-ignore
import {isNullOrUndefined} from "util";


@Injectable({
  providedIn: 'root'
})
export class SessionService extends BaseService {

  public static currentUser: User;

  constructor(http: HttpClient, logger: NGXLogger) {
    super(http, logger);
  }

  /**
   * Create a new Session
   *
   * @param entity
   */
  public login(entity: ILogin): Observable<User> {
    let headers = this.defaultHeaders;
    headers.set("Accept", "application/json");
    headers.set('Authorization', 'Basic ' + btoa(entity.email + ':' + entity.password));
    // return this.http.post(URL, entity, httpOptions);
    return this.httpClient.post<User>(`${this.basePath}${this.REST_URLS.login}`, entity, {
      headers: headers,
      withCredentials: this.withCredentials
    }).pipe(map(
      user => {
        if (!isNullOrUndefined(user)) {
          user.password = btoa(entity.password);
          LocalService.currentUser = user;
          return user;
        }else
          return false;
      }
    ), catchError(this.handleError('post', null)));
  }

  public logout() {
    // let headers = this.defaultHeaders;
    // headers.set("Accept", "application/json");
    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.session}`, {
      headers: this.addBasicAuth(),
      withCredentials: this.withCredentials
    }).pipe(catchError(this.handleError('delete', null)));
  }

  public loginByToken(token: string): Observable<User> {
    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    queryParameters = queryParameters.set('token', token);

    let headers = this.defaultHeaders;
    headers.set("Accept", "application/json");

    return this.httpClient.get<User>(`${this.basePath}${this.REST_URLS.session}`, {
      params: queryParameters,
      headers: headers,
      withCredentials: this.withCredentials
    }).pipe(map(
      user => {
        if (!isNullOrUndefined(user)) {
          LocalService.currentUser = user;
        }
        return user;
      }
    ), catchError(this.handleError('post', null)));
  }

}
