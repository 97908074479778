import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '@env/environment';
import {NGXLogger} from "ngx-logger";
import {User} from "@app/core/models/user.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

export class BaseService {

  public basePath = environment.apiUrl;

  public user:User;

  protected REST_URLS = {
    attributes: 'attributes/computed',
    server: 'server',
    devices: 'devices',
    accumulators: 'accumulators',
    session: 'session',
    login: 'session/login',
    positions: 'positions',
    socket: 'socket',
    users: 'users',
    notifications: 'notifications',
    notificationTypes: 'notifications/types',
    notificators: 'notifications/notificators',
    calendars: 'calendars',
    permissions: 'permissions',
    permissionsArray: 'permissions/array',
    geofences: 'geofences',
    eventsReport: 'reports/events',
    routesReport: 'reports/route',
    stopsReport: 'reports/stops',
    tripsReport: 'reports/trips',
    summaryReport: 'reports/summary',
    groups: 'groups',
    drivers: 'drivers',
    maintenances: 'maintenance',
    maintenanceTypes: 'maintenance/types',
    resetToken: 'users/reset-token'
  };

  protected defaultHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  // 'Cache-Control': 'no-cache',
  // 'Pragma': 'no-cache',
  // 'If-Modified-Since':'0'

  protected addBasicAuth(): HttpHeaders {
    // const user = JSON.parse(localStorage.getItem("currentUser")) as User;
    // const user = LocalService.currentUser;
    // if (!isNullOrUndefined(user) && !isNullOrUndefined(user.password)) {
    //   // console.log("BASIC AUTH "+ JSON.stringify(user,null, 2));
    //   return this.defaultHeaders.set('Authorization', 'Basic ' + btoa(user.email + ':' + atob(user.password)));
    // }else {
    //   return this.defaultHeaders;
    // }
    const auth = sessionStorage.getItem('auth');
    if(!isNullOrUndefined(auth)){
      return this.defaultHeaders.set('Authorization', auth);
    }else{
      return this.defaultHeaders;
    }
  }

  protected withCredentials: boolean = true;

  constructor(protected httpClient: HttpClient, protected logger: NGXLogger) {
  }

  /**
   * Handle Http operation that failed. Letting app to continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.logger.error(error);
      this.logger.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
