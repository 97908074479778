/***
 * project: BUHO Web Interface
 * author: Miguel Hurtado
 * */
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {AlertComponent} from './components/alert/alert.component';

import {SweetAlert2Module} from '@toverux/ngx-sweetalert2';
import {FloatingButtonComponent} from './components/floating-button/floating-button.component';
import {KeysPipePipe} from './pipes/keys-pipe.pipe';
import {SBBooleanPipe} from './pipes/sbboolean.pipe';
import {IconDropdownPickerComponent} from './components/icon-dropdown-picker/icon-dropdown-picker.component';
import {SuiModule} from 'ng2-semantic-ui';
import {FullPageItemListComponent} from './components/full-page-item-list/full-page-item-list.component';
import {SearchTopBarComponent} from './components/search-top-bar/search-top-bar.component';
import {MenuItemService} from "@app/shared/services/menu-item.service";
import {FocusInputDirective} from './directives/focus-input.directive';
import {TopMenuComponent} from './components/top-menu/top-menu.component';
import {RangeSliderComponent} from './components/range-slider/range-slider.component';
import {InputTextComponent} from './components/input-text/input-text.component';
import {TableModule} from "ngx-easy-table";

@NgModule({
  declarations: [AlertComponent, FloatingButtonComponent, KeysPipePipe, SBBooleanPipe,
    IconDropdownPickerComponent,
    FullPageItemListComponent,
    SearchTopBarComponent,
    FocusInputDirective,
    TopMenuComponent,
    RangeSliderComponent,
    InputTextComponent],
  imports: [
    CommonModule,
    FormsModule,
    SweetAlert2Module,
    SuiModule,
    TableModule
  ],
  providers: [
    MenuItemService],
  bootstrap: [],
  exports: [
    CommonModule,
    FormsModule,
    AlertComponent,
    FloatingButtonComponent,
    KeysPipePipe,
    SBBooleanPipe,
    IconDropdownPickerComponent,
    FullPageItemListComponent,
    SearchTopBarComponent,
    FocusInputDirective,
    TopMenuComponent,
    RangeSliderComponent,
    InputTextComponent
  ]
})
export class SharedModule {

}


