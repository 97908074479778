import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusDevice'
})
export class StatusDevicePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if(value == 'online')
      return 'Conectado';
    if(value == 'offline')
      return 'Desconectado';
    if(value == 'unknown')
      return 'Desconocido';
    return '';
  }

}
