import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Device} from "@app/core/models/device.model";
import {Geofence} from "@app/core/models/geofence.model";
import {Notification} from "@app/core/models/notification.model";
import {User} from "@app/core/models/user.model";
import {Group} from "@app/core/models/group.model";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {RolePermission} from "@app/shared/interfaces/SharedTypes";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {WebConfiguration} from "@app/core/models/web-configuration.model";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {Driver} from "@app/core/models/driver.model";
import {Maintenance} from "@app/core/models/maintenance.model";
import {Calendar} from "@app/core/models/calendar.model";
import {Config} from "ngx-easy-table";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {DatePipe} from "@angular/common";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";
import {AttributeComputated} from "@app/core/models/attribute-computated.model";
import {SwalComponent} from "@toverux/ngx-sweetalert2";

@Component({
  selector: 'app-permission-bundle',
  templateUrl: './permission-bundle.component.html',
  styleUrls: ['./permission-bundle.component.css']
})
export class PermissionBundleComponent implements OnInit {

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();
  /* type objects */
  public parentTypeObjects: Array<any> = [];
  public typeObjects: Array<any> = [];
  public selectedParentTypeObject: any;
  public selectedTypeObject: any;
  /* objects */
  public selectedObjects: Array<any> = [];
  public selectedParentObject: any;
  /* permissions */
  private oldPermissions: Array<any> = [];
  private permissions: Array<any> = [];
  private deletePermissions: Array<any> = [];
  /* SEARCHER */
  public searchInputText: string = '';

  /* api loaded*/
  public devices: Array<Device> = [];
  public defaultDevices: Array<Device> = [];
  public groups: Array<Group> = [];
  public defaultGroups: Array<Group> = [];
  public users: Array<User> = [];
  public defaultUsers: Array<User> = [];

  public geofences: Array<Geofence> = [];
  public defaultGeofences: Array<Geofence> = [];

  public notifications: Array<Notification> = [];
  public defaultNotifications: Array<Notification> = [];
  public notificationPipe: NotificationTypePipe = new NotificationTypePipe();

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;

  public maintenances: Array<Maintenance> = [];
  public defaultMaintenances: Array<Maintenance> = [];

  public drivers: Array<Driver> = [];
  public defaultDrivers: Array<Driver> = [];

  public calendars: Array<Calendar> = [];
  public defaultCalendars: Array<Calendar> = [];

  public attributesComputated: Array<AttributeComputated> = [];
  public defaultAttributesComputated: Array<AttributeComputated> = [];

  /* Access Roles*/
  private _user: User;
  private role: string;
  /* wizard */
  public state: number = 0;
  public backText: string = 'ATRAS';
  public nextText: string = 'CONTINUAR';
  public finalState: number = 3;

  public dateFilter: DatePipe = new DatePipe("es");

  public configuration: Config;

  public columnsDevices;
  public columnsGroups;
  public columnsUsers;

  @ViewChild('deviceNameTemplate')
  deviceNameTemplate: TemplateRef<any>;
  @ViewChild('nameTemplate')
  nameTemplate: TemplateRef<any>;
  @ViewChild('roleTemplate')
  roleTemplate: TemplateRef<any>;

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private logger: NGXLogger) {
  }

  ngOnInit() {
    // this._user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this._user = LocalService.currentUser;
    this.role = this._user.attributes['role'];

    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.configuration.checkboxes = false;
    this.configuration.clickEvent = true;
    //create parent items
    this.createParentObjects();
    // this.createObjects();
    //configure Tables
    this.configureDevicesTable();
    this.configureGroupsTable();
    this.configureUsersTable();
  }

  private createParentObjects() {
    if (this.checkRole('device')) {
      this.parentTypeObjects.push({
        id: 'device', name: 'Dispositivo', icon: 'hdd',
        children: ['driver', 'attribute', 'geofence', 'maintenance', 'notification','attribute']
      });
    }
    if (this.checkRole('group')) {
      this.parentTypeObjects.push({
        id: 'group', name: 'Grupo', icon: 'object group outline',
        children: ['driver', 'attribute', 'geofence', 'maintenance', 'notification','attribute']
      });
    }
    if (this.checkRole('user')) {
      this.parentTypeObjects.push({
        id: 'user', name: 'Usuarios', icon: 'users',
        children: ['attribute', 'calendar', 'device', 'driver', 'geofence', 'group', 'maintenance', 'notification', 'user']
      });
    }
  }

  private createObjects() {
    this.typeObjects = [];
    if (this.checkRole('user') && this.selectedParentTypeObject.children.includes('user'))
      this.typeObjects.push({id: 'user', name: 'Usuarios', icon: 'user'});
    if (this.checkRole('geofence') && this.selectedParentTypeObject.children.includes('geofence'))
      this.typeObjects.push({id: 'geofence', name: 'Geocercas', icon: 'block layout'});
    if (this.checkRole('device') && this.selectedParentTypeObject.children.includes('device'))
      this.typeObjects.push({id: 'device', name: 'Dispositivos', icon: 'hdd'});
    if (this.checkRole('group') && this.selectedParentTypeObject.children.includes('group'))
      this.typeObjects.push({id: 'group', name: 'Grupos', icon: 'object group outline'});
    if (this.checkRole('maintenance') && this.selectedParentTypeObject.children.includes('maintenance'))
      this.typeObjects.push({id: 'maintenance', name: 'Mantenimientos', icon: 'wrench'});
    if (this.checkRole('calendar') && this.selectedParentTypeObject.children.includes('calendar'))
      this.typeObjects.push({id: 'calendar', name: 'Calendarios', icon: 'calendar alternate outline'});
    if (this.checkRole('notification') && this.selectedParentTypeObject.children.includes('notification'))
      this.typeObjects.push({id: 'notification', name: 'Alertas', icon: 'bell outline'});
    if (this.checkRole('driver') && this.selectedParentTypeObject.children.includes('driver'))
      this.typeObjects.push({id: 'driver', name: 'Conductores', icon: 'address book outline'});
    if (this.checkRole('attribute') && this.selectedParentTypeObject.children.includes('attribute'))
      this.typeObjects.push({id: 'attribute', name: 'Atributos Calculados', icon: 'code'});
  }

  public loadData(id: string) {
    switch (id) {
      case 'device':
        this.loadDevices();
        break;
      case 'group':
        this.loadGroups();
        break;
      case 'user':
        this.loadUsers();
        break;
      case 'geofence':
        this.loadGeofences();
        break;
      case 'notification':
        this.loadNotifications();
        break;
      case 'driver':
        this.loadDrivers();
        break;
      case 'maintenance':
        this.loadMaintenances();
        break;
      case 'calendar':
        this.loadCalendars();
        break;
      case 'attribute':
        this.loadAttributesComputated();
        break;
    }
  }

  public loadDataAndPermissions() {
    this.permissions = [];
    this.oldPermissions = [];

    switch (this.selectedTypeObject.id) {
      case 'device':
        if (this.selectedParentTypeObject.id == 'user') {
          //verificamos si el usuario esta en algun grupo y este contiene dispositivos.
          let devices: Array<Device> = [];
          this.apiService.deviceService.getDevices(false, null, null, this.selectedParentObject.id).subscribe(value => {
            devices = value;
            // this.logger.warn("[D]"+JSON.stringify(value,null,2)+" [U] "+JSON.stringify(this.selectedParentObject,null,2));
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Dispositivos.");
          }, () => {
            devices.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, deviceId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, deviceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;

      case 'group':
        if (this.selectedParentTypeObject.id == 'user') {
          let groups: Array<Group> = [];
          this.apiService.groupService.getGroups(null, this.selectedParentObject.id).subscribe(value => {
            // this.logger.warn('cargar GRUPOs -> ' + JSON.stringify(value)+' para '+ JSON.stringify(this.selectedParentObject));
            groups = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Grupos.");
          }, () => {
            groups.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, groupId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, groupId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;

      case 'user':
        if (this.selectedParentTypeObject.id == 'user') {
          let users: Array<User> = [];
          this.apiService.userService.getUser(this.selectedParentObject.id).subscribe(value => {
            users = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Usuarios.");
          }, () => {
            users.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, managedUserId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, managedUserId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;

      case 'geofence':
        //user
        if (this.selectedParentTypeObject.id == 'user') {
          let geofences: Array<Geofence> = [];
          this.apiService.geofenceService.getGeofences(null, this.selectedParentObject.id).subscribe(value => {
            geofences = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Geocercas.");
          }, () => {
            geofences.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, geofenceId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, geofenceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        //devices
        else if (this.selectedParentTypeObject.id == 'device') {
          let geofences: Array<Geofence> = [];
          this.apiService.geofenceService.getGeofences(null, null, this.selectedParentObject.id).subscribe(value => {
            geofences = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Geocercas.");
          }, () => {
            geofences.forEach(value => {
              this.permissions.push({deviceId: this.selectedParentObject.id, geofenceId: value.id});
              this.oldPermissions.push({deviceId: this.selectedParentObject.id, geofenceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        //group
        else if (this.selectedParentTypeObject.id == 'group') {
          let geofences: Array<Geofence> = [];
          this.apiService.geofenceService.getGeofences(null, null, null, this.selectedParentObject.id).subscribe(value => {
            geofences = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Geocercas.");
          }, () => {
            geofences.forEach(value => {
              this.permissions.push({groupId: this.selectedParentObject.id, geofenceId: value.id});
              this.oldPermissions.push({groupId: this.selectedParentObject.id, geofenceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;
      case 'notification':
        if (this.selectedParentTypeObject.id == 'user') {
          let notifications: Array<Notification> = [];
          this.apiService.notificationService.getNotifications(null, this.selectedParentObject.id).subscribe(value => {
            notifications = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de las Alertas.");
          }, () => {
            notifications.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, notificationId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, notificationId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'device') {
          let notifications: Array<Notification> = [];
          this.apiService.notificationService.getNotifications(null, null, this.selectedParentObject.id).subscribe(value => {
            notifications = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de las Alertas.");
          }, () => {
            notifications.forEach(value => {
              this.permissions.push({deviceId: this.selectedParentObject.id, notificationId: value.id});
              this.oldPermissions.push({deviceId: this.selectedParentObject.id, notificationId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'group') {
          let notifications: Array<Notification> = [];
          this.apiService.notificationService.getNotifications(null, null, null, this.selectedParentObject.id).subscribe(value => {
            notifications = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de las Alertas.");
          }, () => {
            notifications.forEach(value => {
              this.permissions.push({groupId: this.selectedParentObject.id, notificationId: value.id});
              this.oldPermissions.push({groupId: this.selectedParentObject.id, notificationId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;
      case 'driver':
        let drivers: Array<Driver> = [];
        if (this.selectedParentTypeObject.id == 'user') {
          this.apiService.driverService.getDrivers(null, this.selectedParentObject.id).subscribe(value => {
            drivers = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Conductores.");
          }, () => {
            drivers.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, driverId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, driverId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'device') {
          this.apiService.driverService.getDrivers(null, null, this.selectedParentObject.id).subscribe(value => {
            drivers = value;
            // this.logger.warn("PARA: "+JSON.stringify(this.selectedParentTypeObject)+ " estos son sus conductores "+JSON.stringify(value));
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Conductores.");
          }, () => {
            drivers.forEach(value => {
              this.permissions.push({deviceId: this.selectedParentObject.id, driverId: value.id});
              this.oldPermissions.push({deviceId: this.selectedParentObject.id, driverId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'group') {
          this.apiService.driverService.getDrivers(null, null, null, this.selectedParentObject.id).subscribe(value => {
            drivers = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Conductores.");
          }, () => {
            drivers.forEach(value => {
              this.permissions.push({groupId: this.selectedParentObject.id, driverId: value.id});
              this.oldPermissions.push({groupId: this.selectedParentObject.id, driverId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;

      case 'maintenance':
        let maintenances: Array<Maintenance> = [];
        if (this.selectedParentTypeObject.id == 'user') {
          this.apiService.maintenanceService.getMaintenances(null, this.selectedParentObject.id).subscribe(value => {
            maintenances = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Mantenimientos.");
          }, () => {
            maintenances.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, maintenanceId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, maintenanceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'device') {
          this.apiService.maintenanceService.getMaintenances(null, null, this.selectedParentObject.id).subscribe(value => {
            maintenances = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Mantenimientos.");
          }, () => {
            maintenances.forEach(value => {
              this.permissions.push({deviceId: this.selectedParentObject.id, maintenanceId: value.id});
              this.oldPermissions.push({deviceId: this.selectedParentObject.id, maintenanceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'group') {
          this.apiService.maintenanceService.getMaintenances(null, null, null, this.selectedParentObject.id).subscribe(value => {
            maintenances = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Mantenimientos.");
          }, () => {
            maintenances.forEach(value => {
              this.permissions.push({groupId: this.selectedParentObject.id, maintenanceId: value.id});
              this.oldPermissions.push({groupId: this.selectedParentObject.id, maintenanceId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;

      case 'calendar':
        if (this.selectedParentTypeObject.id == 'user') {
          let calendars: Array<Calendar> = [];
          this.apiService.calendarService.getCalendars(null, this.selectedParentObject.id).subscribe(value => {
            calendars = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de Calendarios.");
          }, () => {
            calendars.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, calendarId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, calendarId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;

      case 'attribute':
        let attributesComputated: Array<AttributeComputated> = [];
        if (this.selectedParentTypeObject.id == 'user') {
          this.apiService.attributeService.getAttributes(null, this.selectedParentObject.id).subscribe(value => {
            attributesComputated = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Atributos.");
          }, () => {
            attributesComputated.forEach(value => {
              this.permissions.push({userId: this.selectedParentObject.id, attributeId: value.id});
              this.oldPermissions.push({userId: this.selectedParentObject.id, attributeId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'device') {
          this.apiService.attributeService.getAttributes(null, null, this.selectedParentObject.id).subscribe(value => {
            attributesComputated = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Atributos.");
          }, () => {
            attributesComputated.forEach(value => {
              this.permissions.push({deviceId: this.selectedParentObject.id, attributeId: value.id});
              this.oldPermissions.push({deviceId: this.selectedParentObject.id, attributeId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        } else if (this.selectedParentTypeObject.id == 'group') {
          this.apiService.attributeService.getAttributes(null, null, null, this.selectedParentObject.id).subscribe(value => {
            attributesComputated = value;
          }, error1 => {
            this.logger.error("Se Produjo un error al cargar los datos de los Atributos.");
          }, () => {
            attributesComputated.forEach(value => {
              this.permissions.push({groupId: this.selectedParentObject.id, attributeId: value.id});
              this.oldPermissions.push({groupId: this.selectedParentObject.id, attributeId: value.id});
            });
            this.loadData(this.selectedTypeObject.id);
          });
        }
        break;
    }
  }

  private loadDevices() {
    this.apiService.deviceService.getDevices(this.role == 'root').subscribe(value => {
      //format
      const formatted = this.formatDevices(value);
      this.devices = [...formatted];
      this.defaultDevices = [...formatted];
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de Dispositivos.");
    });
  }

  private loadGroups(userId?: number) {
    this.apiService.groupService.getGroups(this.role == 'root', userId).subscribe(value => {
      const formatted = this.formatGroups(value);
      this.groups = [...formatted];
      this.defaultGroups = [...formatted];
      // this.logger.warn("CARGAMOS LOS GRUPOS " + JSON.stringify(value));
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de Grupos.");
    });
  }

  private loadUsers(userId?: string) {
    this.apiService.userService.getUser(userId).subscribe(value => {
      const formatted = this.formatUsers(value);
      this.users = [...formatted];
      this.defaultUsers = [...formatted];
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de Grupos.");
    });
  }

  private loadGeofences() {
    this.apiService.geofenceService.getGeofences(this.role == 'root').subscribe(value => {
      this.geofences = value;
      this.defaultGeofences = value;
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de Geocercas.");
    });
  }

  private loadNotifications() {
    this.apiService.notificationService.getNotifications(this.role == 'root').subscribe(value => {
      this.notifications = value;
      this.defaultNotifications = value;
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de las Alertas.");
    });
  }

  private loadDrivers() {
    this.apiService.driverService.getDrivers(this.role == 'root').subscribe(value => {
      this.drivers = value;
      this.defaultDrivers = value;
      // this.logger.warn("CARGADO CONDUCTORES "+JSON.stringify(value));
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de los Conductores.");
    });
  }

  private loadMaintenances() {
    this.apiService.maintenanceService.getMaintenances(this.role == 'root').subscribe(value => {
      this.maintenances = value;
      this.defaultMaintenances = value;
      // this.logger.warn("CARGADO MANTENIMIENTOS "+JSON.stringify(value));
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de los Mantenimientos.");
    });
  }

  private loadAttributesComputated() {
    this.apiService.attributeService.getAttributes(this.role == 'root').subscribe(value => {
      this.attributesComputated = value;
      this.defaultAttributesComputated = value;
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de los Atributos.");
    });
  }

  private loadCalendars() {
    this.apiService.calendarService.getCalendars(this.role == 'root').subscribe(value => {
      this.calendars = value;
      this.defaultCalendars = value;
    }, error1 => {
      this.logger.error("Se Produjo un error al cargar los datos de los Calendarios.");
    })
  }

  /* CHECK ROLES */
  public checkRole(action: RolePermission): boolean {
    return this.apiService.roleService.checkRolePermissions(action);
  }

  public close() {
    this.clearAll();
    this.onClose.emit();
  }

  public hasChanges() {
    if(this.state >= 1){
      this.confirmSwal.show();
    }else{
      this.close();
    }
  }

  /* WIZARD */

  public validateState(): boolean {
    switch (this.state) {
      case 0:
        return !isNullOrUndefined(this.selectedParentTypeObject);
      case 1:
        return !isNullOrUndefined(this.selectedParentObject);
      case 2:
        return !isNullOrUndefined(this.selectedTypeObject);
      case 3:
        return this.selectedObjects.length <= 0;
      default:
        return true;
    }
  }

  //actions
  public backStep(): void {
    if (this.state == 0) {
      this.onClose.emit();
    } else {
      switch (this.state) {
        case 0:
          this.selectedParentTypeObject = undefined;
          break;
        case 1:
          this.selectedParentObject = undefined;
          break;
        case 2:
          this.selectedTypeObject = undefined;
          break;
        case 3:
          this.selectedObjects = [];
      }
      this.state--;
    }
  }

  public nextStep(): void {
    switch (this.state) {
      case 0:
        // this.logger.warn("pasamos al estado 2 y cargamos la data del elemento seleccinado en el estado 1");
        this.loadData(this.selectedParentTypeObject.id);
        this.state++;
        break;
      case 1:
        // this.logger.warn("pasamos al estado 3 para seleccionar el elemento");
        this.searchInputText = '';
        this.restoreDefaults();
        this.createObjects();
        this.state++;
        break;
      case 2:
        // this.logger.warn("pasamos al estado 4 despues de haber seleccionado el tipo de Objecto.");
        this.loadDataAndPermissions();
        this.state++;
        break;
      case 3:
        // this.logger.warn("pasamos al estado 5 y verificamos antes de guardar.");
        this.state++;
        break;
      default:
        this.state++;
        break;
    }
  }

  public doSearch(): void {
    if (this.state == 1) {
      switch (this.selectedParentTypeObject.id) {
        case 'device':
          if (this.searchInputText.length > 0) {
            this.devices = this.defaultDevices.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.devices = this.defaultDevices;
          }
          break;
        case 'group':
          if (this.searchInputText.length > 0) {
            this.groups = this.defaultGroups.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.groups = this.defaultGroups;
          }
          break;
        case 'user':
          if (this.searchInputText.length > 0) {
            this.users = this.defaultUsers.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.users = this.defaultUsers;
          }
          break;
      }
    } else if (this.state == 3) {
      switch (this.selectedTypeObject.id) {
        case 'device':
          if (this.searchInputText.length > 0) {
            this.devices = this.defaultDevices.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()) ||
              value.uniqueId.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.devices = this.defaultDevices;
          }
          break;
        case 'group':
          if (this.searchInputText.length > 0) {
            this.groups = this.defaultGroups.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.groups = this.defaultGroups;
          }
          break;
        case 'user':
          if (this.searchInputText.length > 0) {
            this.users = this.defaultUsers.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.users = this.defaultUsers;
          }
          break;
        case 'geofence':
          if (this.searchInputText.length > 0) {
            this.geofences = this.defaultGeofences.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.geofences = this.defaultGeofences;
          }
          break;
        case 'notification':
          if (this.searchInputText.length > 0) {
            this.notifications = this.defaultNotifications.filter(value => this.notificationPipe.transform(value.type).toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.notifications = this.defaultNotifications;
          }
          break;
        case 'driver':
          if (this.searchInputText.length > 0) {
            this.drivers = this.defaultDrivers.filter(value => value.uniqueId.toUpperCase().includes(this.searchInputText.toUpperCase()) || value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.drivers = this.defaultDrivers;
          }
          break;

        case 'maintenance':
          if (this.searchInputText.length > 0) {
            this.maintenances = this.defaultMaintenances.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.maintenances = this.defaultMaintenances;
          }
          break;

        case 'calendar':
          if (this.searchInputText.length > 0) {
            this.calendars = this.defaultCalendars.filter(value => value.name.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.calendars = this.defaultCalendars;
          }
          break;

        case 'attribute':
          if (this.searchInputText.length > 0) {
            this.attributesComputated = this.defaultAttributesComputated.filter(value => value.description.toUpperCase().includes(this.searchInputText.toUpperCase()));
          } else {
            this.attributesComputated = this.defaultAttributesComputated;
          }
          break;
      }
    }

  }

  /* WIZARD ACTIONS */
  public save() {
    //prepare data for the save
    this.processPermissions();
    //ADD VISIBLE DEVICES TO USER.
    if (this.selectedParentTypeObject.id == 'user' && this.selectedTypeObject.id == 'device') {
      const user = this.clearChunkDataUser(this.selectedParentObject as User);

      let config;
      if (isNullOrUndefined(user.webConfiguration)) {
        config = WebConfiguration.createFromUser(user);
      } else {
        config = WebConfiguration.createFromSanitizedString(user.webConfiguration);
      }
      if (!isNullOrUndefined(config)) {
        let visibleIds = this.permissions.map(value => value.deviceId);
        if (visibleIds.length <= 0) {
          //check for old - delete to activate d8evices
          visibleIds = this.oldPermissions.filter(value => !this.deletePermissions.includes(value)).map(value => value.deviceId);
        }
        config.mapOptions.visibleDeviceIds = visibleIds;

        // this.logger.warn(`ACTIVAR DISPOSITIVOS PARA: ${JSON.stringify(config.mapOptions.visibleDeviceIds)}`);
        user.webConfiguration = JSON.stringify(config.IWebConfig);
        user.password = null;
        this.apiService.userService.updateUser(user.id, user).subscribe(value => {
          //logout
          // this.logger.info("Se actualizo al usuario " + value);
        });
      }
    }
    //
    this.close();
  }

  public selectParentTypeObject(item: any) {
    if (this.selectedParentTypeObject == item)
      this.selectedParentTypeObject = undefined;
    else {
      this.selectedParentTypeObject = item;
      this.nextStep();
    }
  }

  public selectTypeObject(item: any) {
    if (this.selectedTypeObject == item)
      this.selectedTypeObject = undefined;
    else {
      this.selectedTypeObject = item;
      this.nextStep();
    }
  }

  public selectParentObject(item: any) {
    // if (this.selectedParentObject == item)
    //   this.selectedParentObject = undefined;
    // else {
    this.selectedParentObject = item;
    this.nextStep();
    // }
  }


  public isOnList(object: any): boolean {
    switch (this.selectedParentTypeObject.id) {
      case 'user':
        switch (this.selectedTypeObject.id) {
          case 'device':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.deviceId == object.id) !== -1;
          case 'group':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.groupId == object.id) !== -1;
          case 'user':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.managedUserId == object.id) !== -1;
          case 'geofence':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.geofenceId == object.id) !== -1;
          case 'notification':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.notificationId == object.id) !== -1;
          case 'driver':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.driverId == object.id) !== -1;
          case 'maintenance':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.maintenanceId == object.id) !== -1;
          case 'calendar':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.calendarId == object.id) !== -1;
          case 'attribute':
            return this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.attributeId == object.id) !== -1;
        }
        break;
      case 'device':
        switch (this.selectedTypeObject.id) {
          case 'geofence':
            return this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.geofenceId == object.id) !== -1;
          case 'notification':
            return this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.notificationId == object.id) !== -1;
          case 'driver':
            return this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.driverId == object.id) !== -1;
          case 'maintenance':
            return this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.maintenanceId == object.id) !== -1;
          case 'attribute':
            return this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.attributeId == object.id) !== -1;
        }
        break;
      case 'group':
        switch (this.selectedTypeObject.id) {
          case 'geofence':
            return this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.geofenceId == object.id) !== -1;
          case 'notification':
            return this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.notificationId == object.id) !== -1;
          case 'driver':
            return this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.driverId == object.id) !== -1;
          case 'maintenance':
            return this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.maintenanceId == object.id) !== -1;
          case 'attribute':
            return this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.attributeId == object.id) !== -1;
        }
        break;
    }
  }

  public toogleOnList(id: number | string, state: boolean) {
    if (state) {//existe..
      let index = -1;
      switch (this.selectedParentTypeObject.id) {
        case 'user':
          switch (this.selectedTypeObject.id) {
            case 'device':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.deviceId == id);
              if (index == -1) {
                this.permissions.push({userId: this.selectedParentObject.id, deviceId: id});
              }
              break;
            case 'group':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.groupId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, groupId: id});
              break;
            case 'user':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.managedUserId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, managedUserId: id});
              break;
            case 'geofence':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.geofenceId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, geofenceId: id});
              break;
            case 'notification':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.notificationId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, notificationId: id});
              break;
            case 'driver':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.driverId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, driverId: id});
              break;
            case 'maintenance':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.maintenanceId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, maintenanceId: id});
              break;
            case 'calendar':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.calendarId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, calendarId: id});
              break;
            case 'attribute':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.attributeId == id);
              if (index == -1)
                this.permissions.push({userId: this.selectedParentObject.id, attributeId: id});
              break;
          }
          break;
        case 'device':
          switch (this.selectedTypeObject.id) {
            case 'geofence':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.geofenceId == id);
              if (index == -1)
                this.permissions.push({deviceId: this.selectedParentObject.id, geofenceId: id});
              break;
            case 'notification':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.notificationId == id);
              if (index == -1)
                this.permissions.push({deviceId: this.selectedParentObject.id, notificationId: id});
              break;
            case 'driver':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.driverId == id);
              if (index == -1)
                this.permissions.push({deviceId: this.selectedParentObject.id, driverId: id});
              break;
            case 'maintenance':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.maintenanceId == id);
              if (index == -1)
                this.permissions.push({deviceId: this.selectedParentObject.id, maintenanceId: id});
              break;
            case 'attribute':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.attributeId == id);
              if (index == -1)
                this.permissions.push({deviceId: this.selectedParentObject.id, attributeId: id});
              break;
          }
          break;
        case 'group':
          switch (this.selectedTypeObject.id) {
            case 'geofence':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.geofenceId == id);
              if (index == -1)
                this.permissions.push({groupId: this.selectedParentObject.id, geofenceId: id});
              break;
            case 'notification':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.notificationId == id);
              if (index == -1)
                this.permissions.push({groupId: this.selectedParentObject.id, notificationId: id});
              break;
            case 'driver':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.driverId == id);
              if (index == -1)
                this.permissions.push({groupId: this.selectedParentObject.id, driverId: id});
              break;
            case 'maintenance':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.maintenanceId == id);
              if (index == -1)
                this.permissions.push({groupId: this.selectedParentObject.id, maintenanceId: id});
              break;
            case 'attribute':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.attributeId == id);
              if (index == -1)
                this.permissions.push({groupId: this.selectedParentObject.id, attributeId: id});
              break;
          }
          break;
      }
    } else {//no existe..
      let index = -1;
      switch (this.selectedParentTypeObject.id) {
        case 'user':
          switch (this.selectedTypeObject.id) {
            case 'device':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.deviceId == id);
              break;
            case 'group':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.groupId == id);
              break;
            case 'user':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.managedUserId == id);
              break;
            case 'geofence':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.geofenceId == id);
              break;
            case 'notification':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.notificationId == id);
              break;
            case 'driver':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.driverId == id);
              break;
            case 'maintenance':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.maintenanceId == id);
              break;
            case 'calendar':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.calendarId == id);
              break;
            case 'attribute':
              index = this.permissions.findIndex(value => value.userId == this.selectedParentObject.id && value.attributeId == id);
              break;
          }
          break;

        case 'device':
          switch (this.selectedTypeObject.id) {
            case 'geofence':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.geofenceId == id);
              break;
            case 'notification':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.notificationId == id);
              break;
            case 'driver':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.driverId == id);
              break;
            case 'maintenance':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.maintenanceId == id);
              break;
            case 'attribute':
              index = this.permissions.findIndex(value => value.deviceId == this.selectedParentObject.id && value.attributeId == id);
              break;
          }
          break;

        case 'group':
          switch (this.selectedTypeObject.id) {
            case 'geofence':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.geofenceId == id);
              break;
            case 'notification':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.notificationId == id);
              break;
            case 'driver':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.driverId == id);
              break;
            case 'maintenance':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.maintenanceId == id);
              break;
            case 'attribute':
              index = this.permissions.findIndex(value => value.groupId == this.selectedParentObject.id && value.attributeId == id);
              break;
          }
          break;
      }
      //remove
      if (index !== -1) {
        this.permissions.splice(index, 1);
      }
    }
  }

  private clearAll() {
    this.state = 0;
    this.selectedParentTypeObject = undefined;
    this.selectedTypeObject = undefined;
    this.searchInputText = '';
    this.selectedObjects = [];
    this.selectedParentObject = undefined;
    this.permissions = [];
    this.deletePermissions = [];
    this.oldPermissions = [];
  }

  private restoreDefaults() {
    this.searchInputText = '';
    this.devices = this.defaultDevices;
    this.geofences = this.defaultGeofences;
    this.users = this.defaultUsers;
    this.groups = this.defaultGroups;
    this.drivers = this.defaultDrivers;
    this.maintenances = this.defaultMaintenances;
    this.attributesComputated = this.defaultAttributesComputated;
    this.calendars = this.defaultCalendars;
  }

  /* adds elements to remove, to save and to delete*/
  private processPermissions() {
    // this.logger.warn(`[OLD]: ${JSON.stringify(this.oldPermissions)} [CURRENT]: ${JSON.stringify(this.permissions)}. [DELETE]: ${JSON.stringify(this.deletePermissions)}`);
    //let permissions = [];
    this.deletePermissions = [];
    //we remove all permissions that the objects already has
    this.oldPermissions.forEach(value => {
      const index = this.permissions.findIndex(value1 => JSON.stringify(value1) === JSON.stringify(value));
      if (index !== -1) {
        // this.logger.error("BORRAR de actual ["+index+"] "+JSON.stringify(value));
        this.permissions.splice(index, 1);
      } else {
        // this.logger.error("para BORRAR "+JSON.stringify(value));
        //we add the delete array permissions to remove that where in old permissions and are not in permissions.
        this.deletePermissions.push(value);
      }
    });
    //save
    if (this.permissions.length > 0) {
      // this.logger.warn(JSON.stringify(this.permissions,null,2));
      this.apiService.permissionService.permissionsArrayPost(this.permissions).subscribe(value => {
        if (this.deletePermissions.length <= 0)
          this.alertService.success("Permisos Guardados", "Se han guardado los permisos.");
      }, error1 => {
        this.alertService.error("Se produjo un error", "Verifique sus credenciales");
      });
    }
    if (this.deletePermissions.length > 0) {
      this.apiService.permissionService.permissionsArrayDelete(this.deletePermissions).subscribe(value => {
        this.alertService.success("Permisos Guardados", "Se han guardado los permisos.");
      }, error1 => {
        this.alertService.error("Se produjo un error", "Verifique sus credenciales");
      });
    }
    // this.logger.warn(`PERMISOS a guardar: ${JSON.stringify(this.permissions)}. PERMISOS A BORRAR: ${JSON.stringify(this.deletePermissions)}`);
  }

  public openPermissionsOnUser(user: User) {
    if (!isNullOrUndefined(user)) {
      this.selectedParentTypeObject = this.parentTypeObjects.find(value => value.id == 'user');
      this.loadData('user');
      this.selectedParentObject = user;
      this.state = 1;
      this.nextStep();
    }
  }

  private configureDevicesTable(): void {
    this.columnsDevices = [
      {key: 'name', title: 'Placa', cellTemplate: this.deviceNameTemplate, width: '50%'},
      {key: 'phone', title: 'Teléfono', width: '20%'},
      {key: 'model', title: 'Modelo', width: '20%'},
      {key: 'category', title: 'Categoría', width: '15%'},
      {key: 'lastUpdateF', title: 'Ult. Actualización', width: '15%'},
    ];
  }

  private configureGroupsTable(): void {
    this.columnsGroups = [
      {key: 'name', title: 'Nombre', cellTemplate: this.nameTemplate, width: '100%'},
      {key: 'group', title: 'Grupo Padre', width: '50%'}
    ];
  }

  private configureUsersTable(): void {
    this.columnsUsers = [
      {key: 'name', title: 'Nombre', cellTemplate: this.nameTemplate, width: '50%'},
      {key: 'email', title: 'Correo' , width: '25%'},
      {key: 'phone', title: 'Teléfono' , width: '25%'},
      {key: 'role', title: 'Rol' , cellTemplate: this.roleTemplate, width: '25%'},
    ];
  }

  public onClickRow(event) {
    // this.logger.warn(`SELECTED ROW: ${JSON.stringify(event, null, 2)}`);
    switch (event.event) {
      case 'onClick':
        this.selectParentObject(event.value.row);
        break;

    }
  }

  private formatDevices(devices: Array<Device>): Array<any> {
    let formatted = [];
    devices.forEach(value => {
      formatted.push({
        id: value.id,
        uniqueId: value.uniqueId,
        name: value.name,
        icon: value.icon,
        status: value.status,
        lastUpdateF: this.dateFilter.transform(value.lastUpdate, 'dd/MM/yyyy hh:mm'),
        lastUpdate: value.lastUpdate,
        positionId: value.positionId,
        disabled: value.disabled,
        groupId: value.groupId,
        phone: value.phone,
        category: value.category,
        model: value.model,
        contact: value.contact,
        capacityWeight: value.capacityWeight,
        capacityVolume: value.capacityVolume,
        geofenceIds: value.geofenceIds,
        attributes: value.attributes,
      });
    });
    return formatted;
  }

  private formatGroups(groups: Array<Group | any>): Array<any> {
    let formatted = [];
    groups.forEach(value => {
      formatted.push({
        id: value.id,
        name: value.name,
        groupId: value.groupId,
        group: this.getGroupName(value.groupId, groups),
        attributes: value.attributes
      });
    });
    return formatted;
  }

  private formatUsers(users: Array<User | any>): Array<any> {
    let formatted = [];
    users.forEach(value => {
      formatted.push({
        id: value.id,
        name: value.name,
        email: value.email,
        phone: value.phone,
        attributes: value.attributes,
        login: value.login,
        readonly: value.readonly,
        administrator: value.administrator,
        map: value.map,
        latitude: value.latitude,
        longitude: value.longitude,
        zoom: value.zoom,
        twelveHourFormat: value.twelveHourFormat,
        coordinateFormat: value.coordinateFormat,
        disabled: value.disabled,
        expirationTime: value.expirationTime,
        deviceLimit: value.deviceLimit,
        userLimit: value.userLimit,
        deviceReadonly: value.deviceReadonly,
        token: value.token,
        limitCommands: value.limitCommands,
        poiLayer: value.poiLayer,
        webConfiguration: value.webConfiguration,
        role: this.getRoleName(value)
      });
    });
    return formatted;
  }

  private getRoleName(user: User): string {
    if(!isNullOrUndefined(user.attributes) && (!isNullOrUndefined(user.attributes['role'])))
      return user.attributes['role'];
    else
      return 'Sin Definir';
  }

  private getGroupName(groupId: number, groups?: Array<any>): string {
    if (!isNullOrUndefined(groups)) {
      const index = groups.findIndex(value => value.id == groupId);
      if (index !== -1)
        return groups[index].name;
      else
        return '';
    } else {
      if (!isNullOrUndefined(this.groups)) {
        const index = this.groups.findIndex(value => value.id == groupId);
        if (index !== -1)
          return this.groups[index].name;
        else
          return '';
      }else{
        return '';
      }
    }
  }

  private clearChunkDataUser(user: User | any) : User | any {
    delete user.role;
    return user;
  }

  private clearChunkDataDevice(device: Device | any) : Device | any {
    delete device.lastUpdateF;
    return device;
  }

  private clearChunkDataGroup(group: Group | any) : Group | any {
    delete group.group;
    return group;
  }
}
