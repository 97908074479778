import { Injectable } from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Observable} from "rxjs";
import {Driver} from "@app/core/models/driver.model";
import {CustomHttpUrlEncodingCodec} from "@app/core/encoders/custom-http-url-encoding-codec.encoder";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DriverService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * Fetch a list of Drivers
   * Without params, it returns a list of Drivers the user has access to
   * @param all Can only be used by admins or managers to fetch all entities
   * @param userId Standard users can use this only with their own _userId_
   * @param deviceId Standard users can use this only with _deviceId_s, they have access to
   * @param groupId Standard users can use this only with _groupId_s, they have access to
   * @param refresh
   */
  public getDrivers(all?: boolean, userId?: number, deviceId?: number, groupId?: number, refresh?: boolean): Observable<Array<Driver>> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (!isNullOrUndefined(all)) {
      queryParameters = queryParameters.set('all', <any>all);
    }
    if (!isNullOrUndefined(userId)) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }
    if (!isNullOrUndefined(deviceId)) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }
    if (!isNullOrUndefined(groupId)) {
      queryParameters = queryParameters.set('groupId', <any>groupId);
    }
    if (!isNullOrUndefined(refresh)) {
      queryParameters = queryParameters.set('refresh', <any>refresh);
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.get<Array<Driver>>(`${this.basePath}${this.REST_URLS.drivers}`,
      {
        params: queryParameters,
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('get', null))
    );
  }

  /**
   * Delete a Driver
   *
   * @param id
   */
  public deleteDriver(id: number): Observable<any> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling deleteDriver.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.drivers}/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * Update a Driver
   *
   * @param id
   * @param body
   */
  public updateDriver(id: number, body: Driver): Observable<Driver> {
    if (isNullOrUndefined(id)) {
      throw new Error('Required parameter id was null or undefined when calling updateDriver.');
    }
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling updateDriver.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.put<Driver>(`${this.basePath}${this.REST_URLS.drivers}/${encodeURIComponent(String(id))}`,
      body,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Create a Driver
   *
   * @param body
   */
  public createDriver(body: Driver): Observable<Driver> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling createDriver.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<Driver>(`${this.basePath}${this.REST_URLS.drivers}`,
      body,
      {
        withCredentials: this.withCredentials,
        headers: headers
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }
}
