import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NotificationType} from "@app/core/models/notification-type";
import {NGXLogger} from "ngx-logger";
import {Notification} from "@app/core/models/notification.model";
import {ISearchParam} from "@app/core/interfaces/isearch-param.interface";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {Notificator} from "@app/core/models/notificator";
import {ModelPageState} from "@app/core/types/buho-core-types";
import {Calendar} from "@app/core/models/calendar.model";
import {NotificationTypePipe} from "@app/core/pipes/notification-type.pipe";
import {SearchTopBarComponent} from "@app/shared/components/search-top-bar/search-top-bar.component";
import {User} from "@app/core/models/user.model";
import {FilterNotificationBySearchParamPipe} from "@app/core/pipes/filter-notification-by-search-param.pipe";
import {Device} from "@app/core/models/device.model";
import {Dictionary, Set} from "typescript-collections";
import {SwalComponent} from "@toverux/ngx-sweetalert2";
import {ConfigurationTableService} from "@app/core/service/configuration-table.service";
import {Config} from "ngx-easy-table";
import {NotificatorTypePipe} from "@app/core/pipes/notificator-type.pipe";
import {NotificationEditComponent} from "@app/core/components/notification-edit/notification-edit.component";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";

@Component({
  selector: 'app-notification-bundle',
  templateUrl: './notification-bundle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./notification-bundle.component.css'],
  providers: [ConfigurationTableService]
})
export class NotificationBundleComponent implements OnInit, AfterViewInit {

  @Input()
  public device: Device;

  public searchOptions: Array<ISearchParam>;
  public isLoadingNotifications: boolean = false;

  @ViewChild(SearchTopBarComponent)
  appSearchTopBarComponent: SearchTopBarComponent;

  @Output()
  public onClose: EventEmitter<Notification> = new EventEmitter();

  public state: ModelPageState = "search";

  public notifications: Array<Notification | any>;
  public defaultNotifications: Array<Notification | any>;
  public deviceNotifications: Array<Notification>;

  public notificationTypes: Array<NotificationType>;
  public notificators: Array<Notificator>;
  public notificatorsTypes: Array<IModel>;
  public calendars: Array<Calendar>;

  public currentNotification: Notification | any;

  public pipe: NotificationTypePipe = new NotificationTypePipe();
  public notificatorPipe: NotificatorTypePipe = new NotificatorTypePipe();
  public notificationTypesOptions: Array<IModel>;

  filter: FilterNotificationBySearchParamPipe;

  // public viewMode: string = 'list';

  /* CURRENT USER*/
  public user: User;
  public role: string;
  public permissions: Dictionary<string, boolean> = new Dictionary<string, boolean>();

  /* SELECTION TO ACTIONS*/
  public selected: Set<number> = new Set<number>();
  /* smart table */
  public configuration: Config;
  public columns;

  private notificationPipe: NotificationTypePipe = new NotificationTypePipe();

  @ViewChild('notificatorsTemplate')
  private notificatorsTemplate: TemplateRef<any>;

  @ViewChild('buttonsTpl')
  public buttonsTpl: TemplateRef<any>;

  @ViewChild('toogleTpl')
  public toogleTpl: TemplateRef<any>;

  @ViewChild('iconTpl')
  public iconTpl: TemplateRef<any>;

  @ViewChild('confirmSwal') private confirmSwal: SwalComponent;
  @ViewChild('deleteSwal') private deleteSwal: SwalComponent;

  // ATTRIBUTES of notification
  public isVisibleAttributesCompact: boolean;

  @ViewChild(NotificationEditComponent) private editForm: NotificationEditComponent;

  // public canEditType: boolean = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private alertService: AlertService,
    private logger: NGXLogger) {

    this.filter = new FilterNotificationBySearchParamPipe();
    //DEFAULT OPTIONS
    this.searchOptions = [{
      name: 'Nombre',
      value: '',
      color: 'blue',
      field: 'name',
      type: 'text',
      remote: false
    }, {
      name: 'Tipo',
      value: '',
      color: 'green',
      field: 'type',
      type: 'select',
      options: [],
      selectValue: undefined,
      remote: false
    }, {
      name: 'Medio',
      value: '',
      color: 'blue',
      field: 'notificators',
      type: 'select',
      options: [],
      selectValue: undefined,
      remote: false
    }];
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    /*load data */
    // this.user = JSON.parse(localStorage.getItem("currentUser")) as User;
    this.user = LocalService.currentUser;
    this.role = this.user.attributes['role'];
    this.configuration = Object.assign({},ConfigurationTableService.config);
    this.loadPermissions();
  }

  public configureTableData() {
    if (isNullOrUndefined(this.device)) {
      this.columns = [
        {key: 'name', title: 'Nombre', width: '25%'},
        {key: 'nameType', title: 'Tipo', width: '20%'},
        {key: 'notificators', title: 'Medio', cellTemplate: this.notificatorsTemplate, width: '20%'},
        {key: 'always', title: '¿Todos los Dispositivos?', cellTemplate: this.iconTpl, width: '10%'},
        {key: 'calendar', title: 'Calendario', width: '20%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '15%'}
      ];
      this.configuration.checkboxes = false;
    } else {
      this.columns = [
        {key: 'name', title: 'Nombre', width: '25%'},
        {key: 'nameType', title: 'Tipo', width: '20%'},
        {key: 'notificators', title: 'Medio', cellTemplate: this.notificatorsTemplate, width: '15%'},
        {key: 'always', title: '¿Todos los Dispositivos?', cellTemplate: this.iconTpl, width: '10%'},
        {key: 'calendar', title: 'Calendario', width: '15%'},
        {key: 'id', title: 'Acciones', cellTemplate: this.buttonsTpl, orderEnabled: false, width: '15%'},
        {key: '', title: 'Vincular', cellTemplate: this.toogleTpl, orderEnabled: false, width: '10%'}
      ];
      this.configuration.checkboxes = true;
    }
    // this.cdr.detectChanges();
  }

  public loadData(fastMode?: boolean): void {
    // this.loadCalendars();
    //
    this.configureTableData();
    this.loadNotifications();
    //load Calendars
    if (!fastMode) {
      //load notification types
      this.loadNotificatorTypes();
      //load notificators
      this.loadNotificators();
      //loadAdminSearchParam
      this.loadAdminSearchParam();
    }
  }

  public loadAdminSearchParam() {
    if (!isNullOrUndefined(this.user)) {
      if (this.user.administrator) {
        //UserId
        let indexUserId = this.searchOptions.findIndex(value => value.field == 'userId');
        this.apiService.userService.getUser().subscribe(users => {
          let options: Array<IModel> = [];
          users.forEach(user => {
            options.push({id: user.id, name: user.name, display: user.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexUserId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Usuario',
              field: 'userId',
              value: '',
              color: 'violet',
              type: 'select',
              options: options,
              selectValue: {id: this.user.id, name: this.user.name, display: this.user.name},
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });

        //DEVICE
        let deviceIdIndex = this.searchOptions.findIndex(value => value.field == 'deviceId');
        this.apiService.deviceService.getDevices(this.role == 'root').subscribe(devices => {
          let deviceOptions: Array<IModel> = [];
          if (deviceIdIndex !== -1) {
            devices.forEach(device => {
              deviceOptions.push({id: device.id.toString(), name: device.uniqueId, display: device.name});
            });
            this.searchOptions[deviceIdIndex].options = deviceOptions;
          } else {
            this.searchOptions.push({
              name: 'Dispositivo',
              field: 'deviceId',
              value: '',
              color: 'yellow',
              type: 'select',
              options: deviceOptions,
              selectValue: undefined,
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });

        // Group
        let indexGroupId = this.searchOptions.findIndex(value => value.field == 'groupId');
        this.apiService.groupService.getGroups(this.role == 'root').subscribe(groups => {
          let options: Array<IModel> = [];
          groups.forEach(group => {
            options.push({id: group.id.toString(), name: group.id.toString(), display: group.name});
          });
          if (indexUserId !== -1) {
            this.searchOptions[indexGroupId].options = options;
          } else {
            this.searchOptions.push({
              name: 'Grupo',
              field: 'groupId',
              value: '',
              color: 'olive',
              type: 'select',
              options: options,
              remote: true
            });
          }
          //update
          this.appSearchTopBarComponent.updateSearchOptions(this.searchOptions);
          this.cdr.detectChanges();
        }, error1 => {
          this.alertService.error("Acceso Denegado", "Verique sus privigelios.");
          this.logger.error(error1);
        });
      }
    }
  }

  public loadPermissions() {
    this.permissions = this.apiService.roleService.notificationPermissions;
    this.cdr.detectChanges();
  }

  /* api */
  public loadNotifications(all: boolean = false) {
    this.isLoadingNotifications = true;
    //
    if (isNullOrUndefined(this.calendars)) {
      this.apiService.calendarService.getCalendars().subscribe(value => {
        this.calendars = value;
      }, error1 => {
        this.logger.error("Error al cargar los calendarios");
      }, () => {
        //load Devices Notifications
        if (!isNullOrUndefined(this.device)) {
          this.apiService.notificationService.getNotifications(false, null, this.device.id).subscribe(value => {
            this.deviceNotifications = value;
          }, error1 => {
            this.logger.error("Error al cargar las alertas del dispositivo");
          }, () => {
            //load notifications
            this.apiService.notificationService.getNotifications(all).subscribe(value => {
              const formatted = this.formatData(value);
              this.notifications = [...formatted];
              this.defaultNotifications = [...formatted];
            }, error1 => {
              this.alertService.error("Error al cargar datos", "Verifique la conectividad.");
              this.logger.error(error1.toString());
            }, () => {
              this.isLoadingNotifications = false;
              this.cdr.markForCheck();
            });
          });
        } else {
          //load notificationss
          this.apiService.notificationService.getNotifications(all).subscribe(value => {
            const formatted = this.formatData(value);
            this.notifications = [...formatted];
            this.defaultNotifications = [...formatted];
          }, error1 => {
            this.alertService.error("Error al cargar datos", "Verifique la conectividad.");
            this.logger.error(error1.toString());
          }, () => {
            this.isLoadingNotifications = false;
            this.cdr.markForCheck();
            // this.cdr.detectChanges();
            // this.cdr.reattach();
          });
        }
      });
    } else {
      if (!isNullOrUndefined(this.device)) {
        this.apiService.notificationService.getNotifications(false, null, this.device.id).subscribe(value => {
          this.deviceNotifications = value;
        }, error1 => {
          this.logger.error("Error al cargar las alertas del dispositivo");
        }, () => {
          //load notifications
          this.apiService.notificationService.getNotifications(all).subscribe(value => {
            const formatted = this.formatData(value);
            this.notifications = [...formatted];
            this.defaultNotifications = [...formatted];
          }, error1 => {
            this.alertService.error("Error al cargar datos", "Verifique la conectividad.");
            this.logger.error(error1.toString());
          }, () => {
            this.isLoadingNotifications = false;
            // this.cdr.detectChanges();
            // this.cdr.reattach();
            this.cdr.markForCheck();
          });
        });
      } else {
        //load notificationss
        this.apiService.notificationService.getNotifications(all).subscribe(value => {
          const formatted = this.formatData(value);
          this.notifications = [...formatted];
          this.defaultNotifications = [...formatted];
        }, error1 => {
          this.alertService.error("Error al cargar datos", "Verifique la conectividad.");
          this.logger.error(error1.toString());
        }, () => {
          this.isLoadingNotifications = false;
          // this.cdr.detectChanges();
          // this.cdr.reattach();
          this.cdr.markForCheck();
        });
      }
    }

  }

  public loadNotificationsForSearch(params: Array<ISearchParam>, all: boolean, userId: number, deviceId: number, groupId: number, refresh: boolean) {
    // this.logger.warn(`API -> ${JSON.stringify(params)}`);
    this.isLoadingNotifications = true;
    this.apiService.notificationService.getNotifications(all, userId, deviceId, groupId, refresh).subscribe(value => {
      // this.logger.warn(`API -> ${JSON.stringify(value)}`);
      const formatted = this.formatData(value);
      this.notifications = this.filter.transform(formatted, params);
      // this.logger.warn(`API -> ${JSON.stringify(this.notifications)}`);
    }, error1 => {
      this.alertService.error("Error al cargar datos", "Verifique la conectividad.");
      this.logger.error(error1.toString());
    }, () => {
      this.isLoadingNotifications = false;
      this.cdr.detectChanges();
    });
  }

  public loadNotificatorTypes() {
    this.apiService.notificationService.getNotificationsTypes().subscribe(value => {
      this.notificationTypes = value;
      let options: Array<IModel> = [];
      value.forEach(type1 => {
        options.push({
          id: type1.type,
          name: type1.type,
          display: this.pipe.transform(type1.type),
        });
      });
      this.notificationTypesOptions = options;
      this.searchOptions[1].options = options;
      //

      this.cdr.detectChanges();
    });
  }

  public loadNotificators() {
    this.apiService.notificationService.getNotificators().subscribe(value => {
      this.notificators = [];
      let options: Array<IModel> = [];
      value.forEach(type1 => {
        options.push({
          id: type1.type,
          name: type1.type,
          display: this.notificatorPipe.transform(type1.type),
        });
      });
      this.searchOptions[2].options = options;
      this.notificatorsTypes = options;
      this.cdr.detectChanges();
    });
  }

  public loadCalendars() {
    this.apiService.calendarService.getCalendars().subscribe(value => {
      this.calendars = value;
    });
  }

  public newNotification() {
    if (this.validate()) {
      this.apiService.notificationService.createNotification(this.currentNotification).subscribe(value => {
        this.alertService.success("Guardado!", "Alerta guardada con exito");
        //add new item and purge options to create.
        // this.notifications.push(value);
        this.loadData(true);
        // this.purgeNotificationTypes(value.type);
        this.state = "search";
        this.appSearchTopBarComponent.clearSearchParams();
      }, error1 => {
        this.alertService.error("Error al Guardar", `Se produjo un error al guardar`);
        this.logger.error(`Se produjo un error al guardar: ${error1.toString()}`);
      }, () => {
        this.cdr.detectChanges();
      });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  /* CRUD OPERATIONS */
  public editNotification() {
    if (this.validate()) {
      this.clearChunkData();
      this.apiService.notificationService.updateNotification(this.currentNotification.id, this.currentNotification).subscribe(value => {
          this.alertService.success("Actualizado", "La Alerta fue Actualizada con exito");
          this.state = "search";
          this.loadData(true);
          this.appSearchTopBarComponent.clearSearchParams();
        },
        error1 => {
          this.alertService.error("Error al Actualizar", `Se produjo un error al actualizar`);
          this.logger.error(`Se produjo un error al actualizar: ${error1.toString()}`);
        }, () => {
          this.cdr.detectChanges();
        });
    } else {
      this.alertService.error("Existen campos sin llenar", "Llene todos los campos requeridos");
    }
  }

  public deleteNotification() {
    if (this.validate()) {
      this.apiService.notificationService.deleteNotification(this.currentNotification.id).subscribe(value => {
          this.alertService.success("Eliminado", "La Notificacion fue Eliminada con exito");
          this.loadData(true);
          this.state = "search";
          this.appSearchTopBarComponent.clearSearchParams();
        },
        error1 => {
          this.alertService.error("Error al Eliminar", `Se produjo un error al eliminar`);
          this.logger.error(`Se produjo un error al eliminar: ${error1.toString()}`);
        });
    } else {
      this.alertService.error("Error al Eliminar", "Se ha corrompido el sistema, por favor actualice.");
    }
  }

  //on save/update
  public onSubmit() {
    this.currentNotification = this.editForm.getInfo();
    // this.logger.info("SAVE"+JSON.stringify(this.currentNotification));
    this.prepareData();
    if (this.state == "new") {
      this.newNotification();
      //this.logger.info("SAVE"+JSON.stringify(this.currentNotification));
    } else {
      this.editNotification();
      //this.logger.info("UPDATE"+JSON.stringify(this.currentNotification));
    }
  }

  public validate(): boolean {
    if (isNullOrUndefined(this.currentNotification))
      return false;
    // return (!isNullOrUndefined(this.currentNotification.notificators) && !isNullOrUndefined(this.currentNotification.type))
    return !!(this.currentNotification.notificators && this.currentNotification.type);

  }

  //search
  onSearch(params: Array<ISearchParam>) {
    if (params.findIndex(value => value.remote == true) !== -1)
      this.doSearch(params);//do the search if need it. if params has remote=true, we will call the api.
    else
      this.notifications = this.filter.transform(this.defaultNotifications, params);
  }

  public doSearch(params: Array<ISearchParam>) {

    let deviceId: number;
    let groupId: number;
    let userId: number;
    let search: boolean;

    params.forEach(param => {
      if (param.remote) {
        if (param.field == "deviceId" && param.selectValue.id) {
          deviceId = Number(param.selectValue.id);
          // this.logger.info("deviceId: "+deviceId);
        }
        if (param.field == "groupId" && param.selectValue.id) {
          groupId = Number(param.selectValue.id);
          // this.logger.info("groupId: "+groupId);
        }
        if (param.field == "userId" && param.selectValue.id) {
          userId = Number(param.selectValue.id);
          // this.logger.info("userId: "+userId);
        }
        search = true;
      }
    });
    if (search) {
      this.loadNotificationsForSearch(params, false, userId, deviceId, groupId, false);
    }
  }

  /* actions */

  public isNotificationOn(id: number): boolean {
    if (!isNullOrUndefined(this.deviceNotifications))
      return this.deviceNotifications.findIndex(value => value.id == id) !== -1;
    else
      return false;
  }

  public toogleNotification(id: number, state: boolean) {
    if (state) {//existe.. apagamos.
      this.apiService.permissionService.permissionsPost({
        deviceId: this.device.id,
        notificationId: id
      }).subscribe(value => {
        this.appSearchTopBarComponent.clearSearchParams();
      }, error1 => {
        this.alertService.error('Error al activar la alerta en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al activar la alerta en el dispositivo. ' + error1.toString());
      },() => {
        // this.loadData(true);
      });
    } else {//no existe.. agregamos
      this.apiService.permissionService.permissionsDelete({
        deviceId: this.device.id,
        notificationId: id
      }).subscribe(value => {
        this.appSearchTopBarComponent.clearSearchParams();
      }, error1 => {
        this.alertService.error('Error al desactivar la alerta en el dispositivo', 'Verifique sus credenciales.');
        this.logger.error('Error al desactivar la alerta en el dispositivo. ' + error1.toString());
      },() => {
        // this.loadData(true);
      });
    }
    this.state = 'search';
  }

  public addNewNotification(): void {
    this.state = 'new';
    this.currentNotification = new Notification();
    this.currentNotification.always = true;
  }

  public close(): void {
    this.currentNotification = new Notification();
    this.appSearchTopBarComponent.clearSearchParams();
    this.device = undefined;
    this.onClose.emit(this.currentNotification);
  }

  public clearSelection(): void {
    this.currentNotification = new Notification();
    this.state = "search";
  }

  public onCloseAttribs(attribs): void {
    this.isVisibleAttributesCompact = false;
    if (!isNullOrUndefined(attribs)) {
      this.currentNotification.attributes = attribs;
      this.editNotification();
      // this.alertService.success("Guardado", "Atributos Actualizados");
    }
  }

  public edit(id: number): void {
    this.selectNotificationById(id);
    this.state = 'edit';
  }

  public openAttributes(id: number): void {
    this.selectNotificationById(id);
    this.isVisibleAttributesCompact = true;
  }

  /**/
  public toogleAll(flag: boolean): void {
    if (flag) {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceNotifications.findIndex(current => current.id == value);
        if (index === -1) { //no existe agregamos.
          valuesTo.push({
            deviceId: this.device.id,
            notificationId: value
          });
        }
      });
      this.savePermissions(valuesTo);
    } else {
      let valuesTo = [];
      this.selected.forEach(value => {
        let index = this.deviceNotifications.findIndex(current => current.id == value);
        if (index !== -1) { //si existe eliminamos.
          valuesTo.push({
            deviceId: this.device.id,
            notificationId: value
          });
        }
      });
      this.removePermissions(valuesTo);
    }
  }

  /* permissions */
  private savePermissions(notifications) {
    this.apiService.permissionService.permissionsArrayPost(notifications).subscribe(value => {
    }, error1 => {
      this.logger.error(`Error al vincular las alertas: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Guardado', 'Las alertas han sido vinculadas.');
      //reload data
      this.loadData(true);
    });
  }

  private removePermissions(notifications) {
    this.apiService.permissionService.permissionsArrayDelete(notifications).subscribe(value => {
    }, error1 => {
      this.logger.error(`Error al desvincular las alertas: ${error1.toString()}`);
    }, () => {
      this.alertService.success('Eliminado', 'Las alertas han sido desvinculadas.');
      //reload data
      this.loadData(true);
    });
  }


  public onCancelEdit() {
    this.confirmSwal.show();
  }

  public openEdit(notification: Notification, refresh: boolean = false) {
    this.currentNotification = notification;
    this.state = 'edit';
    this.selected.clear();
    if (refresh) {
      this.cdr.detectChanges();
    }
  }

  public openDeleteDialog(id: number): void {
    this.selectNotificationById(id);
    this.deleteSwal.show();
  }

  public selectNotificationById(id: number) {
    this.currentNotification = Object.assign({},this.notifications.find(value => value.id == id));
  }

  /*events*/
  public onClickRow(event) {
    switch (event.event) {
      case 'onCheckboxSelect'://check for selected or not
        if (event.value.event.target.checked) { //existe
          this.selected.add(event.value.row.id);
        } else { //no existe
          this.selected.remove(event.value.row.id);
        }
        break;
      case 'onDoubleClick':
        this.openEdit(event.value.row);
        break;
      case 'onSelectAll':
        if (event.value == true) {
          this.notifications.forEach(value => {
            this.selected.add(value.id);
          });
        }
        else {
          this.selected.clear();
        }
        break;
    }
  }

  private formatData(notifications: Array<Notification>): Array<any> {
    let formatted = [];
    if (isNullOrUndefined(this.device)) {
      notifications.forEach(value => {
        formatted.push({
          id: value.id,
          name: value.name,
          type: value.type,
          nameType: this.notificationPipe.transform(value.type),
          notificators: value.notificators,
          always: value.always,
          calendarId: value.calendarId,
          calendar: this.getCalendarName(value.calendarId, value.always),
          attributes: value.attributes
        });
      });
    } else {
      notifications.forEach(value => {
        formatted.push({
          id: value.id,
          name: value.name,
          type: value.type,
          nameType: this.notificationPipe.transform(value.type),
          notificators: value.notificators,
          always: value.always,
          calendarId: value.calendarId,
          calendar: this.getCalendarName(value.calendarId, value.always),
          attributes: value.attributes,
          isVisible: this.isNotificationOn(value.id)
        });
      });
    }
    this.cdr.markForCheck();
    return formatted;
  }

  public clearChunkData(): void {
    delete this.currentNotification.nameType;
    delete this.currentNotification.calendar;
    delete this.currentNotification.isVisible;
  }

  public prepareData(): void {
    if (this.currentNotification.always) {
      this.currentNotification.calendarId = 0;
    }
  }

  private getCalendarName(calendarId: number, always: boolean): string {
    if (always) {
      return 'Siempre Activo';
    } else {
      if (!isNullOrUndefined(this.calendars)) {
        const calendar = this.calendars.find(value => value.id == calendarId);
        if (!isNullOrUndefined(calendar)) {
          return calendar.name;
        } else {
          return 'Sin Asignar';
        }
      } else {
        return 'Sin Asignar';
      }
    }
  }
}
