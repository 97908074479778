import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CoreRoutingModule} from './core-routing.module';
import {PositionInfoTableComponent} from './components/position-info-table/position-info-table.component';

import {SuiModule} from 'ng2-semantic-ui';
import {SharedModule} from "@app/shared/shared.module";
import {DevicePageDimmerSelectorComponent} from './components/device-page-dimmer-selector/device-page-dimmer-selector.component';
import {FilterDeviceByNamePipe} from './pipes/filter-device-by-name.pipe';
import {FilterMarkerByDeviceIdPipe} from './pipes/filter-marker-by-device-id.pipe';
import {BuhoMarkerComponent} from './components/buho-marker/buho-marker.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ComputatedAttributesPipe} from './pipes/computated-attributes.pipe';
import {MomentModule} from "ngx-moment";
import 'moment/locale/es';
import {CounterLimitPipe} from './pipes/counter-limit.pipe';
import {FilterDeviceBySearchParamPipe} from './pipes/filter-device-by-search-param.pipe';
import {SweetAlert2Module} from "@toverux/ngx-sweetalert2";
import {NotificationBundleComponent} from './components/notification-bundle/notification-bundle.component';
import {NotificationEditComponent} from './components/notification-edit/notification-edit.component';
import {NotificationTypePipe} from './pipes/notification-type.pipe';
import {FilterNotificationTypeByNotificationsPipe} from './pipes/filter-notification-type-by-notifications.pipe';
import {SortNotificationByNamePipe} from './pipes/sort-notification-by-name.pipe';
import {FilterNotificationBySearchParamPipe} from './pipes/filter-notification-by-search-param.pipe';
import {AttributesCompactComponent} from './components/attributes-compact/attributes-compact.component';
import {FilterAttribsBySearchParamPipe} from './pipes/filter-attribs-by-search-param.pipe';
import {DeviceBundleComponent} from './components/device-bundle/device-bundle.component';
import {SortDeviceByNamePipe} from './pipes/sort-device-by-name.pipe';
import {DeviceEditComponent} from './components/device-edit/device-edit.component';
import {NotificationSelectComponent} from './components/notification-select/notification-select.component';
import {GeofenceBundleComponent} from './components/geofence-bundle/geofence-bundle.component';
import {FilterGeofenceBySearchParamPipe} from './pipes/filter-geofence-by-search-param.pipe';
import {GeofenceEditComponent} from './components/geofence-edit/geofence-edit.component';
import {EventDisplayComponent} from './components/event-display/event-display.component';
import {SortEventByNamePipe} from './pipes/sort-event-by-name.pipe';
import {FilterEventBySearchParamPipe} from './pipes/filter-event-by-search-param.pipe';
import {ReportBundleComponent} from './components/report-bundle/report-bundle.component';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ReportEventBundleComponent} from './components/report-event-bundle/report-event-bundle.component';
import {ReportTypePickerComponent} from './components/report-type-picker/report-type-picker.component';
import {PaginatePipe} from './pipes/paginate.pipe';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { ReportRouteBundleComponent } from './components/report-route-bundle/report-route-bundle.component';
import { DeviceSideBarComponent } from './components/device-side-bar/device-side-bar.component';
import { StatusDevicePipe } from './pipes/status-device.pipe';
import { SpeedPipe } from './pipes/speed.pipe';
import { FilterDeviceByPipe } from './pipes/filter-device-by.pipe';
import { NotificationLiteComponent } from './components/notification-lite/notification-lite.component';
import { SortGeofenceByNamePipe } from './pipes/sort-geofence-by-name.pipe';
import { GroupBundleComponent } from './components/group-bundle/group-bundle.component';
import { FilterGroupBySearchParamPipe } from './pipes/filter-group-by-search-param.pipe';
import { SortGroupByNamePipe } from './pipes/sort-group-by-name.pipe';
import { PermissionBundleComponent } from './components/permission-bundle/permission-bundle.component';
import { DriverBundleComponent } from './components/driver-bundle/driver-bundle.component';
import { FilterDriverBySearchParamPipe } from './pipes/filter-driver-by-search-param.pipe';
import { SortDriverByNamePipe } from './pipes/sort-driver-by-name.pipe';
import { MaintenanceBundleComponent } from './components/maintenance-bundle/maintenance-bundle.component';
import { FilterMaintenanceBySearchParamPipe } from './pipes/filter-maintenance-by-search-param.pipe';
import { PositionAttributePipe } from './pipes/position-attribute.pipe';
import { SortMaintenanceByNamePipe } from './pipes/sort-maintenance-by-name.pipe';
import { CalendarBundleComponent } from './components/calendar-bundle/calendar-bundle.component';
import { SortCalendarByNamePipe } from './pipes/sort-calendar-by-name.pipe';
import { FilterCalendarBySearchParamPipe } from './pipes/filter-calendar-by-search-param.pipe';
import { UserBundleComponent } from './components/user-bundle/user-bundle.component';
import { FilterUserBySearchParamPipe } from './pipes/filter-user-by-search-param.pipe';
import { SortUserByNamePipe } from './pipes/sort-user-by-name.pipe';
import { ProfileBundleComponent } from './components/profile-bundle/profile-bundle.component';
import {TableModule} from "ngx-easy-table";
import { NotificatorTypePipe } from './pipes/notificator-type.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { TotalDistancePipe } from './pipes/total-distance.pipe';
import { HoursPipe } from './pipes/hours.pipe';
import { HoursMinSegPipe } from './pipes/hours-min-seg.pipe';
import { RolePipe } from './pipes/role.pipe';
import {AgmOverlays} from "agm-overlays";
import {AgmCoreModule, GoogleMapsAPIWrapper} from "@agm/core";
import {AgmJsMarkerClustererModule} from "@agm/js-marker-clusterer";
import {AgmSnazzyInfoWindowModule} from "@agm/snazzy-info-window";
import {ReportStopBundleComponent} from "@app/core/components/report-stop-bundle/report-stop-bundle.component";
import {ReportTripBundleComponent} from "@app/core/components/report-trips-bundle/report-trip-bundle.component";
import {ReportSummaryBundleComponent} from "@app/core/components/report-summary-bundle/report-summary-bundle.component";
import {AttributesBundleComponent} from "@app/core/components/attributes-bundle/attributes-bundle.component";

@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,
    SuiModule,
    ReactiveFormsModule,
    SharedModule,
    MomentModule,
    SweetAlert2Module,
    TableModule,
    NgxDatatableModule,
    NgxChartsModule,
    ColorPickerModule,
    AgmOverlays,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
  ],
  providers: [GoogleMapsAPIWrapper],
  declarations: [
    PositionInfoTableComponent,
    DevicePageDimmerSelectorComponent,
    FilterDeviceByNamePipe,
    FilterMarkerByDeviceIdPipe,
    BuhoMarkerComponent,
    ComputatedAttributesPipe,
    CounterLimitPipe,
    FilterDeviceBySearchParamPipe,
    NotificationBundleComponent,
    NotificationEditComponent,
    NotificationTypePipe,
    FilterNotificationTypeByNotificationsPipe,
    SortNotificationByNamePipe,
    FilterNotificationBySearchParamPipe,
    AttributesCompactComponent,
    FilterAttribsBySearchParamPipe,
    DeviceBundleComponent,
    SortDeviceByNamePipe,
    DeviceEditComponent,
    NotificationSelectComponent,
    GeofenceBundleComponent,
    FilterGeofenceBySearchParamPipe,
    GeofenceEditComponent,
    EventDisplayComponent,
    SortEventByNamePipe,
    FilterEventBySearchParamPipe,
    ReportBundleComponent,
    ReportEventBundleComponent,
    ReportTypePickerComponent,
    PaginatePipe,
    ReportRouteBundleComponent,
    ReportStopBundleComponent,
    ReportTripBundleComponent,
    ReportSummaryBundleComponent,
    DeviceSideBarComponent,
    StatusDevicePipe,
    SpeedPipe,
    FilterDeviceByPipe,
    NotificationLiteComponent,
    SortGeofenceByNamePipe,
    GroupBundleComponent,
    FilterGroupBySearchParamPipe,
    SortGroupByNamePipe,
    PermissionBundleComponent,
    DriverBundleComponent,
    FilterDriverBySearchParamPipe,
    SortDriverByNamePipe,
    MaintenanceBundleComponent,
    AttributesBundleComponent,
    FilterMaintenanceBySearchParamPipe,
    PositionAttributePipe,
    SortMaintenanceByNamePipe,
    CalendarBundleComponent,
    SortCalendarByNamePipe,
    FilterCalendarBySearchParamPipe,
    UserBundleComponent,
    FilterUserBySearchParamPipe,
    SortUserByNamePipe,
    ProfileBundleComponent,
    NotificatorTypePipe,
    TotalDistancePipe,
    HoursPipe,
    RolePipe,
    HoursMinSegPipe
  ],
  exports: [
    PositionInfoTableComponent,
    DevicePageDimmerSelectorComponent,
    NotificationBundleComponent,
    FilterDeviceByNamePipe,
    FilterMarkerByDeviceIdPipe,
    BuhoMarkerComponent,
    ComputatedAttributesPipe,
    CounterLimitPipe,
    NotificationEditComponent,
    NotificationTypePipe,
    FilterNotificationTypeByNotificationsPipe,
    SortNotificationByNamePipe,
    FilterNotificationBySearchParamPipe,
    AttributesCompactComponent,
    FilterAttribsBySearchParamPipe,
    DeviceBundleComponent,
    SortDeviceByNamePipe,
    DeviceEditComponent,
    NotificationSelectComponent,
    GeofenceBundleComponent,
    FilterGeofenceBySearchParamPipe,
    GeofenceEditComponent,
    EventDisplayComponent,
    SortEventByNamePipe,
    FilterEventBySearchParamPipe,
    ReportBundleComponent,
    ReportEventBundleComponent,
    ReportTypePickerComponent,
    PaginatePipe,
    ReportRouteBundleComponent,
    ReportStopBundleComponent,
    ReportTripBundleComponent,
    ReportSummaryBundleComponent,
    DeviceSideBarComponent,
    StatusDevicePipe,
    SpeedPipe,
    FilterDeviceByPipe,
    NotificationLiteComponent,
    GroupBundleComponent,
    BuhoMarkerComponent,
    FilterGroupBySearchParamPipe,
    SortGroupByNamePipe,
    PermissionBundleComponent,
    DriverBundleComponent,
    FilterDriverBySearchParamPipe,
    SortDriverByNamePipe,
    MaintenanceBundleComponent,
    AttributesBundleComponent,
    FilterMaintenanceBySearchParamPipe,
    PositionAttributePipe,
    SortMaintenanceByNamePipe,
    CalendarBundleComponent,
    SortCalendarByNamePipe,
    UserBundleComponent,
    FilterUserBySearchParamPipe,
    ProfileBundleComponent,
    NotificatorTypePipe,
    TotalDistancePipe,
    HoursPipe,
    RolePipe
  ],
  entryComponents: [ReportEventBundleComponent, ReportTypePickerComponent, ReportRouteBundleComponent, ReportStopBundleComponent, ReportTripBundleComponent, ReportSummaryBundleComponent]
})
export class CoreModule {
}
