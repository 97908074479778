export class AttributeComputated {
  id?: number;
  description?: string;
  attribute?: string;
  expression?: string;
  /**
   * String|Number|Boolean
   */
  type?: string;
}
