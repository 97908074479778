import {Injectable} from '@angular/core';
import {BaseService} from "@app/core/service/base.service";
import {HttpClient} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Observable} from "rxjs";
import {isNullOrUndefined} from "util";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseService {

  constructor(httpClient: HttpClient, logger: NGXLogger) {
    super(httpClient, logger);
  }

  /**
   * There's no model for permission, because we sent the a pair of 2 keys.
   * Ex: { userId: '1', deviceId:'2'}
   * Ex: { deviceId:'2', notificationId: '11'}
   * */


  /**
   * Unlink an Object from another Object
   *
   * @param body
   */
  public permissionsDelete(body: any): Observable<any> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling permissionsDelete.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    let options = {
      headers: headers,
      body: body,
      withCredentials: true
    };

    return this.httpClient.delete<any>(`${this.basePath}${this.REST_URLS.permissions}`,
      options
    ).pipe(
      catchError(this.handleError('delete', null))
    );
  }

  /**
   * Link an Object to another Object
   *
   * @param body
   */
  public permissionsPost(body: any): Observable<any> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling permissionsPost.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<any>(`${this.basePath}${this.REST_URLS.permissions}`,
      body,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }

  /**
   * Unlink an Object from another Object
   * Accepts an array
   *
   * @param body
   */
  public permissionsArrayDelete(body: any): Observable<any> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling permissionsArrayDelete.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    let options = {
      headers: headers,
      withCredentials: true
    };

    return this.httpClient.put<any>(`${this.basePath}${this.REST_URLS.permissionsArray}`, body,
      options
    ).pipe(
      catchError(this.handleError('put', null))
    );
  }

  /**
   * Link an Object to another Object
   *
   * @param body
   */
  public permissionsArrayPost(body: any): Observable<any> {
    if (isNullOrUndefined(body)) {
      throw new Error('Required parameter body was null or undefined when calling permissionsArrayPostt.');
    }

    let headers = this.addBasicAuth();
    headers.set("Accept", "application/json");

    return this.httpClient.post<any>(`${this.basePath}${this.REST_URLS.permissionsArray}`,
      body,
      {
        headers: headers,
        withCredentials: true
      }
    ).pipe(
      catchError(this.handleError('post', null))
    );
  }
}
