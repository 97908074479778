import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {User} from "@app/core/models/user.model";
import {ApiService} from "@app/shared/services/api.service";
import {AlertService} from "@app/shared/services/alert.service";
import {NGXLogger} from "ngx-logger";
import {WebConfiguration} from "@app/core/models/web-configuration.model";
// @ts-ignore
import {isNullOrUndefined} from "util";
import {WebConfigurationService} from "@app/shared/services/web-configuration.service";
import {IModel} from "@app/core/interfaces/imodel.interface";
import {Router} from "@angular/router";
import {SessionService} from "@app/core/service/session.service";
import {LocalService} from "@app/core/service/local.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-profile-bundle',
  templateUrl: './profile-bundle.component.html',
  styleUrls: ['./profile-bundle.component.css']
})
export class ProfileBundleComponent implements OnInit {

  @Output()
  public onClose: EventEmitter<any> = new EventEmitter();

  public currentUser: User;
  //formatted value
  // public currentUser: User;

  // public password: string = '';
  // public repassword: string = '';

  // public reconnectTime: string;

  public editForm: FormGroup;
  public passwordForm: FormGroup;
  public reconnectTime: string = '';
  public reconnectOn : boolean = false;

  get formFields() {
    return this.editForm.controls;
  }

  get passwordFormFields() {
    return this.passwordForm.controls;
  }

  public configuration: WebConfiguration;

  /*map*/
  public selectedMapType: IModel;
  public mapTypes: Array<IModel> = [];

  public selectedMapStyle: IModel;
  public mapThemes: Array<IModel> = [];

  private buhoStyles = [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff"
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 13
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000"
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#144b53"
        },
        {
          lightness: 14
        },
        {
          weight: 1.4
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#08304b"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#0c4152"
        },
        {
          lightness: 5
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#0b434f"
        },
        {
          lightness: 25
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#0b3d51"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          color: "#146474"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#021019"
        }
      ]
    }
  ];
  private whiteStyle = [
    {
      elementType: "geometry",
      stylers: [{color: "#f5f5f5"}]
    }, {
      elementType: "labels.icon",
      stylers: [{"visibility": "off"}]
    }, {elementType: "labels.text.fill", stylers: [{color: "#616161"}]}, {
      elementType: "labels.text.stroke",
      stylers: [{color: "#f5f5f5"}]
    }, {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{color: "#bdbdbd"}]
    }, {featureType: "poi", elementType: "geometry", stylers: [{color: "#eeeeee"}]}, {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}]
    }, {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{color: "#e5e5e5"}]
    }, {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}]
    }, {
      featureType: "road",
      elementType: "geometry",
      stylers: [{color: "#ffffff"}]
    }, {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}]
    }, {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{color: "#dadada"}]
    }, {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}]
    }, {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}]
    }, {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{color: "#e5e5e5"}]
    }, {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{color: "#eeeeee"}]
    }, {featureType: "water", elementType: "geometry", stylers: [{color: "#c9c9c9"}]}, {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}]
    }];
  private retroStyle = [
    {
      elementType: "geometry",
      stylers: [{color: "#ebe3cd"}]
    }, {elementType: "labels.text.fill", stylers: [{color: "#523735"}]}, {
      elementType: "labels.text.stroke",
      stylers: [{color: "#f5f1e6"}]
    }, {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [{color: "#c9b2a6"}]
    }, {
      featureType: "administrative.land_parcel",
      elementType: "geometry.stroke",
      stylers: [{color: "#dcd2be"}]
    }, {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{color: "#ae9e90"}]
    }, {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [{color: "#dfd2ae"}]
    }, {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{color: "#dfd2ae"}]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{color: "#93817c"}]
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [{color: "#a5b076"}]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{color: "#447530"}]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{color: "#f5f1e6"}]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{color: "#fdfcf8"}]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{color: "#f8c967"}]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{color: "#e9bc62"}]
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [{color: "#e98d58"}]
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry.stroke",
      stylers: [{color: "#db8555"}]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{color: "#806b63"}]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{color: "#dfd2ae"}]
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.fill",
      stylers: [{color: "#8f7d77"}]
    },
    {
      featureType: "transit.line",
      elementType: "labels.text.stroke",
      stylers: [{color: "#ebe3cd"}]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{color: "#dfd2ae"}]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [{color: "#b9d3c2"}]
    }, {featureType: "water", elementType: "labels.text.fill", stylers: [{color: "#92998d"}]}];
  private nightStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#242f3e"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#746855"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#242f3e"
        }
      ]
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#d59563"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#d59563"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#263c3f"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#6b9a76"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#38414e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#212a37"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9ca5b3"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#746855"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#1f2835"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#f3d19c"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#2f3948"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#d59563"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#17263c"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#515c6d"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#17263c"
        }
      ]
    }
  ];
  private mikeStyle = [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        {
          "visibility": "on"
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        {
          "visibility": "off"
        },
        {
          "saturation": "-100"
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          "saturation": 36
        },
        {
          color: "#000000"
        },
        {
          lightness: 40
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          "visibility": "off"
        },
        {
          color: "#000000"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 17
        },
        {
          weight: 1.2
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#4d6059"
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4d6059"
        }
      ]
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#4d6059"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          lightness: 21
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#4d6059"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4d6059"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          "visibility": "on"
        },
        {
          color: "#7f8d89"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#7f8d89"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#7f8d89"
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#7f8d89"
        },
        {
          lightness: 29
        },
        {
          weight: 0.2
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 18
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#7f8d89"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#7f8d89"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#7f8d89"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#7f8d89"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 19
        }
      ]
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#2b3638"
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#2b3638"
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#24282b"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#24282b"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.icon",
      stylers: [
        {
          "visibility": "off"
        }
      ]
    }
  ];

  constructor(private apiService: ApiService,
              private alertService: AlertService,
              private configService: WebConfigurationService,
              private fb: FormBuilder,
              private logger: NGXLogger) {
    this.mapThemes.push({id: 'default', name: 'Google'});
    this.mapThemes.push({id: 'night', name: 'Nocturno'});
    this.mapThemes.push({id: 'retro', name: 'Retro'});
    this.mapThemes.push({id: 'white', name: 'Blanco/Negro'});
    this.mapThemes.push({id: 'mike', name: 'Verde Sin Etiquetas'});
    this.mapThemes.push({id: 'buho', name: 'Buho Nocturno'});
    //map types
    this.mapTypes.push({id: 'roadmap', name: 'Carreteras'});
    this.mapTypes.push({id: 'satellite', name: 'Satelital'});
    this.mapTypes.push({id: 'hybrid', name: 'Hibrido'});
    this.mapTypes.push({id: 'terrain', name: 'Terrestre'});
    //create forms
    this.editForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
      login: ['', [Validators.required, Validators.maxLength(128)]],
      phone: ['', [Validators.required, Validators.pattern("^(\\d{7}|\\d{8})")]],
    });

    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
      repassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    });
  }

  ngOnInit() {
    const currentUser = Object.assign({},LocalService.currentUser);
    this.reloadData(currentUser);
  }

  public close(): void {
    this.onClose.emit();
  }

  public onSubmit(): void {
    if(this.isValid()) {
      this.updateUser();
    }else{
      this.alertService.error("Hay Campos requeridos sin llenar","Verifique e intente nuevamente.");
    }
  }

  public reloadConfigurations(): void {
    if (isNullOrUndefined(this.currentUser.webConfiguration)) {
      this.configuration = WebConfiguration.createFromUser(this.currentUser);
    } else {
      this.configuration = WebConfiguration.createFromSanitizedString(this.currentUser.webConfiguration);
    }
    //select things
    if (!isNullOrUndefined(this.configuration.mapOptions)) {
      //map options
      if (!isNullOrUndefined(this.configuration.mapOptions.theme)) {
        this.selectedMapStyle = this.mapThemes.find(value => value.id == this.configuration.mapOptions.theme);
      } else {
        this.selectedMapStyle = this.mapThemes[0];
      }
      //map options
      if (!isNullOrUndefined(this.configuration.mapOptions.mapTypeId)) {
        this.selectedMapType = this.mapTypes.find(value => value.id == this.configuration.mapOptions.mapTypeId);
      } else {
        this.selectedMapType = this.mapTypes[0];
      }
    }
  }

  public updateConfigurations(): void {
    this.currentUser.webConfiguration = JSON.stringify(this.configuration.IWebConfig);
    this.currentUser.password = null;
    this.apiService.userService.updateUser(this.currentUser.id, this.currentUser).subscribe(value => {
      this.reloadData(value);
      this.alertService.success('Preferencias Actualizadas', 'Se han actualizado sus preferencias');
    }, error1 => {
      this.alertService.error("Se produjo un error", "No se pudo actualizar los datos");
    }, () => {
      this.configService.updateConfig(this.configuration);
    });
  }

  public updateUser(): void {

    // this.logger.warn(`USUARIO: ${JSON.stringify(this.currentUser)}`);
    // this.logger.warn(`SETTINGS: ${JSON.stringify(this.currentUser.attributes)}`);
    this.fillFromForm();
    this.currentUser.webConfiguration = JSON.stringify(this.configuration.IWebConfig);
    this.currentUser.password = null;
    this.apiService.userService.updateUser(this.currentUser.id, this.currentUser).subscribe(value => {
      LocalService.currentUser.login = value.login;
      LocalService.currentUser.email = value.email;
      this.reloadData(value);
      this.alertService.success('Datos Actualizados', 'Sus datos han sido actualizados.');
    }, error1 => {
      this.alertService.error("Se produjo un error", "No se pudo actualizar los datos: ");
      this.logger.error(error1);
    }, () => {
      this.configService.updateConfig(this.configuration);
      console.log("DATOS ACTUALIZADOS");
      this.close();
    });
  }

  public changeMapStyle(theme: IModel) {
    switch (theme.id) {
      case 'default':
        this.configuration.mapOptions.styles = [];
        break;
      case 'night':
        this.configuration.mapOptions.styles = this.nightStyle;
        break;
      case 'retro':
        this.configuration.mapOptions.styles = this.retroStyle;
        break;
      case 'white':
        this.configuration.mapOptions.styles = this.whiteStyle;
        break;
      case 'mike':
        this.configuration.mapOptions.styles = this.mikeStyle;
        break;
      case 'buho':
        this.configuration.mapOptions.styles = this.buhoStyles;
        break;
      default:
        this.configuration.mapOptions.styles = [];
        break;
    }
    this.configuration.mapOptions.theme = theme.id;
  }

  public changeMapType(type: IModel){
    this.configuration.mapOptions.mapTypeId = type.id;
  }

  private reloadData(value: User) {
    if (!isNullOrUndefined(value)) {
      this.currentUser = value;
      if (isNullOrUndefined(value.webConfiguration)) {
        this.configuration = WebConfiguration.createFromUser(value);
      } else {
        this.configuration = WebConfiguration.createFromSanitizedString(value.webConfiguration);
      }
      //select things
      if (!isNullOrUndefined(this.configuration.mapOptions)) {
        //map options
        if (!isNullOrUndefined(this.configuration.mapOptions.theme)) {
          this.selectedMapStyle = this.mapThemes.find(value => value.id == this.configuration.mapOptions.theme)
        } else {
          this.selectedMapStyle = this.mapThemes[0];
        }//map options
        if (!isNullOrUndefined(this.configuration.mapOptions.mapTypeId)) {
          this.selectedMapType = this.mapTypes.find(value => value.id == this.configuration.mapOptions.mapTypeId)
        } else {
          this.selectedMapType = this.mapTypes[0];
        }
      }
      //fill form
      this.fillForm(value);
    }
  }

  public changePassword(): void {
    if (this.passwordForm.valid && this.passwordFormFields.password.value == this.passwordFormFields.repassword.value) {
      this.currentUser.password = this.passwordFormFields.password.value;
      this.apiService.userService.updateUser(this.currentUser.id, this.currentUser).subscribe(value => {
        LocalService.currentUser.password = btoa(this.passwordFormFields.password.value);
        this.alertService.success("Contraseña Actualizada", "Su contraseña fue actualizada.");
      }, error1 => {
        this.alertService.error("Error al Actualizar", "La Contraseña no pudo ser actualizada.");
        this.logger.error("Se ha producido un error al actualizar la contrasena: " + error1.toString());
      }, () => {
        this.passwordForm.reset();
      });
    }
    else {
      this.alertService.error("Contraseña Incorrecta", "La contraseña no coincide, verifique.");
      this.passwordForm.reset();
    }
  }

  public getReconectTime():string {
    if(this.configuration.reconnectTimeout > 59999 ){
      const minutes = (this.configuration.reconnectTimeout/60000).toFixed(0);
      const seconds = (this.configuration.reconnectTimeout%60000)/1000;
      if(seconds > 0 && seconds < 60) {
        if(seconds>=30)
          return `${Number(minutes)-1} min. ${seconds} seg.`;
        else
          return `${minutes} min. ${seconds} seg.`;
      }
      else
        return `${minutes} min.`;
    }else{
      return `${this.configuration.reconnectTimeout/1000} seg.`;
    }
  }

  public calculateReconnectTime() : void {
    this.reconnectTime = this.getReconectTime();
    this.reconnectOn =  this.configuration.reconnectTimeout > 0;
  }

  private fillForm(user: User) : void {
    this.formFields.name.setValue(user.name);
    this.formFields.login.setValue(user.login);
    this.formFields.email.setValue(user.email);
    this.formFields.phone.setValue(user.phone);
    this.reconnectOn =  this.configuration.reconnectTimeout > 0;
    // this.reconnectTime = this.getReconectTime();
  }

  private fillFromForm() : void {
    this.currentUser.name = this.formFields.name.value;
    this.currentUser.login = this.formFields.login.value;
    this.currentUser.email = this.formFields.email.value;
    this.currentUser.phone = this.formFields.phone.value;
  }

  private isValid() : boolean {
    return this.editForm.valid;
  }
}
