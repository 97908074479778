import { Pipe, PipeTransform } from '@angular/core';
import {isNullOrUndefined} from "@swimlane/ngx-datatable/release/utils";

@Pipe({
  name: 'hoursMinSeg'
})
export class HoursMinSegPipe implements PipeTransform {

  transform(value: string, args?: string): string {
    if(!isNullOrUndefined(value)){
      let seconds = Number(value)  / 1000;
      let secondsString = seconds % 60;
      let minutes = Math.floor( (seconds % 3600) / 60 );
      let auxHour = Math.floor( seconds / 3600 );
      const hours = Number(auxHour) % 24;
      const days = Math.floor(Number(auxHour) / 24);

      if(days >= 1){
        if(hours > 0)
          return `${days.toFixed(0)} día(s) y ${hours.toFixed(0)} hrs.`;
        else
          return `${days.toFixed(0)} día(s).`;
      }else{
        if(hours > 0){
          return `${hours.toFixed(0)} hrs. ${minutes} min. ${secondsString} seg.`;
        }else{
          if(minutes > 0){
            return `${minutes} min. ${secondsString} seg.`;
          }else{
            return `${secondsString} seg.`;
          }
        }
      }
    }else{
      return value;
    }
  }

}
