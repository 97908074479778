import { Pipe, PipeTransform } from '@angular/core';
import {User} from "@app/core/models/user.model";
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'sortUserByName'
})
export class SortUserByNamePipe implements PipeTransform {

  transform(values: Array<User>, order?: string): Array<User> {
    if(isNullOrUndefined(order))
      return values;
    if(order == 'asc'){
      return values.sort((a, b) => (a.name > b.name ? -1 : 1));
    }else{
      return values.sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  }
}
