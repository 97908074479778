import {Injectable} from '@angular/core';
// @ts-ignore
import {Config, STYLE, THEME} from "ngx-easy-table";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationTableService {

  public static config: Config = {
    searchEnabled: false,
    headerEnabled: true,
    orderEnabled: true,
    orderEventOnly: false,
    globalSearchEnabled: false,
    paginationEnabled: false,
    exportEnabled: true,
    clickEvent: true,
    selectRow: true,
    selectCol: false,
    selectCell: false,
    rows: 1000,
    additionalActions: false,
    serverPagination: false,
    isLoading: false,
    detailsTemplate: false,
    groupRows: false,
    paginationRangeEnabled: true,
    collapseAllRows: false,
    checkboxes: true,
    resizeColumn: false,
    fixedColumnWidth: true,
    horizontalScroll: true,
    draggable: false,
    logger: false,
    showDetailsArrow: false,
    showContextMenu: false,
    persistState: false,
    paginationMaxSize: 5,
    tableLayout: {
      style: STYLE.NORMAL, // or big or tiny
      theme: THEME.LIGHT, // or dark
      borderless: true,
      hover: true,
      striped: true,
    }
  };
  constructor() { }
}
