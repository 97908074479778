import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {TransitionController} from "ng2-semantic-ui";
import {fadeAnimation} from "@app/animations.animation";

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.css'],
  animations: [fadeAnimation]
})
export class FloatingButtonComponent implements OnInit {

  @Input()
  direction: FbDirection;
  @Input()
  styleClass: string = "circular ui icon animated button red";
  @Input()
  btnIcons: string[] = ["bars icon"];
  @Input()
  size : FbSize = "massive";

  @Input()
  visible: boolean = true;
  @Output()
  action = new EventEmitter();

  @Input()
  customTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

  toogle(){
    this.visible = !this.visible;
  }

  setVisibile(isVisible:boolean){
    this.visible = isVisible;
  }
}

export declare type FbDirection = 'top-left' | 'top-middle' | 'top-right' | 'middle-left' | 'middle-middle'
  | 'middle-right' | 'bottom-left' | 'bottom-middle' | 'bottom-right';
export declare type FbSize = 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big' | 'huge' | 'massive';
