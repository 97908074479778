import {Pipe, PipeTransform} from '@angular/core';
import {Dictionary} from "typescript-collections";
import {isNullOrUndefined} from "util";

@Pipe({
  name: 'positionAttribute'
})
export class PositionAttributePipe implements PipeTransform {

  private _mapKeys: Dictionary<string, string>;
  private _mapValues: Dictionary<string, string>;

  constructor() {
    this._mapKeys = new Dictionary<string, string>();
    this._mapKeys.setValue('distance', 'Distancia');
    this._mapKeys.setValue('totalDistance', 'Distancia Recorrida');
    this._mapKeys.setValue('motion', 'En Movimiento');
    this._mapKeys.setValue('alarm', 'Alarma');
    this._mapKeys.setValue('type', 'Tipo');
    this._mapKeys.setValue('powerRestored', 'Energia Restablecida');
    this._mapKeys.setValue('powerOn', 'Disp. Encendido');
    this._mapKeys.setValue('powerOff', 'Disp. Apagado');
    this._mapKeys.setValue('acceleration', 'Aceleracion');
    this._mapKeys.setValue('odometer', 'Odómetro');
    this._mapKeys.setValue("battery", "Batería");
    this._mapKeys.setValue("batteryLevel", "Nivel de Batería");
    this._mapKeys.setValue("raw", "Bruto");
    this._mapKeys.setValue("index", "Indice");
    this._mapKeys.setValue("hdop", "HDOP");
    this._mapKeys.setValue("vdop", "VDOP");
    this._mapKeys.setValue("pdop", "PDOP");
    this._mapKeys.setValue("sat", "Satelites");
    this._mapKeys.setValue("satVisible", "Satelites Visibles");
    this._mapKeys.setValue("rssi", "RSSI");
    this._mapKeys.setValue("gps", "GPS");
    this._mapKeys.setValue("roaming", "Roaming");
    this._mapKeys.setValue("event", "Evento");
    this._mapKeys.setValue("status", "Estado");
    this._mapKeys.setValue("serviceOdometer", "Odómetro de Mantenimiento");
    this._mapKeys.setValue("tripOdometer", "Odómetro de Viaje");
    this._mapKeys.setValue("hours", "Horas");
    this._mapKeys.setValue("steps", "Pasos");
    this._mapKeys.setValue("input", "Input");
    this._mapKeys.setValue("output", "Output");
    this._mapKeys.setValue("power", "Energía");
    this._mapKeys.setValue("battery", "Batería");
    this._mapKeys.setValue("batteryLevel", "Nivel de Batería");
    this._mapKeys.setValue("fuel", "Combustible");
    this._mapKeys.setValue("fuelConsumption", "Consumo de Combustible");
    this._mapKeys.setValue("versionFw", "Versión de Firmware");
    this._mapKeys.setValue("versionHw", "Versión de Hardware");
    this._mapKeys.setValue("ignition", "Encendido de motor");
    this._mapKeys.setValue("flags", "Banderas");
    this._mapKeys.setValue("charge", "Carga");
    this._mapKeys.setValue("ip", "IP");
    this._mapKeys.setValue("archive", "Archivo");
    this._mapKeys.setValue("rpm", "Rpm");
    this._mapKeys.setValue("vin", "VIN");
    this._mapKeys.setValue("approximate", "Aproximado");
    this._mapKeys.setValue("throttle", "Acelerador");
    this._mapKeys.setValue("armed", "Armado");
    this._mapKeys.setValue("geofence", "Geocerca");
    this._mapKeys.setValue("deviceTemp", "Temperatura del Dispositivo");
    this._mapKeys.setValue("operator", "Operador");
    this._mapKeys.setValue("command", "Comando");
    this._mapKeys.setValue("blocked", "Bloqueado");
    this._mapKeys.setValue("dtcs", "Códigos de Problemas de Diagnóstico");
    this._mapKeys.setValue("obdSpeed", "Velocimetro (Diagnóstico a Bordo)");
    this._mapKeys.setValue("result", "Resultado del Comando");
    this._mapKeys.setValue("driverUniqueId", "Licencia del conductor");
    this._mapKeys.setValue("date", "Fecha");
    this._mapKeys.setValue("movement", "En Movimiento");
    this._mapKeys.setValue("movement", "En Movimiento");
    this._mapKeys.setValue("powerCut", "Se corto el suministro de energía");
    this._mapKeys.setValue("Command RTO accepted", "Comando RTO aceptado");
    //values
    this._mapValues = new Dictionary<string, string>();
  }

  transform(type: any, args?: string): string {
    if(!isNullOrUndefined(args)){
      if(typeof type === "number" ){
        return type.toFixed(2);
      }else if(typeof type === "string" ){
        return this.convertString(type.toLowerCase());
      }else if(typeof type === "boolean" ){
        return type? 'Si':'No';
      }else if(typeof type == "object" ){
        return type.toString();
      }else if(type == undefined || type == null){
        return 'Desconocido';
      }else{
        return type;
      }
    }else {
      return this._mapKeys.getValue(type);
    }
  }

  private convertString(type: string):string {
    //check for date
    if(type.includes("-") && type.includes(":") && type.includes("t")){
      return new Date(type.toUpperCase()).toLocaleDateString();
    }
    switch (type) {
      case 'poweron': return 'Disp. Encendido';
      case 'poweroff': return 'Disp. Apagado';
      case 'powerrestored': return 'Disp. Apagado';
      case 'movement': return 'Inicia Movimiento';
      case 'true': return 'Sí';
      case 'false': return 'No';
      default: return type;
    }
  }

  public getValues(): Array<string>{
    return this._mapKeys.values();
  }

}
